import React from "react";
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';

export default function Preloader() {

	return (
		<>
			<div className="wrapper" id="myscreen">
				<div className="pre-loader-img top-content logincenter"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
			</div>
		</>
	)
}