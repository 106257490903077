import { toast } from 'react-toastify';
import axios from 'axios';
import iconSuccess from './../assets/images/icons8-check-64.png';
import iconWarning from './../assets/images/menu-warning.png';
import iconError from './../assets/images/icons-error-48.png';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import { BLOB_ACCOUNT_IMAGE, BLOB_BOIMAGE_CONTAINER, BLOB_BRAND_IMAGE, BLOB_COMPANY_IMAGE, BLOB_PRODUCT_CONTAINER, BLOB_STAFF_CONTAINER, BLOB_STORE_CONTAINER } from '../config/constant';

export function showSuccessMessage(errorMessage, autoClose = 5000, onClose = () => {}) {
    toast.configure();
    toast.success(errorMessage, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="success" src={iconSuccess} />,
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    })
}

export function showWarningMessage(errorMessage, autoClose = 5000, onClose = () => {}) {
    toast.configure();
    toast.success(errorMessage, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="warning" src={iconWarning} />,
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    })
}

export function showErrorMessage(errorMessage, autoClose = 5000, onClose = () => {}) {
    toast.configure();
    toast.error(errorMessage, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="error" src={iconError} />,
        autoClose: autoClose,
        onClose: () => {
            if (onClose) onClose();
        }
    })
}

export function defaultApiError(error) {
    let message = "An error occurred while trying to process your request.";
    message = error?.message ? `${message} ${error.message}.` : message;

    toast.configure();
    toast.error(message, {
        theme: "dark",
        icon: ({ theme, type }) => <img alt="error" src={iconError} />,
        autoClose: false
    })
}

export function showConfirmationMessage(message, onConfirm = () => {}){
    Swal.fire({
        title: "",
        text: "",
        html: message,
        icon: 'warning',
        showDenyButton: true,
        allowOutsideClick: false,
        confirmButtonColor: '#205380',
        denyButtonColor: '#205380',
        confirmButtonText: 'YES',
        denyButtonText: 'NO',
        background: '#1d2530',
        color: '#fff',
        iconColor: "rgb(255, 153, 0)",
        width: "500px",
        padding: '2em',
        reverseButtons: false
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm();            
        }
    })
}

export function makePostRequest(endpoint, data, useFormData = false, setShowLoader = null, onError = () => {}, onUploadProgress = undefined) {
    return new Promise((resolve, reject) => {
        const api_url = `${process.env.REACT_APP_API_URL}${endpoint}?code=${process.env.REACT_APP_API_CODE}`

        let axiosConfig = !useFormData
            ? {}
            : {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
            };

        if (setShowLoader) setShowLoader(true);

        if (onUploadProgress)
            axiosConfig = {...axiosConfig, onUploadProgress: onUploadProgress};

        axios
            .post(api_url, data, axiosConfig)
            .then((response) => {
                if (response.data?.status === 200) {
                    resolve(response);
                } else {
                    const message = (response.data?.message && typeof (response.data?.message) === 'string') ? response.data?.message : "An error occurred while trying to process your request.";
                    showErrorMessage(message);
                    resolve(response);                        
                }
            })
            .catch((error) => {
                defaultApiError(error);

                onError(error);
                //reject(error);
            }).finally(() => {
                if (setShowLoader) {
                    setTimeout(() => {
                        setShowLoader(false);
                    }, 0);
                }
            });
    });
}

//TODO: makeGetRequest make return promise
export function makeGetRequest(endpoint, data = [], onError = () => {}) {
    let api_url = `${process.env.REACT_APP_API_URL}${endpoint}?code=${process.env.REACT_APP_API_CODE}`;

    // If data is provided, append it as query parameters
    if (data) {
        const params = new URLSearchParams();

        // Loop through the data object and add each key-value pair to the URL
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params.append(key, data[key]);
            }
        }

        // Append the parameters to the URL
        api_url += `&${params.toString()}`;
    }

    return axios
        .get(api_url)
        .then((response) => {
            if (response.status === 200) {
                return response;
            } else {
                throw new Error("Error code " + response.status);
            }
        })
        .catch((error) => {
            defaultApiError(error);

            console.error("API Request Error:", error);
            //throw error;
            onError(error);
        });
}

export function uploadBlobFile(fileBlob, container, setShowLoader = null ) {
    return new Promise((resolve, reject) => {
        if (![
            BLOB_ACCOUNT_IMAGE,
            BLOB_BOIMAGE_CONTAINER,
            BLOB_BRAND_IMAGE,
            BLOB_COMPANY_IMAGE,
            BLOB_COMPANY_IMAGE,
            BLOB_PRODUCT_CONTAINER,
            BLOB_STAFF_CONTAINER,
            BLOB_STORE_CONTAINER
        ].includes(container)){
            reject(`Container ${container} not recognized for uploading files. Try again later.`);
            return;
        }

        if (!fileBlob || !fileBlob.name){
            reject('File selected is not recognized as compatible for upload. Try again later.');
            return;
        }

        const formData = new FormData();
        formData.append("containerName", container);
        formData.append("files", fileBlob);
        formData.append("blobName", fileBlob.name);

        makePostRequest("uploadBlobFile", formData, true, setShowLoader).then(res => {
            resolve(res);
        }).catch(error => reject(error));
    });
};

export async function getUserIP () {
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
};

export function openNewPage(url){
    if (!url){
        showErrorMessage("Can't open new page, URL is not defined.");
        return;
    }

    window.open(url, "_blank");
}

export function showEnv() {
    console.log("Env Vars:", process.env);
}