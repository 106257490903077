import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import * as Library from './../../utils/Library';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";

export default function Billing(props) {
	document.title = 'BOstageB - Select Billing Plan';

	/* #region  STATES */
	const ref = useRef();
	const navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [denied, setDenied] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	const [companyId, setCompanyId] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [data1, setData1] = useState(0);
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				// setTimeout((e)=>{
				if (!_data?.BillingMenu) {

					setPreLoader(false);
					setDenied(true);

				} else {
					setDenied(false);
					setPreLoader(false);
				}
				//	},1000);	
			}
		}

	}, [props.compData]);

	useEffect(() => {

		if (typeof props?.compData?.Companies != 'undefined') {
			setCompanyId(localStorage.getItem('brandID'));
			setCompanyName(localStorage.getItem('brandName'));
		}

	}, [props.compData]);

	useEffect(() => {

		getStoreList();
	}, [])
	/* #endregion */

	/* #region  METHODS */
	const moveTo = (type) => {

		if (type == "store") {
			navigate("/store");
		}
		if (type == "retailer") {
			navigate("/retailer/create")
		}
		if (type == 'boimage') {
			navigate("/boimages/canvas")
		}

	}

	const getStoreList = () => {
		let formData = { brandID: localStorage.getItem("brandID") };
		Library.makePostRequest("get-brand-store-list", formData).then(res => {
			//console.log(res?.data?.data.length);
			setPreLoader(false)
			if (res?.data?.data.length) {
				setData1(1);
			}
		});
	}
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar thankyouwelcomestore">
							<div className="centerwelcome-msg"><span className="brandName-msg">{companyName}</span> is now <span className="brand-status-msg">active</span>. Thank you and welcome!</div>
						</div>

						{(preLoader)
							?
							<div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div className="creators-section billing-page-plans">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="what-to-do-next">
													<h2 className="what-heading">What to do next?</h2>
													<div className="row">
														<div className="col-md-3" onClick={(e) => moveTo('store')} style={{ display: (data1) ? "block" : "none" }} >
															<div className="explore-icons">
																<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
																<i className="fa-light fa-store"></i>
																<h3>Activate Store</h3>
															</div>
														</div>

														<div className="col-md-3" onClick={(e) => moveTo('retailer')}>
															<div className="explore-icons">
																<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
																<i className="fa-light fa-sitemap"></i>
																<h3>Invite Retailers</h3>
															</div>
														</div>

														<div className="col-md-3" onClick={(e) => moveTo('boimage')}>
															<div className="explore-icons">
																<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
																<i className="fa-light fa-user-tag"></i>
																<h3>Create BOimages</h3>
															</div>
														</div>

														<div className="col-md-3">
															<div className="explore-icons">
																<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
																<i className="fa-light fa-user"></i>
																<h3>Create Brand Admins</h3>
															</div>
														</div>
													</div>
													<h2 className="what-heading">Use menu to explore more</h2>
												</div>
											</div>
										</div>

									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	)
}