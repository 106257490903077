import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import * as Library from './../../utils/Library';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
import DataTable, { createTheme } from 'react-data-table-component';
import Select from 'react-select';
import './css/invoiceList.css';

function Account(props) {
    document.title = 'BOstageB - Invoice List';

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();
    
    //VIEW STATES
    const [elementData, setElementData] = useState("");
    const [preLoader, setPreLoader]     = useState(true);

    //COMPANY DATA
    const [compData, setCompData]       = useState({});
    const [companyName, setCompanyName] = useState("");

    //PAYMENTS LIST
    const [invoiceList, setInvoiceList] = useState([]); 
    const [dataLoading, setDataLoading] = useState(false);

    /* #endregion */

    /* #region  CONSTANTS */
    createTheme('solarized', {
        text: {
            primary: '#fff',
            secondary: '#fff',
        },
        background: {
            default: '#10141B',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#333F50',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ':hover': {
                backgroundColor: "#205380",
                color: 'white',
            },
        }),
    };

    const productStylesOptions = [
        {value: 'All', label: 'all'}
    ];

    const columns = [
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '12%',
            cell: (row) => <><span className="store-name2" style={{color: row.status === 'PAID' ? "#00BB00" : "#00AEEF"}} title={row.status}>{row.status}</span></>
        },
        {
            name: 'Amount',
            selector: row => row.amount + " " + row.currency,
            sortable: true,
            width: '12%'
        },
        {
            name: 'Doc Number',
            selector: row => row.docNumber,
            sortable: true,
            width: '12%',
            style: {
                minWidth: '150px'
            }
            
        },
        {
            name: 'For Period',
            selector: row => row.periodLabel,
            sortable: true,
            width: '12%',
            style: {
                minWidth: '150px'
            }
        },
        {
            name: 'Payments',
            selector: row => row.payments,
            sortable: true,
            width: '12%'
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true
        },
        {
            name: 'Action',
            selector: row => row.paymentURL,
            sortable: false,
            cell: (row) => <>
                    <Link className={row.downloadPDF !== null ? "btn btn-success" : "btn btn-success button-not-active"} to="#" onClick={() => {Library.openNewPage(row.downloadPDF);}}>INVOICE</Link>
                    <Link className={row.paymentURL !== null ? "btn btn-success" : "btn btn-success button-not-active"} to="#" onClick={() => {Library.openNewPage(row.paymentURL);}} >{row.status=== "PAID" ? "VIEW" : "PAY" }</Link> 
                 </>,
            width: '130px',
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true
        }
    ];
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        if (props?.compData === '') return;
        if (props?.compData === undefined || typeof props?.compData?.Companies === 'undefined') {
            Library.showErrorMessage("There was an error while trying to retrieve your company data!", false);
            navigate("/");
            return;
        }

        setCompData(props.compData);

        //TODO: select active company brand instead of first
        setCompanyName(props?.compData?.Companies[0].companyName);
    }, [props.compData]);

    useEffect(() => {
        if (props.compData?.companyEmail === undefined) return;

        let filterData = {
            email : props.compData?.companyEmail
        };

        Library.makePostRequest("stripeGetCustomerInvoices", filterData, false, setPreLoader).then(res => {
            if (res === undefined || res.status !== 200 || res?.data === undefined || res?.data?.data === undefined){
                Library.showErrorMessage("Couldn't obtain invoices from server. ");
                setInvoiceList([]);
                return;
            }

            let data = res.data.data;
            setInvoiceList(data);
        }).catch(error => Library.showErrorMessage(error.message));

    }, [props.compData]);
    /* #endregion */

    /* #region  METHODS */
    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>)
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">

                    <div className="controlbar">
                        <div className="productsearch leftsidetr">
                            <Link data-widget="search" className="button-not-active" to={preLoader ? "#" : "#"} role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                <span className="searchprodicttext text-center">Invoices</span>
                            </Link>
                        </div>
                        <div className="centerbtn">
                            <Link to={preLoader ? "#" : "#"} className="btn btn-success active">BILLING</Link>
                        </div>
                        <div className="rightcontrolserch">
                            <div className="productsearch rightareas">
                                <Link data-widget="search" to={preLoader ? "#" : "/billing-plan"} role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-file-invoice-dollar"></i></span>
                                    <span className="searchprodicttext">Plans</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas">
                                <Link to={preLoader ? "#" : "/billing-plan/invoices"} role="button">
                                    <span className="btn btn-success active"><i className="fa-thin fa-file-invoice"></i></span>
                                    <span className="searchprodicttext">Invoices</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {(preLoader)
                        ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                        :
                        <>
                            {(preLoader)
                                ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                                :
                                <>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="labelgreentext billing-for">Applying to company: <span className="itvalue">{companyName}</span></div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="filtersarea">
                                                    <div className="container-fluid filter-container">
                                                        <div className="row" style={{paddingTop: "15px"}}>

                                                            <div className="col-md-6">
                                                                <div className="filter-section">
                                                                        <input className="form-control" style={{marginRight: "8px"}} type="text" placeholder="Search list:" aria-label="Search" disabled='disabled' />

                                                                        <span className="filter-btn-with-label disabled">
                                                                            <button type="button" className="btn btn-success filter-btn disabled">
                                                                                <i className="fa-thin fa-arrow-right"></i>
                                                                            </button>
                                                                            <span className="">Go</span>
                                                                        </span>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="filter-section right">
                                                                        <p>Filters:</p>

                                                                        <Select className={"selects"}
                                                                                options={productStylesOptions}
                                                                                styles={colourStyles}
                                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                                 />
                                                                        
                                                                        <span className="filter-container">
                                                                            <span className="filter-btn-with-label">
                                                                                <button type="button" className="btn btn-success filter-btn"><i className="fa-thin fa-rotate-left"></i></button>
                                                                                <span className="">Reset</span>
                                                                            </span>
                                                                            <span className="filter-btn-with-label disabled">
                                                                                <button type="button" className="btn btn-success filter-btn"><i className="fa-thin fa-arrow-right"></i></button>
                                                                                <span className="">Go</span>
                                                                            </span>
                                                                            <span className="filter-btn-with-label disabled">
                                                                                <button type="button" className="btn btn-success filter-btn"><i className="fa-thin fa-arrows-rotate"></i></button>
                                                                                <span className="">Refresh</span>
                                                                            </span>
                                                                        </span>
                                                                </div>
                                                            </div>
                                                                
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tablestructure productPages">
                                                    <DataTable
                                                        className="showItems"
                                                        columns={columns}
                                                        data={invoiceList}
                                                        theme="solarized"
                                                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true" style={{marginLeft: "5px"}}></i>}
                                                        progressPending={dataLoading}
                                                        selectableRows
                                                        //selectableRowsComponent={Checkbox}
                                                        defaultSortFieldId={1}
                                                        defaultSortAsc={true}
                                                        responsive={true}
                                                        //expandableRowsComponent={ExpandedComponent}
                                                        progressComponent={<CustomLoader />}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                    }
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    )
}

export default Account;