import React, {useRef, useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Tsbrand from './../../../assets/images/uploads/brandlogs.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import LoaderIcon from './../../../assets/images/loader-icon.png';
function Index() {
	const { processId } = useParams();
	const [resData, setResData] = useState(null);
	const [resS, setResS] = useState(0);
	const [ratio, setRatio] = useState({h:0, w:0})
	const [renderData, setRenderData] = useState("");
	const [showLoader, setShowLoader] = useState(0);
	const [preLoader, setPreLoader] = useState(true);
	const targetImageRef = useRef();
	useEffect(() => {
		getPublisherData(processId);
	},[])
	const getPublisherData = async (id) => {
		let formData = {
			procressedId: processId
		}
		await axios.post(process.env.REACT_APP_API_URL+"getPublisherUrl?code="+process.env.REACT_APP_API_CODE,formData).then((res)=>{
			if(res.data.status==200){
				//console.log(res.data.data);
				setShowLoader(1);
				setPreLoader(false);
				setResData(res.data.data);
				setRenderData(res.data.data.renderedIamge)
			}else{
				setResData(null)
				setResS(1);
				setPreLoader(false);
				setShowLoader(1);
			}
		});
	}
	const onImgLoad = (e) => {
		let height = targetImageRef.current.clientHeight/renderData?.height;
		let width = targetImageRef.current.clientWidth/renderData?.width;
		setRatio({h:height, w:width});
	}
	return(
	
			<>
			 <div className="wrappers">
			  <div className="container-fluid">
			    <div className="row">
					{/* <div className="col-md-12" style={{"display":(showLoader)?"none":"block"}}>
								<div className="loaderclass">
									<i className="fa fa-spinner fa-spin"></i>
								</div>
					</div> */}
				{(preLoader)?<div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:
			      <div className="col-md-12 no-padding">
			      {
			      	(resData)?
			      	<div className="singleimageview">
			      	<div className="sourceimgandname">

					<div className="imageupdates">
						<div  className="image-drag">
			      	<figure style={{"position":"relative"}}>
			          <img src={resData.imageURL} ref={targetImageRef} onLoad={onImgLoad}/>
			          {
						resData?.BOtags?.map((r, i) => {
							return(
								<div key={i} id={i+1} style={{"position":"absolute","zIndex":"1","left":(r.beaconXposition*ratio.w)+"px", "top":(r.beaconYposition*ratio.h)+"px"}}>
									<div className="custom-className itslinked">
										{/* <div className="boxonimg" style={{"left":(r.tagXposition*ratio.w)+"px", "top":(r.tagYposition*ratio.h)+"px"}}>
											<div className="imgbrand"><img alt="tshirt" draggable="false" src={Tsbrand} /></div>
											<div className="imgbrandtitle">{r.productName}</div>
											<div className="size-color">
												<span className="sizecth"><span className="sizeklabel">Size</span><span className="size">{r.size}</span></span>
												<span className="sizecth"><span className="sizeklabel">Color</span><span className="color">{r.color}</span></span>
											</div>
											<div className="linedtext">Linked</div>
											<div className="delivery-option"><span className="notlinkeddatared"></span>-</div>
										</div> */}
									<div className="shownumber">{r.BotagId}</div>
								</div>
								</div>
							)
						})
					}
					
					</figure>
					</div>
					</div>
					</div>		          
			        </div> 
			        :
			        <div className="invalidUrl" style={{"display":(resS)?"block":"none"}}>Invalid Publisher URL</div>
			      }
				
			               
			      </div>
				}
			    </div>
			  </div>

			</div>
		</>
				
	)
}
export default Index;