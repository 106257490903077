import update from "immutability-helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { read, utils } from "xlsx";
import "./../../assets/css/checkbox.css";
import LoaderIcon from "./../../assets/images/loader-icon.png";
import LoaderSvg from "./../../assets/images/loader.svg";
import BrandAside from "../layouts/BrandAside.js";
import BrandNavigation from "../layouts/BrandDashboardHeader.js";
import BrandFooter from "../layouts/BrandFooter.js";
import { Box } from "../brand/products/Box.js";
import { Dustbin } from "../brand/products/Dustbin.js";
import Select from "react-select";
import * as Library from "../../utils/Library.js";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

createTheme("solarized", {
    text: {
        primary: "#fff",
        secondary: "#fff",
    },
    background: {
        default: "#10141B",
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF",
    },
    divider: {
        default: "#333F50",
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
    },
}, "dark");

/* #region  SCOPED CSS */
const StyledPage = styled.div`

label[for=tobrand]{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.perfom-input.recommended {
    border: 1px solid #10141b;
    border-left: 5px solid #00aeef;
}

.fieldfouds {
    height: 645px !important;
}

.products-col > h4 {
    width: 200%;
}

.less-padding {
    padding-left: 7px;
    padding-right: 7px;
}

.formdivisions .col-md-4 {
    width:20% !important;
}

.formdivisions .col-md-1 {
    width:10% !important;
}

.form-group > .products-col {
    height: 45px;
}

.import-fields-group .perfom-input {
    display: inline-flex !important;
    font-size: 12px;
    width: 100%;
}

.import-fields-group .field-label {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 4px;
    align-self: center;
}

.import-fields-group .removethis-entry {
    align-self: center;
}

.perform-input.fileimport-left-select{
    width: 200px;
    padding: 0;
    float: left;
    position: absolute;
    top: 12px;
    border: 1px solid #333F50;
    border-left: 5px solid #FF9900;
    border-radius: 5px;
    color: #D1D1D1;
    font-size: 12px;
    font-weight: 400;
}

.perform-input.fileimport-left-select .selects >div {
    height: 37px;
}
`;
/* #endregion */

function Dashboard(props) {
    document.title = "BOstageB - Product Importer";

    const obj = {};

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const [elementData, setElementData] = useState("");
    const [progressBar, setProgressBar] = useState(0);
    const [brandID, setBrandID] = useState("");
    const [storeID, setStoreID] = useState("");
    const [storesOptions, setStoresOptions] = useState([]);
    const [errorStoreID, setErrorStoreID] = useState(false);
    const { allUserInfo } = useSelector((state) => state.userData);

    const [preLoader, setPreLoader] = useState(true);
    const [onSubmitLoader, setOnSubmitLoader] = useState(false);
    const [loadingStores, setLoadingStores] = useState(false);

    const [filePreviewButtonDisable, setFilePreviewButtonDisable] = useState(true);
    const [saveButtonDisable, setSaveButtonDisable] = useState(true);
    const [productList, setProductList] = useState([]);
    const [fileName, setFileName] = useState("");
    const [detectedFields, setDetectedFields] = useState([]);
    const [draggedData, setDraggedData] = useState([]);
    const [previousPosData, setPreviousPosData] = useState([]);
    const [chevShow, setChevShow] = useState(0);
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [temD, setTemD] = useState([]);

    const [importerFields, setImporterFields] = useState([]);

    const [formDataFinal, setFormDataFinal] = useState([]);
    const [readyToUpload, setReadyToUpload] = useState(false);

    const [droppedBoxNames, setDroppedBoxNames] = useState([]);

    const dustBinsTitles = [
        "Order nr.", "Order date", "Delivery window", "Collection name", "Season", "Total items", "VAT%", "Total incl. VAT",
        "Order ID", "Order type", "Estim. del. date", "Collection code", "Season code", "Currency", "Total VAT", "Total excl. VAT",
        "Barcode", "Style nr.", "Category", "Category code", "Short description", "Net weight", "Country of origin", "Country code",
        "Style name", "Reference", "SKU", "Sub-categ.", "Description", "Gross weight", "Tariff code", "Gender",
        "Material 1", "Material 3", "Material 5", "Material 7", "Care 1", "Care 3", "Care 5", "Care 7",
        "Material 2", "Material 4", "Material 6", "Material 8", "Care 2", "Care 4", "Care 6", "Care 8",
        "Size 1", "Size 3", "Color 1 name", "Color 1 code", "Color 2 name", "Color 2 code", "Color 3 name", "Color 3 code",
        "Size 2", "Size 4", "Color 1 description", "Color 1 hexa code", "Color 2 description", "Color 2 hexa code", "Color 3 description", "Color 3 hexa code"
    ];

    const dustBinsLabels = [
        "Order nr", "Order date", "Delivery window", "Collection name", "Season", "Total items", "VAT Perc", "Total inc Vat",
        "Order ID", "Order type", "Estim del date", "Collection code", "Season code", "Currency", "Total VAT", "Total exc Vat",
        "Barcode", "Style nr", "Category", "Category code", "Short description", "Net weight", "Country of origin", "Country code",
        "Style name", "Reference", "SKU", "Sub-categ", "Description", "Gross weight", "Tariff code", "Gender",
        "Material 1", "Material 3", "Material 5", "Material 7", "Care 1", "Care 3", "Care 5", "Care 7",
        "Material 2", "Material 4", "Material 6", "Material 8", "Care 2", "Care 4", "Care 6", "Care 8",
        "Size 1", "Size 3", "Color 1 name", "Color 1 code", "Color 2 name", "Color 2 code", "Color 3 name", "Color 3 code",
        "Size 2", "Size 4", "Color 1 description", "Color 1 hexa code", "Color 2 description", "Color 2 hexa code", "Color 3 description", "Color 3 hexa code"
    ];

    const [dustbins0, setDustbins0] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[0] }]);
    const [dustbins1, setDustbins1] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[1] }]);
    const [dustbins2, setDustbins2] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[2] }]);
    const [dustbins3, setDustbins3] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[3] }]);
    const [dustbins4, setDustbins4] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[4] }]);
    const [dustbins5, setDustbins5] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[5] }]);
    const [dustbins6, setDustbins6] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[6] }]);
    const [dustbins7, setDustbins7] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[7] }]);
    const [dustbins8, setDustbins8] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[8] }]);
    const [dustbins9, setDustbins9] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[9] }]);
    const [dustbins10, setDustbins10] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[10] }]);
    const [dustbins11, setDustbins11] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[11] }]);
    const [dustbins12, setDustbins12] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[12] }]);
    const [dustbins13, setDustbins13] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[13] }]);
    const [dustbins14, setDustbins14] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[14] }]);
    const [dustbins15, setDustbins15] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[15] }]);
    const [dustbins16, setDustbins16] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[16] }]);
    const [dustbins17, setDustbins17] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[17] }]);
    const [dustbins18, setDustbins18] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[18] }]);
    const [dustbins19, setDustbins19] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[19] }]);
    const [dustbins20, setDustbins20] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[20] }]);
    const [dustbins21, setDustbins21] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[21] }]);
    const [dustbins22, setDustbins22] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[22] }]);
    const [dustbins23, setDustbins23] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[23] }]);
    const [dustbins24, setDustbins24] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[24] }]);
    const [dustbins25, setDustbins25] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[25] }]);
    const [dustbins26, setDustbins26] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[26] }]);
    const [dustbins27, setDustbins27] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[27] }]);
    const [dustbins28, setDustbins28] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[28] }]);
    const [dustbins29, setDustbins29] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[29] }]);
    const [dustbins30, setDustbins30] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[30] }]);
    const [dustbins31, setDustbins31] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[31] }]);
    const [dustbins32, setDustbins32] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[32] }]);
    const [dustbins33, setDustbins33] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[33] }]);
    const [dustbins34, setDustbins34] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[34] }]);
    const [dustbins35, setDustbins35] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[35] }]);
    const [dustbins36, setDustbins36] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[36] }]);
    const [dustbins37, setDustbins37] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[37] }]);
    const [dustbins38, setDustbins38] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[38] }]);
    const [dustbins39, setDustbins39] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[39] }]);
    const [dustbins40, setDustbins40] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[40] }]);
    const [dustbins41, setDustbins41] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[41] }]);
    const [dustbins42, setDustbins42] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[42] }]);
    const [dustbins43, setDustbins43] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[43] }]);
    const [dustbins44, setDustbins44] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[44] }]);
    const [dustbins45, setDustbins45] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[45] }]);
    const [dustbins46, setDustbins46] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[46] }]);
    const [dustbins47, setDustbins47] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[47] }]);
    const [dustbins48, setDustbins48] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[48] }]);
    const [dustbins49, setDustbins49] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[49] }]);
    const [dustbins50, setDustbins50] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[50] }]);
    const [dustbins51, setDustbins51] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[51] }]);
    const [dustbins52, setDustbins52] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[52] }]);
    const [dustbins53, setDustbins53] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[53] }]);
    const [dustbins54, setDustbins54] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[54] }]);
    const [dustbins55, setDustbins55] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[55] }]);
    const [dustbins56, setDustbins56] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[56] }]);
    const [dustbins57, setDustbins57] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[57] }]);
    const [dustbins58, setDustbins58] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[58] }]);
    const [dustbins59, setDustbins59] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[59] }]);
    const [dustbins60, setDustbins60] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[60] }]);
    const [dustbins61, setDustbins61] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[61] }]);
    const [dustbins62, setDustbins62] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[62] }]);
    const [dustbins63, setDustbins63] = useState([{ accepts: ["text"], lastDroppedItem: null, label: dustBinsLabels[63] }]);

    const [fieldRequired1, setFieldRequired1] = useState(false);
    const [fieldRequired2, setFieldRequired2] = useState(false);
    const [fieldRequired3, setFieldRequired3] = useState(false);
    const [fieldRequired4, setFieldRequired4] = useState(false);
    const [fieldRequired5, setFieldRequired5] = useState(false);
    const [fieldRequired6, setFieldRequired6] = useState(false);
    const [fieldRequired7, setFieldRequired7] = useState(false);
    const [fieldRequired8, setFieldRequired8] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [dataRowCount, setDataRowCount] = useState(10);

    const [isPosUpload, setIsPosUpload] = useState(false);
    const [removePosSections, setRemovePosSections] = useState(true);

    //FORM STATES
    const [posProvider, setPosProvider] = useState("");
    const [posProviderError, setPosProviderError] = useState(false);
    const [defaultPOSProvider, setDefaultPOSProvider] = useState("");
    const [allPOSOptions, setAllPOSOptions] = useState({});
    const [posSectionVisible, setPosSectionVisible] = useState(false);
    const [posName, setPosName] = useState("");
    const [buttonGray, setButtonGray] = useState(true);

    const [currentPOSData, setCurrentPOSData] = useState({});
    const [allPOSData, setAllPOSData] = useState({});
    const [connectFields, setConnectFields] = useState([]);
    const [allPosFormFields, setAllPosFormFields] = useState([]);
    const [formFieldErrorIndex, setFormFieldErrorIndex] = useState([]);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setPreLoader(false);
        setElementData(ref.current);
        setBrandID(localStorage.getItem("brandID"));

        getStoresConnectedFFCByBrand(localStorage.getItem("brandID"));
    }, [localStorage.getItem("brandID")]);

    useEffect(() => {
        if (!readyToUpload || !formDataFinal) return;
        SaveDistribution();
    }, [readyToUpload, formDataFinal]);

    /*useEffect(() => {
        setPreviousPosData(previousPosData);
    }, [previousPosData]);*/

    /*useEffect(() => {
        console.log("setDetectedFields ");
        setDetectedFields(detectedFields);
    }, [detectedFields]);*/

    useEffect(() => {
        //TODO: THIS SEEMS TO DO NOTHING AT ALL
        temD.map((item, index) => {
            const inss = detectedFields.map(e => e.name).indexOf(item?.itemName);
            item.leftItemIndex = inss;
            return item;
        });
    }, [temD]);

    /* eslint-disable no-useless-computed-key */
    useEffect(() => {
        if (previousPosData.length && detectedFields.length) {
            previousPosData.forEach((item, index) => {
                const ins = detectedFields.map(item => item?.name).indexOf(item?.itemName);
                if (item.itemName === detectedFields[ins]?.name) {
                    const itemName = { name: item?.itemName };

                    setDroppedBoxNames(
                        update(droppedBoxNames, item?.boxName ? { $push: [item?.boxName] } : { $push: [] }),
                    );

                    if (item.boxName === dustBinsLabels[0]) {
                        setDustbins0(
                            update(dustbins0, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[1]) {
                        setDustbins1(
                            update(dustbins1, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[2]) {
                        setDustbins2(
                            update(dustbins2, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[3]) {
                        setDustbins3(
                            update(dustbins3, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[4]) {
                        setDustbins4(
                            update(dustbins4, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[5]) {
                        setDustbins5(
                            update(dustbins5, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[6]) {
                        setDustbins6(
                            update(dustbins6, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[7]) {
                        setDustbins7(
                            update(dustbins7, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[8]) {
                        setDustbins8(
                            update(dustbins8, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[9]) {
                        setDustbins9(
                            update(dustbins9, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[10]) {
                        setDustbins10(
                            update(dustbins10, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[11]) {
                        setDustbins11(
                            update(dustbins11, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[12]) {
                        setDustbins12(
                            update(dustbins12, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[13]) {
                        setDustbins13(
                            update(dustbins13, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[14]) {
                        setDustbins14(
                            update(dustbins14, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[15]) {
                        setDustbins15(
                            update(dustbins15, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[16]) {
                        setDustbins16(
                            update(dustbins16, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[17]) {
                        setDustbins17(
                            update(dustbins17, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[18]) {
                        setDustbins18(
                            update(dustbins18, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[19]) {
                        setDustbins19(
                            update(dustbins19, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[20]) {
                        setDustbins20(
                            update(dustbins20, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[21]) {
                        setDustbins21(
                            update(dustbins21, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[22]) {
                        setDustbins22(
                            update(dustbins22, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[23]) {
                        setDustbins23(
                            update(dustbins23, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[24]) {
                        setDustbins24(
                            update(dustbins24, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[25]) {
                        setDustbins25(
                            update(dustbins25, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[26]) {
                        setDustbins26(
                            update(dustbins26, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }

                    if (item.boxName === dustBinsLabels[27]) {
                        setDustbins27(
                            update(dustbins27, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[28]) {
                        setDustbins28(
                            update(dustbins28, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[29]) {
                        setDustbins29(
                            update(dustbins29, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[30]) {
                        setDustbins30(
                            update(dustbins30, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[31]) {
                        setDustbins31(
                            update(dustbins31, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[32]) {
                        setDustbins32(
                            update(dustbins32, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[33]) {
                        setDustbins33(
                            update(dustbins33, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[34]) {
                        setDustbins34(
                            update(dustbins34, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[35]) {
                        setDustbins35(
                            update(dustbins35, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[36]) {
                        setDustbins36(
                            update(dustbins36, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[37]) {
                        setDustbins37(
                            update(dustbins37, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[38]) {
                        setDustbins38(
                            update(dustbins38, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[39]) {
                        setDustbins39(
                            update(dustbins39, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[40]) {
                        setDustbins40(
                            update(dustbins40, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[41]) {
                        setDustbins41(
                            update(dustbins41, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[42]) {
                        setDustbins42(
                            update(dustbins42, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[43]) {
                        setDustbins43(
                            update(dustbins43, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[44]) {
                        setDustbins44(
                            update(dustbins44, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[45]) {
                        setDustbins45(
                            update(dustbins45, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[46]) {
                        setDustbins46(
                            update(dustbins46, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[47]) {
                        setDustbins47(
                            update(dustbins47, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[48]) {
                        setDustbins48(
                            update(dustbins48, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[49]) {
                        setDustbins49(
                            update(dustbins49, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[50]) {
                        setDustbins50(
                            update(dustbins50, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[51]) {
                        setDustbins51(
                            update(dustbins51, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[52]) {
                        setDustbins52(
                            update(dustbins52, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[53]) {
                        setDustbins53(
                            update(dustbins53, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[54]) {
                        setDustbins54(
                            update(dustbins54, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[55]) {
                        setDustbins55(
                            update(dustbins55, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[56]) {
                        setDustbins56(
                            update(dustbins56, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[57]) {
                        setDustbins57(
                            update(dustbins57, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[58]) {
                        setDustbins58(
                            update(dustbins58, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[59]) {
                        setDustbins59(
                            update(dustbins59, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[60]) {
                        setDustbins60(
                            update(dustbins60, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[61]) {
                        setDustbins61(
                            update(dustbins61, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[62]) {
                        setDustbins62(
                            update(dustbins62, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                    if (item.boxName === dustBinsLabels[63]) {
                        setDustbins63(
                            update(dustbins63, {
                                [0]: {
                                    lastDroppedItem: {
                                        $set: itemName,
                                    },
                                    label: { $set: item.boxName }
                                },
                            }),
                        );
                    }
                }
            });
        }
    }, [previousPosData, detectedFields]);

    useEffect(() => {
        if (isPosUpload)
            if (_.isEmpty(allPOSData)) getAllPOS();
    }, [isPosUpload]);

    useEffect(() => {
        handleConnectFieldsValidation();
    }, [allPosFormFields]);
    /* #endregion */

    /* #region  METHODS */
    const handleStoreChange = (e) => {
        setErrorStoreID(false);
        setStoreID({ value: e.value, label: e.label });
    };

    const handleConnectFieldChange = (e, key) => {
        const tmp = [...allPosFormFields];
        tmp[key] = e.target.value;
        setAllPosFormFields(tmp);

    };

    const handleConnectFieldsValidation = () => {
        let formIsValid = true;
        if (!posProvider) {
            setPosProviderError(true);
            formIsValid = false;
            setButtonGray(true);
        }

        if (connectFields.length !== allPosFormFields.length) {
            formIsValid = false;
            Library.showErrorMessage("Please check if all fields are correct from configure POS Section and try again.");
            setButtonGray(true);
            return false;
        }

        const formFieldErrors = [];
        for (let i = 0; i < allPosFormFields.length; i++) {
            const field = allPosFormFields[i];

            if (!field) {
                formIsValid = false;
                formFieldErrors.push(i);
            }
        }
        setFormFieldErrorIndex(formFieldErrors);
        setButtonGray(!formIsValid);
        return formIsValid;
    };

    const handleConnectBtn = async (e) => {
        e.preventDefault();
        Library.showWarningMessage("Connecting to POS for importing order list is not yet supported!");
        return;

        // eslint-disable-next-line no-unreachable
        if (handleConnectFieldsValidation()) {

            const formJson = {
                //"storeID": storeID, 
                "POS_ID": posProvider
            };

            const connectFieldsArray = allPosFormFields.map((field, index) => {
                const tmp = {};
                tmp[connectFields[index].name] = field;
                return tmp;
            });

            formJson.connectFields = connectFieldsArray;

            Library.makePostRequest("productImporterByAPI", formJson, false, setPreLoader).then(res => {
                if (res.data.status !== 200) return;

                const fields = res.data?.data?.keys?.map(item => ({ "name": item, "type": "text" }));
                const products = res.data?.data?.products;

                setDetectedFields(fields);
                setProductList(products);
                setFilePreviewButtonDisable(false);
                setSaveButtonDisable(false);
                handleToogleIsPOSUpload(undefined);

                Library.showSuccessMessage("Successfully connected to POS.");

                brandInfo();
            });
        }
    };

    const getAllPOS = () => {
        Library.makePostRequest("getAllPOS", {}, false, setPreLoader).then(res => {
            if (res.data.status === 200) {

                const data = res.data.data;
                const items = [{ value: "", label: "None" }];
                data.forEach((item) => {
                    items.push({ value: item._id, label: item.name });
                });

                setAllPOSOptions(items);
                setAllPOSData(data);
            }
        });
    };

    const getStoresConnectedFFCByBrand = (brandID) => {
        Library.makePostRequest("getStoresConnectedFFCByBrand", { labelBrandOurID: brandID }, false, setLoadingStores).then(res => {
            if (res.data.status === 200) {

                const data = res.data.data;
                const items = [];
                data.forEach((item) => {
                    items.push({ value: item.ourID, label: item.name });
                });

                setStoresOptions(items);
            }
        });
    };

    const handleSelectPOSProvider = (e) => {
        setPosSectionVisible(e.value.length > 0);
        if (!e.value) {
            setDefaultPOSProvider("");
            setPosProvider("");
            setPosProviderError(true);
            return;
        }

        setDefaultPOSProvider(e);
        setPosProvider(e.value);
        setPosProviderError(false);

        setPosName(e.label);
        const match = allPOSData.filter(item => item._id === e.value);
        if (!match) {
            Library.showErrorMessage("We couldn't find this POS provider in the Database. Try again later!");
            setPosSectionVisible(false);
            return;
        }

        const posData = match[0];
        const connectFields = posData.connectFields.filter(field => !field?.storeOnlyField);
        setCurrentPOSData(posData);
        setConnectFields(connectFields);

        setAllPosFormFields(Array(connectFields.length));
    };

    const handleToogleIsPOSUpload = (e) => {
        const showPOSSections = !isPosUpload;
        setIsPosUpload(showPOSSections);

        if (!showPOSSections) {
            setTimeout(() => {
                setRemovePosSections(true);
            }, 300);
        }
        else setRemovePosSections(false);
    };

    const fullScreenCheck = (e) => {
        const fulls = localStorage.getItem("fullscreen");
        if (fulls === "full") {
            document.body.requestFullscreen();
        }
    };

    const readImporterFile = (e) => {
        setPreviousPosData([]);
        const files = e.target.files;
        if (files.length) {
            setTemD([]);

            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const detectedHeaders = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        header: 1,
                        defval: "",
                        blankrows: false
                    });

                    const detectedData = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        defval: "",
                        blankrows: false
                    });

                    const customHeader = [];

                    detectedHeaders[0].sort().forEach((item, index) => {
                        customHeader.push({ "name": item, "type": "text" });
                    });
                    setDetectedFields(customHeader);
                    setProductList(detectedData);
                    setFilePreviewButtonDisable(false);
                    setSaveButtonDisable(false);
                }
            };
        }
    };

    const isDropped = (boxName) => {
        return droppedBoxNames.indexOf(boxName) > -1;
    };

    const fileList = (index, item, label) => {
        setTemD(temD => [...temD, { leftItemIndex: index, boxName: label, itemName: item.name }]);

        const filterData = importerFields.filter(r => { return r.boxName !== label; });
        filterData.push({ leftItemIndex: index, boxName: label, itemName: item.name });
        setImporterFields(filterData);
    };

    const rmvData = (label) => {
        const dat = importerFields.filter((e) => {
            if (e.boxName !== label) {
                return e;
            }
        });
        setImporterFields(dat);
    };

    /* #region  HANDLE DROP */
    const handleDrop0 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins0(
                update(dustbins0, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins0],
    );

    const handleDrop1 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins1(
                update(dustbins1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins1],
    );

    const handleDrop2 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins2(
                update(dustbins2, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins2],
    );

    const handleDrop3 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins3(
                update(dustbins3, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins3],
    );

    const handleDrop4 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins4(
                update(dustbins4, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins4],
    );

    const handleDrop5 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins5(
                update(dustbins5, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins5],
    );

    const handleDrop6 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins6(
                update(dustbins6, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins6],
    );

    const handleDrop7 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins7(
                update(dustbins7, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins7],
    );

    const handleDrop8 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins8(
                update(dustbins8, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins8],
    );

    const handleDrop9 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins9(
                update(dustbins9, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins9],
    );

    const handleDrop10 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins10(
                update(dustbins10, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins10],
    );

    const handleDrop11 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins11(
                update(dustbins11, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins11],
    );

    const handleDrop12 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins12(
                update(dustbins12, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins12],
    );

    const handleDrop13 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins13(
                update(dustbins13, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins13],
    );

    const handleDrop14 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins14(
                update(dustbins14, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins14],
    );

    const handleDrop15 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins15(
                update(dustbins15, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins15],
    );

    const handleDrop16 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins16(
                update(dustbins16, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins16],
    );

    const handleDrop17 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins17(
                update(dustbins17, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins17],
    );

    const handleDrop18 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins18(
                update(dustbins18, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins18],
    );

    const handleDrop19 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins19(
                update(dustbins19, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins19],
    );

    const handleDrop20 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins20(
                update(dustbins20, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins20],
    );

    const handleDrop21 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins21(
                update(dustbins21, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins21],
    );

    const handleDrop22 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins22(
                update(dustbins22, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins22],
    );

    const handleDrop23 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins23(
                update(dustbins23, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins23],
    );

    const handleDrop24 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins24(
                update(dustbins24, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins24],
    );

    const handleDrop25 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins25(
                update(dustbins25, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins25],
    );

    const handleDrop26 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins26(
                update(dustbins26, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins26],
    );

    const handleDrop27 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins27(
                update(dustbins27, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins27],
    );

    const handleDrop28 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins28(
                update(dustbins28, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins28],
    );

    const handleDrop29 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins29(
                update(dustbins29, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins29],
    );

    const handleDrop30 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins30(
                update(dustbins30, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins30],
    );

    const handleDrop31 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins31(
                update(dustbins31, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins31],
    );

    const handleDrop32 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins32(
                update(dustbins32, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins32],
    );

    const handleDrop33 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins33(
                update(dustbins33, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins33],
    );

    const handleDrop34 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins34(
                update(dustbins34, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins34],
    );

    const handleDrop35 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins35(
                update(dustbins35, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins35],
    );

    const handleDrop36 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins36(
                update(dustbins36, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins36],
    );

    const handleDrop37 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins37(
                update(dustbins37, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins37],
    );

    const handleDrop38 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins38(
                update(dustbins38, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins38],
    );

    const handleDrop39 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins39(
                update(dustbins39, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins39],
    );

    const handleDrop40 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins40(
                update(dustbins40, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins40],
    );

    const handleDrop41 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins41(
                update(dustbins41, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins41],
    );

    const handleDrop42 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins42(
                update(dustbins42, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins42],
    );

    const handleDrop43 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins43(
                update(dustbins43, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins43],
    );

    const handleDrop44 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins44(
                update(dustbins44, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins44],
    );

    const handleDrop45 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins45(
                update(dustbins45, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins45],
    );

    const handleDrop46 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins46(
                update(dustbins46, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins46],
    );

    const handleDrop47 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins47(
                update(dustbins47, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins47],
    );

    const handleDrop48 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins48(
                update(dustbins48, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins48],
    );

    const handleDrop49 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins49(
                update(dustbins49, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins49],
    );

    const handleDrop50 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins50(
                update(dustbins50, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins50],
    );

    const handleDrop51 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins51(
                update(dustbins51, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins51],
    );

    const handleDrop52 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins52(
                update(dustbins52, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins52],
    );

    const handleDrop53 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins53(
                update(dustbins53, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins53],
    );

    const handleDrop54 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins54(
                update(dustbins54, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins54],
    );

    const handleDrop55 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins55(
                update(dustbins55, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins55],
    );

    const handleDrop56 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins56(
                update(dustbins56, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins56],
    );

    const handleDrop57 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins57(
                update(dustbins57, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins57],
    );

    const handleDrop58 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins58(
                update(dustbins58, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins58],
    );

    const handleDrop59 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins59(
                update(dustbins59, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins59],
    );

    const handleDrop60 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins60(
                update(dustbins60, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins60],
    );

    const handleDrop61 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins61(
                update(dustbins61, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins61],
    );

    const handleDrop62 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins62(
                update(dustbins62, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins62],
    );

    const handleDrop63 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins63(
                update(dustbins63, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins63],
    );
    /* #endregion */

    const arr = [];

    const clear = ((ele, lbl) => {
        const item = ele;
        const index = droppedBoxNames.indexOf(item);
        droppedBoxNames.splice(index, 1);

        setDroppedBoxNames(
            update(droppedBoxNames, item ? { $push: [""] } : { $push: [] }),
        );
        // console.log("previousArray - "+ JSON.stringify(arr));
        let lblRm = lbl.split(" ").join("_");
        lblRm = lblRm.split("/").join("_");

        const inx = arr.findIndex(ar => ar[lblRm]);
        arr.splice(inx, 1);
        //console.log("AfterDelArray - "+ JSON.stringify(arr));

        rmvData(lbl);
    });

    const arrData = ((inx, val) => {
        //  console.log("arrData",inx,val);
        const ob = cunstObj(inx, val);
        arr.push(ob);
        setDraggedData(JSON.stringify(arr));
    });

    const cunstObj = ((inx, val) => {
        //console.log("val",typeof val);
        let value = val?.toString()?.split(" ")?.join("_");
        value = value?.split("/")?.join("_");
        let indx = inx?.split(" ")?.join("_");
        indx = indx?.split("/")?.join("_");
        return { [indx]: value };
    });

    /**************save data************** */

    const searchDataInDB = () => {
        const draggedFieldsNames = {};
        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);

            jsonObjectArray.forEach(item => {
                const key = Object.keys(item);
                const value = Object.values(item);
                draggedFieldsNames[key] = value[0];
            });
        }
        //console.log("draggedFieldsNames", draggedFieldsNames);

        const formData = [];
        const errorsInfo = [];
        productList.forEach((value, index) => {
            try {

                const postData = {
                    "Order_nr": (draggedFieldsNames.Order_nr) ? value[draggedFieldsNames.Order_nr]?.toString() : "",
                    "Order_ID": (draggedFieldsNames.Order_ID) ? value[draggedFieldsNames.Order_ID]?.toString() : "",
                    "Order_date": (draggedFieldsNames.Order_date) ? value[draggedFieldsNames.Order_date]?.toString() : "",
                    "Order_type": (draggedFieldsNames.Order_type) ? value[draggedFieldsNames.Order_type]?.toString() : "",
                    "Delivery_window": (draggedFieldsNames.Delivery_window) ? value[draggedFieldsNames.Delivery_window]?.toString() : "",
                    "Estim_del_date": (draggedFieldsNames.Estim_del_date) ? value[draggedFieldsNames.Estim_del_date]?.toString() : "",
                    "Collection_name": (draggedFieldsNames.Collection_name) ? value[draggedFieldsNames.Collection_name]?.toString() : "",
                    "Collection_code": (draggedFieldsNames.Collection_code) ? value[draggedFieldsNames.Collection_code]?.toString() : "",
                    "Season": (draggedFieldsNames.Season) ? value[draggedFieldsNames.Season]?.toString() : "",
                    "Season_code": (draggedFieldsNames.Season_code) ? value[draggedFieldsNames.Season_code]?.toString() : "",

                    "Total_items": (draggedFieldsNames.Total_items) ? value[draggedFieldsNames.Total_items]?.toString() : "",
                    "Currency": (draggedFieldsNames.Currency) ? value[draggedFieldsNames.Currency]?.toString() : "",
                    "VAT_Perc": (draggedFieldsNames.VAT_Perc) ? value[draggedFieldsNames.VAT_Perc]?.toString() : "",
                    "Total_VAT": (draggedFieldsNames.Total_VAT) ? value[draggedFieldsNames.Total_VAT]?.toString() : "",
                    "Total_inc_Vat": (draggedFieldsNames.Total_inc_Vat) ? value[draggedFieldsNames.Total_inc_Vat]?.toString() : "",
                    "Total_exc_Vat": (draggedFieldsNames.Total_exc_Vat) ? value[draggedFieldsNames.Total_exc_Vat]?.toString() : "",
                    "Barcode": (draggedFieldsNames.Barcode) ? value[draggedFieldsNames.Barcode]?.toString() : "",
                    "Style_name": (draggedFieldsNames.Style_name) ? value[draggedFieldsNames.Style_name]?.toString() : "",
                    "Style_nr": (draggedFieldsNames.Style_nr) ? value[draggedFieldsNames.Style_nr]?.toString() : "",
                    "Reference": (draggedFieldsNames.Reference) ? value[draggedFieldsNames.Reference]?.toString() : "",

                    "Category": (draggedFieldsNames.Category) ? value[draggedFieldsNames.Category]?.toString() : "",
                    "SKU": (draggedFieldsNames.SKU) ? value[draggedFieldsNames.SKU]?.toString() : "",
                    "Category_code": (draggedFieldsNames.Category_code) ? value[draggedFieldsNames.Category_code]?.toString() : "",
                    "Sub-categ": (draggedFieldsNames["Sub-categ"]) ? value[draggedFieldsNames["Sub-categ"]]?.toString() : "",
                    "Short_description": (draggedFieldsNames.Short_description) ? value[draggedFieldsNames.Short_description]?.toString() : "",
                    "Description": (draggedFieldsNames.Description) ? value[draggedFieldsNames.Description]?.toString() : "",
                    "Net_weight": (draggedFieldsNames.Net_weight) ? value[draggedFieldsNames.Net_weight]?.toString() : "",
                    "Gross_weight": (draggedFieldsNames.Gross_weight) ? value[draggedFieldsNames.Gross_weight]?.toString() : "",
                    "Country_of_origin": (draggedFieldsNames.Country_of_origin) ? value[draggedFieldsNames.Country_of_origin]?.toString() : "",
                    "Tariff_code": (draggedFieldsNames.Tariff_code) ? value[draggedFieldsNames.Tariff_code]?.toString() : "",

                    "Country_code": (draggedFieldsNames.Country_code) ? value[draggedFieldsNames.Country_code]?.toString() : "",
                    "Gender": (draggedFieldsNames.Gender) ? value[draggedFieldsNames.Gender]?.toString() : "",
                    "Material_1": (draggedFieldsNames.Material_1) ? value[draggedFieldsNames.Material_1]?.toString() : "",
                    "Material_2": (draggedFieldsNames.Material_2) ? value[draggedFieldsNames.Material_2]?.toString() : "",
                    "Material_3": (draggedFieldsNames.Material_3) ? value[draggedFieldsNames.Material_3]?.toString() : "",
                    "Material_4": (draggedFieldsNames.Material_4) ? value[draggedFieldsNames.Material_4]?.toString() : "",
                    "Material_5": (draggedFieldsNames.Material_5) ? value[draggedFieldsNames.Material_5]?.toString() : "",
                    "Material_6": (draggedFieldsNames.Material_6) ? value[draggedFieldsNames.Material_6]?.toString() : "",
                    "Material_7": (draggedFieldsNames.Material_7) ? value[draggedFieldsNames.Material_7]?.toString() : "",
                    "Material_8": (draggedFieldsNames.Material_8) ? value[draggedFieldsNames.Material_8]?.toString() : "",

                    "Care_1": (draggedFieldsNames.Care_1) ? value[draggedFieldsNames.Care_1]?.toString() : "",
                    "Care_2": (draggedFieldsNames.Care_2) ? value[draggedFieldsNames.Care_2]?.toString() : "",
                    "Care_3": (draggedFieldsNames.Care_3) ? value[draggedFieldsNames.Care_3]?.toString() : "",
                    "Care_4": (draggedFieldsNames.Care_4) ? value[draggedFieldsNames.Care_4]?.toString() : "",
                    "Care_5": (draggedFieldsNames.Care_5) ? value[draggedFieldsNames.Care_5]?.toString() : "",
                    "Care_6": (draggedFieldsNames.Care_6) ? value[draggedFieldsNames.Care_6]?.toString() : "",
                    "Care_7": (draggedFieldsNames.Care_7) ? value[draggedFieldsNames.Care_7]?.toString() : "",
                    "Care_8": (draggedFieldsNames.Care_8) ? value[draggedFieldsNames.Care_8]?.toString() : "",
                    "Size_1": (draggedFieldsNames.Size_1) ? value[draggedFieldsNames.Size_1]?.toString() : "",
                    "Size_2": (draggedFieldsNames.Size_2) ? value[draggedFieldsNames.Size_2]?.toString() : "",

                    "Size_3": (draggedFieldsNames.Size_3) ? value[draggedFieldsNames.Size_3]?.toString() : "",
                    "Size_4": (draggedFieldsNames.Size_4) ? value[draggedFieldsNames.Size_4]?.toString() : "",
                    "Color_1_name": (draggedFieldsNames.Color_1_name) ? value[draggedFieldsNames.Color_1_name]?.toString() : "",
                    "Color_1_description": (draggedFieldsNames.Color_1_description) ? value[draggedFieldsNames.Color_1_description]?.toString() : "",
                    "Color_1_code": (draggedFieldsNames.Color_1_code) ? value[draggedFieldsNames.Color_1_code]?.toString() : "",
                    "Color_1_hexa_code": (draggedFieldsNames.Color_1_hexa_code) ? value[draggedFieldsNames.Color_1_hexa_code]?.toString() : "",
                    "Color_2_name": (draggedFieldsNames.Color_2_name) ? value[draggedFieldsNames.Color_2_name]?.toString() : "",
                    "Color_2_description": (draggedFieldsNames.Color_2_description) ? value[draggedFieldsNames.Color_2_description]?.toString() : "",
                    "Color_2_code": (draggedFieldsNames.Color_2_code) ? value[draggedFieldsNames.Color_2_code]?.toString() : "",
                    "Color_2_hexa_code": (draggedFieldsNames.Color_2_hexa_code) ? value[draggedFieldsNames.Color_2_hexa_code]?.toString() : "",

                    "Color_3_name": (draggedFieldsNames.Color_3_name) ? value[draggedFieldsNames.Color_3_name]?.toString() : "",
                    "Color_3_description": (draggedFieldsNames.Color_3_description) ? value[draggedFieldsNames.Color_3_description]?.toString() : "",
                    "Color_3_code": (draggedFieldsNames.Color_3_code) ? value[draggedFieldsNames.Color_3_code]?.toString() : "",
                    "Color_3_hexa_code": (draggedFieldsNames.Color_3_hexa_code) ? value[draggedFieldsNames.Color_3_hexa_code]?.toString() : "",
                };

                const errorFields = [];

                /*
                if (!postData.Barcode) errorFields.push("Barcode");
                if (!postData.Style_nr) errorFields.push("Style nr");
                if (!postData.Short_description) errorFields.push("Short description");
                if (!postData.Material_1) errorFields.push("Material 1");
                if (!postData.Care_1) errorFields.push("Care 1");
                if (!postData.Color_1_name) errorFields.push("Color 1 name");
                if (!postData.Color_1_code) errorFields.push("Color 1 code");

                if (errorFields.length > 0) errorsInfo.push({ index: index, errorFields: errorFields });
                else */
                formData.push(postData);
            }
            catch (error) {
                console.log(`Ignoring product #${index}, reason: `, error);
            }
        });

        const numErrorFields = errorsInfo.length;
        const numProducts = productList.length;
        const importingTotal = numProducts - numErrorFields;

        const continueMessage = numErrorFields > 0
            ? `Of the ${numProducts} items, ${numErrorFields} are missing required fields. <br />Would you like to import the remaining ${importingTotal} items?`
            : `Do you confirm you wish to import ${(importingTotal)} items?`;

        setFormDataFinal(formData);

        if (handleValidation(draggedFieldsNames)) {
            Library.showConfirmationMessage(continueMessage, () => {
                setReadyToUpload(true);
            });
        }
        else setReadyToUpload(false);
    };

    const resetImportPage = () => {
        setProductList([]);
        setTemD([]);
        setDetectedFields([]);
        setFileName("");
        setFilePreviewButtonDisable(true);
        setSaveButtonDisable(true);
        setDustbins0(update(dustbins0, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins1(update(dustbins1, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins2(update(dustbins2, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins3(update(dustbins3, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins4(update(dustbins4, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins5(update(dustbins5, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins6(update(dustbins6, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins7(update(dustbins7, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins8(update(dustbins8, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins9(update(dustbins9, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins10(update(dustbins10, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins11(update(dustbins11, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins12(update(dustbins12, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins13(update(dustbins13, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins14(update(dustbins14, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins15(update(dustbins15, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins16(update(dustbins16, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins17(update(dustbins17, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins18(update(dustbins18, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins19(update(dustbins19, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins20(update(dustbins20, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins21(update(dustbins21, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins22(update(dustbins22, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins23(update(dustbins23, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins24(update(dustbins24, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins25(update(dustbins25, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins26(update(dustbins26, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins27(update(dustbins27, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins28(update(dustbins28, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins29(update(dustbins29, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins30(update(dustbins30, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins31(update(dustbins31, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins32(update(dustbins32, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins33(update(dustbins33, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins34(update(dustbins34, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins35(update(dustbins35, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins36(update(dustbins36, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins37(update(dustbins37, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins38(update(dustbins38, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins39(update(dustbins39, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins40(update(dustbins40, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins41(update(dustbins41, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins42(update(dustbins42, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins43(update(dustbins43, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins44(update(dustbins44, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins45(update(dustbins45, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins46(update(dustbins46, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins47(update(dustbins47, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins48(update(dustbins48, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins49(update(dustbins49, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins50(update(dustbins50, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins51(update(dustbins51, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins52(update(dustbins52, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins53(update(dustbins53, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins54(update(dustbins54, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins55(update(dustbins55, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins56(update(dustbins56, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins57(update(dustbins57, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins58(update(dustbins58, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins59(update(dustbins59, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins60(update(dustbins60, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins61(update(dustbins61, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins62(update(dustbins62, { [0]: { lastDroppedItem: { $set: "", } } }));
        setDustbins63(update(dustbins63, { [0]: { lastDroppedItem: { $set: "", } } }));
    };

    const SaveDistribution = () => {
        setReadyToUpload(false);
        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);
            jsonObjectArray.forEach(item => {
                const key = Object.keys(item);
                const value = Object.values(item);
                obj[key] = value[0];
            });
        }

        if (handleValidation(obj)) {
            //importData(true);

            /**/
            Swal.fire({
                text: "Next upload should respect this distribution?",
                icon: "success",
                showDenyButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#205380",
                denyButtonColor: "#205380",
                confirmButtonText: "Yes",
                denyButtonText: "No",
                background: "#1d2530",
                color: "#fff",
                iconColor: "#0B0",
                width: "500px",
                padding: "10px"
            }).then((result) => {
                if (result.isConfirmed) {
                    importData(true);
                } else if (result.isDenied) {
                    importData(false);
                }
            });
        }
    };

    const importData = (consent) => {

        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);
            jsonObjectArray.forEach((item, index) => {
                const key = Object.keys(item);
                const value = Object.values(item);
                obj[key] = value[0];
            });
        }

        //console.log("formDataFinal", formDataFinal, formDataFinal.length, formDataFinal.find(item => item === null) !== undefined);

        if (!formDataFinal.length || formDataFinal.find(item => item === null) !== undefined) {
            Library.showErrorMessage("No data was received to import. Please try again later.");
            return;
        }

        const formJsonData = {
            "postData": formDataFinal,
            "storeID": storeID?.value,
            "labelBrandID": brandID,
            "OrdersImporter": (consent) ? importerFields : "",
            "OrdersImporterStoreID": storeID?.value
        };

        setFilePreviewButtonDisable(true);
        setSaveButtonDisable(true);
        setOnSubmitLoader(true);

        const onUploadProgress = progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100);
            setProgressBar(percentComplete);
        };

        Library.makePostRequest("uploadOrders", formJsonData, false, setOnSubmitLoader, () => { }, onUploadProgress).then((res) => {
            setFilePreviewButtonDisable(false);
            setSaveButtonDisable(false);

            if (res.data.status === 200) {
                props.loginFun(localStorage.getItem("token"), "60");

                resetImportPage();

                var r = /\d+/g;
                var s = res.data.message;

                if (s.match(r) == null) {
                    Library.showSuccessMessage(res.data.message);
                    setProgressBar(0);
                } else {
                    Library.showSuccessMessage(res.data.message, true, () => {
                        navigate("/products/image-importer");
                    });
                }
            }
            setOnSubmitLoader(false);
        });
    };

    const brandInfo = () => {

        const formData = {
            "id": brandID
        };
        Library.makePostRequest("get-brand-info", formData, false, setPreLoader).then(res => {
            if (res.data.status === 200) {
                if (res.data.data?.OrdersImporter !== undefined) {
                    setDustbins0(update(dustbins0, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins1(update(dustbins1, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins2(update(dustbins2, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins3(update(dustbins3, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins4(update(dustbins4, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins5(update(dustbins5, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins6(update(dustbins6, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins7(update(dustbins7, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins8(update(dustbins8, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins9(update(dustbins9, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins10(update(dustbins10, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins11(update(dustbins11, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins12(update(dustbins12, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins13(update(dustbins13, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins14(update(dustbins14, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins15(update(dustbins15, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins16(update(dustbins16, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins17(update(dustbins17, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins18(update(dustbins18, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins19(update(dustbins19, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins20(update(dustbins20, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins21(update(dustbins21, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins22(update(dustbins22, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins23(update(dustbins23, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins24(update(dustbins24, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins25(update(dustbins25, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins26(update(dustbins26, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins27(update(dustbins27, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins28(update(dustbins28, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins29(update(dustbins29, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins30(update(dustbins30, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins31(update(dustbins31, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins32(update(dustbins32, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins33(update(dustbins33, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins34(update(dustbins34, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins35(update(dustbins35, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins36(update(dustbins36, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins37(update(dustbins37, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins38(update(dustbins38, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins39(update(dustbins39, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins40(update(dustbins40, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins41(update(dustbins41, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins42(update(dustbins42, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins43(update(dustbins43, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins44(update(dustbins44, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins45(update(dustbins45, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins46(update(dustbins46, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins47(update(dustbins47, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins48(update(dustbins48, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins49(update(dustbins49, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins50(update(dustbins50, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins51(update(dustbins51, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins52(update(dustbins52, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins53(update(dustbins53, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins54(update(dustbins54, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins55(update(dustbins55, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins56(update(dustbins56, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins57(update(dustbins57, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins58(update(dustbins58, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins59(update(dustbins59, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins60(update(dustbins60, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins61(update(dustbins61, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins62(update(dustbins62, { [0]: { lastDroppedItem: { $set: "", } } }));
                    setDustbins63(update(dustbins63, { [0]: { lastDroppedItem: { $set: "", } } }));

                    let ordersImporter = res.data.data?.OrdersImporter;

                    ordersImporter = ordersImporter.filter(item => {
                        return dustBinsLabels.includes(item?.boxName);
                    });

                    setPreviousPosData(ordersImporter);
                    setImporterFields(ordersImporter);

                    const dataArray = [];
                    ordersImporter.forEach((item, index) => {
                        dataArray.push(item.itemName);
                        // console.log(dataArray)
                    });
                    setDroppedBoxNames(dataArray);
                }
            }
        });
    };

    /* eslint-disable no-unreachable */
    const handleValidation = (obj) => {
        let formIsValid = true;
        if (!storeID) {
            setErrorStoreID(true);
            formIsValid = false;

        }

        //TODO: CHANGE THIS IN THE FUTURE TO HANDLE VALIDATION
        //return formIsValid;

        if (typeof obj.Barcode === "undefined") {
            setFieldRequired1(true);
            formIsValid = false;
        }
        if (typeof obj.Style_nr === "undefined") {
            setFieldRequired2(true);
            formIsValid = false;
        }
        if (typeof obj.Short_description === "undefined") {
            setFieldRequired3(true);
            formIsValid = false;
        }
        if (typeof obj.Material_1 === "undefined") {
            setFieldRequired4(true);
            formIsValid = false;
        }
        if (typeof obj.Care_1 === "undefined") {
            setFieldRequired5(true);
            formIsValid = false;
        }
        if (typeof obj.Size_1 === "undefined") {
            setFieldRequired6(true);
            formIsValid = false;
        }
        if (typeof obj.Color_1_name === "undefined") {
            setFieldRequired7(true);
            formIsValid = false;
        }
        if (typeof obj.Color_1_code === "undefined") {
            setFieldRequired8(true);
            formIsValid = false;
        }
        return formIsValid;
    };

    var percentage = progressBar + "%";

    const PaginationComponent = (e) => {
        const totalRecord = e.rowCount;
        const rowsPerPage = e.rowsPerPage;
        const noOfPage = Math.ceil(totalRecord / rowsPerPage);
        const dataPagination = [];
        for (let i = 1; i <= noOfPage; i++) {
            dataPagination.push(i);
        }
        return (
            <>
                <div className="paginationfixed">
                    <ul className="pagination custompagination">
                        <li className="prevnext"><Link to="#" onClick={() => prevPage(currentPage)} className={(e.currentPage === 1) ? "disabled" : ""}>Prev</Link></li>
                        {
                            dataPagination.map((item, index) => {
                                return (<li key={index}><Link to="#" className={(e.currentPage === item) ? "disabled" : ""} onClick={() => clickPage(item)}>{item}</Link></li>);
                            })
                        }
                        <li className="prevnext"><Link to="#" onClick={() => nextPage(currentPage)} className={(e.currentPage === noOfPage) ? "disabled" : ""}>Next</Link></li>
                    </ul>
                </div>
            </>
        );
    };

    const clickPage = (page) => {
        setCurrentPage(page);
    };

    const nextPage = (page) => {
        setCurrentPage(page + 1);
    };

    const prevPage = (page) => {
        if (page > 1) {
            setCurrentPage(page - 1);
        }
    };

    const getTheNoOfRowSelected = (e) => {
        // alert(productList.length);
        setDataRowCount(e.target.value);
    };
    /* #endregion */

    /* #region  CONSTANTS */
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: "#10141B",
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: "#333F50", color: "#d1d1d1" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: "#333F50",
                color: "#d1d1d1",
                cursor: "pointer",
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: "#10141B" }),
        menu: styles => ({ ...styles, backgroundColor: "#10141B", marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: "#d1d1d1" }),
        placeholder: styles => ({ ...styles, color: "#d1d1d1" }),
        input: styles => ({ ...styles, color: "#d1d1d1" }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ":hover": {
                backgroundColor: "#205380",
                color: "white",
            },
        }),
    };

    var data = [];
    productList.forEach((value, index) => {
        Object.keys(value).forEach((key) => {
            var replacedKey = key.replace(/ /g, "_").replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
            if (key !== replacedKey) {
                value[replacedKey] = (typeof value[key] !== "undefined") ? value[key] : "-";
                if (typeof value[key] !== "undefined") {
                    delete value[key];
                }
            }
        });
        data.push(value);
    });

    const columns = [];
    detectedFields.forEach((res) => {
        const selector = (((res.name).toString())?.split(" ").join("_")).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
        const dataHeader = {
            name: res.name,
            selector: selector,
            sortable: true,
        };
        columns.push(dataHeader);
    });

    const ket = [];
    if (draggedData?.length) {
        const dataValidation = JSON.parse(draggedData);
        dataValidation?.forEach(r => {
            const key = Object.keys(r);
            ket.push(key);
        });
    }
    /* #endregion */

    return (
        <StyledPage>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />

                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr ">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to="/orders" className="btn btn-success active" >Orders</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/orders" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="orders/importer" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext">Importer</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {(preLoader || loadingStores)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="products-listingarea filtersarea images-importer">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="custom-breadcrumb">
                                                    <div className="products-col">
                                                        <div className={`perform-input fileimport-left-select ${errorStoreID ? "error-input" : ""}`}>
                                                            <Select value={storeID} className="selects"
                                                                styles={colourStyles}
                                                                onChange={handleStoreChange}
                                                                options={storesOptions}
                                                                placeholder={<div className="italic-placeholder">Select store</div>}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="product-importer-filebtn" style={{ maxWidth: "610px" }}>
                                                    <div className="form-group">
                                                        <input type="file" name="fileImport" id="fileImport" className="inputfile inputfile-6" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => { readImporterFile(e); fullScreenCheck(e); brandInfo(); }} onClick={(e) => { e.target.value = null; }} />
                                                        <label htmlFor="fileImport"><span>{fileName}</span>
                                                        </label>
                                                        <label htmlFor="fileImport" className={(fileName) ? "stromngclass active" : "stromngclass active"}>Upload File</label>
                                                        <button onClick={handleToogleIsPOSUpload} className="stromngclass active">POS UPLOAD</button>

                                                    </div>
                                                </div>

                                                <div className="filtersection">
                                                    <span className="btnsets">
                                                        <span className={`customeset gobtn ${!isPosUpload ? "hidden" : ""}`}>
                                                            <button type="submit" className="btn btn-success" onClick={handleConnectBtn} disabled={(buttonGray) ? "disabled" : ""}>
                                                                <i className="fa-thin fa-table"></i> CONNECT
                                                            </button>
                                                        </span>

                                                        <span className="customeset gobtn">
                                                            <button type="submit" className="btn btn-success" onClick={() => { setShowFilePreview(true); }} disabled={(filePreviewButtonDisable) ? "disabled" : ""}>
                                                                <i className="fa-thin fa-table"></i> FILE PREVIEW
                                                            </button>
                                                        </span>

                                                        <span className="customeset gobtn">
                                                            <button type="submit" className="btn btn-success save-control" onClick={searchDataInDB} disabled={(saveButtonDisable) ? "disabled" : ""}>
                                                                {(!productList.length)
                                                                    ? (<i className="fa-thin fa-save"></i>)
                                                                    : (saveButtonDisable)
                                                                        ? (<i className="fas fa-spinner fa-spin"></i>)
                                                                        : (<i className="fa-thin fa-save"></i>)}
                                                                {(productList.length) ? "SAVE " + productList.length + " ITEMS" : "SAVE"}
                                                            </button>
                                                            {/* <button type="submit" className="btn btn-success save-control" onClick={SaveDistribution} disabled={(saveButtonDisable)?'disabled':'' }> {(!productList.length)?(<i className="fa-thin fa-save"></i>):(saveButtonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-save"></i>)} {(productList.length)?'SAVE '+productList.length+' ITEMS':'SAVE'}</button> */}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`pos-select-section ${isPosUpload ? "show-transition-faster" : "hide-transition-faster"} ${removePosSections ? "hidden" : ""}`}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cardsection">
                                                    <div className="card-header">
                                                        <h3 className="card-title">PRODUCTS POS
                                                            <span className="formcautions">
                                                                <ul>
                                                                    <li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
                                                                    <li><span className="makesqaure lockedthis"></span>locked fields</li>
                                                                </ul>
                                                            </span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group activeborder">
                                                                                <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> POS Provider</label>
                                                                                <Select className={(posProviderError) ? "selects error-input" : "selects"}
                                                                                    value={defaultPOSProvider}
                                                                                    onChange={handleSelectPOSProvider}
                                                                                    options={allPOSOptions}
                                                                                    styles={colourStyles}
                                                                                    placeholder={<div className="italic-placeholder">Select POS...</div>}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`pos-section ${isPosUpload ? "show-transition-faster" : "hide-transition-faster"} ${removePosSections || !posSectionVisible ? "hidden" : ""}`}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cardsection">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Configure POS - {posName}<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <form>
                                                            <div className="row">

                                                                {!connectFields
                                                                    ? ""
                                                                    :
                                                                    connectFields.filter(item => !item?.storeOnlyField).map((field, index) =>
                                                                        <div className="col-md-4" key={index}>
                                                                            <div className="form-group activeborder">
                                                                                <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> {field.name}</label>
                                                                                <input
                                                                                    type={field?.numbersOnly ? "number" : "text"}
                                                                                    value={allPosFormFields[index]}
                                                                                    onChange={(e) => { handleConnectFieldChange(e, index); }}
                                                                                    className={formFieldErrorIndex.includes(index) ? "form-control error-input" : "form-control"}
                                                                                    placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="product-infosection">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="productinfo productDivimporter">
                                                    <div className="cardsection prdoctMargin">
                                                        <div className="card-header">
                                                            <h4 className="card-title">

                                                                Orders File
                                                                <span className="formcautions">
                                                                    <ul>
                                                                        <li><span className="makesqaure" style={{ background: "#00aeef" }}></span>recommended fields</li>
                                                                        <li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
                                                                        <li><span className="makesqaure lockedthis"></span>locked fields</li>
                                                                    </ul>
                                                                </span>
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                                    <i className="fa fa-chevron-down pull-right" aria-hidden="true" onClick={() => setChevShow(1)} style={{ "display": (chevShow) ? "none" : "block" }}></i>
                                                                    <i className="fa fa-chevron-up pull-right" aria-hidden="true" onClick={() => setChevShow(0)} style={{ "display": (chevShow) ? "block" : "none" }}></i>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="info-products-form">
                                                                <form>
                                                                    <div className="headpart">
                                                                        <div className="row">
                                                                        </div>
                                                                    </div>

                                                                    <div className="showprogress">
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{ width: percentage }}>
                                                                                <span className="sr-only">{progressBar}% Complete</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <DndProvider backend={HTML5Backend}>
                                                                        <div className="formdivisions">
                                                                            <div className="row">
                                                                                <div className="col-md-4">
                                                                                    <div className="fieldsarea">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Fields detected {detectedFields.length ? <span style={{ "color": "#00AEEF" }}> {"  "}  Drag fields below to a box on the right</span> : ""}</label>
                                                                                        </div>
                                                                                        <div className="fieldfouds">
                                                                                            {detectedFields?.map(({ name, type }, index) => {
                                                                                                return (
                                                                                                    <Box
                                                                                                        name={name}
                                                                                                        type={type}
                                                                                                        isDropped={isDropped(name)}
                                                                                                        key={index}
                                                                                                    />
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>Order ID & Totals</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[0]}</label>
                                                                                            {dustbins0.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop0(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[1]}</label>
                                                                                            {dustbins1.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop1(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[2]}</label>
                                                                                            {dustbins2.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop2(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[3]}</label>
                                                                                            {dustbins3.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop3(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[4]}</label>
                                                                                            {dustbins4.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop4(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[5]}</label>
                                                                                            {dustbins5.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop5(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[6]}</label>
                                                                                            {dustbins6.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop6(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[7]}</label>
                                                                                            {dustbins7.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop7(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>&nbsp;</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[8]}</label>
                                                                                            {dustbins8.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop8(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[9]}</label>
                                                                                            {dustbins9.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop9(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[10]}</label>
                                                                                            {dustbins10.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop10(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[11]}</label>
                                                                                            {dustbins11.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop11(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[12]}</label>
                                                                                            {dustbins12.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop12(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[13]}</label>
                                                                                            {dustbins13.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop13(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[14]}</label>
                                                                                            {dustbins14.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop14(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[15]}</label>
                                                                                            {dustbins15.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop15(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>Item ID</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[16]}</label>
                                                                                            {dustbins16.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop16(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired1}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[17]}</label>
                                                                                            {dustbins17.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop17(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired2}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[18]}</label>
                                                                                            {dustbins18.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop18(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[19]}</label>
                                                                                            {dustbins19.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop19(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[20]}</label>
                                                                                            {dustbins20.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop20(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired3}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[21]}</label>
                                                                                            {dustbins21.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop21(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[22]}</label>
                                                                                            {dustbins22.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop22(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[23]}</label>
                                                                                            {dustbins23.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop23(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>&nbsp;</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[24]}</label>
                                                                                            {dustbins24.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop24(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[25]}</label>
                                                                                            {dustbins25.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop25(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[26]}</label>
                                                                                            {dustbins26.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop26(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[27]}</label>
                                                                                            {dustbins27.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop27(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[28]}</label>
                                                                                            {dustbins28.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop28(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[29]}</label>
                                                                                            {dustbins29.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop29(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[30]}</label>
                                                                                            {dustbins30.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop30(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[31]}</label>
                                                                                            {dustbins31.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop31(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>Material & Care</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[32]}</label>
                                                                                            {dustbins32.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop32(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired4}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[33]}</label>
                                                                                            {dustbins33.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop33(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[34]}</label>
                                                                                            {dustbins34.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop34(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[35]}</label>
                                                                                            {dustbins35.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop35(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[36]}</label>
                                                                                            {dustbins36.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop36(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired5}
                                                                                                    isMandatory={true}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[37]}</label>
                                                                                            {dustbins37.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop37(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[38]}</label>
                                                                                            {dustbins38.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop38(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[39]}</label>
                                                                                            {dustbins39.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop39(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>&nbsp;</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[40]}</label>
                                                                                            {dustbins40.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop40(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[41]}</label>
                                                                                            {dustbins41.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop41(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[42]}</label>
                                                                                            {dustbins42.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop42(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[43]}</label>
                                                                                            {dustbins43.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop43(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[44]}</label>
                                                                                            {dustbins44.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop44(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[45]}</label>
                                                                                            {dustbins45.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop45(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[46]}</label>
                                                                                            {dustbins46.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop46(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[47]}</label>
                                                                                            {dustbins47.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop47(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>Item Variations</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[48]}</label>
                                                                                            {dustbins48.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop48(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired6}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[49]}</label>
                                                                                            {dustbins49.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop49(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[50]}</label>
                                                                                            {dustbins50.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop50(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired7}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[51]}</label>
                                                                                            {dustbins51.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop51(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={fieldRequired8}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[52]}</label>
                                                                                            {dustbins52.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop52(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[53]}</label>
                                                                                            {dustbins53.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop53(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[54]}</label>
                                                                                            {dustbins54.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop54(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[55]}</label>
                                                                                            {dustbins55.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop55(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                    isRecommended={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1 less-padding">
                                                                                    <div className="products-col">
                                                                                        <h4>&nbsp;</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[56]}</label>
                                                                                            {dustbins56.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop56(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[57]}</label>
                                                                                            {dustbins57.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop57(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[58]}</label>
                                                                                            {dustbins58.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop58(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[59]}</label>
                                                                                            {dustbins59.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop59(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[60]}</label>
                                                                                            {dustbins60.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop60(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[61]}</label>
                                                                                            {dustbins61.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop61(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[62]}</label>
                                                                                            {dustbins62.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop62(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> {dustBinsTitles[63]}</label>
                                                                                            {dustbins63.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop63(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={false}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </DndProvider>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                </div>

                <BrandFooter logo={props.imageData.footer_logo} />
            </div>

            {(onSubmitLoader)
                ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>
                : ""
            }

            <Modal
                backdrop="static"
                className="filepreviewTable"
                keyboard={false}
                show={showFilePreview}
                onHide={() => { setShowFilePreview(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title>ORDERS FILE PREVIEW</Modal.Title>
                    <div className="toppanels">
                        <div className="toppadintions">
                            <div className="dataTables_length" id="example_length">
                                <label>Show
                                    <select name="example_length" aria-controls="example" className="custom-select custom-select-sm form-control form-control-sm" onChange={getTheNoOfRowSelected}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                    </select> entries
                                </label>
                            </div>
                        </div>
                        <div className="toppadintions searchrighrts">
                            <div className="dt-buttons">
                                <div className="dt-buttons btn-group">
                                    <Link to="#" className="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="example"><span>Copy</span></Link>
                                    <Link to="#" className="btn btn-default buttons-csv buttons-html5" tabindex="0" aria-controls="example"><span>CSV</span></Link>
                                    <Link to="#" className="btn btn-default buttons-excel buttons-html5" tabindex="0" aria-controls="example"><span>Excel</span></Link>
                                    <Link to="#" className="btn btn-default buttons-pdf buttons-html5" tabindex="0" aria-controls="example"><span>PDF</span></Link>
                                    <Link to="#" className="btn btn-default buttons-print" tabindex="0" aria-controls="example"><span>Print</span></Link>
                                </div>
                            </div>
                            <div id="example_filter" className="dataTables_filter">
                                <label>Search:<input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example" /></label>
                            </div>
                        </div>
                        <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to {dataRowCount} of {productList.length} entries</div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {/*<DataTableExtensions filterPlaceholder="" {...tableData}>*/}
                    <DataTable
                        columns={columns}
                        data={data}
                        theme="solarized"
                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                        defaultSortFieldId={0}
                        defaultSortAsc={false}
                        pagination
                        paginationComponent={PaginationComponent}
                        paginationDefaultPage={currentPage}
                    />
                    {/*</DataTableExtensions>*/}
                </Modal.Body>

            </Modal>
            {/* {Prompt} */}
        </StyledPage>
    );
}

export default Dashboard;