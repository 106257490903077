import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import './../../assets/css/main.css';

/* #region  SCOPED CSS */
const Container = styled.div`
    .NoInternetMarker {
        color: white;
        z-index: 999999;
        position: absolute;
        width: 459px;
        background: #D13636;
        top: 65px;
        text-align: center;
        border-radius: 5px;
        opacity: 1;
        display: flex;
        align-items: center;
        margin: 0px auto;
        left: 0px;
        right: 0px;
    }

    .NoInternetMarker .warning-icon{
        margin: 0px 28px;
        width: 21px;
        height: 18px;
        flex-shrink: 0;
        font-size: 18pt;
    }

    .NoInternetMarker .close-icon{
        margin:22px
    }

    .hide-transition{
        transition: opacity 0.5s linear;
        opacity: 0;
    }
    
    .show-transition{
        transition: opacity 0.5s linear;
        opacity: 1;
    }

    .message{
        width: 100%;
        text-align: initial;

        color: #FFF;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .close-icon{
        border-radius: 5px;
        border: 1px solid #FFF;
        display: flex;
        width: 30px;
        height: 30px;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;
    }

    .close-icon >i {
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        font-size: 8pt;
    }

    .hidden {
        display: none;
    }
`;
/* #endregion */

function NoInternetMarker(props) {

    /* #region USE STATES */
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [dismissOffline, setDismissOffline] = useState(false);
    const [popupHide, setPopupHide] = useState(true);
    /* #endregion */

    /* #region USE EFFECTS */
    useEffect(() => {
        function onlineHandler() {
            //console.log("Triggered onlineHandler");
            setIsOnline(true);
            setDismissOffline(false);
            setPopupHideTimeout(true);
        }

        function offlineHandler() {
            //console.log("Triggered offlineHandler");
            setPopupHide(false);
            setTimeout(() =>{
            setDismissOffline(false);
            setIsOnline(false);
            }, 50);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);

        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);
    /* #endregion */

    /* #region METHODS */
    const handleClose = () => {
        setDismissOffline(true);
        setPopupHideTimeout(true);
    };

    const setPopupHideTimeout= (hidden) =>{
        setTimeout(() => {
            setPopupHide(hidden);
        }, 500);
    }
    /* #endregion */

    return (
        <Container>
            <div
                className={`NoInternetMarker ${isOnline ? 'hide-transition' : dismissOffline ? 'hide-transition' : 'show-transition'} ${popupHide ? 'hidden' : ''} `}>
                <i className="warning-icon fa-light fa-triangle-exclamation"></i>
                <div className='message'>
                    Your're offline. <br />
                    Please check your internet connection
                </div>
                <div className="close-icon" onClick={handleClose}>
                    <i className="fa-light">X</i>
                </div>
            </div>
        </Container>
    )
}

export default NoInternetMarker;