import { createSlice } from '@reduxjs/toolkit'

export const userDataSlice = createSlice({
    name: 'userData',
    initialState: {
        allUserInfo: null,
        userAppData: null
    },
    reducers: {
        setData: (state, action) => {
            state.allUserInfo = action.payload.allUserInfo;
            state.userAppData = action.payload.userAppData;
        }
    }
});

export const { setData } = userDataSlice.actions;

export default userDataSlice.reducer;