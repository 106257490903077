/* #region  IMPORTS */
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/custom.css';
import './assets/css/main.css';
import Logo from './assets/images/logologin.png';
import FooterLogo from './assets/images/logologinFooter.png';
import Login from './component/authentication/login';
import ForgotCredentialsEmail from './component/authentication/forgot-credentials-email';
import ForgotCredentialsPhone from './component/authentication/forgot-credentials-phone';
import Boimages from './component/brand/boimages/index';
import BoimagesCreater from './component/brand/boimages/creator';
import BoimagesCreaterCanvas from './component/brand/boimages/canvas';
import BoimagesTarget from './component/brand/boimages/targets';
import Products from './component/brand/products/index';
import Bopics from './component/brand/products/bopics';
import Importer from './component/brand/products/importer';
import ProductOrders from './component/orders/ordersList';
import ProductOrdersImporter from './component/orders/ordersImporter';
import PageNotFound from './component/error/404';
import ImageImporter from './component/brand/products/image-importer';
import PublisherURL from './component/brand/publisher/index';
import Drag from './component/rnd/drag';
import Table from './component/rnd/table';
import PreEnrrolment from './component/preenrrolment/index';
import AccountSetup from './component/accounts/index';
import PhoneVerification from './component/accounts/phoneVerification';
import CompanyAdmin from './component/accounts/companyAdmin';
import CompanyAdminRegistration from './component/accounts/companyAdminRegistration';
import PreCompanyLogoUpload from './component/accounts/preCompanyLogoUpload';
import BrandPreRegistrationInvitation from './component/preenrrolment/brandPreRegistrationInvitation';
import PreBrandEnroll from './component/accounts/preBrandEnroll';
import CompanyLogo from './component/accounts/companyLogo';
import BrandCreator from './component/brand/creator';
import BrandPreLogoUpload from './component/brand/preLogoUpload';
import BrandLogo from './component/brand/brandLogo';
import PreHashTags from './component/brand/preHashTags';
import TargetsHashtags from './component/brand/targetsHashtags';
import PreBoImage from './component/brand/preBOimage';
import PreStore from './component/store/preStore';
import PreCreateStore from './component/store/preCreateStore';
import BrandList from './component/brand/brandList';
import './assets/css/bostage_a_custom.css';
import CreateStore from './component/store/createStore';
import CreateOpenHours from './component/store/openHours';
import InviteStaff from './component/store/inviteStaffMember';
import StorePOS from './component/store/storePOS';
import StaffPreRegistrationInvitation from './component/preenrrolment/staffPreRegistrationInvitation';
import StoreDelivery from './component/store/delivery';
import RetailerCreator from './component/retailer/creator';
import BilingPlan from './component/billing/index';
import PreBilling from './component/billing/preBilling';
import PreBillingRetailer from './component/billing/preBillingRetailer';
import NewPreCreateStore from './component/store/newPreStoreCreate';
import RetailerStoreLogo from './component/store/retailerStoreLogo';
import BilingPlanRetailer from './component/billing/billingPlanRetailer';
import ThankyouWelcomeStore from './component/billing/thankyouWelcomeStore';
import ThankyouWelcomeRetailer from './component/billing/thankyouWelcomeRetailer';
import StoreList from './component/store/storeList';
import FFCList from './component/ffc/ffcList';
import FFCStock from './component/ffc/stock';
import CompanyList from './component/accounts/companyList';
import ReatailerList from './component/retailer/retailerList';
import RetaillerInviteList from './component/retailer/retaillerInviteList';
import Dashboard from './component/dasboard/index';
import PreviewImage from './component/brand/boimages/previewImage';
import * as Library from './utils/Library';
import InvoiceList from './component/billing/invoiceList';
import StoreStaffInvites from './component/store/storeStaffInvites';
import StoreStaffList from './component/store/storeStaffList';
import NoInternetMarker from './component/layouts/NoInternetMarker';
import { useDispatch } from 'react-redux';
import { setData } from './utils/redux/userDataSlice';
/* #endregion */

/* #region GLOBAL METHODS AND CONSTANTS */
const imageData = {
    'logo': Logo,
    'footer_logo': FooterLogo
};

function PrivateRoute({ children }) {
    //TODO: CHECK AUTH TOKEN IN API
    const auth = localStorage.getItem('token');
    return auth ? children : <Navigate to="/" />;
}

function PublicRoute({ children }) {
    const auth = localStorage.getItem('token');
    const status = localStorage.getItem('status');

    if (auth && status === 'Completed')
        return <Navigate to="/account/company/list" />;
    else
        return children;
}
/* #endregion */

function App() {

    /* #region  STATES */
    const [companyData, setCompanyData] = useState('');
    const [updData, setUpdData] = useState('');
    const dispatch = useDispatch();
    //const { userData } = useSelector((state) => state.userData);

    if (['development', 'testing', 'production', 'staging'].includes(process.env.NODE_ENV)) {
        window.BOLibrary = Library;
    }
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        if (localStorage.getItem('token')) {
            const formData = {
                'userID': localStorage.getItem('token')
            };
            refreshUserDataLocalStorage(formData, true);
        }
    }, []);

    useEffect(() => {
        setCompanyData(updData);
    }, [updData]);
    /* #endregion */

    /* #region  FUNCTIONS */
    const refreshUserDataLocalStorage = (formData, firstTime) => {
        Library.makePostRequest('get-user-data', formData, true).then(res => {
            if (res.data.status === 200) {
                setUserDataLocalStorage(res.data.data, firstTime);
            }
            else localStorage.clear();
        });
    };

    const setUserDataLocalStorage = (data, firstTime = true) => {
        const resData = {
            'status': data?.status,
            'completePercentage': data?.completePercentage,
            'AccountMenu': data?.AccountMenu,
            'DashboardMenu': data?.DashboardMenu,
            'BOimagesMenu': data?.BOimagesMenu,
            'FullfillmentCenterMenu': data?.FullfillmentCenterMenu,
            'StoresMenu': data?.StoresMenu,
            'RetailersMenu': data?.RetailersMenu,
            'ProductsMenu': data?.ProductsMenu,
            'OrdersMenu': data?.OrdersMenu,
            'BrandsMenu': data?.BrandsMenu,
            'PromotersMenu': data?.PromotersMenu,
            'companyName': (typeof data?.Companies != 'undefined') ? data?.Companies[0]?.companyName : '',
            'companyLogo': data?.companyDetail?.logoURL,
            'BillingMenu': data?.BillingMenu
        };
        localStorage.setItem('__data', JSON.stringify(resData));
        localStorage.setItem('groupName', data?.groupData);

        if (firstTime) setCompanyData(data);
        else setUpdData(data);

        //redux save userData
        dispatch(setData({ allUserInfo: data.allUserData, userAppData: data }));

        if (firstTime) {
            if (!localStorage.getItem('brandID') && !localStorage.getItem('brandName')) {
                if (data?.activeBrand) {
                    localStorage.setItem('brandID', data?.activeBrand?.brandID);
                    localStorage.setItem('brandName', data?.activeBrand?.brandName);
                }
            }
        }
        else {
            if (data?.activeBrand && localStorage.getItem('preEnrollData') == null) {
                localStorage.setItem('brandID', data?.activeBrand?.brandID);
                localStorage.setItem('brandName', data?.activeBrand?.brandName);
            }
        }

        if (data?.allUserData?.Company?._retailBrands?.length) {
            localStorage.setItem('retailBrandID', data?.allUserData?.Company?._retailBrands?.[0]?.ourID);
            localStorage.setItem('retailBrandName', data?.allUserData?.Company?._retailBrands?.[0]?.name);
            localStorage.setItem('currentRetailBrandID', data?.allUserData?.Company?._retailBrands?.[0]?._id);
            localStorage.setItem('currentRetailBrandName', data?.allUserData?.Company?._retailBrands?.[0]?.name);
        }

        if (!localStorage.getItem('currentCompanyID')) {
            const currentCompanyID = data?.companyDetail?._id;
            const currentCompanyName = data?.companyDetail?.name !== undefined
                ? data?.companyDetail?.name : '-';

            localStorage.setItem('currentCompanyID', currentCompanyID);
            localStorage.setItem('currentCompanyName', currentCompanyName);
        }
    };

    const loginFun = (id, statusPercentage) => {
        const formData = {
            'userID': id,
            'status': statusPercentage
        };

        if (localStorage.getItem('status') === 100) {
            localStorage.removeItem('storeID');
            localStorage.removeItem('boimageID');
        }

        if (parseInt(localStorage.getItem('status')) <= parseInt(statusPercentage) || statusPercentage === 'Completed') {
            localStorage.setItem('status', statusPercentage);
            Library.makePostRequest('updateUserStatus', formData, true).then(res => {
                refreshUserDataLocalStorage(formData, false);
            });
        } else {
            Library.makePostRequest('get-user-data', formData, true).then(res => {
                refreshUserDataLocalStorage(formData, false);
            });
        }
    };
    /* #endregion */

    return (
        <BrowserRouter>
            <NoInternetMarker />
            <Routes>
                <Route path="/"
                    element={<PublicRoute><Login loginFun={loginFun} imageData={imageData} /></PublicRoute>} exact={true} />

                <Route path="/drag"
                    element={<Drag />} />

                <Route path="/table"
                    element={<Table />} />

                <Route path="*"
                    element={<PageNotFound imageData={imageData} />} />

                <Route path="/handyad/v/:processId"
                    element={<PublisherURL />} exact={true} />

                <Route path="/dashboard"
                    element={<PrivateRoute><Dashboard compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/forgot-password-email"
                    element={<PublicRoute><ForgotCredentialsEmail imageData={imageData} /></PublicRoute>} exact={true} />

                <Route path="/forgot-password-phone"
                    element={<PublicRoute><ForgotCredentialsPhone imageData={imageData} /></PublicRoute>} exact={true} />

                <Route path="/orders"
                    element={<PrivateRoute><ProductOrders compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/orders/importer"
                    element={<PrivateRoute><ProductOrdersImporter compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/products"
                    element={<PrivateRoute><Products compData={companyData} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/products/bopics"
                    element={<PrivateRoute><Bopics compData={companyData} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/products/importer"
                    element={<PrivateRoute><Importer compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/products/image-importer"
                    element={<PrivateRoute><ImageImporter compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages"
                    element={<PrivateRoute><Boimages compData={companyData} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/creater"
                    element={<PrivateRoute><BoimagesCreater compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/creater/:BOimageIdData"
                    element={<PrivateRoute><BoimagesCreater loginFun={loginFun} compData={companyData} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/canvas"
                    element={<PrivateRoute><BoimagesCreaterCanvas compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/canvas/:BOimageIdData"
                    element={<PrivateRoute><BoimagesCreaterCanvas compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/target/:BOimageIdData"
                    element={<PrivateRoute><BoimagesTarget compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                <Route path="/boimages/pre-boimages"
                    element={<PrivateRoute><PreBoImage compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/products-new"
                    element={<PrivateRoute><Products compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/pre-enrrolment/:id"
                    element={<PreEnrrolment loginFun={loginFun} imageData={imageData} />} exact={true} />

                <Route path="/account/company"
                    element={<PrivateRoute><AccountSetup compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company/:compId"
                    element={<PrivateRoute><AccountSetup compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company/phone-verification"
                    element={<PrivateRoute><PhoneVerification loginFun={loginFun} compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company-admin"
                    element={<PrivateRoute><CompanyAdmin compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company-admin/registration"
                    element={<PrivateRoute><CompanyAdminRegistration loginFun={loginFun} compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company/pre-company-logo-upload"
                    element={<PrivateRoute><PreCompanyLogoUpload loginFun={loginFun} compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company/list"
                    element={<PrivateRoute><CompanyList loginFun={loginFun} compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/register-brand-user/:id"
                    element={<BrandPreRegistrationInvitation imageData={imageData} />} exact={true} />

                <Route path="/account/company/brand-pre-registration"
                    element={<PrivateRoute><PreBrandEnroll loginFun={loginFun} compData={companyData} imageData={imageData} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/account/company/logo"
                    element={<PrivateRoute><CompanyLogo compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/creator"
                    element={<PrivateRoute><BrandCreator compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/creator/:brId"
                    element={<PrivateRoute><BrandCreator compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/brand-pre-logo-upload/:id"
                    element={<PrivateRoute><BrandPreLogoUpload compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/logo/:id"
                    element={<PrivateRoute><BrandLogo compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/pre-tags-target/:id"
                    element={<PrivateRoute><PreHashTags compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand/tags-target/:id"
                    element={<PrivateRoute><TargetsHashtags compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/brand"
                    element={<PrivateRoute><BrandList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/pre-store"
                    element={<PrivateRoute><PreStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/pre-create-new-store"
                    element={<PrivateRoute><NewPreCreateStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/pre-create-store"
                    element={<PrivateRoute><PreCreateStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/retail-brand-logo"
                    element={<PrivateRoute><RetailerStoreLogo compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/staff/invites"
                    element={<PrivateRoute><StoreStaffInvites compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/staff/list"
                    element={<PrivateRoute><StoreStaffList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store"
                    element={<PrivateRoute><CreateStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/:storeId"
                    element={<PrivateRoute><CreateStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/open/:id"
                    element={<PrivateRoute><CreateOpenHours compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/invite-staff/:storeID"
                    element={<PrivateRoute><InviteStaff compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/POS/:storeID"
                    element={<PrivateRoute><StorePOS compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/delivery/:id"
                    element={<PrivateRoute><StoreDelivery compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/store/list"
                    element={<PrivateRoute><StoreList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                { /*<Route path="/store/orders"
                    element={<PrivateRoute><StoreOrderList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />
                */}

                <Route path="/register-staff-member/:id"
                    element={<StaffPreRegistrationInvitation imageData={imageData} />} exact={true} />

                <Route path="/retailer/create"
                    element={<PrivateRoute><RetailerCreator compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/billing-plan"
                    element={<PrivateRoute><BilingPlan compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/billing-plan/retailer"
                    element={<PrivateRoute><BilingPlanRetailer compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/billing-plan/pre-billing"
                    element={<PrivateRoute><PreBilling compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/billing-plan/pre-billing-retailer"
                    element={<PrivateRoute><PreBillingRetailer compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/billing-plan/invoices"
                    element={<PrivateRoute><InvoiceList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/welcome-store"
                    element={<PrivateRoute><ThankyouWelcomeStore compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/welcome-retailer"
                    element={<PrivateRoute><ThankyouWelcomeRetailer compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/fulfillmentcenter"
                    element={<PrivateRoute><FFCList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/fulfillmentcenter/stock/:storeID"
                    element={<PrivateRoute><FFCStock compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/retailer"
                    element={<PrivateRoute><ReatailerList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/retailer/retailer-invite-list"
                    element={<PrivateRoute><RetaillerInviteList compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} />

                <Route path="/boimages/priview/:BOimageIdData"
                    element={<PrivateRoute><PreviewImage compData={companyData} loginFun={loginFun} imageData={imageData} /></PrivateRoute>} exact={true} />

                {/* #region UNUSED ROUTES */}
                {/* <Route path="/brand" element={<PrivateRoute><Brands compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>} exact={true} /> */}
                {/* <Route path="/brand-dashboard" element={<PrivateRoute><BrandDashboard compData={companyData} imageData={imageData}/></PrivateRoute>} exact={true} />  */}
                {/* <Route path="/boimages/retailer" element={<PrivateRoute><RetailerBoimages compData={companyData} loginFun={loginFun} imageData={imageData}/></PrivateRoute>} exact={true} /> */}
                {/* <Route path="/billing-plan/selected-plan" element={<PrivateRoute><SelectedPlan compData={companyData} imageData={imageData} loginFun={loginFun} type="new" /></PrivateRoute>}  exact={true} /> */}
                {/* #endregion */}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
