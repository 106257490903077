import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

function BrandNavigation(props) {

    /* #region  METHODS */
    let navigate = useNavigate();
    const expandCollapse = (e) => {
        e.preventDefault();
        if (IsFullScreenCurrently()) {
            GoOutFullscreen();
        } else {
            openFullscreen();
        }
    }
    const IsFullScreenCurrently = () => {

        //var full_screen_element = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement || null;

        if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
            localStorage.setItem('fullscreen', 'full');
            return false;
        } else {
            localStorage.setItem('fullscreen', 'exit');
            return true;
        }

    }
    const GoOutFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-compress");
        document.getElementById("expand-button").classList.add("fa-expand");
        if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen)
            document.webkitExitFullscreen();
        else if (document.msExitFullscreen)
            document.msExitFullscreen();
    }
    //let elem = props.data;
    let elem = document.body;
    const openFullscreen = () => {
        document.getElementById("expand-button").classList.remove("fa-expand");
        document.getElementById("expand-button").classList.add("fa-compress");
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    }
    const logOut = (e) => {
        e.preventDefault();
        /**/
        Swal.fire({
            text: 'Are you sure want to logout?',
            icon: 'success',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
            background: '#1d2530',
            color: '#fff',
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate('/');
            } else if (result.isDenied) {
                Swal.close()
            }
        })
    }

    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('webkitfullscreenchange', exitHandler);
    document.addEventListener('mozfullscreenchange', exitHandler);
    document.addEventListener('MSFullscreenChange', exitHandler);

    function exitHandler() {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            // console.log("ESC");
            document.getElementById("expand-button").classList.add("fa-expand");
            document.getElementById("expand-button").classList.remove("fa-compress");
        }
    }
    /* #endregion */

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-fixed-top" onClick={props.preHide}>
                <div className="previosnext">
                    <ul>
                        <li><Link to="#" onClick={(e) => e.preventDefault()}><i className="fa-thin fa-chevron-left"></i></Link></li> &nbsp;
                        <li><Link to="#" onClick={(e) => e.preventDefault()}><i className="fa-thin fa-chevron-right"></i></Link></li>
                    </ul>
                    <li className="nav-item dropdown profileopen active-brand-names">
                        <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                            <div className="profiletext">

                                <span className="branddisplayname"> Enrolling Brand:  <span className="brandgreenname"> {props.brandData} </span></span>

                            </div>
                        </Link>


                    </li>

                </div>


                <div className="centerbtn">

                </div>


                <ul className="navbar-nav ml-auto diselects">
                    <li className="nav-item">
                        <Link id="" className="nav-link btn btn-success" to="#" onClick={expandCollapse} role="button">
                            <i className="fa-thin fa-expand" id="expand-button" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li className="nav-item logoutbtn">
                        <Link className="nav-link btn btn-success" data-widget="logout" to="#" role="button">
                            <i className="fa-thin fa-power-off" aria-hidden="true"></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default BrandNavigation;