import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoaderIcon from './../../../assets/images/loader-icon.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import BrandAside from "./../../layouts/BrandAside";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandFooter from "./../../layouts/BrandFooter";
import BeconData from './beconData';

function Dashboard(props) {
    document.title = 'BOstageB - BOimages';
    // console.log(props?.compData?.activeBrand?.brandID);

    /* #region  STATES */
    const ref = useRef();
    let navigate = useNavigate();
    const [elementData, setElementData] = useState("");
    const [isPagerefresh, setIsPageRefresh] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [denied, setDenied] = useState(false)
    const [ratio, setRatio] = useState({ h: 0, w: 0 })
    const [lastInsertIdData, setLastInsertIdData] = useState("");
    const [renderData, setRenderData] = useState("");
    const [imageData, setImageData] = useState("");
    const [brandLogo, setBrandLogo] = useState('');
    const targetImageRef = useRef();
    const { BOimageIdData } = useParams();
    const [showData, setShowData] = useState(false);
    const [beaconID, setBeaconID] = useState("");
    const [infoLoader, setInfoLoader] = useState(false);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        let _data = JSON.parse(localStorage.getItem("__data"));

        if (typeof props?.compData?.companyDetail != "undefined") {
            if (_data) {
                if (!_data?.BOimagesMenu) {
                    setPreLoader(false);
                    setDenied(true)
                } else {
                    setDenied(false)
                }
            }
        }

    }, [props.compData]);

    useEffect(() => {
        setElementData(ref.current);
        getDataOfLid(BOimageIdData);
        getBrandInfo(localStorage.getItem('brandID'));

    }, [localStorage.getItem('brandID')])

    useEffect(() => {
        // console.log("lastInsertIdData,brandLogo",lastInsertIdData,brandLogo)
        if (lastInsertIdData && brandLogo) {
            setPreLoader(false);
        }

    }, [lastInsertIdData, brandLogo])
    /* #endregion */

    /* #region  METHODS */
    const onImgLoad = (e) => {
        let height = targetImageRef.current.clientHeight / renderData?.height;
        let width = targetImageRef.current.clientWidth / renderData?.width;
        setRatio({ h: height, w: width });

    }

    const productOptions = [
        //   { value: 'product-1', label: 'Product 1' },
        //   { value: 'product-2', label: 'Product 2' },
        //   { value: 'product-3', label: 'Product-3' },
    ];
    const locationOptions = [
        //   { value: 'Location-1', label: 'Location 1' },
        //   { value: 'Location-2', label: 'Location 2' },
        //   { value: 'Location-3', label: 'Location-3' },
    ];

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ':hover': {
                backgroundColor: "#205380",
                color: 'white',
            },
        }),
    };

    const getDataOfLid = async (dataLid) => {
        const formData = {
            "id": dataLid
        }
        await axios.post(process.env.REACT_APP_API_URL + "get-boimage-id-data-by-id?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                let da = res.data.resData.Targets;
                setLastInsertIdData(res.data.resData);
                setRenderData(res.data.resData.renderedIamge)
                setImageData(res.data.resData.imageURL)



            } else {
                navigate("/boimages/creater")
            }
            setInfoLoader(true)
        });
    }

    const getBrandInfo = (brandID) => {
        let formData = {
            "id": brandID
        }
        axios.post(process.env.REACT_APP_API_URL + 'get-brand-info?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                if (res?.data?.data?.logos?.mainLogoWhiteURL) {
                    setBrandLogo(res?.data?.data?.logos?.mainLogoWhiteURL)
                } else {
                    setBrandLogo(res?.data?.data?.logos?.mainLogoURL)
                }
            }
        })
    }

    const handelCross = () => {
        window.close();
    }

    const handelBoTag = (boid) => {
        setShowData(true);
        setBeaconID(boid);
    }

    const handelData = () => {
        setBeaconID("");
    }
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" to="#" role="button" className="button-not-active">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">BOimages</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to="#" className="btn btn-success active">Boimages</Link>
                                <Link to="#" className="btn btn-success button-not-active">Edit Boimage</Link>
                                <Link to="/boimages/creater" className="btn btn-success">New Boimage</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas cutomepublisher">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-user-tag"></i></span>
                                        <span className="searchprodicttext">Publisher</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext">Stats</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {(preLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height">   <img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" />  </div></div>
                            :
                            <div className="preview-imgBo">
                                <div className="preview-imgBoBox">
                                    <div className="backtocreator"><Link to="#" onClick={handelCross}><i className="fa-light fa-xmark"></i></Link></div>
                                    <div className="brandWithLogoName"><img className="brandnamelogo" src={brandLogo} /></div>
                                    <div className="imageshowcase target-hashatags-screen">
                                        <div className="sourceimgandname">
                                            <div className="imageupdates">
                                                <div className="image-drag">
                                                    <figure style={{ "position": "relative" }}>
                                                        <img ref={targetImageRef} src={imageData} onLoad={onImgLoad} onClick={handelData} />
                                                        {
                                                            lastInsertIdData?.BOtags?.map((r, i) => {
                                                                return (
                                                                    <>
                                                                        <BeconData data={r} beaconID={beaconID} ratio={ratio} showBecon={showData} />

                                                                        <div id={i + 1} onClick={e => { handelBoTag(r.BotagId) }} style={{ "position": "absolute", "zIndex": "1", "left": (r.beaconXposition * ratio.w) + "px", "top": (r.beaconYposition * ratio.h) + "px" }}  >
                                                                            {/*onClick={e =>{ handelBoTag(r.BotagId)}} */}
                                                                            <div className="custom-class itslinked">
                                                                                <div className="shownumber">{r.BotagId}</div>
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                )
                                                            })
                                                        }

                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}
export default Dashboard;