import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Vector3 from './../../../assets/images/vector-3.svg';
import Vector75 from './../../../assets/images/vector-75.svg';



export default function BeconData({ data, beaconID,showBecon,visible }) {
  
    /*
   const [imageData, setImageData] = useState([]);
    const [dis,setDis] = useState(false);

   
const getImageInformation = async (productID, color) => {
    await axios.post(process.env.REACT_APP_API_URL+"get-image-by-productID?code="+process.env.REACT_APP_API_CODE,{productID: productID, color:color}).then((res)=>{
        if(res.data.status === 200){
            setImageData(res.data.data[0].URL)
           // console.log("as",res.data.data);
        }
    });
}

useEffect(()=>{
   
 if(beaconID==showBecon){
  getImageInformation(data.productID,data.color)
   
 }
},[beaconID,showBecon])

 */

    const [visData,setVisData] = useState(0);
    /*
useEffect(()=>{
   setVisData(0);
    if(beaconID==showBecon){
       if(visible){
         console.log("1")
         setVisData(0);
       }else{
         console.log("2")
         setVisData(1);
       }
    }else{
      console.log("3")
         setVisData(0);
    }
 console.log("visible",visible,beaconID,showBecon);
},[visible,beaconID,showBecon]);

*/

    useEffect(()=>{
 
        if (beaconID==showBecon && visible) {   
            setVisData(0);
        } else if (beaconID==showBecon && !visible) {
            setVisData(1);
        } else {
            setVisData(0);
        }
        console.log('visible',visible,beaconID,showBecon);
    },[visible,beaconID,showBecon]);



    return (
        <> {/*(beaconID==showBecon) */}
            <div className="botag-body botag-body-default product-info-box" style={{ 'zIndex': '9','display': (visData)?'flex':'none' }}>
                <div className="flex-row">
                    <img className="image-41-1" src={data.image} alt="Img" />
                    <div className="flex-col">
                        <div className="brand-product-type"> {localStorage.getItem('brandName')}  </div>
                        <div className="product-name">
                            <div className="dipper-check-s-1 opensans-semi-bold-white-14px">{data.productName}</div>
                        </div>
                        <div className="flex-row-1">
                            <div className="size-circle"title={data.size}>{data.size}</div>
                            <div className="color-circle" style={{ 'backgroundColor': ('#'+data.color).toString() }}></div>
                            <div className="qty">
                                <div className="number opensans-semi-bold-white-14px">1</div>
                                <div className="vector-container">
                                    <i className="fa-solid fa-caret-up"></i>
                                    <i className="fa-solid fa-caret-down"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-col-1">
                    <div className="devider "></div>
                    <div className="delivery-slider">
                        <div className="delivery-options pick-up ">Pick up</div>
                        <div className="delivery-options courier">Courier</div>
                        <div className="delivery-options shipping active">Shipping</div>
                    </div>
                    <div className="flex-row-2">
                        <div className="map-icon">
                            <div className="overlap-group-2"><i className="fa-light fa-location-dot"></i></div>
                        </div>
                        <div className="flex-col-2">
                            <div className="delivery-info">
                                <p className="x230m-at-store opensans-medium-white-10px">
                                    <span className="opensans-medium-stack-10px">(-) from</span><span className="opensans-medium-white-10px-2">&nbsp;</span><span className="opensans-medium-stack-10px">store</span></p>
                            </div>
                            <div className="store-name">[Store name]</div>
                        </div>
                    </div>
                    <div className="flex-col-3">
                        <div className="flex-row-3">
                            <div className="devider devider-6"></div>
                            <p className="kr-delivery-120">Item -kr / Delivery -kr</p>
                            <div className="devider-5 "></div>
                        </div>
                        <div className="flex-col-4">
                            <div className="pricetag ">
                                <div className="x1400-kr opensans-bold-white-18px">[-kr]</div>
                            </div>
                            <div className="flex-row-4">
                                <div className="group-3208"><i className="fa-light fa-eye"></i></div>
                                <div className="group-3207"><img className="vector-28" src={Vector3} alt="Vector" />
                                    <img className="vector-29" src={Vector75} alt="Vector" /></div>
                                <div className="buy-now-button">Buy now</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );



}