import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import './../../assets/css/inputTags.css';
import BrandAside from '../layouts/BrandAside';
import BrandNavigation from '../layouts/BrandDashboardHeader';
import BrandFooter from '../layouts/BrandFooter';
import * as Library from '../../utils/Library';
import styled from 'styled-components';
import LoaderSvg from './../../assets/images/loader.svg';
import LoaderIcon from './../../assets/images/loader-icon.png';
import './../../assets/css/main.css';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

toast.configure();

/* #region  SCOPED CSS */
const Container = styled.div`
		.profile-div{
			height: 120px !important;
		}

		.datatable-section h4{
			color: #D1D1D1;
			margin-right: 10px;
			font-size: 14px;
		}
		
		.datatable-section .search-bar{
			margin: 0;
			border-bottom: 2px solid #1E2631;
		}

		.datatable-section .search-bar >span {
			display: flex;
			line-height: 20px;
			color: #4F5B6D;
			align-items: center;
			font-size: 9pt;
		}

		.filter-section >span {
			line-height: 35px;
			font-size: 10pt;
			margin-right: 10px;
			border-bottom: 2px 
		}

		.filter-section >.search-input{
			border-radius: 0;
			background: #1E2631;
			border-color: #1E2631;	
			box-shadow: none;
			height: 35px;
		}

		.creators-section .cardsection{
			margin-bottom: 10px;	
		}
	`;
/* #endregion */

function Dashboard(props) {
    document.title = 'BOstageB - Store POS';
    const ref = useRef();

    /* #region  STATES */
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [elementData, setElementData] = useState('');
    const [buttonDoneDisable, setButtonDoneDisable] = useState(true);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [preLoader, setPreLoader] = useState(false);

    const [buttonGray, setButtonGray] = useState(true);
    const [disabledbtn, setDisabledbtn] = useState(false);
    const [buttonName, setButtonName] = useState('next');
    const [editMode, setEditMode] = useState(0);
    const [editModeBtnFun, setEditModeBtnFun] = useState(0);
    const [dataReadonly, setDataReadonly] = useState(0);

    //FORM STATES
    const [posProvider, setPosProvider] = useState('');
    const [posProviderError, setPosProviderError] = useState(false);
    const [defaultPOSProvider, setDefaultPOSProvider] = useState('');


    const { allUserInfo, userAppData } = useSelector((state) => state.userData);
    const { storeID } = useParams();

    const [posSectionVisible, setPosSectionVisible] = useState(false);
    const [posName, setPosName] = useState('');
    const [currentPOSData, setCurrentPOSData] = useState({});
    const [allPOSOptions, setAllPOSOptions] = useState({});

    const [allPOSData, setAllPOSData] = useState({});
    const [connectFields, setConnectFields] = useState([]);

    const [allPosFormFields, setAllPosFormFields] = useState([]);
    const [formFieldErrorIndex, setFormFieldErrorIndex] = useState([]);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        if (localStorage.getItem('viewMode') === 'editmode') {
            setEditMode(1);
            setEditModeBtnFun(1);
            setButtonName('SAVE');
        }
    }, [localStorage.getItem('viewMode')]);

    useEffect(() => {
        setElementData(ref.current);
        //setStatus(props?.compData?.completePercentage)
        setStatus(localStorage.getItem('status'));
    }, [localStorage.getItem('status')]);

    useEffect(() => {
        getAllPOS();
    }, []);

    useEffect(() => {
        if (Object.keys(allPOSData).length === 0) return;

        checkStoreID(storeID);
    }, [storeID, allPOSData]);

    /* TODO: FLAGGED FOR DELETION!
	useEffect(() => {
		console.log("changes", "dataReadonly = " + dataReadonly,  "editModeBtnFun = " + editModeBtnFun,  "disabledbtn = " + disabledbtn)
		console.log("isDisabled = " + ((dataReadonly || editModeBtnFun) ? true : disabledbtn));
	}, [dataReadonly, editModeBtnFun,disabledbtn])*/

    useEffect(() => {
        if (!posProvider) {
            setButtonGray(true);
        } else {
            setButtonGray(false);
        }
    }, [posProvider]);

    useEffect(() => {
        handleConnectFieldsValidation();
    }, [allPosFormFields]);

    /*useEffect(() => {
		if (!userAppData) return;

		if (userAppData?.Companies?.length > 0){
			const company = userAppData.Companies[0];
		}
	}, [userAppData])*/

    /*******************save popup********************** */
    const refWarp = useRef(null);   //new datatttt
    useEffect(() => {

        function handleSaveOutside(event) {
            // console.log("parentNode",event.target.parentNode);

            //menu
            const pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
            const pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
            // rightcontrolserch
            const prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
            const prClass = event?.target?.parentNode?.parentNode;
            //center upper btn
            const pCNClass = event?.target?.parentNode;

            if (editMode && !editModeBtnFun) {
                //  console.log("test",editMode ,editModeBtnFun);
                //if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
                if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains('saveIt') || pclass?.classList?.contains('menusections') || pclass?.classList?.contains('navitems-area') || pclassimg?.classList?.contains('navitems-area') || pclassimg?.classList?.contains('menusections') || prClassIcon?.classList?.contains('saveIt') || prClass?.classList?.contains('saveIt') || pCNClass?.classList?.contains('saveIt'))) {
                    Swal.fire({
                        title: '',
                        text: 'Changes will not be saved',
                        icon: 'warning',
                        showDenyButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: '#205380',
                        denyButtonColor: '#205380',
                        confirmButtonText: 'SAVE NOW',
                        denyButtonText: 'CANCEL',
                        background: '#1d2530',
                        color: '#fff',
                        iconColor: '#FF9900',
                        width: '500px',
                        padding: '10px',
                        reverseButtons: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setEditModeBtnFun(1);
                            handleSubmit(event);
                            //document.getElementById("submitBtnC").click();
                        }
                        else if (result.isDenied) {
                            setEditModeBtnFun(1);
                        }

                    });


                }
            }
        }
        document.addEventListener('mousedown', handleSaveOutside);
        return () => {
            document.removeEventListener('mousedown', handleSaveOutside);
        };
    }, [refWarp, editMode, editModeBtnFun]);

    /**************************************************** */
    /* #endregion */

    /* #region  METHODS */
    const checkStoreID = (id) => {
        const formData = { 'id': id };
        Library.makePostRequest('get-store-info', formData, false, setPreLoader).then(res => {
            if (res.data.status !== 200 || !res?.data?.data) {
                navigate('/store');
            }

            const storeData = res?.data?.data;

            if (storeData?.POS && storeData?.POSData) {
                const e = { value: storeData.POS.ID, label: storeData.POSData.name };
                handleSelectPOSProvider(e);

                const tmpArray = [];
                storeData.POS.connectFields.forEach((field) => {
                    const key = Object.keys(field)[0];
                    const value = field[key];
                    tmpArray.push(value);
                });
                //console.log("fields", tmpArray);
                setAllPosFormFields(tmpArray);
            }
        }).catch(error => {
            console.log(error);
            navigate('/store');
        });
    };

    const getAllPOS = () => {
        Library.makePostRequest('getAllPOS', {}, false, setPreLoader).then(res => {
            if (res.data.status === 200) {

                const data = res.data.data;
                const items = [{ value: '', label: 'None' }];
                data.forEach((item) => {
                    items.push({ value: item._id, label: item.name });
                });

                setAllPOSOptions(items);
                setAllPOSData(data);
            }
        });
    };

    const handleSelectPOSProvider = (e) => {
        setPosSectionVisible(e.value.length > 0);
        if (!e.value) {
            setDefaultPOSProvider('');
            setPosProvider('');
            setPosProviderError(true);
            return;
        }

        //sam editMode
        if (editMode) {
            setEditModeBtnFun(0);
        }

        setDefaultPOSProvider(e);
        setPosProvider(e.value);
        setPosProviderError(false);

        setPosName(e.label);
        const match = allPOSData.filter(item => item._id === e.value);
        if (!match) {
            Library.showErrorMessage('We couldn\'t find this POS provider in the Database. Try again later!');
            setPosSectionVisible(false);
            return;
        }

        const posData = match[0];
        const connectFields = posData.connectFields;
        setCurrentPOSData(posData);
        setConnectFields(connectFields);

        setAllPosFormFields(Array(connectFields.length));
    };

    const handleConnectFieldChange = (e, key) => {
        const tmp = [...allPosFormFields];
        tmp[key] = e.target.value;
        setAllPosFormFields(tmp);

        handleConnectFieldsValidation();
    };

    const handleConnectFieldsValidation = () => {
        let formIsValid = true;
        if (!posProvider) {
            setPosProviderError(true);
            formIsValid = false;
        }

        if (connectFields.length !== allPosFormFields.length) {
            formIsValid = false;
            Library.showErrorMessage('Please check if all fields are correct from configure POS Section and try again.');
            return false;
        }

        const formFieldErrors = [];

        for (let i = 0; i < allPosFormFields.length; i++) {
            const field = allPosFormFields[i];

            if (!field) {
                formIsValid = false;
                formFieldErrors.push(i);
            }
        }
        setFormFieldErrorIndex(formFieldErrors);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        if (dataReadonly) {
            //navigate("/store/delivery/" + storeID.id);
        } else {

            e.preventDefault();
            if (handleConnectFieldsValidation()) {
                /*if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
					setDataReadonly(1);
				}*/
                setDisabledbtn(true);
                const formJson = {
                    'storeID': storeID,
                    'POS_ID': posProvider
                };

                const connectFieldsArray = allPosFormFields.map((field, index) => {
                    const tmp = {};
                    tmp[connectFields[index].name] = field;
                    return tmp;
                });

                formJson.connectFields = connectFieldsArray;
                console.log('submit', formJson);

                Library.makePostRequest('updateStorePOS', formJson, false, setSubmitLoader).then(res => {
                    setDisabledbtn(false);

                    if (res.data.status !== 200) return;
                    if (editMode) {
                        setEditModeBtnFun(1);
                    }

                    Library.showSuccessMessage('POS Data successfully updated.');

                    Library.makePostRequest('updateStorePOSProducts', { storeID: storeID });
                });
            }
        }
    };

    /* #endregion */

    /* #region  CONSTANTS */
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: 'transparent',
            border: state.isFocused ? '1px solid #0B0' : '1px solid transparent',
            boxShadow: state.isFocused ? '0px 0px 1px transparent' : 'none',
            '&:hover': {
                border: '1px solid #0B0',
            },
            '&:active': {
                border: '1px solid #0B0',
            }
        }),
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: '1px solid #10141B',
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                '&:hover': {
                    border: '1px solid #00AEEF',
                },
                '&:active': {
                    backgroundColor: '#333F50',
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: '2px',
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px'
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
            ':hover': {
                backgroundColor: '#205380',
                color: 'white',
            },
        }),
    };
    /* #endregion */

    return (
        <Container>
            {(submitLoader)
                ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>
                : ''
            }

            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">
                    <div className="controlbar">
                        <div className="productsearch leftsidetr">
                            <Link data-widget="search" className="button-not-active" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                <span className="searchprodicttext text-center">Stores</span>
                            </Link>
                        </div>
                        <div className="centerbtn">
                            <Link to={(status === 'Completed') ? '/store/list' : '#'} className={(status === 'Completed') ? 'btn btn-success' : 'btn btn-success button-not-active'}>Stores</Link>
                            <Link to={'/store/' + storeID} className={(editMode) ? 'btn btn-success active saveIt' : 'btn btn-success button-not-active'}>Edit Store</Link>
                            <Link to="/store" className={(editMode) ? 'btn btn-success saveIt' : 'btn btn-success active saveIt'}>New Store</Link>
                            <Link to="/store/staff/invites" className={(editMode) ? 'btn btn-success saveIt' : 'btn btn-success active saveIt'}>Staff</Link>
                        </div>
                        <div className="rightcontrolserch">
                            <div className="productsearch rightareas saveIt">
                                <Link data-widget="search" to={'/store/' + storeID} role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
                                    <span className="searchprodicttext">ID</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas saveIt">
                                <Link to={'/store/open/' + storeID} role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
                                    <span className="searchprodicttext">Open</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas saveIt">
                                <Link data-widget="search" to={'/store/delivery/' + storeID} role="button">
                                    <span className="btn btn-success "><i className="fa-thin fa-box"></i></span>
                                    <span className="searchprodicttext text-center">Delivery</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas saveIt">
                                <Link data-widget="search" to={'/store/invite-staff/' + storeID} role="button">
                                    <span className="btn btn-success "><i className="fa-thin fa-people-group"></i></span>
                                    <span className="searchprodicttext text-center">Staff</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas saveIt">
                                <Link data-widget="search" to="#" role="button">
                                    <span className="btn btn-success active"><i className="fa-thin fa-cash-register"></i></span>
                                    <span className="searchprodicttext text-center">POS</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {(preLoader)
                        ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                        :
                        <><div ref={refWarp}>
                            <div className="filtertopcombo">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="filtersection creatorsbtnset enroll-brand ">
                                                <span className={(buttonGray) ? 'customeset textinside next-new-btn disabledbtn' : 'customeset textinside next-new-btn'}>
                                                    <button type="submit" onClick={handleSubmit} className="btn btn-success invite-control" disabled={(dataReadonly || editModeBtnFun) ? true : disabledbtn} >
                                                        {buttonName}
                                                        {(buttonDoneDisable)
                                                            ? (<i className="fas fa-spinner fa-spin"></i>)
                                                            : (<i className="fa-thin fa-chevron-right"></i>)
                                                        }
                                                    </button>
                                                </span>
                                            </div>
                                            <StepCombination cdata={props.compData} />

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="pos-select-section">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="cardsection">
                                                <div className="card-header">
                                                    <h3 className="card-title">Store POS<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group activeborder">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> POS Provider</label>
                                                                            <Select className={(posProviderError) ? 'selects error-input' : 'selects'}
                                                                                value={defaultPOSProvider}
                                                                                onChange={handleSelectPOSProvider}
                                                                                options={allPOSOptions}
                                                                                styles={colourStyles}
                                                                                isDisabled={(dataReadonly) ? true : false}
                                                                                placeholder={<div className="italic-placeholder">Select POS...</div>}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    { /*
																	<div className="col-md-4">
																		<div className="form-group ">
																			<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Last name</label>
																			<input type="text" className="form-control" placeholder="" value={lastName} />
																		</div>
																	</div>
																	*/ }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="row">
                                                                    <div className="col-md-12">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`pos-section ${posSectionVisible ? 'show-transition-faster' : 'hide-transition-faster'}`}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="cardsection">
                                                <div className="card-header">
                                                    <h3 className="card-title">Configure POS - {posName}<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="row">

                                                            {!connectFields
                                                                ? ''
                                                                :
                                                                connectFields.map((field, index) =>
                                                                    <div className="col-md-4" key={index}>
                                                                        <div className="form-group activeborder">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> {field.name}</label>
                                                                            <input
                                                                                type={field?.numbersOnly ? 'number' : 'text'}
                                                                                value={allPosFormFields[index]}
                                                                                onChange={(e) => { handleConnectFieldChange(e, index); }}
                                                                                className={formFieldErrorIndex.includes(index) ? 'form-control error-input' : 'form-control'}
                                                                                placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </Container>
    );
}

export default Dashboard;