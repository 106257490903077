import DataTable, { createTheme } from 'react-data-table-component';

const columns = [
    {
        "name": "Req Ref",
        "selector": "Req_Ref",
        "sortable": true
    },
    {
        "name": "No",
        "selector": "No",
        "sortable": true
    },
    {
        "name": "REQUIREMENT DETAILS",
        "selector": "REQUIREMENT_DETAILS",
        "sortable": true
    },
    {
        "name": "Hrs.",
        "selector": "Hrs",
        "sortable": true
    },
    {
        "name": "Sub Totals",
        "selector": "Sub_Totals",
        "sortable": true
    },
    {
        "name": "Status",
        "selector": "Status",
        "sortable": true
    },
    {
        "name": "Remarks/ Assumptions",
        "selector": "Remarks/_Assumptions",
        "sortable": true
    },
    {
        "name": "Client Remarks, Notes",
        "selector": "Client_Remarks,_Notes",
        "sortable": true
    }
];

const data = [
    {
        "No": "-",
        "Hrs.": 3344,
        "Status": "-",
        "Req_Ref": "Map",
        "REQUIREMENT_DETAILS": "Total Development Effort",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 100,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 1,
        "REQUIREMENT_DETAILS": "UI/ UX Design and Development",
        "Sub_Totals": 16,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 101,
        "Hrs.": 8,
        "Status": 0.4,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "UI/ UX design development and approval",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Mock-up creation and theme finalization",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 102,
        "Hrs.": 8,
        "Status": 0.4,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "HTML Development for required forms and pages",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "HTML 5.0, and CSS development as UI to finalized",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": "-",
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "-",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 200,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 2,
        "REQUIREMENT_DETAILS": "Database and Script Development",
        "Sub_Totals": 33,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 201,
        "Hrs.": 12,
        "Status": 0.25,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Logical and Physical schema design and development",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 202,
        "Hrs.": 21,
        "Status": 0.25,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Script and API development to pull and push data",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": "-",
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "-",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 300,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 3,
        "REQUIREMENT_DETAILS": "Web Portal",
        "Sub_Totals": 1207,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 301,
        "Hrs.": 21,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Home Page/ Landing Page",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 302,
        "Hrs.": 324,
        "Status": 0.8,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Platform ready to enrol any number of Brands and Retailers",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "This may change based on the complexity of registration process.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 303,
        "Hrs.": 345,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Subscription plan page",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Monthly subscription and stripe payment gateway integration",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 304,
        "Hrs.": 8,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Login, Forgot Password",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Change password link will be sent to email",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 305,
        "Hrs.": 8,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Contact Us",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Email will be sent to configured email id, and also storing data into table; customer may like to integrate Lead Management Module in future",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 306,
        "Hrs.": 16,
        "Status": 0.4,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Buying Tab - informatic tabs",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Expecting 3-4 static pages",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 307,
        "Hrs.": 34,
        "Status": 0.4,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Selling Tab - informatic tabs",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Expecting 3-4 static pages",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 308,
        "Hrs.": 435,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Available Markets",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Showing distribution centres - showing on Map",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 309,
        "Hrs.": 16,
        "Status": 0.8,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Other Static Pages - About Us, Team, Services, Terms of Users, Privacy Policy",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": "-",
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "-",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 400,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 4,
        "REQUIREMENT_DETAILS": "Web Application - Admin",
        "Sub_Totals": 798,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 401,
        "Hrs.": 4,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Login and Forgot",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 402,
        "Hrs.": 345,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Managing Brands, and Retailers",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "CRUD operations and facility to filter and export into CSV, print list, and filter options",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 403,
        "Hrs.": 345,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Verification Process",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "eKYC process (third party integration) to validate license of Brand, and Retailers to specific state",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 404,
        "Hrs.": 43,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Dashboard",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Expecting KPIs, charting to show the trend - this estimate may revise if complexity increases as design has not finalized yet.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 405,
        "Hrs.": 5,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Managing Masters",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "e.g., Country, State, Region/ County, Age Groups, Markets - Not considering masters for City, and Zip code",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 406,
        "Hrs.": 56,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Role Management for Brand Users ",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Role, and their Privileges",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": "-",
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "-",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 500,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 5,
        "REQUIREMENT_DETAILS": "Web Application - Brands",
        "Sub_Totals": 934,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 501,
        "Hrs.": 4,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Login and Forgot Password",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 502,
        "Hrs.": 567,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Company and Head Quarters, Profile Management, and Change Password",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Additionally required eKYC form and verification",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 503,
        "Hrs.": 40,
        "Status": 0.4,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Manage Brands - CRUD Operation; Archive and Activate brands",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Describe brand here. From the tabs below, add images, video, collateral and reviews that will showcase your product in the store. If you have more than one brand, click on ADD BRAND to the left.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 504,
        "Hrs.": 65,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Manage Products - CRUD Operation; Archive and Activate products",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Enter your product information here. Add descriptions, images, tasting notes and sales materials. Then price offerings under the MARKETS page.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 505,
        "Hrs.": 12,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Search Brands, and/ Or Product",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 506,
        "Hrs.": 16,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "User Management and Role Assignment; welcome email will be delivered to user.",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "CRUD operations and facility to filter and export into CSV",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 507,
        "Hrs.": 6,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Managing Warehouses and associate users",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "CRUD operations and facility to filter and export into CSV ",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 508,
        "Hrs.": 24,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Generating Invoice for storage, and shipping",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Storage cost, and shipping charges will be separate calculation in warehouse are not scoped",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 509,
        "Hrs.": 40,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Shipping and Receiving products to Warehouse - manage inventory",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Available for Sale",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 510,
        "Hrs.": 8,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Managing Retailers - Associate Contact Person",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "CRUD operations and facility to filter and export into CSV; contact person will be notified through email.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 511,
        "Hrs.": 24,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Dashboard",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Same template will be used only the data will be shown against brand. Showing registration progress, and wizard will disappear once process is completed.",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 512,
        "Hrs.": 64,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Order Module",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Order receives and dispatch process starts",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 513,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Selling Module",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 514,
        "Hrs.": 64,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Inventory Module - Warehouse",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Integration with country mall inventory system",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": "-",
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "-",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 600,
        "Hrs.": "-",
        "Status": "-",
        "Req_Ref": 6,
        "REQUIREMENT_DETAILS": "Web Application - Retailers",
        "Sub_Totals": 356,
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 601,
        "Hrs.": 4,
        "Status": 1,
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Login and Forgot Interface",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 602,
        "Hrs.": 24,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Company and Head Quarters, Profile Management, and Change Password",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Additionally required eKYC form and verification",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 603,
        "Hrs.": 64,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Home",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Watch List, My Lists, Your Favourites, Recommended for Your, Popular Products, Selling Near You, Start Shopping",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 604,
        "Hrs.": 24,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "SHOP - Product Search, and Filter",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Product type, Format (size), Price, Bear type",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 605,
        "Hrs.": 80,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Product catalogue - Product Listing, Add to Cart, Create List - different view (grid, and list)",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 606,
        "Hrs.": 40,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Cart, Purchase Order, and Delivery Confirmation Process",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 607,
        "Hrs.": 40,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Deliveries",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "Export Deliveries, and Invoices - with option to cancel Purchase Order",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 608,
        "Hrs.": 24,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Invoices",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "List of Invoices and facility to download",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 609,
        "Hrs.": 40,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "Orders - Quick Order, Lists, Favourites, Recommended, Invoiced Items",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "-",
        "Client_Remarks,_Notes": "-"
    },
    {
        "No": 610,
        "Hrs.": 16,
        "Status": "-",
        "Req_Ref": "-",
        "REQUIREMENT_DETAILS": "User Management and Role Assignment",
        "Sub_Totals": "-",
        "Remarks/_Assumptions": "CRUD operations and facility to filter and export into CSV",
        "Client_Remarks,_Notes": "-"
    }
];

export default function Table() {
    return (
        <DataTable
            columns={columns}
            data={data}
            theme="solarized"
            defaultSortFieldId={1}
            defaultSortAsc={false}
            pagination
        />
    )
}