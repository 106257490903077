import React, { Component } from "react";
import Draggable from "react-draggable";

function App() {
    return (
        <div style={{ "position": "relative", "top": "90px", "left": "90px", "backgroundColor": "#fff", "width": "1000px", "height": "700px" }}>

            <Draggable bounds="parent">
                <p style={{ width: "150px" }}>I can only be moved within my offsetParent.<br /><br />
                    Both parent padding and child margin work properly.</p>
            </Draggable>

        </div>
    )
}
export default App;
