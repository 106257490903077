import React, { useEffect, useRef, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import BrandAside from '../layouts/BrandAside';
import BrandNavigation from '../layouts/BrandDashboardHeader';
import BrandFooter from '../layouts/BrandFooter';
import './../../assets/css/checkbox.css';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import * as Library from '../../utils/Library';
import { useSelector } from 'react-redux';
import { APP_TITLE } from '../../config/constant';

toast.configure();
createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: '#10141B',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333F50',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

function Dashboard(props) {
    document.title = `${APP_TITLE} - Stores`;

    /* #region  STATES */
    const ref = useRef();
    useNavigate();
    const [elementData, setElementData] = useState('');
    const [preLoader, setPreLoader] = useState(true);
    const [storeDataList, setStoreDataList] = useState([]);
    const [isPagerefresh, setIsPageRefresh] = useState(false);
    const { allUserInfo } = useSelector((state) => state.userData);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setElementData(ref.current);
        localStorage.removeItem('viewMode');
    }, []);

    useEffect(() => {
        if (!allUserInfo) return;
        getStoreList();
    }, [allUserInfo]);

    useEffect(() => {
        if (!allUserInfo) return;
        getStoreList();
    }, [localStorage.getItem('brandID')]);

    useEffect(() => {
        if (isPagerefresh) {
            getStoreList();
        }
        setIsPageRefresh(false);
    }, [isPagerefresh]);
    /* #endregion */

    /* #region  METHODS */
    const getStoreList = () => {
        if (!allUserInfo || !localStorage.getItem('retailBrandID')) return;
        const retailBrandID = localStorage.getItem('retailBrandID');

        const stores = allUserInfo?.Company?._retailBrands
            ?.find(retail => retail.ourID === retailBrandID)
            ?._stores;

        setStoreDataList(stores);
        setPreLoader(false);
    };

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox"
                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const handleDelete = (data) => {
        Swal.fire({
            title: '',
            text: '',
            html: 'Are you sure to delete Store ?',
            icon: 'warning',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'YES',
            denyButtonText: 'NO',
            background: '#1d2530',
            color: '#fff',
            iconColor: 'rgb(255, 153, 0)',
            width: '500px',
            padding: '2em',
            reverseButtons: false
        }).then((result) => {
            if (result.isConfirmed) {
                setPreLoader(true);
                const formData = {
                    storeID: data
                };
                Library.makePostRequest('deleteStore', formData).then(res => {
                    if (res.data.status === 200) {
                        toast.success(res.data.message, {
                            theme: 'dark',
                            icon: ({ theme, type }) => <img alt="Success" src={errorSuccess} />,
                            onClose: setPreLoader(false),
                        });
                        getActiveStoreCount1();
                        getStoreList();

                    } else {

                    }
                });
            }
        });
    };

    const getActiveStoreCount1 = () => {
        const formData = {
            'brandID': localStorage.getItem('brandID')
        };
        Library.makePostRequest('count-active-store-ffc', formData).then(res => {
            if (res.data.status === 200) {
                localStorage.setItem('totalStore', res.data?.data?.totalActiveStore);
                localStorage.setItem('totalFFc', res.data?.data?.totalFFCStore);
            }
        });
    };

    const dataReloadfunction = (e) => {
        setIsPageRefresh(e);
    };
    /* #endregion */

    /* #region  CONSTANTS */
    const columns = [
        {
            name: 'Store ID',
            selector: row => row.ourID,
            width: '125px',
            sortable: true,
        },
        {
            name: 'Store Name',
            selector: row => row.name,
            sortable: true,
            //width: '215px',
            cell: (row) => <><span className="store-name" title={row.name}>{row.name}</span></>
        },
        {
            name: 'Staff',
            selector: row => row.staffCount,
            sortable: true,
            width: '90px',
            cell: (row) => (row.staffCount) ? <>row.staffCount</> : <><span className="data-table-orng">0</span></>
        },
        {
            name: 'City, Country',
            selector: row => row.city,
            sortable: true,
            width: '150px',
            cell: (row) => row.city + ', ' + row.country
        },
        {
            name: 'POS',
            selector: row => (row.POSData && row.POSData.name) ? row.POSData.name : '-',
            sortable: true,
            width: '130px'
        },
        {
            name: 'POS Status',
            selector: row => '-',
            sortable: true,
            width: '150px',
            cell: (row) => !row.POS || !row.POS.status
                ? '-'
                : <span className={row.POS.status === 'Connected'
                    ? 'data-table-green'
                    : 'data-table-orng'
                }>
                    {row.POS.status}
                </span>
        },
        {
            name: 'Last Ping',
            selector: row => row.POS && row.POS.lastPingDate ? row.POS.lastPingDate : '-',
            sortable: true,
            width: '120px'
        },
        {
            name: 'Pickup',
            selector: row => (row.pickup) ? row.pickup : '-',
            sortable: true,
            width: '90px'
        },
        {
            name: 'Courier',
            selector: row => (row.courier) ? row.courier : '-',
            sortable: true,
            width: '90px'
        },
        {
            name: 'Shipping',
            selector: row => (row.shipping) ? row.shipping : '-',
            sortable: true,
            width: '90px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '100px',
            //cell: (row) => (row.staffCount)?<>row.staffCount</>:<><span className="data-table-orng">Not active</span></>
            cell: (row) => <><span className="data-table-green">Active</span></>
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true,
            width: '250px',
            cell: row => <><Link className="btn btn-success" to={'/store/' + row.ourID} onClick={(e) => { localStorage.setItem('viewMode', 'editmode'); }} >Edit</Link> <Link className="btn btn-success buttondim button-not-active" to="#" >Suspend</Link><Link className="btn btn-success " onClick={e => { handleDelete(row.ourID); }} to="#">Remove</Link></>
        },
    ];

    const productOptions = [

    ];

    const locationOptions = [

    ];

    const statusOptions = [

    ];

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: 'transparent',
            border: state.isFocused ? '1px solid #0B0' : '1px solid #333F50',
            boxShadow: state.isFocused ? '0px 0px 1px transparent' : 'none',
            '&:hover': {
                border: '1px solid #0B0',
            },
            '&:active': {
                border: '1px solid #0B0',
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: '1px solid #10141B',
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                '&:hover': {
                    border: '1px solid #00AEEF',
                },
                '&:active': {
                    backgroundColor: '#333F50',
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: '2px',
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px'
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
            ':hover': {
                backgroundColor: '#205380',
                color: 'white',
            },
        }),
    };
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation dataReloadfunction={dataReloadfunction} data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper" >
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                    <span className="searchprodicttext text-center">Stores</span>
                                </Link>
                            </div>
                            <div className="centerbtn">
                                <Link to="/store/list" className="btn btn-success active">Stores</Link>
                                <Link to="#" className="btn btn-success button-not-active">Edit Store</Link>
                                <Link to="/store" className="btn btn-success  ">New Store</Link>
                                <Link to="/store/staff/invites" className="btn btn-success">Staff</Link>
                            </div>
                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas ">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
                                        <span className="searchprodicttext">Grid</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext text-center">Stats</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {(preLoader)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="filtersarea">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="serchbopicspr">
                                                    <form className="form-inline">
                                                        <input className="form-control" type="text" placeholder="Search list:"
                                                            aria-label="Search" disabled='disabled' />
                                                        <span className="customeset gobtn button-not-active">
                                                            <button type="button" className="btn btn-success">
                                                                <i className="fa-thin fa-arrow-right"></i>
                                                            </button>
                                                            <span className="searchprodicttext">Go</span>
                                                        </span>
                                                    </form>
                                                </div>
                                                <div className="filtersection">
                                                    <form className="form-inline" name="filter">
                                                        <p className="form-control-static">Filters:</p>
                                                        <div className="form-group">
                                                            <label htmlFor="collection" className="sr-only">POS status</label>
                                                            <Select
                                                                options={productOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="creator" className="sr-only">Country</label>
                                                            <Select
                                                                options={locationOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="status" className="sr-only">Status</label>
                                                            <Select
                                                                options={statusOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <span className="btnsets products-fit ">
                                                            <span className="customeset revertfilters button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
                                                                <span className="searchprodicttext">Reset</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
                                                                <span className="searchprodicttext">Apply</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
                                                                <span className="searchprodicttext">Refresh</span>
                                                            </span>
                                                        </span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tablestructureser topteninvite"><h3 className="card-title">Stores </h3></div>
                                <div className="tablestructure">
                                    <DataTable
                                        columns={columns}
                                        className='showItems'
                                        data={storeDataList}
                                        theme="solarized"
                                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                        selectableRows
                                        selectableRowsComponent={Checkbox}
                                        defaultSortFieldId={1}
                                        defaultSortAsc={true}
                                        responsive={true}
                                    />
                                </div>
                            </>
                        }
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}

export default Dashboard;