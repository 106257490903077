import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
toast.configure();

function Dashboard(props) {
	document.title = 'BOstageB - Create Company Admin ';

	/* #region  STATES */
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		localStorage.setItem('tel', '');
	});
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />

				<div className="content-wrapper enroll-wrapper">
					
					<div className="controlbar">
						<div className="productsearch leftsidetr">
							<Link data-widget="search" to="#" role="button">
								<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
								<span className="searchprodicttext text-center">Users</span>
							</Link>
						</div>
						<div className="centerbtn">
							<Link to="/account/company" className="btn btn-success active">Company</Link>
							<Link to="/account/company-admin/registration" className="btn btn-success ">Users</Link>
						</div>
						<div className="rightcontrolserch">
							<div className="productsearch rightareas">
								<Link data-widget="search" to="/account/company" role="button">
									<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
									<span className="searchprodicttext">ID</span>
								</Link>
							</div>
							{/* <div className="productsearch rightareas notactive"> */}
							<div className="productsearch rightareas ">
								<Link data-widget="search" to="/account/company/logo" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-sign-hanging"></i></span>
									<span className="searchprodicttext">Logo</span>
								</Link>
							</div>
							<div className="productsearch rightareas notactive">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
									<span className="searchprodicttext">Users</span>
								</Link>
							</div>
						</div>
					</div>

					<div className="filtertopcombo">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<StepCombination cdata={props.compData} />
								</div>
							</div>
						</div>
					</div>

					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="small-formas" >
										<div className="blueicons-area">
											<img src={ExclamtionBlue} />
										</div>
										<p className="verifying-text wt" >Now let's create the brand-account <b>Admin</b>.
											<br /><br />

											The brand-account Admin is the overall manager of the <br />
											BOstageB account for the brand on dispaly in the Control bar <br />
											at the top of the screen.<br /><br />

											If this BOstageB account owner enrolls another brand to this <br />
											account, a new brand-account Admin for this other brand will <br />
											be requested(can be the same).

										</p>

										<div className="send-blockbtn twobtnsets" >
											<Link to="/account/company-admin/pre-company-logo-upload" >SAME AS OWNER</Link>
											<Link to="/account/company-admin/registration" >CREATE ADMIN</Link>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}
export default Dashboard;