import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import * as Library from './../../utils/Library';
import BrandAside from './../layouts/BrandAside';
import BrandNavigation from './../layouts/BrandDashboardHeader';
import BrandFooter from './../layouts/BrandFooter';
toast.configure();

function Dashboard(props) {
    document.title = 'BOstageB - Store ';

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const [elementData, setElementData] = useState('');
    const [denied, setDenied] = useState(false);
    const [onSubmitLoader, setOnSubmitLoader] = useState(false);
    const [companyName, setCompanyName] = useState('');
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        const _data = JSON.parse(localStorage.getItem('__data'));

        if (typeof props?.compData?.companyDetail != 'undefined') {
            if (_data) {
                if (!_data?.StoresMenu) {

                    setDenied(true);
                } else {
                    setDenied(false);
                }
            }
        }

    }, [props.compData]);

    // useEffect(() => {
    // 	if(localStorage.getItem('status') > 75){
    // 		navigate("/store");
    // 	}
    //  }); 

    useEffect(() => {

    });

    useEffect(() => {

        if (typeof props?.compData?.Companies != 'undefined') {

            setCompanyName(props?.compData?.Companies[0]?.companyName);
        }

    }, [props.compData]);
    /* #endregion */

    /* #region  METHODS */
    const handelYes = () => {
        //props.loginFun(localStorage.getItem('token'), '80');
        //console.log(localStorage.getItem('brandID'));
        //let compName = "";
        //  if (typeof props?.compData?.Companies != 'undefined') {

        // }

        const formData = {
            'brandID': localStorage.getItem('brandID'),
            'companyName': props?.compData?.Companies[0]?.companyName
        };

        setOnSubmitLoader(true);

        Library.makePostRequest('insert-retail-brand-data', formData).then(res => {
            if (res.data.status == 200) {
                props.loginFun(localStorage.getItem('token'), '80');
                navigate('/store');
                toast.success(res.data.message,
                    {
                        theme: 'dark',
                        icon: ({ theme, type }) => <img src={errorSuccess} />,
                        onClose: () => {
                            // navigate("/store");
                        }
                    });
            } else {
                toast.error(res.data.message, {
                    theme: 'dark',
                    icon: ({ theme, type }) => <img src={errorWarning} />
                });
            }
        });



        // navigate("/store");
    };

    const handelNo = () => {

        navigate('/store/retail-brand-logo');
    };
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                    <span className="searchprodicttext text-center">Stores</span>
                                </Link>
                            </div>
                            <div className="centerbtn">
                                <Link to="#" className="btn btn-success button-not-active">Store</Link>
                                <Link to="#" className="btn btn-success active ">New Store</Link>

                            </div>
                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas ">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
                                        <span className="searchprodicttext">ID</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-clock"></i></span>
                                        <span className="searchprodicttext">Open</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-box"></i></span>
                                        <span className="searchprodicttext text-center">Delivery</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-people-group"></i></span>
                                        <span className="searchprodicttext text-center">Staff</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="filtertopcombo">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') ?
                                                    <div className="labelgreentext billing-for infoicons"><i className="fas fa-info-circle" aria-hidden="true"></i> <span className="green-tet-name">Enrolling store to company:<br /><span className="itvalue">{companyName}</span></span></div> : ''
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <StepCombination cdata={props.compData} />
                                            </div>
                                            <div className="col-md-3">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="creators-section">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="small-formas pre-storebox" >
                                            <div className="blueicons-area">
                                                <img src={ExclamtionBlue} />
                                            </div>
                                            <p className="verifying-text wt" >Do those stores go by the same logo/name as your Brand? If the logo/name is different answer 'No' so you can upload the stores' logo and name.</p>

                                            <div className="send-blockbtn twobtnsets similarsize" >
                                                {/* <Link to="/store/retail-brand-logo" >No</Link> 
                                    <Link to="/store" onClick={handelYes} >Yes</Link> */}
                                                <button type="submit" className="btn btn-primary " onClick={handelNo} >No</button>
                                                <button type="submit" className="btn btn-primary " onClick={handelYes} >Yes</button>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
            {(onSubmitLoader)
                ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>
                : ''
            }
        </>
    );
}

export default Dashboard;