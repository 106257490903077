import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import MenuIsOn from './../../assets/images/dashboardicon.png';
import MenuIsOff from './../../assets/images/dashoboardoff.png';
import BOimageIsOn from './../../assets/images/boimageson.png';
import BOimageIsOff from './../../assets/images/boimagesoff.png';
import FulfillmentOn from './../../assets/images/fullfillmenton.png';
import FulfillmentOff from './../../assets/images/fullfillmentoff.png';
import StoresOn from './../../assets/images/storeson.png';
import StoresOff from './../../assets/images/storesoff.png';
import BrandOn from './../../assets/images/brandon.png';
import BrandOff from './../../assets/images/brandoff.png';
import RetailersOn from './../../assets/images/retailerson.png';
import RetailersOff from './../../assets/images/retailersonoff.png';
import ProductisOn from './../../assets/images/productison.png';
import ProductisOff from './../../assets/images/productisoff.png';
import AccountOn from './../../assets/images/accounton.png';
import AccountOff from './../../assets/images/accountoff.png';
import BillingOn from './../../assets/images/billingon.png';
import BillingOff from './../../assets/images/Billingoff.png';
import SettingOn from './../../assets/images/settingon.png';
import SupportOn from './../../assets/images/supporton.png';
import PromoterOff from './../../assets/images/promotersoff.png';
import Tooltip from '@mui/material/Tooltip';
import MenuWarning from './../../assets/images/menu-warning.png';

function BrandAside(props) {

    /* #region  EFFECTS */
    useEffect(() => {
        if (localStorage.getItem('brandID')) {
            getActiveStoreCount();
        }

    }, [localStorage.getItem('brandID'), localStorage.getItem('totalStore'), localStorage.getItem('totalFFc')]);

    const getActiveStoreCount = () => {
        const formData = {

            'brandID': localStorage.getItem('brandID')
        };
        axios.post(process.env.REACT_APP_API_URL + 'count-active-store-ffc?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status === 200) {
                //	console.log("dataStore",res.data?.data?.totalActiveStore);
                localStorage.setItem('totalStore', res.data?.data?.totalActiveStore);
                localStorage.setItem('totalFFc', res.data?.data?.totalFFCStore);
            }
        });
    };
    /* #endregion */

    /* #region  METHODS */
    const navigate = useNavigate();
    const checkUserLoggedIn = () => {
        const auth = localStorage.getItem('token');
        if (!auth) {
            navigate('/');
        }
    };
    setInterval(checkUserLoggedIn, 2000);
    const onMouseOver = (e) => {
        if (e.currentTarget.getElementsByTagName('a')[0].classList.contains('active')) {
            e.currentTarget.classList.remove('remove-uper-border');
            e.currentTarget.classList.remove('remove-bottom-border');
        }
        if (e.currentTarget.nextSibling) {
            if (e.currentTarget.nextSibling.getElementsByTagName('a')[0].classList.contains('active')) {
                e.currentTarget.nextSibling.classList.add('remove-uper-border');
            } else {
                const liTag = document.getElementById('__navbar').getElementsByTagName('li');
                var elemsLenght = liTag.length;
                for (var i = 0; i < elemsLenght; ++i) {
                    if (liTag[i].classList.contains('remove-uper-border')) {
                        liTag[i].classList.remove('remove-uper-border');
                    }
                }
            }
        }
        if (e.currentTarget.previousSibling) {
            if (e.currentTarget.previousSibling.getElementsByTagName('a')[0].classList.contains('active')) {
                e.currentTarget.previousSibling.classList.add('remove-bottom-border');
            } else {
                const liTag = document.getElementById('__navbar').getElementsByTagName('li');
                var elemsLen = liTag.length;
                for (var j = 0; j < elemsLen; ++j) {
                    if (liTag[j].classList.contains('remove-bottom-border')) {
                        liTag[j].classList.remove('remove-bottom-border');
                    }
                }
            }
        }

    };
    const onMouseLeave = (e) => {
        const liTag = document.getElementById('__navbar').getElementsByTagName('li');
        var elemsLenght = liTag.length;
        for (var i = 0; i < elemsLenght; ++i) {
            if (liTag[i].classList.contains('remove-bottom-border') || liTag[i].classList.contains('remove-uper-border')) {
                liTag[i].classList.remove('remove-bottom-border');
                liTag[i].classList.remove('remove-uper-border');
            }
        }
    };

    const BrandNavigate = () => {
        (props.cdata?.completePercentage === 'Completed' || props.cdata?.status === '100') ? navigate('/brand') : navigate('/brand/creator');
    };

    const BOimagesNavigate = () => {
        //	(props.cdata?.completePercentage==="Completed" || props.cdata?.status==="100")?navigate("/boimages"):(localStorage.getItem("boimageID") != "undefined")?navigate("/boimages/creater/"+localStorage.getItem("boimageID")):navigate("/boimages/creater");
        (props.cdata?.completePercentage === 'Completed' || props.cdata?.status === '100') ? navigate('/boimages') : (localStorage.getItem('boimageID') != 'undefined') ? navigate('/boimages/canvas/' + localStorage.getItem('boimageID')) : navigate('/boimages/canvas');
    };

    const handleOrdersNavigation = (e) => {
        e.preventDefault();
        if (props.cdata?.completePercentage === 'Completed' || props.cdata?.status === '100') {
            navigate('/orders');
        } else {
            navigate('/orders/importer');
        }
    };

    const handleProductNavigation = (e) => {
        e.preventDefault();
        if (props.cdata?.completePercentage === 'Completed' || props.cdata?.status === '100') {
            navigate('/products');
        } else {
            navigate('/products/importer');
        }
    };

    const storeHandel = (e) => {
        e.preventDefault();
        if (props.cdata?.status == '75') {
            navigate('/store/pre-store');
        } else {
            if (props.cdata?.completePercentage === 'Completed') {
                navigate('/store/list');
            } else {
                navigate('/store');
            }

        }
    };

    const retailHandel = (e) => {
        e.preventDefault();

        if (props.cdata?.completePercentage === 'Completed') {
            navigate('/retailer');
        } else {
            navigate('/retailer/create');
        }

    };

    const billingHandel = (e) => {
        e.preventDefault();
        if (props.cdata?.status == '95') {
            navigate('/billing-plan/pre-billing');
        } else {
            navigate('/billing-plan');
        }
    };

    function capitalize(s) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    const dataFilter = (data) => {
        const arrOfPlan = ['Pilot', 'BOimage', 'Influencers', 'Business'];
        const billPlan = capitalize(data);
        const res = arrOfPlan.includes(billPlan);
        if (res) {
            return data;
        } else {
            return '-';
        }
    };

    const clickImage = () => {

        if (localStorage.getItem('status') >= 10 || localStorage.getItem('status') == 'Completed') {
            navigate('/account/company/logo');
        }
        // console.log("image click")
    };

    const handleAccountMenu = (e) => {
        e.preventDefault();
        (!(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed')) ? navigate('/account/company') : navigate('/account/company/list');
    };
    /* #endregion */

    return (
        <>
            <aside className="main-sidebar">
                {/* <div className="custom-logo"> */}
                {/* <div className="logoname">
			      <img alt="" className="leftmainlogo" src={LeftLogo} />			      
			      </div>   */}
                <div className="user-profile-height stageb">
                    <div className={(JSON.parse(localStorage.getItem('__data'))?.companyLogo) ? 'custom-logo' : 'custom-logo withoutlogo'}>
                        <div className={(JSON.parse(localStorage.getItem('__data'))?.companyLogo) ? 'logoname logoimg' : 'logoname'} >
                            { // (props.logoImg!="" && props.logoImg !=undefined)? <><img alt="" className="leftmainlogo" src={props.logoImg} />{(localStorage.getItem('status')==100 || localStorage.getItem('status')=='Completed')?<span className="btn btn-success editComp" onClick={clickImage}><i className="fa-thin fa-edit"></i></span>:""}</> :
                                (JSON.parse(localStorage.getItem('__data'))?.companyLogo) ? <><img alt="" className="leftmainlogo" src={JSON.parse(localStorage.getItem('__data'))?.companyLogo} />{(localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed') ? <span className="btn btn-success editComp saveIt" onClick={clickImage}><i className="fa-thin fa-edit saveIt"></i></span> : ''}</> : (<><span className="maincompany">Company Logo</span><small>Max 200x90px 100kb</small></>)
                            }

                        </div>
                        <div className="itemsas ">{JSON.parse(localStorage.getItem('__data'))?.companyName}</div>
                    </div>
                    <div className="itemscenter">
                        <ul>
                            <li>Current status: <span className={(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? 'crop-guidelines' : (localStorage.getItem('status') == 100) ? 'brandgreens' : 'orng'}>{(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? 'Active' : JSON.parse(localStorage.getItem('__data'))?.completePercentage}</span> </li>

                            <li className="notactivateds">Billing plan: <span className={(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? 'crop-guidelines' : 'orng'}>{dataFilter(props?.cdata?.companyDetail?.billingPlan)}</span> {(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? <span className="checktext">Change</span> : ''}</li>
                            <li className="notactivateds">Group plan: <span className={(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? 'crop-guidelines' : (localStorage.getItem('status') == 100) ? 'brandgreens' : 'orng'}>{localStorage.getItem('groupName')}</span></li>
                            <li>Active stores: <span className={(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed' && localStorage.getItem('totalStore') > 0) ? 'crop-guidelines' : (localStorage.getItem('status') == 100) ? 'brandgreens' : 'orng'}> {(localStorage.getItem('totalStore')) ? localStorage.getItem('totalStore') : 0}  </span> {(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? <span className="checktext">Check</span> : ''} </li>
                            <li>Active FFcenters: <span className={(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed' && localStorage.getItem('totalFFc') > 0) ? 'crop-guidelines' : (localStorage.getItem('status') == 100) ? 'brandgreens' : 'orng'}>  {(localStorage.getItem('totalFFc')) ? localStorage.getItem('totalFFc') : 0}  </span> {(JSON.parse(localStorage.getItem('__data'))?.completePercentage == 'Completed') ? <span className="checktext">Check</span> : ''} </li>

                        </ul>
                    </div>
                </div>
                <div className="menusections">
                    <div className="navitems-area" id="__navbar">
                        <ul onMouseLeave={onMouseLeave}>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.DashboardMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {/* <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.DashboardMenu)?<img alt="MenuIsOn" className="menuison" src={MenuIsOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />}
			                </div>
			              Dashboard
			              </div>
			            </Link> */}
                                {(JSON.parse(localStorage.getItem('__data'))?.DashboardMenu) ?
                                    <NavLink to="/dashboard" >
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.DashboardMenu) ? <img alt="MenuIsOn" className="menuison" src={MenuIsOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />}
                                            </div>
											Dashboard
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.DashboardMenu) ? <img alt="MenuIsOn" className="menuison" src={MenuIsOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />}
                                            </div>
											Dashboard
                                        </div>
                                    </Link>
                                }

                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.BOimagesMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.BOimagesMenu) ?
                                    <NavLink to="/boimages" onClick={(e) => { e.preventDefault(); BOimagesNavigate(); }}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.BOimagesMenu) ? <img alt="MenuIsOn" className="menuison" src={BOimageIsOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={BOimageIsOff} />}
                                            </div>
											BOimages
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.BOimagesMenu) ? <img alt="MenuIsOn" className="menuison" src={BOimageIsOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={BOimageIsOff} />}
                                            </div>
											BOimages
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.FullfillmentCenterMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.FullfillmentCenterMenu) ?
                                    <NavLink to="/fulfillmentcenter" >
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.FullfillmentCenterMenu) ? <img alt="MenuIsOn" className="menuison" src={FulfillmentOn} /> :
                                                    <img alt="menuisoff" className="MenuIsOff" src={FulfillmentOff} />}
                                            </div>
											Fulfillment Centers
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.FullfillmentCenterMenu) ? <img alt="MenuIsOn" className="menuison" src={FulfillmentOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={FulfillmentOff} />}
                                            </div>
											Fulfillment Centers
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.StoresMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.StoresMenu) ?
                                    <NavLink to="/store" onClick={storeHandel}>
                                        {false && (localStorage.getItem('totalStore') >= 1) ? <Tooltip
                                            title="One or more stores is missing information. Click this menu button to provide missing information and activate the store."
                                            arrow
                                            placement={'top-start'}
                                        >
                                            <img className="custom-faicon caution-menu" src={MenuWarning} />
                                        </Tooltip> : ''}
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.StoresMenu) ? <img alt="MenuIsOn" className="menuison" src={StoresOn} /> :
                                                    <img alt="menuisoff" className="MenuIsOff" src={StoresOff} />}
                                            </div>
											Stores
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.StoresMenu) ? <img alt="MenuIsOn" className="menuison" src={StoresOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={StoresOff} />}
                                            </div>
											Stores
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.RetailersMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.RetailersMenu) ?
                                    <NavLink to="/retailer" onClick={retailHandel}  >
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.RetailersMenu) ? <img alt="MenuIsOn" className="menuison" src={RetailersOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={RetailersOff} />}
                                            </div>
											Retailers
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.RetailersMenu) ? <img alt="MenuIsOn" className="menuison" src={RetailersOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={RetailersOff} />}
                                            </div>
											Retailers
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.OrdersMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.OrdersMenu) ?
                                    <NavLink to="/orders" onClick={handleOrdersNavigation} >
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.OrdersMenu) ? <i className="fa-xl fa-solid fa-hand-holding-box" aria-hidden="true"></i> :
                                                    <i className="fa-xl fa-solid fa-hand-holding-box" aria-hidden="true"></i>}
                                            </div>
											Orders
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.OrdersMenu) ? <i className="fa-xl fa-solid fa-hand-holding-box" aria-hidden="true"></i> :
                                                    <i className="fa-xl fa-solid fa-hand-holding-box" aria-hidden="true"></i>}
                                            </div>
											Orders
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.ProductsMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.ProductsMenu) ?
                                    <NavLink to="/products" onClick={handleProductNavigation} >
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.ProductsMenu) ? <img alt="MenuIsOn" className="menuison" src={ProductisOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={ProductisOff} />}
                                            </div>
											Products
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.ProductsMenu) ? <img alt="MenuIsOn" className="menuison" src={ProductisOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={ProductisOff} />}
                                            </div>
											Products
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.BrandsMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.BrandsMenu) ?
                                    <NavLink to="/brand" onClick={(e) => { e.preventDefault(); BrandNavigate(); }}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.BrandsMenu) ? <img alt="BrandOn" className="menuison" src={BrandOn} /> :
                                                    <img alt="BrandOff" className="menuisoff" src={BrandOff} />}
                                            </div>
											Brands
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.BrandsMenu) ? <img alt="MenuIsOn" className="menuison" src={BrandOn} /> :
                                                    <img alt="MenuIsOff" className="menuisoff" src={BrandOff} />}
                                            </div>
											Brands
                                        </div>
                                    </Link>
                                }
                            </li>
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.PromotersMenu) ? 'MenuIsoff ' : 'MenuIsoff'}>
                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                    <div className="menuimages">
                                        <div className="menuimgbg">
                                            {(JSON.parse(localStorage.getItem('__data'))?.PromotersMenu) ? <img alt="BrandOn" className="menuison" src={PromoterOff} /> :
                                                <img alt="BrandOff" className="menuisoff" src={PromoterOff} />}
                                        </div>
										Promoters
                                    </div>
                                </Link>
                            </li>
                            {/* <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.PromotersMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.PromotersMenu)? <img alt="BrandOn"  className="menuison" src={PromoterOn} />:
			                  <img alt="BrandOff"  className="menuisoff" src={PromoterOff} /> }
			                </div>
			                Promoters
			              </div>
			            </Link>
			          </li> */}
                            <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem('__data'))?.AccountMenu) ? 'fullarea menuisonstate ' : 'MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.AccountMenu) ?
                                    <NavLink to="/account" onClick={handleAccountMenu}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.AccountMenu) ? <img alt="menuison" className="menuison" src={AccountOn} /> :
                                                    <img alt="menuisoff" className="menuisoff" src={AccountOff} />}
                                            </div>
											Account
                                        </div>
                                    </NavLink>
                                    :
                                    <Link to="/account/company" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <div className="menuimgbg">
                                                {(JSON.parse(localStorage.getItem('__data'))?.AccountMenu) ? <img alt="menuison" className="menuison" src={AccountOn} /> :
                                                    <img alt="menuisoff" className="menuisoff" src={AccountOff} />}
                                            </div>
											Account
                                        </div>
                                    </Link>
                                }
                            </li>

                        </ul>
                    </div>
                    <div className="blankspacearea">
                    </div>
                    <div className="navitems-area bottom-section">
                        <ul>
                            <li className="halfarea">
                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                    <div className="menuimages">
                                        <img alt="setting-on" src={SettingOn} />
                                    </div>
									Settings
                                </Link>
                            </li>

                            <li className={(JSON.parse(localStorage.getItem('__data'))?.BillingMenu) ? 'halfarea ' : 'halfarea MenuIsoff'}>
                                {(JSON.parse(localStorage.getItem('__data'))?.BillingMenu) ?
                                    <NavLink to="/billing-plan" onClick={billingHandel} >
                                        {(localStorage.getItem('status') == 'Completed' || localStorage.getItem('status') == 100) ? <Tooltip
                                            arrow
                                            placement={'top-start'}
                                        >
                                            <img className="custom-faicon caution-menu" src={MenuWarning} />
                                        </Tooltip> : ''}

                                        <div className="menuimages">
                                            {(JSON.parse(localStorage.getItem('__data'))?.BillingMenu) ? <img alt="menuison" className="menuison" src={BillingOn} /> :
                                                <img alt="menuisoff" className="menuisoff" src={BillingOff} />}
                                        </div>
										Billing

                                    </NavLink>
                                    :
                                    <Link to="#" onClick={(e) => e.preventDefault()}>
                                        <div className="menuimages">
                                            <img alt="billing-on" src={BillingOff} />
                                        </div>
										Billing
                                    </Link>
                                }
                            </li>

                            <li className="halfarea">
                                <Link to="#" onClick={(e) => e.preventDefault()}>
                                    <div className="menuimages">
                                        <img alt="SupportOn" src={SupportOn} />
                                    </div>
									Support
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default BrandAside;