import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
toast.configure();

function Dashboard(props) {
	document.title = 'BOstageB - Brand Logo';

	/* #region  STATES */
	const ref = useRef();
	let navigate = useNavigate();
	let { id } = useParams();
	const [elementData, setElementData] = useState("");

	const [denied, setDenied] = useState(false)
	const [showPreLoader, setShowPreLoader] = useState(true)
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {

					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {
		// if(!props?.compData?.BrandsMenu){
		// 	navigate("/account/company");
		// }
	}, [props?.compData]);

	useEffect(() => {
		checkBrandID(id);
	}, [id]);

	/* #endregion */

	/* #region  METHODS */
	const checkBrandID = (id) => {
		let formData = { "id": id }

		axios.post(process.env.REACT_APP_API_URL + "get-brand-info?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status != 200) {
				navigate("/brand/creator");
			} else {
				setShowPreLoader(false);
			}
		})


	}
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fas fa-search" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to="/brand" className="btn btn-success">Brands</Link>
								<Link to="/brand/creator" className="btn btn-success ">Creator</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success "><i className="fas fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className="productsearch rightareas notactive">
									<Link to="#" role="button">
										<span className="btn btn-success"><i className="fas fa-sign"></i></span>
										<span className="searchprodicttext">Logos</span>
									</Link>
								</div>
								<div className="productsearch rightareas notactive">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success"><i className="fas fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>

						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div className="filtertopcombo">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<StepCombination cdata={props.compData} />
											</div>
										</div>
									</div>
								</div>

								<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="small-formas" >
													<div className="blueicons-area">
														<img src={ExclamtionBlue} />
													</div>
													<p className="verifying-text wt" >Now time to</p>
													<p className="verifying-text wt" > UPLOAD LOGOS  </p><br />
													<p className="verifying-text wt" >You'll be able to upload two versions of {props.compData?.activeBrand?.brandName} logos: the normal logo and a short version logo if you have it.The short<br />version is to be used when the normal version is too wide or <br /> too tall to fit the logo placeholder.</p><br /><br />
													<br />
													<p className="wt-color">Uploading png files is highly recommended(transparent background).<br /> Max 200 x 90px 300Kb</p>


													<br /><br />

													<div className="send-blockbtn twobtnsets" >
														<Link to={"/brand/logo/" + id} >UPLOAD LOGOS</Link>

													</div>


												</div>
											</div>

										</div>
									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>

		</>
	);
}

export default Dashboard;