import update from "immutability-helper";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { read, utils } from "xlsx";
import "./../../../assets/css/checkbox.css";
import LoaderIcon from "./../../../assets/images/loader-icon.png";
import LoaderSvg from "./../../../assets/images/loader.svg";
import BrandAside from "./../../layouts/BrandAside";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandFooter from "./../../layouts/BrandFooter";
import { Box } from "./Box.js";
import { Dustbin } from "./Dustbin.js";
import Select from "react-select";
import * as Library from "../../../utils/Library";
import _ from "lodash";

toast.configure();
createTheme("solarized", {
    text: {
        primary: "#fff",
        secondary: "#fff",
    },
    background: {
        default: "#10141B",
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF",
    },
    divider: {
        default: "#333F50",
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
    },
}, "dark");

function Dashboard(props) {
    document.title = "BOstageB - Product Importer";

    const obj = {};

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const [elementData, setElementData] = useState("");
    const [progressBar, setProgressBar] = useState(0);
    const [brandID, setBrandID] = useState("");
    const [preLoader, setPreLoader] = useState(true);
    const [onSubmitLoader, setOnSubmitLoader] = useState(false);
    const [filePreviewButtonDisable, setFilePreviewButtonDisable] = useState(true);
    const [saveButtonDisable, setSaveButtonDisable] = useState(true);
    const [productList, setProductList] = useState([]);
    const [fileName, setFileName] = useState("");
    const [detectedFields, setDetectedFields] = useState([]);
    const [draggedData, setDraggedData] = useState([]);
    const [previousPosData, setPreviousPosData] = useState([]);
    const [chevShow, setChevShow] = useState(0);
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [temD, setTemD] = useState([]);

    const [dataS, setDataS] = useState([]);
    const [imageFieldsAreURLs, setImageFieldsAreURLs] = useState(false);

    const [formDataFinal, setFormDataFinal] = useState([]);

    const [droppedBoxNames, setDroppedBoxNames] = useState([]);
    const [dustbins, setDustbins] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Product Name" }]);
    const [dustbins1, setDustbins1] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Description" }]);
    const [dustbins2, setDustbins2] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Short Description" }]);
    const [dustbins3, setDustbins3] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Composition" }]);
    const [dustbins4, setDustbins4] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Maintenance" }]);
    const [dustbins5, setDustbins5] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Country of origin" }]);
    const [dustbins6, setDustbins6] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Color Name" }]);
    const [dustbins7, setDustbins7] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Size" }]);
    const [dustbins8, setDustbins8] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Barcode" }]);
    const [dustbins9, setDustbins9] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Reference Code" }]);
    const [dustbins10, setDustbins10] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Main Image" }]);
    const [dustbins11, setDustbins11] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Eco Statement" }]);
    const [dustbins12, setDustbins12] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Factory/Origin" }]);
    const [dustbins13, setDustbins13] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Image 2" }]);
    const [dustbins14, setDustbins14] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Image 3" }]);
    const [dustbins15, setDustbins15] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Image 4" }]);
    const [imageFiveDustbin, setImageFiveDustbin] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Image 5" }]);
    const [productTypeDustbin, setProductTypeDustbin] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Product Type" }]);
    const [colorCodeDustbin, setColorCodeDustbin] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Color Code" }]);
    const [noteDustbin, setNoteDustbin] = useState([{ accepts: ["text"], lastDroppedItem: null, label: "Note" }]);

    const [nameRequired, setNameRequired] = useState(false);
    const [descRequired, setDescRequired] = useState(false);
    const [shortDescRequired, setShortDescRequired] = useState(false);
    const [compositionRequired, setCompositionRequired] = useState(false);
    const [maintenanceRequired, setMaintenanceRequired] = useState(false);
    const [cOORequired, setCOORequired] = useState(false);
    const [colorRequired, setColorRequired] = useState(false);
    const [sizeRequired, setSizeRequired] = useState(false);
    const [isProductTypeRequired, setIsProductTypeRequired] = useState(false);
    const [referenceCodeRequired, setReferenceCodeRequired] = useState(false);
    const [barcodeRequired, setBarcodeRequired] = useState(false);
    const [mainImageRequired, setMainImageRequired] = useState(false);
    const [image2Required, setImage2Required] = useState(false);
    const [image3Required, setImage3Required] = useState(false);
    const [image4Required, setImage4Required] = useState(false);
    const [colorCodeRequired, setColorCodeRequired] = useState(false);
    const [ecoStatementRequired, setEcoStatementRequired] = useState(false);
    const [factoryOriginRequired, setFactoryOriginRequired] = useState(false);
    const [noteRequired, setNoteRequired] = useState(false);
    const [image5Required, setImage5Required] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [dataRowCount, setDataRowCount] = useState(10);

    const [isPosUpload, setIsPosUpload] = useState(false);
    const [removePosSections, setRemovePosSections] = useState(true);

    //FORM STATES
    const [posProvider, setPosProvider] = useState("");
    const [posProviderError, setPosProviderError] = useState(false);
    const [defaultPOSProvider, setDefaultPOSProvider] = useState("");
    const [allPOSOptions, setAllPOSOptions] = useState({});
    const [posSectionVisible, setPosSectionVisible] = useState(false);
    const [posName, setPosName] = useState("");
    const [buttonGray, setButtonGray] = useState(true);


    const [currentPOSData, setCurrentPOSData] = useState({});
    const [allPOSData, setAllPOSData] = useState({});
    const [connectFields, setConnectFields] = useState([]);

    const [allPosFormFields, setAllPosFormFields] = useState([]);
    const [formFieldErrorIndex, setFormFieldErrorIndex] = useState([]);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setPreLoader(false);
        setElementData(ref.current);
        setBrandID(localStorage.getItem("brandID"));
    }, [localStorage.getItem("brandID")]);

    useEffect(() => {
        setPreviousPosData(previousPosData);
    }, [previousPosData]);

    //TODO: THIS SEEMS TO DO NOTHING AT ALL
    useEffect(() => {
        temD.map((item, index) => {
            const inss = detectedFields.map(e => e.name).indexOf(item?.itemName);
            item.leftItemIndex = inss;
            return item;
        });
    }, [temD]);

    useEffect(() => {
        setDetectedFields(detectedFields);
    }, [detectedFields]);

    useEffect(() => {
        if (previousPosData.length && detectedFields.length) {
            previousPosData.forEach((_v, _i) => {
                const ins = detectedFields.map(f => f?.name).indexOf(_v?.itemName);
                if (_v.itemName === detectedFields[ins]?.name) {
                    const item = { name: _v?.itemName };
                    setDroppedBoxNames(
                        update(droppedBoxNames, _v?.boxName ? { $push: [_v?.boxName] } : { $push: [] }),
                    );
                    if (_v.boxName === "Product Name") {
                        setDustbins(
                            update(dustbins, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Description") {
                        setDustbins1(
                            update(dustbins1, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Product Type") {
                        setProductTypeDustbin(
                            update(dustbins1, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Short Description") {
                        setDustbins2(
                            update(dustbins2, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Composition") {
                        setDustbins3(
                            update(dustbins3, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Maintenance") {
                        setDustbins4(
                            update(dustbins4, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Country of origin") {
                        setDustbins5(
                            update(dustbins5, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Color Name") {
                        setDustbins6(
                            update(dustbins6, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Size") {
                        setDustbins7(
                            update(dustbins7, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Barcode") {
                        setDustbins8(
                            update(dustbins8, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Reference Code") {
                        setDustbins9(
                            update(dustbins9, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Main Image") {
                        setDustbins10(
                            update(dustbins10, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Eco Statement") {
                        setDustbins11(
                            update(dustbins11, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Factory/Origin") {
                        setDustbins12(
                            update(dustbins12, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Image 2") {
                        setDustbins13(
                            update(dustbins13, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Image 3") {
                        setDustbins14(
                            update(dustbins14, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Image 4") {
                        setDustbins15(
                            update(dustbins15, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Image 5") {
                        setImageFiveDustbin(
                            update(imageFiveDustbin, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Note") {
                        setNoteDustbin(
                            update(noteDustbin, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                    if (_v.boxName === "Color Code") {
                        setColorCodeDustbin(
                            update(colorCodeDustbin, {
                                0: {
                                    lastDroppedItem: {
                                        $set: item,
                                    },
                                    label: { $set: _v.boxName }
                                },
                            }),
                        );
                    }
                }
                // console.log(_v.itemName, detectedFields[ins])
            });
        }
    }, [previousPosData, detectedFields]);

    useEffect(() => {
        if (isPosUpload)
            if (_.isEmpty(allPOSData)) getAllPOS();
    }, [isPosUpload]);

    useEffect(() => {
        if (_.isEmpty(allPOSData)) return;
        
        if (isPosUpload) brandInfo(true);
    }, [allPOSData]);

    useEffect(() => {
        handleConnectFieldsValidation();
    }, [allPosFormFields]);
    /* #endregion */

    /*setTimeout(() => {
        setSaveButtonDisable(false);
        setOnSubmitLoader(false);
    }, 5000);*/

    /* #region  METHODS */
    const handleConnectFieldChange = (e, key) => {
        const tmp = [...allPosFormFields];
        tmp[key] = e.target.value;
        setAllPosFormFields(tmp);

    };

    const handleConnectFieldsValidation = () => {
        let formIsValid = true;
        if (!posProvider) {
            setPosProviderError(true);
            formIsValid = false;
            setButtonGray(true);
        }

        if (connectFields.length !== allPosFormFields.length) {
            formIsValid = false;
            Library.showErrorMessage("Please check if all fields are correct from configure POS Section and try again.");
            setButtonGray(true);
            return false;
        }

        const formFieldErrors = [];
        for (let i = 0; i < allPosFormFields.length; i++) {
            const field = allPosFormFields[i];

            if (!field) {
                formIsValid = false;
                formFieldErrors.push(i);
            }
        }
        setFormFieldErrorIndex(formFieldErrors);
        setButtonGray(!formIsValid);
        return formIsValid;
    };

    const handleConnectBtn = async (e) => {
        e.preventDefault();
        if (handleConnectFieldsValidation()) {

            const formJson = {
                //"storeID": storeID, 
                "POS_ID": posProvider
            };

            const connectFieldsArray = allPosFormFields.map((field, index) => {
                const tmp = {};
                tmp[connectFields[index].name] = field;
                return tmp;
            });

            formJson.connectFields = connectFieldsArray;

            Library.makePostRequest("productImporterByAPI", formJson, false, setPreLoader).then(res => {
                if (res.data.status !== 200) return;

                const fields = res.data?.data?.keys?.map(item => ({ "name": item, "type": "text" }));
                const products = res.data?.data?.products;

                setDetectedFields(fields);
                setProductList(products);
                setFilePreviewButtonDisable(false);
                setSaveButtonDisable(false);
                handleToogleIsPOSUpload(undefined);

                Library.showSuccessMessage("Successfully connected to POS.");

                brandInfo();

                formJson.brandID = brandID;
                saveBrandPOSData(formJson);
            });
        }
    };

    const saveBrandPOSData = (formJson) => {
        Library.makePostRequest("updateBrandPOS", formJson, false, setPreLoader).then(res => {
            if (res.data.status !== 200) return;

            console.log("Successfully saved brand POS Data.");
        });
    };

    const getAllPOS = () => {
        Library.makePostRequest("getAllPOS", {}, false, setPreLoader).then(res => {
            if (res.data.status === 200) {

                const data = res.data.data;
                const items = [{ value: "", label: "None" }];
                data.forEach((item) => {
                    items.push({ value: item._id, label: item.name });
                });

                setAllPOSOptions(items);
                setAllPOSData(data);
                
                //if (isPosUpload) brandInfo(true);
            }
        });
    };

    const handleSelectPOSProvider = (e) => {
        setPosSectionVisible(e.value.length > 0);
        if (!e.value) {
            setDefaultPOSProvider("");
            setPosProvider("");
            setPosProviderError(true);
            return;
        }

        setDefaultPOSProvider(e);
        setPosProvider(e.value);
        setPosProviderError(false);

        setPosName(e.label);
        const match = allPOSData.filter(item => item._id === e.value);
        if (!match) {
            Library.showErrorMessage("We couldn't find this POS provider in the Database. Try again later!");
            setPosSectionVisible(false);
            return;
        }

        const posData = match[0];
        const connectFields = posData.connectFields.filter(field => !field?.storeOnlyField);
        setCurrentPOSData(posData);
        setConnectFields(connectFields);

        setAllPosFormFields(Array(connectFields.length));
    };

    const handleToogleIsPOSUpload = (e) => {
        const showPOSSections = !isPosUpload;
        setIsPosUpload(showPOSSections);

        if (!showPOSSections) {
            setTimeout(() => {
                setRemovePosSections(true);
            }, 300);
        }
        else {
            setRemovePosSections(false);
        }
    };

    const fullScreenCheck = (e) => {
        const fulls = localStorage.getItem("fullscreen");
        if (fulls === "full") {
            document.body.requestFullscreen();
        }
    };

    const readImporterFile = (e) => {
        setPreviousPosData([]);
        const files = e.target.files;
        if (files.length) {
            setTemD([]);

            const file = files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const detectedHeaders = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        header: 1,
                        defval: "",
                        blankrows: false
                    });

                    const detectedData = utils.sheet_to_json(wb.Sheets[sheets[0]], {
                        defval: "-",
                        blankrows: true
                    });

                    const customHeader = [];

                    detectedHeaders[0].forEach((item, index) => {
                        customHeader.push({ "name": item, "type": "text" });
                    });
                    setDetectedFields(customHeader);
                    setProductList(detectedData);
                    setFilePreviewButtonDisable(false);
                    setSaveButtonDisable(false);
                }
            };
        }
    };

    const isDropped = (boxName) => {
        return droppedBoxNames.indexOf(boxName) > -1;
    };

    const fileList = (index, item, label) => {
        setTemD(temD => [...temD, { leftItemIndex: index, boxName: label, itemName: item.name }]);

        const filterDataS = dataS.filter(r => { return r.boxName !== label; });
        filterDataS.push({ leftItemIndex: index, boxName: label, itemName: item.name });
        setDataS(filterDataS);
    };

    const rmvData = (label) => {
        const dat = dataS.filter((e) => {
            if (e.boxName !== label) {
                return e;
            }
        });
        setDataS(dat);
    };

    /* #region  HANDLE DROP */
    const handleDrop = useCallback(
        (index, item, label) => {

            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);

            setDustbins(
                update(dustbins, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins]
    );

    const handleDrop1 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins1(
                update(dustbins1, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins1],
    );

    const handleDrop2 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins2(
                update(dustbins2, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins2],
    );

    const handleDrop3 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins3(
                update(dustbins3, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins3],
    );

    const handleDrop4 = useCallback((index, item, label) => {
        const { name } = item;
        setDroppedBoxNames(
            update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
        );
        fileList(index, item, label);
        setDustbins4(
            update(dustbins4, {
                [index]: {
                    lastDroppedItem: {
                        $set: item,
                    },
                },
            }),
        );
    },
    [droppedBoxNames, dustbins4],
    );

    const handleDrop5 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins5(
                update(dustbins5, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins5],
    );

    const handleDrop6 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins6(
                update(dustbins6, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins6],
    );

    const handleDrop7 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins7(
                update(dustbins7, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins7],
    );

    const handleDrop8 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins8(
                update(dustbins8, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins8],
    );

    const handleDrop9 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins9(
                update(dustbins9, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins9],
    );

    const handleDrop10 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins10(
                update(dustbins10, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins10],
    );

    const handleDrop11 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins11(
                update(dustbins11, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins11],
    );

    const handleDrop12 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins12(
                update(dustbins12, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins12],
    );

    const handleDrop13 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins13(
                update(dustbins13, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins13],
    );

    const handleDrop14 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins14(
                update(dustbins14, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins14],
    );

    const handleDrop15 = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setDustbins15(
                update(dustbins15, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, dustbins15],
    );

    const handleProductType = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setProductTypeDustbin(
                update(productTypeDustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, productTypeDustbin],
    );

    const handleColorCode = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setColorCodeDustbin(
                update(colorCodeDustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, colorCodeDustbin],
    );
    const handleNote = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setNoteDustbin(
                update(noteDustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, noteDustbin],
    );

    const handleImageFive = useCallback(
        (index, item, label) => {
            const { name } = item;
            setDroppedBoxNames(
                update(droppedBoxNames, name ? { $push: [name] } : { $push: [] }),
            );
            fileList(index, item, label);
            setImageFiveDustbin(
                update(imageFiveDustbin, {
                    [index]: {
                        lastDroppedItem: {
                            $set: item,
                        },
                    },
                }),
            );
        },
        [droppedBoxNames, imageFiveDustbin],
    );

    /* #endregion */

    const arr = [];

    const clear = ((ele, lbl) => {
        const item = ele;
        const index = droppedBoxNames.indexOf(item);
        droppedBoxNames.splice(index, 1);

        setDroppedBoxNames(
            update(droppedBoxNames, item ? { $push: [""] } : { $push: [] }),
        );
        // console.log("previousArray - "+ JSON.stringify(arr));
        let lblRm = lbl.split(" ").join("_");
        lblRm = lblRm.split("/").join("_");

        const inx = arr.findIndex(ar => ar[lblRm]);
        arr.splice(inx, 1);
        //console.log("AfterDelArray - "+ JSON.stringify(arr));

        rmvData(lbl);
    });

    const arrData = ((inx, val) => {
        //  console.log("arrData",inx,val);
        const ob = cunstObj(inx, val);
        arr.push(ob);
        setDraggedData(JSON.stringify(arr));
    });

    const cunstObj = ((inx, val) => {
        //console.log("val",typeof val);
        let value = val?.toString()?.split(" ")?.join("_");
        value = value?.split("/")?.join("_");
        let indx = inx?.split(" ")?.join("_");
        indx = indx?.split("/")?.join("_");
        return { [indx]: value };
    });

    /**************save data************** */

    const searchDataInDB = () => {
        const draggedFieldsNames = {};
        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);

            jsonObjectArray.forEach(item => {
                const key = Object.keys(item);
                const value = Object.values(item);
                draggedFieldsNames[key] = value[0];
            });
        }


        const formData = [];
        const errorsInfo = [];
        productList.forEach((value, index) => {
            try {
                const postData = {
                    "name": (draggedFieldsNames.Product_Name) ? value[draggedFieldsNames.Product_Name]?.toString() : "",
                    "productType": (draggedFieldsNames.Product_Type) ? value[draggedFieldsNames.Product_Type]?.toString() : "",
                    "description": (draggedFieldsNames.Description) ? value[draggedFieldsNames.Description]?.toString() : "",
                    "shortDescription": (draggedFieldsNames.Short_Description) ? value[draggedFieldsNames.Short_Description]?.toString() : "",
                    "composition": (draggedFieldsNames.Composition) ? value[draggedFieldsNames.Composition]?.toString() : "",
                    "maintenance": (draggedFieldsNames.Maintenance) ? value[draggedFieldsNames.Maintenance]?.toString() : "",
                    "color": (draggedFieldsNames.Color_Name) ? value[draggedFieldsNames.Color_Name]?.toString() : "",
                    "colorCode": (draggedFieldsNames.Color_Code) ? value[draggedFieldsNames.Color_Code]?.toString() : "",
                    "size": (draggedFieldsNames.Size) ? value[draggedFieldsNames.Size]?.toString() : "",
                    "referenceCode": (draggedFieldsNames.Reference_Code) ? value[draggedFieldsNames.Reference_Code]?.toString() : "",
                    "barcode": (draggedFieldsNames.Barcode) ? value[draggedFieldsNames.Barcode]?.toString() : "",
                    "mainImage": (draggedFieldsNames.Main_Image) ? value[draggedFieldsNames.Main_Image]?.toString() : "",

                    "countryOfOrigin": (draggedFieldsNames.Country_of_origin) ? value[draggedFieldsNames.Country_of_origin]?.toString() : "",
                    "image_1": (draggedFieldsNames.Image_2) ? value[draggedFieldsNames.Image_2]?.toString() : "",
                    "image_2": (draggedFieldsNames.Image_3) ? value[draggedFieldsNames.Image_3]?.toString() : "",
                    "image_3": (draggedFieldsNames.Image_4) ? value[draggedFieldsNames.Image_4]?.toString() : "",
                    "image_4": (draggedFieldsNames.Image_5) ? value[draggedFieldsNames.Image_5]?.toString() : "",
                    "notes": (draggedFieldsNames.Note) ? value[draggedFieldsNames.Note]?.toString() : "",
                    "ecoStatement": (draggedFieldsNames.Eco_Statement) ? value[draggedFieldsNames.Eco_Statement]?.toString() : "",
                    "factoryOrigin": (draggedFieldsNames.factoryOrigin) ? value[draggedFieldsNames.factoryOrigin]?.toString() : ""
                };

                const errorFields = [];

                if (!postData.name) errorFields.push("Product Name");
                if (!postData.productType) errorFields.push("Product Type");
                if (!postData.description) errorFields.push("Description");
                if (!postData.shortDescription) errorFields.push("Short Description");
                if (!postData.composition) errorFields.push("Composition");
                if (!postData.maintenance) errorFields.push("Maintenance");
                if (!postData.color) errorFields.push("Color Name");
                if (!postData.colorCode) errorFields.push("Color Code");
                if (!postData.size) errorFields.push("Size");
                if (!postData.referenceCode) errorFields.push("Reference Code");
                if (!postData.barcode) errorFields.push("Barcode");
                if (!postData.mainImage) errorFields.push("Main Image");

                if (errorFields.length > 0) errorsInfo.push({ index: index, errorFields: errorFields });
                else formData.push(postData);
            }
            catch (error) {
                console.log(`Ignoring product #${index}, reason: `, error);
            }
        });

        if (imageFieldsAreURLs) {
            formData.forEach((value, index) => {
                const mainImage = value.mainImage;

                let imagesArray = [];

                if (mainImage.includes(","))
                    imagesArray = mainImage.split(",");
                else if (mainImage.includes(";"))
                    imagesArray = mainImage.split(";");

                if (imagesArray[0]) formData[index].mainImage = imagesArray[0];
                if (imagesArray[1] && !value.image_1) formData[index].image_1 = imagesArray[1];
                if (imagesArray[2] && !value.image_2) formData[index].image_2 = imagesArray[2];
                if (imagesArray[3] && !value.image_3) formData[index].image_3 = imagesArray[3];
                if (imagesArray[4] && !value.image_4) formData[index].image_4 = imagesArray[4];
            });
        }

        const numErrorFields = errorsInfo.length;
        const numProducts = productList.length;
        const importingTotal = numProducts - numErrorFields;

        const continueMessage = numErrorFields > 0
            ? `Of the ${numProducts} items, ${numErrorFields} are missing required fields. <br />Would you like to import the remaining ${importingTotal} items?`
            : `Do you confirm you wish to import ${(importingTotal)} items?`;

        setFormDataFinal(formData);

        if (handleValidation(draggedFieldsNames)) {
            Library.showConfirmationMessage(continueMessage, () => {
                SaveDistribution();
            });
        }
    };


    const resetImportPage = () => {
        setProductList([]);
        setTemD([]);
        setDetectedFields([]);
        setFileName("");
        setFilePreviewButtonDisable(true);
        setSaveButtonDisable(true);
        setDustbins(update(dustbins, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins1(update(dustbins1, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins2(update(dustbins2, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins3(update(dustbins3, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins4(update(dustbins4, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins5(update(dustbins5, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins6(update(dustbins6, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins7(update(dustbins7, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins8(update(dustbins8, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins9(update(dustbins9, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins10(update(dustbins10, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins11(update(dustbins11, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins12(update(dustbins12, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins13(update(dustbins13, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins14(update(dustbins14, { 0: { lastDroppedItem: { $set: "", } } }));
        setDustbins15(update(dustbins15, { 0: { lastDroppedItem: { $set: "", } } }));
        setImageFiveDustbin(update(imageFiveDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
        setProductTypeDustbin(update(productTypeDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
        setColorCodeDustbin(update(colorCodeDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
        setNoteDustbin(update(noteDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
    };

    const SaveDistribution = () => {
        /* TODO: THIS SEEMS TO NOT DO ANYTHING AT ALL... */
        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);
            jsonObjectArray.forEach(item => {
                const key = Object.keys(item);
                const value = Object.values(item);
                obj[key] = value[0];
            });
        }

        if (handleValidation(obj)) {
            //importData(true);

            /**/
            Swal.fire({
                text: "Next upload should respect this distribution?",
                icon: "success",
                showDenyButton: true,
                allowOutsideClick: false,
                confirmButtonColor: "#205380",
                denyButtonColor: "#205380",
                confirmButtonText: "Yes",
                denyButtonText: "No",
                background: "#1d2530",
                color: "#fff",
                iconColor: "#0B0",
                width: "500px",
                padding: "10px"
            }).then((result) => {
                if (result.isConfirmed) {
                    importData(true);
                } else if (result.isDenied) {
                    importData(false);
                }
            });
        }
    };

    const importData = (consent) => {

        if (draggedData.length) {
            const jsonObjectArray = JSON.parse(draggedData);
            jsonObjectArray.forEach((item, index) => {
                const key = Object.keys(item);
                const value = Object.values(item);
                obj[key] = value[0];
            });
        }

        //console.log("formDataFinal", formDataFinal, formDataFinal.length, formDataFinal.find(item => item === null) !== undefined);

        if (!formDataFinal.length || formDataFinal.find(item => item === null) !== undefined) {
            Library.showErrorMessage("No data was received to import. Please try again later.");
            return;
        }

        /*productList.map((value, index) => {
            let postData = {
                "name": (obj.Product_Name) ? value[obj.Product_Name].toString() : "",
                "productType": (obj.Product_Type) ? value[obj.Product_Type].toString() : "",
                "description": (obj.Description) ? value[obj.Description].toString() : "",
                "shortDescription": (obj.Short_Description) ? value[obj.Short_Description].toString() : "",
                "composition": (obj.Composition) ? value[obj.Composition].toString() : "",
                "maintenance": (obj.Maintenance) ? value[obj.Maintenance].toString() : "",
                "countryOfOrigin": (obj.Country_of_origin) ? value[obj.Country_of_origin].toString() : "",
                "color": (obj.Color_Name) ? value[obj.Color_Name].toString() : "",
                "colorCode": (obj.Color_Code) ? value[obj.Color_Code].toString() : "",
                "size": (obj.Size) ? value[obj.Size].toString() : "",
                "referenceCode": (obj.Reference_Code) ? value[obj.Reference_Code].toString() : "",
                "barcode": (obj.Barcode) ? value[obj.Barcode].toString() : "",
                "mainImage": (obj.Main_Image) ? value[obj.Main_Image].toString() : "",
                "image_1": (obj.Image_2) ? value[obj.Image_2].toString() : "",
                "image_2": (obj.Image_3) ? value[obj.Image_3].toString() : "",
                "image_3": (obj.Image_4) ? value[obj.Image_4].toString() : "",
                "image_4": (obj.Image_5) ? value[obj.Image_5].toString() : "",
                "notes": (obj.Note) ? value[obj.Note].toString() : "",
                "ecoStatement": (obj.Eco_Statement) ? value[obj.Eco_Statement].toString() : "",
                "factoryOrigin": (obj.factoryOrigin) ? value[obj.factoryOrigin].toString() : ""
            }
            formData.push(postData);
        });*/

        const formJsonData = {
            "brandID": brandID,
            "postData": formDataFinal,
            "ProductsImporter": (consent) ? dataS : "",
            "imageFieldsAreURLs": imageFieldsAreURLs
        };

        setFilePreviewButtonDisable(true);
        setSaveButtonDisable(true);
        setOnSubmitLoader(true);

        const onUploadProgress = progressEvent => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100);
            setProgressBar(percentComplete);
        };

        Library.makePostRequest("product-importer", formJsonData, false, setOnSubmitLoader, () => { }, onUploadProgress).then((res) => {
            setFilePreviewButtonDisable(false);
            setSaveButtonDisable(false);

            if (res.data.status === 200) {
                props.loginFun(localStorage.getItem("token"), "60");

                resetImportPage();

                var r = /\d+/g;
                var s = res.data.message;

                if (s.match(r) == null) {
                    Library.showSuccessMessage(res.data.message);
                    setProgressBar(0);
                } else {
                    Library.showSuccessMessage(res.data.message, true, () => {
                        navigate("/products/image-importer");
                    });
                }
            }
            setOnSubmitLoader(false);
        });
    };

    const brandInfo = (loadPOSConnectFields = false) => {
        const formData = {
            "id": localStorage.getItem("brandID")
        };
        Library.makePostRequest("get-brand-info", formData, false, setPreLoader).then(res => {
            if (res.data.status === 200) {
                if (!loadPOSConnectFields && res.data.data?.ProductsImporter !== undefined) {
                    setDustbins(update(dustbins, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins1(update(dustbins1, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins2(update(dustbins2, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins3(update(dustbins3, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins4(update(dustbins4, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins5(update(dustbins5, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins6(update(dustbins6, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins7(update(dustbins7, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins8(update(dustbins8, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins9(update(dustbins9, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins10(update(dustbins10, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins11(update(dustbins11, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins12(update(dustbins12, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins13(update(dustbins13, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins14(update(dustbins14, { 0: { lastDroppedItem: { $set: "", } } }));
                    setDustbins15(update(dustbins15, { 0: { lastDroppedItem: { $set: "", } } }));
                    setImageFiveDustbin(update(imageFiveDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
                    setProductTypeDustbin(update(productTypeDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
                    setColorCodeDustbin(update(colorCodeDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
                    setNoteDustbin(update(noteDustbin, { 0: { lastDroppedItem: { $set: "", } } }));
                    setPreviousPosData(res.data.data?.ProductsImporter);
                    setDataS(res.data.data?.ProductsImporter);

                    const dataArray = [];
                    res.data.data?.ProductsImporter.forEach((item, index) => {
                        dataArray.push(item.itemName);
                        // console.log(dataArray)
                    });
                    setDroppedBoxNames(dataArray);
                }

                if (loadPOSConnectFields && res.data.data?.POS?.connectFields) {

                    const POS_ID = res.data.data?.POS?.ID;
                    const POS_name = res.data.data?.POS?.name;
                    const connectFields = res.data.data?.POS?.connectFields;
                    
                    const e = { value: POS_ID, label: POS_name };
                    handleSelectPOSProvider(e);
    
                    const tmpArray = [];
                    connectFields.forEach((field) => {
                        const key = Object.keys(field)[0];
                        const value = field[key];
                        tmpArray.push(value);
                    });
                    
                    setAllPosFormFields(tmpArray);
                }
            }
        });
    };

    const handleValidation = (obj) => {
        let formIsValid = true;
        if (typeof obj.Product_Name === "undefined") {
            setNameRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Product_Type === "undefined") {
            setIsProductTypeRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Description === "undefined") {
            setDescRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Short_Description === "undefined") {
            setShortDescRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Composition === "undefined") {
            setCompositionRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Maintenance === "undefined") {
            setMaintenanceRequired(true);
            formIsValid = false;
        }
        /* if (typeof obj.Country_of_origin === 'undefined') {
            setCOORequired(true)
            formIsValid = false;
        } */
        if (typeof obj.Color_Name === "undefined") {
            setColorRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Color_Code === "undefined") {
            setColorCodeRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Size === "undefined") {
            setSizeRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Reference_Code === "undefined") {
            setReferenceCodeRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Barcode === "undefined") {
            setBarcodeRequired(true);
            formIsValid = false;
        }
        if (typeof obj.Main_Image === "undefined") {
            setMainImageRequired(true);
            formIsValid = false;
        }
        /* if (typeof obj.Image_2 === 'undefined') {
            setImage2Required(true)
            formIsValid = false;
        }
        if (typeof obj.Image_3 === 'undefined') {
            setImage3Required(true)
            formIsValid = false;
        }
        if (typeof obj.Image_4 === 'undefined') {
            setImage4Required(true)
            formIsValid = false;
        }
        if (typeof obj.Image_5 === 'undefined') {
            setImage5Required(true)
            formIsValid = false;
        } */
        return formIsValid;
    };

    var percentage = progressBar + "%";

    const PaginationComponent = (e) => {
        const totalRecord = e.rowCount;
        const rowsPerPage = e.rowsPerPage;
        const noOfPage = Math.ceil(totalRecord / rowsPerPage);
        const dataPagination = [];
        for (let i = 1; i <= noOfPage; i++) {
            dataPagination.push(i);
        }
        return (
            <>
                <div className="paginationfixed">
                    <ul className="pagination custompagination">
                        <li className="prevnext"><Link to="#" onClick={() => prevPage(currentPage)} className={(e.currentPage === 1) ? "disabled" : ""}>Prev</Link></li>
                        {
                            dataPagination.map((item, index) => {
                                return (<li key={index}><Link to="#" className={(e.currentPage === item) ? "disabled" : ""} onClick={() => clickPage(item)}>{item}</Link></li>);
                            })
                        }
                        <li className="prevnext"><Link to="#" onClick={() => nextPage(currentPage)} className={(e.currentPage === noOfPage) ? "disabled" : ""}>Next</Link></li>
                    </ul>
                </div>
            </>
        );
    };

    const clickPage = (page) => {
        setCurrentPage(page);
    };

    const nextPage = (page) => {
        setCurrentPage(page + 1);
    };

    const prevPage = (page) => {
        if (page > 1) {
            setCurrentPage(page - 1);
        }
    };

    const getTheNoOfRowSelected = (e) => {
        // alert(productList.length);
        setDataRowCount(e.target.value);
    };
    /* #endregion */

    /* #region  CONSTANTS */
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: "#10141B",
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: "#333F50", color: "#d1d1d1" }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: "#333F50",
                color: "#d1d1d1",
                cursor: "pointer",
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: "#10141B" }),
        menu: styles => ({ ...styles, backgroundColor: "#10141B", marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: "#d1d1d1" }),
        placeholder: styles => ({ ...styles, color: "#d1d1d1" }),
        input: styles => ({ ...styles, color: "#d1d1d1" }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ":hover": {
                backgroundColor: "#205380",
                color: "white",
            },
        }),
    };

    var data = [];
    productList.forEach((value, index) => {
        Object.keys(value).forEach((key) => {
            var replacedKey = key.replace(/ /g, "_").replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
            if (key !== replacedKey) {
                value[replacedKey] = (typeof value[key] !== "undefined") ? value[key] : "-";
                if (typeof value[key] !== "undefined") {
                    delete value[key];
                }
            }
        });
        data.push(value);
    });

    const columns = [];
    detectedFields.forEach((res) => {
        const selector = (((res.name).toString())?.split(" ").join("_")).replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, "_");
        const dataHeader = {
            name: res.name,
            selector: selector,
            sortable: true,
        };
        columns.push(dataHeader);
    });

    const ket = [];
    if (draggedData?.length) {
        const dataValidation = JSON.parse(draggedData);
        dataValidation?.forEach(r => {
            const key = Object.keys(r);
            ket.push(key);
        });
    }
    /* #endregion */
    
    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />

                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr ">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to={(props.compData?.completePercentage === "Completed" || props.compData?.status == "100") ? "/products" : "#"} className={(props.compData?.completePercentage === "Completed" || props.compData?.status == "100") ? "btn btn-success" : "btn btn-success button-not-active"}>Products</Link>
                                <Link to="#" className="btn btn-success button-not-active" >New Product</Link>
                                <Link to="#" className="btn btn-success active">Importer</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
                                        <span className="searchprodicttext">ID</span>
                                    </Link>
                                </div>

                                <div className={"productsearch rightareas"}>
                                    {/* <Link data-widget="search" to={(props.compData?.completePercentage==="Completed")?"/products/image-importer":"#"} role="button"> */}
                                    <Link data-widget="search" to={"/products/image-importer"} role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-file-image"></i></span>
                                        <span className="searchprodicttext">Images</span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                        {(preLoader)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="products-listingarea filtersarea images-importer">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {/*<div className="custom-breadcrumb">
                                                <StepCombination cdata={props.compData}/>
                                                </div>*/}
                                                <div className="product-importer-filebtn" style={{ maxWidth: "610px" }}>
                                                    <div className="form-group">
                                                        <input type="file" name="fileImport" id="fileImport" className="inputfile inputfile-6" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => { readImporterFile(e); fullScreenCheck(e); brandInfo(); }} onClick={(e) => { e.target.value = null; }} />
                                                        <label htmlFor="fileImport"><span>{fileName}</span>
                                                        </label>
                                                        <label htmlFor="fileImport" className={(fileName) ? "stromngclass active" : "stromngclass active"}>Upload File</label>
                                                        <button onClick={handleToogleIsPOSUpload} className="stromngclass active">POS UPLOAD</button>

                                                    </div>
                                                </div>

                                                <div className="filtersection">
                                                    <span className="btnsets">
                                                        <span className={`customeset gobtn ${!isPosUpload ? "hidden" : ""}`}>
                                                            <button type="submit" className="btn btn-success" onClick={handleConnectBtn} disabled={(buttonGray) ? "disabled" : ""}>
                                                                <i className="fa-thin fa-table"></i> CONNECT
                                                            </button>
                                                        </span>

                                                        <span className="customeset gobtn">
                                                            <button type="submit" className="btn btn-success" onClick={() => { setShowFilePreview(true); }} disabled={(filePreviewButtonDisable) ? "disabled" : ""}>
                                                                <i className="fa-thin fa-table"></i> FILE PREVIEW
                                                            </button>
                                                        </span>

                                                        <span className="customeset gobtn">
                                                            <button type="submit" className="btn btn-success save-control" onClick={searchDataInDB} disabled={(saveButtonDisable) ? "disabled" : ""}>
                                                                {(!productList.length)
                                                                    ? (<i className="fa-thin fa-save"></i>)
                                                                    : (saveButtonDisable)
                                                                        ? (<i className="fas fa-spinner fa-spin"></i>)
                                                                        : (<i className="fa-thin fa-save"></i>)}
                                                                {(productList.length) ? "SAVE " + productList.length + " ITEMS" : "SAVE"}
                                                            </button>
                                                            {/* <button type="submit" className="btn btn-success save-control" onClick={SaveDistribution} disabled={(saveButtonDisable)?'disabled':'' }> {(!productList.length)?(<i className="fa-thin fa-save"></i>):(saveButtonDisable)?(<i className="fas fa-spinner fa-spin"></i>):(<i className="fa-thin fa-save"></i>)} {(productList.length)?'SAVE '+productList.length+' ITEMS':'SAVE'}</button> */}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`pos-select-section ${isPosUpload ? "show-transition-faster" : "hide-transition-faster"} ${removePosSections ? "hidden" : ""}`}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cardsection">
                                                    <div className="card-header">
                                                        <h3 className="card-title">PRODUCTS POS<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <form>
                                                            <div className="row">
                                                                <div className="col-md-9">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group activeborder">
                                                                                <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> POS Provider</label>
                                                                                <Select className={(posProviderError) ? "selects error-input" : "selects"}
                                                                                    value={defaultPOSProvider}
                                                                                    onChange={handleSelectPOSProvider}
                                                                                    options={allPOSOptions}
                                                                                    styles={colourStyles}
                                                                                    placeholder={<div className="italic-placeholder">Select POS...</div>}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="row">
                                                                        <div className="col-md-12">

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`pos-section ${isPosUpload ? "show-transition-faster" : "hide-transition-faster"} ${removePosSections || !posSectionVisible ? "hidden" : ""}`}>
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="cardsection">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Configure POS - {posName}<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <form>
                                                            <div className="row">

                                                                {!connectFields
                                                                    ? ""
                                                                    :
                                                                    connectFields.filter(item => !item?.storeOnlyField).map((field, index) =>
                                                                        <div className="col-md-4" key={index}>
                                                                            <div className="form-group activeborder">
                                                                                <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> {field.name}</label>
                                                                                <input
                                                                                    type={field?.numbersOnly ? "number" : "text"}
                                                                                    value={allPosFormFields[index]}
                                                                                    onChange={(e) => { handleConnectFieldChange(e, index); }}
                                                                                    className={formFieldErrorIndex.includes(index) ? "form-control error-input" : "form-control"}
                                                                                    placeholder="" />
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="product-infosection">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="productinfo productDivimporter">
                                                    <div className="cardsection prdoctMargin">
                                                        <div className="card-header">
                                                            <h4 className="card-title">

                                                                Products File
                                                                <div className="file-include-urls">
                                                                    <div className="form-group padd-top">
                                                                        <label className="container-checkbox">
                                                                            <input type="checkbox" value={imageFieldsAreURLs} onChange={(e) => setImageFieldsAreURLs(e.target.checked)} />
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                        <span className="file-text"> File includes URLs for product images <i className="fas fa-info-circle"></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <span className="formcautions">
                                                                    <ul>
                                                                        <li><span className="makesqaure mandatorythis"></span>mandatory fields</li>
                                                                        <li><span className="makesqaure lockedthis"></span>locked fields</li>
                                                                    </ul>
                                                                </span>
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                                                    <i className="fa fa-chevron-down pull-right" aria-hidden="true" onClick={() => setChevShow(1)} style={{ "display": (chevShow) ? "none" : "block" }}></i>
                                                                    <i className="fa fa-chevron-up pull-right" aria-hidden="true" onClick={() => setChevShow(0)} style={{ "display": (chevShow) ? "block" : "none" }}></i>
                                                                </a>
                                                            </h4>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="info-products-form">
                                                                <form>
                                                                    <div className="headpart">
                                                                        <div className="row">
                                                                        </div>
                                                                    </div>

                                                                    <div className="showprogress">
                                                                        <div className="progress">
                                                                            <div className="progress-bar" role="progressbar" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100" style={{ width: percentage }}>
                                                                                <span className="sr-only">{progressBar}% Complete</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <DndProvider backend={HTML5Backend}>
                                                                        <div className="formdivisions">
                                                                            <div className="row">
                                                                                <div className="col-md-3">
                                                                                    <div className="fieldsarea">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Fields detected {detectedFields.length ? <span style={{ "color": "#00AEEF" }}> {"  "}  Drag fields below to a box on the right</span> : ""}</label>
                                                                                        </div>
                                                                                        <div className="fieldfouds">
                                                                                            {detectedFields?.map(({ name, type }, index) => {
                                                                                                return (
                                                                                                    <Box
                                                                                                        name={name}
                                                                                                        type={type}
                                                                                                        isDropped={isDropped(name)}
                                                                                                        key={index}
                                                                                                    />
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-md-3">
                                                                                    <div className="products-col">
                                                                                        <h4>Product Info</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Product Name</label>
                                                                                            {dustbins.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={nameRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Product Type</label>
                                                                                            {productTypeDustbin.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleProductType(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={isProductTypeRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="products-col" >
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Description</label>
                                                                                            {dustbins1.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop1(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={descRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Short Description</label>
                                                                                            {dustbins2.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop2(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={shortDescRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Composition</label>
                                                                                            {dustbins3.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop3(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={compositionRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Maintenance</label>
                                                                                            {dustbins4.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop4(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={maintenanceRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Country of origin</label>
                                                                                            {dustbins5.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop5(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={cOORequired}
                                                                                                    isMandatory={false}

                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Notes</label>
                                                                                            {noteDustbin.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleNote(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={noteRequired}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="products-col">
                                                                                        <h4>Variation</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Color Name</label>
                                                                                            {dustbins6.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop6(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={colorRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Color Code</label>
                                                                                            {colorCodeDustbin.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleColorCode(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={colorCodeRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Size</label>
                                                                                            {dustbins7.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop7(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={sizeRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <h4 className="seconchild">Product Id</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Barcode</label>
                                                                                            {dustbins8.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop8(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={barcodeRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Reference Code</label>
                                                                                            {dustbins9.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop9(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={referenceCodeRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Main Image</label>
                                                                                            {dustbins10.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop10(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={mainImageRequired}
                                                                                                    isMandatory={true}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-3">
                                                                                    <div className="products-col">
                                                                                        <h4>Eco, Transparency & Traceability</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Eco Statement</label>
                                                                                            {dustbins11.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop11(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={ecoStatementRequired}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Factory/Origin</label>
                                                                                            {dustbins12.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop12(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={factoryOriginRequired}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <h4 className="seconchild">Other Product Images</h4>
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 2</label>
                                                                                            {dustbins13.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop13(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={image2Required}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 3</label>
                                                                                            {dustbins14.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop14(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={image3Required}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 4</label>
                                                                                            {dustbins15.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleDrop15(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={image4Required}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="products-col">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Image 5</label>
                                                                                            {imageFiveDustbin.map(({ accepts, lastDroppedItem, label }, index) => (
                                                                                                <Dustbin
                                                                                                    accept={accepts}
                                                                                                    lastDroppedItem={lastDroppedItem}
                                                                                                    onDrop={(item) => handleImageFive(index, item, label)}
                                                                                                    key={index}
                                                                                                    label={label}
                                                                                                    clear={clear}
                                                                                                    arrData={arrData}
                                                                                                    validates={image5Required}
                                                                                                    isMandatory={false}
                                                                                                />
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </DndProvider>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </>
                </div>

                <BrandFooter logo={props.imageData.footer_logo} />
            </div>

            {(onSubmitLoader)
                ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>
                : ""
            }

            <Modal
                backdrop="static"
                className="filepreviewTable"
                keyboard={false}
                show={showFilePreview}
                onHide={() => { setShowFilePreview(false); }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered>

                <Modal.Header closeButton>
                    <Modal.Title>PRODUCTS FILE PREVIEW</Modal.Title>
                    <div className="toppanels">
                        <div className="toppadintions">
                            <div className="dataTables_length" id="example_length">
                                <label>Show
                                    <select name="example_length" aria-controls="example" className="custom-select custom-select-sm form-control form-control-sm" onChange={getTheNoOfRowSelected}>
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                    </select> entries
                                </label>
                            </div>
                        </div>
                        <div className="toppadintions searchrighrts">
                            <div className="dt-buttons">
                                <div className="dt-buttons btn-group">
                                    <Link to="#" className="btn btn-default buttons-copy buttons-html5" tabindex="0" aria-controls="example"><span>Copy</span></Link>
                                    <Link to="#" className="btn btn-default buttons-csv buttons-html5" tabindex="0" aria-controls="example"><span>CSV</span></Link>
                                    <Link to="#" className="btn btn-default buttons-excel buttons-html5" tabindex="0" aria-controls="example"><span>Excel</span></Link>
                                    <Link to="#" className="btn btn-default buttons-pdf buttons-html5" tabindex="0" aria-controls="example"><span>PDF</span></Link>
                                    <Link to="#" className="btn btn-default buttons-print" tabindex="0" aria-controls="example"><span>Print</span></Link>
                                </div>
                            </div>
                            <div id="example_filter" className="dataTables_filter">
                                <label>Search:<input type="search" className="form-control form-control-sm" placeholder="" aria-controls="example" /></label>
                            </div>
                        </div>
                        <div className="dataTables_info" id="example_info" role="status" aria-live="polite">Showing 0 to {dataRowCount} of {productList.length} entries</div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    {/*<DataTableExtensions filterPlaceholder="" {...tableData}>*/}
                    <DataTable
                        columns={columns}
                        data={data}
                        theme="solarized"
                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                        defaultSortFieldId={0}
                        defaultSortAsc={false}
                        pagination
                        paginationComponent={PaginationComponent}
                        paginationDefaultPage={currentPage}
                    />
                    {/*</DataTableExtensions>*/}
                </Modal.Body>

            </Modal>
            {/* {Prompt} */}
        </>
    );
}

export default Dashboard;