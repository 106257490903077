import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BrandAside from "../layouts/BrandAside";
import BrandNavigation from "../layouts/BrandDashboardHeader";
import BrandFooter from "../layouts/BrandFooter";
import './../../assets/css/checkbox.css';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
toast.configure();
createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: '#10141B',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333F50',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

function Dashboard(props) {
    document.title = 'BOstageB - Retailer List ';

    /* #region  STATES */
    const ref = useRef();
    const [elementData, setElementData] = useState("");
    const [denied, setDenied] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [responseArray, setResponseArray] = useState([]);
    const [storeDataList, setStoreDataList] = useState([]);
    const [data1, setData1] = useState(0);
    let navigate = useNavigate();
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        let _data = JSON.parse(localStorage.getItem("__data"));

        if (typeof props?.compData?.companyDetail != "undefined") {
            if (_data) {
                if (!_data?.StoresMenu) {
                    setPreLoader(false);
                    setDenied(true)
                } else {
                    setDenied(false)
                }
            }
        }

    }, [props.compData]);

    // useEffect(() => {
    //     getStoreList();
    // }, [])

    useEffect(() => {
        if (localStorage.getItem('brandID')) {
            getStoreList();
        } else {
            setPreLoader(false);
        }
    }, [localStorage.getItem('brandID')]);

    useEffect(() => {

        setStoreDataList(responseArray);
    }, [responseArray])

    useEffect(() => {
        if (data1) {
            setPreLoader(false);
            // if (!responseArray.length) {
            //     // console.log(responseArray.length);
            //     askQ();
            // }

        }
    }, [data1]);
    /* #endregion */

    /* #region  METHODS */
    const timeConvert = (data) => {
        var date = new Date(data);
        return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
    }

    const getStoreList = () => {
        setResponseArray([]);
        axios.post(process.env.REACT_APP_API_URL + "get-retailler-list-active?code=" + process.env.REACT_APP_API_CODE, { brandID: localStorage.getItem("brandID") }).then((res) => {
            //  axios.post(" http://localhost:7071/api/get-retailler-list-active" , { brandID: localStorage.getItem("brandID") }).then(res => {
            setResponseArray(res?.data?.data)
            if (res?.data?.status) {
                setData1(1);
            }


            const dataA = res?.data?.data?.filter((ret) => {
                if (ret?.status == 'Active') {
                    return ret;
                }
            });

            setResponseArray(dataA);
            console.log(res?.data?.data);
            //console.log("dataA",dataA);
            // askQ();
        })
    }

    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox"
                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
            </>
        )
    });
    const retailerOptions = [

    ];
    const retailBrandOptions = [

    ];
    const statusOptions = [

    ];
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ':hover': {
                backgroundColor: "#205380",
                color: 'white',
            },
        }),
    };
    const columns = [
        {
            name: 'ID',
            selector: row => row.ID,
            sortable: true,
            width: '120px',
        },
        {
            name: 'Retail Brand',
            selector: row => row.retailBrandName,
            sortable: true,
            width: '240px',
            cell: (row) => <><span className="store-name" title={(row.retailBrandName) ? row.retailBrandName : "-"}>{(row.retailBrandName) ? row.retailBrandName : "-"}</span></>
        },

        {
            name: 'Country',
            selector: row => row.counrty,
            sortable: true,
            width: '110px',
            cell: (row) => <><span className="store-name" title={(row.counrty) ? row.counrty : "-"}>{(row.counrty) ? row.counrty : "-"}</span></>
        },
        {
            name: 'Contact person',
            selector: row => row.contactPerson,
            sortable: true,
            //width: '142px'
        },
        {
            name: 'Contact email',
            selector: row => row.contactEmail,
            sortable: true,
            //width: '240px',
            //cell: (row) => <span className={(row.posStatus == 'Active') ? "data-table-green" : "data-table-red"}>{row.posStatus}</span>
        },
        {
            name: 'Max Stores',
            selector: row => row.maxStore,
            sortable: true,
            width: '100px',
            cell: (row) => (row.maxStore) ? row.maxStore : "-"
        },
        {
            name: 'Stores Accepted',
            selector: row => row.storeAccepted,
            sortable: true,
            width: '100px',
            cell: (row) => (row.storeAccepted) ? row.storeAccepted : "0"
        },
        {
            name: 'Active Stores',
            selector: row => row.activeStore,
            sortable: true,
            width: '100px',
            //cell: (row) => (row.lastUpdate) ? timeConvert(row.lastUpdate) : "-"
        },
        {
            name: 'Active FFC',
            selector: row => row.activeFFC,
            sortable: true,
            width: '100px',
            // cell: (row) => (row.lastUpdate) ? timeConvert(row.lastUpdate) : "-"
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '100px',
            //  cell: (row) => (row.status)?row.status:<span className="data-table-orng">Not Active</span>
            cell: (row) => <span className={(row.status == 'Active') ? "data-table-green" : "data-table-orng"}>{row.status}</span>

        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true,
            width: '250px',
            cell: row => <><Link className="btn btn-success" to="#">Edit</Link><Link className="btn btn-success buttondim button-not-active" to="#" >Suspend</Link><Link className="btn btn-success buttondim button-not-active" to="#">Remove</Link></>
        },
    ];

    const ExpandedComponent = ({ data }) => {
        //  let ffc_lists = data?.ffc_data
        return (
            <>
                { /*
              ffc_lists.map((v, i) => {
                  return(
                      <div className="nested-tablerow" key={i}>
                          <div className="nested-cols this-one">
                          </div>
                          <div className="nested-cols this-two">
                              
                          </div>
                          <div className="nested-cols this-three">{"Brand: "+v?.brand_name}</div>
                          <div className="nested-cols this-four">{"Invitation available: "+v?.storesLimit}</div>
                          <div className="nested-cols this-five"></div>
                          <div className="nested-cols this-six"></div>
                          <div className="nested-cols this-seven"></div>
                          <div className="nested-cols this-eight"><span className="data-table-orng">Not Fulfilling</span></div>
                          <div className="nested-cols this-nine">
                             
                                  <Link className="btn btn-success buttondim" to="#">Accept</Link>
                                  <Link className="btn btn-success buttondim" to="#" >Suspend</Link>
                                  <Link className="btn btn-success buttondim" to="#">Remove</Link>

                          </div>
                      </div>
                  )
              })
            */ }
            </>
        )
    };
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">

                                <Link data-widget="search" to="#" role="button" className="button-not-active">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Retailers</span>
                                </Link>

                            </div>
                            <div className="centerbtn">
                                <Link to="#" className="btn btn-success active">Retailers</Link>
                                <Link to="/retailer/create" className="btn btn-success ">New Retailer</Link>
                                {/* <Link to="#" className="btn btn-success">IMPORTER</Link> */}
                            </div>
                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas ">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
                                        <span className="searchprodicttext">Grid</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext text-center">Stats</span>
                                    </Link>
                                </div>

                            </div>
                        </div>

                        {(preLoader)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="filtersarea">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="serchbopicspr">
                                                    <form className="form-inline">
                                                        <input className="form-control" type="text" placeholder="Search list:"
                                                            aria-label="Search" disabled='disabled' />
                                                        <span className="customeset gobtn button-not-active">
                                                            <button type="button" className="btn btn-success">
                                                                <i className="fa-thin fa-arrow-right"></i>
                                                            </button>
                                                            <span className="searchprodicttext">Go</span>
                                                        </span>
                                                    </form>
                                                </div>
                                                <div className="filtersection">
                                                    <form className="form-inline" name="filter">
                                                        <p className="form-control-static">Filters:</p>
                                                        <div className="form-group">
                                                            <label htmlFor="collection" className="sr-only">Retailer</label>
                                                            <Select
                                                                options={retailerOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isdisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="creator" className="sr-only">Retail Brand</label>
                                                            <Select
                                                                options={retailBrandOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isdisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="status" className="sr-only">Status</label>
                                                            <Select
                                                                options={statusOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isdisabled={true} />
                                                        </div>
                                                        <span className="btnsets products-fit">
                                                            <span className="customeset revertfilters button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
                                                                <span className="searchprodicttext">Reset</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
                                                                <span className="searchprodicttext">Apply</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
                                                                <span className="searchprodicttext">Refresh</span>
                                                            </span>
                                                        </span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tablestructureser topteninvite"><h3 className="card-title">RETAILERS</h3></div>
                                <div className="tablestructure">
                                    <DataTable
                                        columns={columns}
                                        data={storeDataList}
                                        theme="solarized"
                                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                        selectableRows
                                        selectableRowsComponent={Checkbox}
                                        defaultSortFieldId={1}
                                        defaultSortAsc={true}
                                        responsive={true}
                                        persistTableHead={true}
                                    //  expandableRows = {true}
                                    //  expandableRowsComponent={ExpandedComponent}
                                    />
                                </div>
                            </>
                        }
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}

export default Dashboard;