import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import CircleTick from './../../assets/images/circle-tick.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - Create Tags    ';
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [denied, setDenied] = useState(false)

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {

					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);
	let navigate = useNavigate();


	let { id } = useParams();
	useEffect(() => {
		checkBrandID(id);
	}, [id]);

	const [showPreLoader, setShowPreLoader] = useState(true);

	const checkBrandID = (id) => {
		let formData = { "id": id }

		axios.post(process.env.REACT_APP_API_URL + "get-brand-info?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status != 200) {
				navigate("/brand/creator");
			} else {
				setShowPreLoader(false);
			}
		})


	}



	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" to="#" role="button">
									<span className="btn btn-success"><i className="fas fa-search" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to="/brand" className="btn btn-success">Brands</Link>
								<Link to="/brand/creator" className="btn btn-success ">Creator</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas">
									<Link data-widget="search" to="/brand/creator" role="button">
										<span className="btn btn-success"><i className="fas fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className="productsearch rightareas">
									<Link to={"/brand/logo/" + id} role="button">
										<span className="btn btn-success "><i className="fas fa-sign"></i></span>
										<span className="searchprodicttext">Logos</span>
									</Link>
								</div>
								<div className="productsearch rightareas notactive">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success"><i className="fas fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>

						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div className="filtertopcombo">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">

												<StepCombination cdata={props.compData} />

											</div>
										</div>
									</div>
								</div>

								<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="small-formas" >
													<div className="blueicons-area">
														<img src={CircleTick} />
													</div>
													<p className="verifying-text wt" >{props.compData?.activeBrand?.brandName} logo is now uploaded. </p>
													<br /> <br />
													<p className="verifying-text wt" >You can now set the main target audience of {props.compData?.activeBrand?.brandName} and <br />
														create a few hashtags to help shoppers find your brand easier.
													</p>
													<br /><br /><br />

													<div className="send-blockbtn twobtnsets" >
														<Link to="#" >SET LATER</Link>
														<Link to={"/brand/tags-target/" + id} >SET NOW</Link>
													</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>

		</>
	);
}
export default Dashboard;