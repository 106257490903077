import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
toast.configure();

function Login(props) {
    document.title = 'Forgot Credentials';
    //let navigate = useNavigate();

    /* #region  STATES */
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [btnText, setBtnText] = useState("GO");
    const [buttonDisable, setButtonDisable] = useState(false);
    /* #endregion */

    /* #region  METHODS */
    const handlePhoneNumber = (e) => {
        setPhoneError(false);
        if (/^[0-9]+$/.test(e.target.value) || !e.target.value) {
            setPhoneNumber(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            setButtonDisable(true);
            setBtnText('');
            const formData = {
                "data": phoneNumber,
                "type": "phoneNumber"
            }
            axios.post(process.env.REACT_APP_API_URL + 'forgot-password?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
                if (res.data.status === 200) {
                    setPhoneNumber('');
                    toast.success(res.data.success_message)
                } else {
                    toast.error(res.data.message)
                }
                setBtnText("GO")
                setButtonDisable(false);
            });
        }
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (!phoneNumber) {
            formIsValid = false;
            setPhoneError(true);
        } else if (phoneNumber.length !== 10) {
            formIsValid = false;
            setPhoneError(true);
            toast.error('Please enter valid phone number')
        }
        return formIsValid;
    }
    /* #endregion */

    return (
        <>
            <div className="loginwindow">
                <Header />
                <div className="loginscreens">
                    <div className="container">
                        <div className="loginwidn">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="logoineerforgot">
                                        <h3>Good We have More Options</h3>
                                        <p>If you know the mobile phone number registered on the account please insert it down below. Otherwise, use the 'Can't remember' link.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="loginforms forgot-credentials byphone">
                                        <form className="form-inline" onSubmit={handleSubmit} autoComplete="off">
                                            <p>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> Account mobile nr.
                                            </p>
                                            <div className="form-group havewemial">
                                                <label className="sr-only" htmlFor="email">
                                                    Mobile address
                                                </label>
                                                <select className="form-control">
                                                    <option>+91</option>
                                                    <option>+62</option>
                                                    <option>+98</option>
                                                    <option>+964</option>
                                                    <option>+972</option>
                                                </select>

                                            </div>
                                            <div className="form-group havepassword">
                                                <label className="sr-only" htmlFor="phoneNumber">
                                                    Password
                                                </label>
                                                <input type="text" className={(phoneError) ? "form-control passwordfield error-input" : "form-control passwordfield"} id="phoneNumber" value={phoneNumber} maxLength={10} placeholder="" onChange={handlePhoneNumber} />
                                                <p>
                                                    <Link to="/">Can't remember</Link>
                                                </p>
                                            </div>
                                            <button type="submit" className="btn btn-default" disabled={(buttonDisable) ? 'disabled' : ''}>
                                                {btnText}
                                                {(buttonDisable) ? <i className="fa fa-spinner fa-spin" style={{ "fontSize": "24px" }}></i> : ""}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer logo={props.imageData.footer_logo} />
        </>
    );
}

export default Login;