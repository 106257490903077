import React, { useState } from "react";
import { Link } from "react-router-dom";
import TermsConditions from "./../static/termsCondition";
import PrivacyPolicy from "./../static/privacyPolicy";
import packageJson from "./../../../package.json";
import moment from "moment";

export default function Footer(props) {

    /* #region  STATES */
    const [termsCondition, setTermsCondtion] = useState('none');
    const [privacyPolicy, setPrivacyPolicy] = useState('none');
    /* #endregion */

    /* #region  METHODS */
    const setShowTermsCondition = () => {
        setTermsCondtion('none')
    }
    const isVisibleTermsCondition = () => {
        // setTermsCondtion('block')
    }
    const setShowPrivacyPolicy = () => {
        setPrivacyPolicy('none')
    }
    const isVisiblePrivacyPolicy = () => {
        // setPrivacyPolicy('block')
    }
    /* #endregion */

    return (
        <div>
            <div className="footerarea">
                <TermsConditions setShowTermsCondition={setShowTermsCondition} isVisible={termsCondition} />
                <PrivacyPolicy setShowPrivacyPolicy={setShowPrivacyPolicy} isVisible={privacyPolicy} />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="ftrlogo">
                                <img alt="footer-logo" src={props.logo} />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="ftrlinks">
                                <ul>
                                    <li>
                                        <Link to="#">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={isVisibleTermsCondition}>Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={isVisiblePrivacyPolicy}>Privacy & Security</Link>
                                    </li>
                                </ul>

                                <div className="versiontext">
                                    <p>BOtag Norge AS © {moment().format('yyyy')}</p>
                                </div>

                                <div className="versiontext">
                                    <p>{`V ${packageJson.version}`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
