import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import errorSuccess from './../../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import { BLOB_PRODUCT_CONTAINER, BLOB_URL } from './../../../config/constant.js';
import BrandAside from './../../layouts/BrandAside';
import BrandNavigation from './../../layouts/BrandDashboardHeader';
import BrandFooter from './../../layouts/BrandFooter';
import uploadFileToBlob from './blob';
toast.configure();

function ImageImporter(props) {
    document.title = 'BOstageB - Image Importer';

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();

    const [elementData, setElementData] = useState('');
    const [brandIDData, setBrandIDData] = useState('');

    const [files, setFiles] = useState([]);
    const [onSubmitLoader, setOnSubmitLoader] = useState(false);
    const [found, setFound] = useState(0);
    const [notFound, setNotFound] = useState(0);
    const [duplicate, setDuplicate] = useState(0);

    const [funBtn, setFunBtn] = useState(true);
    const [funBtnSub, setFunBtnSub] = useState(true);

    const [buttonDisable, setButtonDisable] = useState(false);

    const [notfoundBTN, setNotfoundBTN] = useState(false);
    const [foundBTN, setFoundBTN] = useState(false);
    const [dupBTN, setDupBTN] = useState(false);
    const [impBTN, setImpBTN] = useState(false);

    const [saveBtn, setSaveBtn] = useState(true);
    const [addBtn, setAddBtn] = useState(true);
    const [delBtn, setDelBtn] = useState(true);

    const [newHideShowUS, setNewHideShowUS] = useState('');
    const [newHideShowNT, setNewHideShowNT] = useState('');
    const [newHideShowDU, setNewHideShowDU] = useState('');
    const [acceptedValue, setAcceptedValue] = useState([]);
    const [preData, setPreData] = useState([]);
    const [dragClass, setDragClass] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    const [denied, setDenied] = useState(false);
    /* #endregion */

    /* #region  EFFECTS */
    /* useEffect(() => {
      setElementData(ref.current);
      setBrandIDData(props?.compData?.activeBrand?.brandID)
    },[props?.compData?.activeBrand?.brandID]);  */

    useEffect(() => {
        setElementData(ref.current);
        setBrandIDData(localStorage.getItem('brandID'));
    }, [localStorage.getItem('brandID')]);

    useEffect(() => {
        const _data = JSON.parse(localStorage.getItem('__data'));

        if (typeof props?.compData?.companyDetail != 'undefined') {

            if (_data) {
                if (!_data?.ProductsMenu) {
                    setPreLoader(false);
                    setDenied(true);
                } else {
                    setDenied(false);
                    setPreLoader(false);
                }
            } else {
                setPreLoader(false);
            }
        }

    }, [props.compData]);

    useEffect(() => {
        const found = [];
        const notFound = [];
        const duplicate = [];
        const filesAllData = [].concat(...files);
        filesAllData.forEach((v) => {
            if (v.status == '0') {
                notFound.push('0');
            } else if (v.status == '1') {
                found.push('1');
            } else {
                duplicate.push('2');
            }
        });
        setFound(found.length);
        setNotFound(notFound.length);
        setDuplicate(duplicate.length);

    }, [files]);
    /* #endregion */

    /* #region  METHODS */
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: useCallback(acceptedFiles => {
            const fileArray = [];
            setAcceptedValue(acceptedFiles);
            acceptedFiles.map((value, key) => {
                fileArray.push(value.name);
            });
            setOnSubmitLoader(true);
            axios.post(process.env.REACT_APP_API_URL + 'check-product-image-name?code=' + process.env.REACT_APP_API_CODE, { 'name': fileArray, 'brandID': brandIDData }).then((res) => {
                if (res.data.status === 200) {
                    setPreData(res.data.response.preData);
                    const resData = res.data.response.resData;

                    const renamedAcceptedFiles = acceptedFiles.map((file) => (
                        new File([file], `${Math.floor((Math.random() * 1000000) + 1)}${brandIDData}__${file.name}`, { type: file.type })
                    ));
                    setFiles((files) => [...files, renamedAcceptedFiles.map((file, index) => Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        status: resData[index].status
                    }))]);
                    setAddBtn(false);
                    setDelBtn(false);
                    setFunBtn(false);
                    setFunBtnSub(false);
                    setSaveBtn(false);


                    setNewHideShowNT('');
                    setNewHideShowUS('');
                    setNewHideShowDU('');

                    setNotfoundBTN(false);
                    setFoundBTN(false);
                    setDupBTN(false);
                    setImpBTN(true);
                }
                setOnSubmitLoader(false);
            });
            fullScreenCheck();
            setDragClass(false);
        }, [brandIDData]),
        onDragOver: acceptedFiles => {
            setDragClass(true);
        },
        onDragLeave: acceptedFiles => {
            setDragClass(false);
        }
    });
    const getAllIndexes = (arr, val) => {
        var indexes = [], i = -1;
        while ((i = arr.lastIndexOf(val, i + 1)) != -1) {
            indexes.push(i);
        }
        return indexes;
    };

    const filesDataArray = [].concat(...files);
    const thumbs = filesDataArray.map((file, i) => (
        //"imageupdates uploadsuccess ":"imageupdates uploadnotfound "
        /*<div className={(file?.isAvailable)?"imageupdates uploadsuccess "+newHideShowUS:"imageupdates uploadnotfound "+newHideShowNT} key={i}>*/
        <div className={(file?.status == '0') ? 'imageupdates uploadnotfound ' + newHideShowNT : (file?.status == '1') ? 'imageupdates uploadsuccess ' + newHideShowUS : 'imageupdates uploadduplicate ' + newHideShowDU} key={i}>
            <img src={file.preview} onLoad={() => { URL.revokeObjectURL(file.preview); }} />
        </div>
    ));
    const uniqueByKey = (array, key) => {
        return [...new Map(array.map((x) => [x[key], x])).values()];
    };
    const onFileUpload = async () => {
        //setPreLoader(true)
        /*setAddBtn(true);
        setDelBtn(true);
        setFunBtnSub(true);
        setSaveBtn(true)*/
        setOnSubmitLoader(true);
        const da = filesDataArray.filter(function (re) { return (re.status == 1); });
        const filesName = [];
        da.map((res) => {
            filesName.push(res.name);
        });


        const blobsInContainer: string[] = await uploadFileToBlob(da).then(async res => {
            const pushData = [];
            filesName.forEach((res) => {
                preData.forEach((r) => {
                    (r.images).forEach(re => {
                        const newName = res.split('__');
                        newName.splice(0, 1);
                        const updName = newName.join();
                        if (updName === re.name) {
                            //console.log(updName, re.name)
                            re.URL = BLOB_URL + BLOB_PRODUCT_CONTAINER + '/' + res;
                        }
                    });
                    pushData.push(r);
                });
            });
            const unique = uniqueByKey(pushData, '_id');
            await updateGTINURL(unique);

            props.loginFun(localStorage.getItem('token'));

            toast.success(found + ' images saved', {
                theme: 'dark',
                icon: ({ theme, type }) => <img src={errorSuccess} />
            });
            setFiles([]);
            setFunBtn(true);
            setNewHideShowNT('');
            setNewHideShowUS('');
            //setSaveBtn(true);
            setButtonDisable(false);
            if (localStorage.getItem('canvasId')) {
                if (props.compData?.completePercentage != 'Completed') {
                    navigate('/boimages/canvas/' + localStorage.getItem('canvasId'));
                }
            }



        });
    };

    const updateGTINURL = (formData) => {

        axios.post(process.env.REACT_APP_API_URL + 'update-gtin-image?code=' + process.env.REACT_APP_API_CODE, formData).then(res => {
            //setPreLoader(false);
            setSaveBtn(true);
            setOnSubmitLoader(false);
        });
    };

    const hideShow = (e) => {
        // console.log(e);
        if (e == 1) { //show only not found
            setNewHideShowNT('');
            setNewHideShowUS('nones');
            setNewHideShowDU('nones');

            setNotfoundBTN(true);
            setFoundBTN(false);
            setDupBTN(false);
            setImpBTN(false);

        } else if (e == 2) { //show only duplicate (add one more ustate for duplicate)
            setNewHideShowNT('nones');
            setNewHideShowUS('nones');
            setNewHideShowDU('');

            setNotfoundBTN(false);
            setFoundBTN(false);
            setDupBTN(true);
            setImpBTN(false);

        } else if (e == 3) { //show found 
            setNewHideShowNT('nones');
            setNewHideShowUS('');
            setNewHideShowDU('nones');

            setNotfoundBTN(false);
            setFoundBTN(true);
            setDupBTN(false);
            setImpBTN(false);

        } else if (e == 4) { //show all
            setNewHideShowNT('');
            setNewHideShowUS('');
            setNewHideShowDU('');

            setNotfoundBTN(false);
            setFoundBTN(false);
            setDupBTN(false);
            setImpBTN(true);

        } else if (e == 5) { //show all except found
            setNewHideShowNT('');
            setNewHideShowUS('nones');
            setNewHideShowDU('');


        } else {
            setNewHideShowNT('');
            setNewHideShowUS('');
            setNewHideShowDU('');

            setNotfoundBTN(false);
            setFoundBTN(false);
            setDupBTN(false);
            setImpBTN(true);
        }


    };
    const fullScreenCheck = (e) => {
        const fulls = localStorage.getItem('fullscreen');
        if (fulls == 'full') {
            document.body.requestFullscreen();
        }
    };
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>
                            <div className="centerbtn">
                                <Link to={(props.compData?.completePercentage === 'Completed' || props.compData?.status == '100') ? '/products' : '#'} className={(props.compData?.completePercentage === 'Completed' || props.compData?.status == '100') ? 'btn btn-success' : 'btn btn-success button-not-active'}>Products</Link>
                                <Link to="#" className="btn btn-success button-not-active" >New Product</Link>
                                <Link to="#" className="btn btn-success active">Importer</Link>
                            </div>
                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/products/importer" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
                                        <span className="searchprodicttext">ID</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-file-image" aria-hidden="true"></i></span>
                                        <span className="searchprodicttext">Images</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="filtersarea">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="filtersection prodoctimgimporter">
                                            <span className="btnsets">
                                                <span className={(funBtn) ? 'customeset textinside' : 'customeset textinside active'}>
                                                    <button type="submit" onClick={() => { hideShow(1); }} className={(notfoundBTN) ? 'btn btn-success active' : 'btn btn-success'} disabled={(funBtn || funBtnSub) ? 'disabled' : ''} ><i className="fa-thin fa-question"></i> {notFound} Not Found</button>
                                                </span>
                                                <span className={(funBtn) ? 'customeset textinside' : 'customeset textinside active'}>
                                                    <button type="submit" onClick={() => { hideShow(2); }} className={(dupBTN) ? 'btn btn-success active' : 'btn btn-success'} disabled={(funBtn || funBtnSub) ? 'disabled' : ''} ><i className="fa-thin fa-exclamation"></i> {duplicate} Duplicates</button>
                                                </span>
                                                <span className={(funBtn) ? 'customeset textinside' : 'customeset textinside active'}>
                                                    <button type="submit" onClick={() => { hideShow(3); }} className={(foundBTN) ? 'btn btn-success active' : 'btn btn-success'} disabled={(funBtn || funBtnSub) ? 'disabled' : ''} ><i className="fa-thin fa-check"></i> {found} Found</button>
                                                </span>
                                                <span className={(funBtn) ? 'customeset textinside' : 'customeset textinside active'}>
                                                    <button type="submit" onClick={() => { hideShow(4); }} className={(impBTN) ? 'btn btn-success active' : 'btn btn-success'} disabled={(funBtn || funBtnSub) ? 'disabled' : ''} ><i className="fa-thin fa-download"></i> {notFound + found + duplicate} Imported</button>
                                                </span>
                                                <span className="customeset gobtn">
                                                    <button type="submit" className="btn btn-success" disabled={(delBtn) ? 'disabled' : ''} ><i className="fa-thin fa-trash-alt"></i></button>
                                                    <span className="searchprodicttext">Delete</span>
                                                </span>
                                                <span className={(addBtn) ? 'customeset gobtn' : 'customeset gobtn active'}>
                                                    <button type="submit" className="btn btn-success" disabled={(addBtn) ? 'disabled' : ''} onClick={open}><i className="fa-thin fa-plus"></i></button>
                                                    <span className="searchprodicttext">Add More</span>
                                                </span>
                                                <span className={(saveBtn) ? 'customeset textinside' : 'customeset textinside active'}>
                                                    <button type="submit" onClick={(e) => { onFileUpload(); hideShow(5); }} className="btn btn-success save-control" disabled={(saveBtn) ? 'disabled' : ''} >{(preLoader) ? (<i className="fas fa-spinner fa-spin"></i>) : <i className="fa-thin fa-save"></i>} {(found) ? 'Save ' + found + ' Images' : 'Save'}</button>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(preLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> :
                            <div className="image-importer">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="cardsection">
                                                <div className="card-header">
                                                    <h3 className="card-title">Images</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Images uploader</label>
                                                    </div>
                                                    <div className={(dragClass) ? 'custom-upload on-drag' : 'custom-upload'}>

                                                        <div {...getRootProps({ className: 'dropzone-div' })}>
                                                            <input {...getInputProps()} />
                                                            {(!files.length) ? <p className="dropzone-label">Drag and drop images here to upload</p> : ''}
                                                            <aside className={(files.length) ? 'map-height' : ''}>
                                                                {thumbs}
                                                            </aside>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
            {(onSubmitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ''}
        </>
    );
}

export default ImageImporter;