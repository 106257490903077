import React, { useState } from "react";
import noBoimage from './../../../assets/images/uploads/no_img.png';
import Vector75 from './../../../assets/images/vector-75.svg';
import Vector28 from './../../../assets/images/vector28.svg';


export default function BeconData({ data, beaconID, ratio, showBecon }) {
    
    /* #region  STATES */
    const [imageData, setImageData] = useState([]);
    /* #endregion */

    /* #region  OLD CODE */
    /*   
    const getImageInformation = async (productID, color) => {
        await axios.post(process.env.REACT_APP_API_URL+"get-image-by-productID?code="+process.env.REACT_APP_API_CODE,{productID: productID, color:color}).then((res)=>{
            if(res.data.status === 200){
                   
                setImageData(res.data.data[0].URL)
               // console.log(res.data.data);
            }
        });
    }
    
    
    useEffect(()=>{
     if(data){
        getImageInformation(data.productID,data.color)
     }
     console.log("datass",beaconID,data);
    },[data]) */
    /* #endregion */

    return (
        <>
            {/* <div className="BotagData " id={data.BotagId} style={{ "position": "absolute", "zIndex": "1", "left": (data.beaconXposition * ratio.w) + "px", "top": (data.beaconYposition * ratio.h) + "px" , "display":(beaconID==data.BotagId)?"block":"none"}}   >
            </div> 
            */}

            <div className="botag-body botag-body-default product-info-box" style={{ "zIndex": "9", "display": (beaconID == data.productID) ? "flex" : "none" }}>
                <div className="flex-row">
                    {/* <img className="image-41-1" src={imageData} /> */}

                    <img className="image-41-1" src={(data?.pImage != "" && data?.pImage != undefined) ? data.pImage : noBoimage} />
                    <div className="flex-col">
                        <div className="brand-product-type"> {localStorage.getItem('brandName')} </div>
                        <div className="product-name">
                            <div className="dipper-check-s-1 opensans-semi-bold-white-14px">{data.productName}</div>
                        </div>
                        <div className="flex-row-1">
                            <div className="size-circle" title={data.size}>{data.size}</div>
                            <div className="color-circle" style={{ "backgroundColor": "#" + data.colorCode }}></div>
                            <div className="size-circle qty">
                                <div className="number opensans-semi-bold-white-14px">1</div>

                                {/* <div className="vector-container">
                                        <i className="fa-solid fa-caret-up"></i>
                                        <i className="fa-solid fa-caret-down"></i>
                                    </div> 
                                */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex-col-1">
                    <div className="devider "></div>
                    <div className="delivery-slider">
                        <div className="delivery-options pick-up ">Pick up</div>
                        <div className="delivery-options courier">Courier</div>
                        <div className="delivery-options shipping ">Shipping</div>
                    </div>
                    <div className="flex-row-2">

                        {/* <div className="map-icon">
                                <div className="overlap-group-2"><i className="fa-light fa-location-dot"></i></div> 
                            </div> 
                        */}

                        <div className="mycustomcloseOpenbx"><span className="NewBoxCompound openNowbox">Open</span></div>
                        <div className="flex-col-2">
                            <div className="delivery-info">
                                <p className="x230m-at-store opensans-medium-white-10px">
                                    <span className="opensans-medium-stack-10px">(-) from</span><span className="opensans-medium-white-10px-2">&nbsp;</span><span className="opensans-medium-stack-10px">store</span></p>
                            </div>
                            <div className="store-name">[Store name]</div>
                        </div>
                    </div>
                    <div className="flex-col-3">
                        <div className="flex-row-3">
                            <div className="devider devider-6"></div>
                            <p className="kr-delivery-120">Item -kr / Delivery -kr</p>
                            <div className="devider-5 "></div>
                        </div>
                        <div className="flex-col-4">
                            <div className="pricetag ">
                                <div className="x1400-kr opensans-bold-white-18px">[-kr]</div>
                            </div>
                            <div className="flex-row-4">
                                <div className="group-3208"><i className="fa-light fa-eye"></i></div>
                                <div className="group-3207"><img className="vector-28" src={Vector28} alt="Vector" />
                                    <img className="vector-29" src={Vector75} alt="Vector" /></div>
                                <div className="buy-now-button">Buy now</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}