import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import BoTagIcon from './../../../assets/images/bOtag-icond.png';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import Tserone from './../../../assets/images/uploads/tone.png';
import BrandAside from './../../layouts/BrandAside';
import BrandNavigation from './../../layouts/BrandDashboardHeader';
import BrandFooter from './../../layouts/BrandFooter';
import BeconData from './beconDataFile';

function Dashboard(props) {
    document.title = 'BOstageB - BOpics';

    /* #region  STATES */
    const ref = useRef();
    const navigate = useNavigate();
    const [elementData, setElementData] = useState('');
    const [preLoader, setPreLoader] = useState(true);
    const [brandLogo, setBrandLogo] = useState('');
    const [isPagerefresh, setIsPageRefresh] = useState(false);
    const [elementId, setElementId] = useState(null);
    const [shVal, setShVal] = useState(0);
    const [defVal, setDefVal] = useState(0);
    const [bopicData, setbopicData] = useState([]);
    /* #endregion */

    /* #region  EFFECTS */
    /* useEffect(() => {
       setElementData(ref.current);
       getBrandInfo(props?.compData?.activeBrand?.brandID)
     },[props?.compData?.activeBrand?.brandID]);
     */
    const [denied, setDenied] = useState(false);
    useEffect(() => {
        const _data = JSON.parse(localStorage.getItem('__data'));

        if (typeof props?.compData?.companyDetail != 'undefined') {
            if (_data) {
                if (!_data?.ProductsMenu) {
                    //setPreLoader(false);
                    setDenied(true);
                } else {
                    setDenied(false);
                }
            }
        }

    }, [props.compData]);

    useEffect(() => {
        setElementData(ref.current);

        if (localStorage.getItem('brandID')) {
            getBrandInfo(localStorage.getItem('brandID'));
        } else {
            setPreLoader(false);
        }
    }, [localStorage.getItem('brandID')]);

    useEffect(() => {
        if (isPagerefresh) {
            getBrandInfo(localStorage.getItem('brandID'));
        }
        setIsPageRefresh(false);
    }, [isPagerefresh]);
    /* #endregion */

    /* #region  CONSTANTS */
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'with-sync-problems', label: 'With sync problems' },
        { value: 'missing-product-images', label: 'Missing product images' },
    ];
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: 'transparent',
            border: state.isFocused ? '1px solid #0B0' : '1px solid #333F50',
            boxShadow: state.isFocused ? '0px 0px 1px transparent' : 'none',
            '&:hover': {
                border: '1px solid #0B0',
            },
            '&:active': {
                border: '1px solid #0B0',
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: '1px solid #10141B',
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                '&:hover': {
                    border: '1px solid #00AEEF',
                },
                '&:active': {
                    backgroundColor: '#333F50',
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({ ...styles, padding: 0 }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
            ':hover': {
                backgroundColor: '#205380',
                color: 'white',
            },
        }),
    };
    /* #endregion */

    /* #region   */
    const shareAsBOimage = (e) => {
        e.preventDefault();
        Swal.fire({
            text: 'The selected BOpic is now added to the BOimages list where you can get the BOimage ready for publishing.',
            icon: 'success',
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: '#205380',
            denyButtonColor: '#205380',
            confirmButtonText: 'STAY HERE',
            denyButtonText: 'GO TO BOIMAGES',
            background: '#1d2530',
            color: '#fff',
            iconColor: '#0B0',
            width: '500px',
            padding: '10px'
        });
    };

    const getGTINsList = (brandID) => {
        setbopicData([]);
        setPreLoader(true);
        const formData = {
            'brandID': brandID
        };
        axios.post(process.env.REACT_APP_API_URL + 'get-gtins-list?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                setbopicData(res.data.data);
            } else {
                setbopicData([]);
            }
            setPreLoader(false);
        });
    };

    const getBrandInfo = (brandID) => {
        const formData = {
            'id': brandID
        };
        axios.post(process.env.REACT_APP_API_URL + 'get-brand-info?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                // console.log(res?.data?.data)
                setBrandLogo(res?.data?.data?.logos?.mainLogoURL);
                getGTINsList(brandID);
            }
        });
    };

    const onError = (e) => {
        e.target.onerror = null;
        e.target.src = Tserone;
        return true;
    };

    const dataReloadfunction = (e) => {
        setIsPageRefresh(e);
    };

    const showHide = (ind) => {
        setElementId(ind);
        if (defVal) {
            setDefVal(0);
        } else {
            setDefVal(1);
        }
    };
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation dataReloadfunction={dataReloadfunction} data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to="#" className="btn btn-success active">Products</Link>
                                <Link to="#" className="btn btn-success button-not-active">New Product</Link>
                                <Link to="/products/importer" className="btn btn-success">Importer</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/products" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/products/bopics" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-id-badge"></i></span>
                                        <span className="searchprodicttext">BOpics</span>
                                    </Link>
                                </div>

                                <div className="productsearch rightareas notactive ">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-eye"></i></span>
                                        <span className="searchprodicttext">View/Edit</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext">Stats</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                        <div className="filtersarea">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="serchbopicspr">
                                            <form className="form-inline">
                                                <input className="form-control" type="text" placeholder="Search BOpic"
                                                    aria-label="Search" disabled='disabled' />
                                                <span className="customeset gobtn button-not-active">
                                                    <button type="submit" className="btn btn-success" disabled='disabled'>
                                                        <i className="fa-thin fa-arrow-right"></i>
                                                    </button>
                                                    <span className="searchprodicttext">Go</span>
                                                </span>
                                            </form>
                                        </div>
                                        <div className="filtersection bopics">
                                            <form className="form-inline" name="filter">
                                                <p className="form-control-static">Filters:</p>
                                                <div className="form-group">
                                                    <label htmlFor="status" className="sr-only">Status</label>
                                                    <Select
                                                        options={statusOptions}
                                                        styles={colourStyles}
                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                        isdisabled={true} />
                                                </div>
                                                <span className="btnsets products-fit">
                                                    <span className="customeset revertfilters button-not-active">
                                                        <button type="submit" className="btn btn-success" disabled='disabled'><i className="fa-thin fa-rotate-left"></i></button>
                                                        <span className="searchprodicttext">Reset</span>
                                                    </span>
                                                    <span className="customeset gobtn button-not-active">
                                                        <button type="submit" className="btn btn-success" disabled='disabled'><i className="fa-thin fa-arrow-right"></i></button>
                                                        <span className="searchprodicttext">Apply</span>
                                                    </span>
                                                    <span className="customeset gobtn button-not-active">
                                                        <button type="submit" className="btn btn-success" disabled='disabled'><i className="fa-thin fa-arrows-rotate"></i></button>
                                                        <span className="searchprodicttext">Refresh</span>
                                                    </span>
                                                </span>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {(preLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <div className="products-listings">
                                <div className="container-fluid">
                                    <div className="row listed-products">
                                        {
                                            //   (loader)?
                                            //   <div className="loading-data" >
                                            //     {/* <div className="loading-text">Loading...</div> */}
                                            //     <div className="loaderclass">
                                            //           <i className="fa fa-spinner fa-spin"></i>
                                            //     </div>
                                            // </div>:""

                                            //  (loader)?<div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>:(denied)?<ForBidden />:<></>



                                        }
                                        {
                                            (!bopicData.length && !preLoader) ?

                                                <div className="loading-data">
                                                    <div className="">There are no records to display</div>
                                                </div> : ''

                                        }
                                        {
                                            bopicData.map((value, index) => {
                                                return (
                                                    <div className="col-md-3" key={index}>
                                                        <div className="products-singles">
                                                            <div className="primgs">
                                                                <img alt={value.productName} className="proimg" src={value.image} onError={onError} />
                                                                {/* <div className="boxonimg">
                                                        <div className="imgbrand"><img alt="brand-logo" src={brandLogo} /></div>
                                                        <div className="imgbrandtitle">{value.productName}</div>
                                                        <div className="size-color">
                                                            <span className="editbtnpen"><Link to="#"><i className="fa fa-pencil" aria-hidden="true"></i></Link></span>
                                                            <span className="sizecth"><span className="sizeklabel">Size</span>{value.size}</span>
                                                            <span className="sizecth"><span className="sizeklabel">Color</span>{value.color}</span>
                                                        </div>
                                                        <div className="clth-cost">[Cost]</div>
                                                        <div className="clth-otherinfo">[other-info]</div>
                                                        <div className="delivery-option">delivery option</div>
                                                    </div> */}
                                                                <BeconData data={value} beaconID={index} showBecon={elementId} visible={defVal} />
                                                            </div>
                                                            <div className="product-descriptions">
                                                                <div className="setboimageBtn" >
                                                                    <span className={(index == elementId) ? 'setBoimageContent active' : 'setBoimageContent'} onClick={() => { showHide(index); }} ><img src={BoTagIcon} /></span>
                                                                    <span className="setBoname">BOtag</span>
                                                                </div>
                                                                <div className="subtext">{value.ourID}</div>
                                                                <div className="product-title">{value.productName}</div>
                                                                <div className="product-despara">{value.productName}</div>
                                                                <div className="mulitsetbtns button-not-active">
                                                                    <Link to="#" className="btn btn-success ">View/Edit</Link>
                                                                    <Link to="#" className="btn btn-success middlegbtn">Share as BOimage</Link>
                                                                    <Link to="#" className="btn btn-success">Share as BOad</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }

                                    </div>


                                </div>
                            </div>
                        }
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}
export default Dashboard;