import { BlobServiceClient, ContainerClient} from '@azure/storage-blob';
//import {BLOB_ACCOUNT_IMAGE} from './../../config/constant.js';
//const containerName = BLOB_ACCOUNT_IMAGE;
export const isStorageConfigured = () => {
  return (!process.env.REACT_APP_STORAGE_NAME || !process.env.REACT_APP_SAS_TOKEN) ? false : true;
}
const getBlobsInContainer = async (containerClient: ContainerClient, containerName) => {
  const returnedBlobUrls: string[] = [];
  for await (const blob of containerClient.listBlobsFlat()) {
    returnedBlobUrls.push(
      `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
const createBlobInContainer = async (containerClient: ContainerClient, files: File) => {
  //console.log(files);
  let res = '';
  files.map( async (file, key) => {
    const blobClient = containerClient.getBlockBlobClient(file.name);
    const options = { blobHTTPHeaders: { blobContentType: file.type } };
    res = await blobClient.uploadData(file, options);
  })
  
  return true;
}
const uploadFileToBlob = async (file: File | null, containerName): Promise<string[]> => {
  if (!file.length) return [];
  const blobService = new BlobServiceClient(
    `https://${process.env.REACT_APP_STORAGE_NAME}.blob.core.windows.net/?${process.env.REACT_APP_SAS_TOKEN}`
  );
  const containerClient: ContainerClient = blobService.getContainerClient(containerName);
  /*await containerClient.createIfNotExists({
    access: 'container',
  });*/
  let fileUpload = await createBlobInContainer(containerClient, file);
  return fileUpload;//getBlobsInContainer(containerClient, containerName);
};

export default uploadFileToBlob;

