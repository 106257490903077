import React, { useEffect, useRef, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import Swal from "sweetalert2";
import "./../../../assets/css/checkbox.css";
import errorWarning from "./../../../assets/images/error-icon.png";
import errorSuccess from "./../../../assets/images/icons8-check-64.png";
import LoaderIcon from "./../../../assets/images/loader-icon.png";
import LoaderSvg from "./../../../assets/images/loader.svg";
import * as Library from "./../../../utils/Library";
import BrandAside from "./../../layouts/BrandAside";
import BrandAsideNew from "./../../layouts/BrandAsideNew";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandFooter from "./../../layouts/BrandFooter";

toast.configure();
createTheme("solarized", {
    text: {
        primary: "#fff",
        secondary: "#fff",
    },
    background: {
        default: "#10141B",
    },
    context: {
        background: "#cb4b16",
        text: "#FFFFFF",
    },
    divider: {
        default: "#333F50",
    },
    action: {
        button: "rgba(0,0,0,.54)",
        hover: "rgba(0,0,0,.08)",
        disabled: "rgba(0,0,0,.12)",
    },
}, "dark");

/* #region  SCOPED CSS */
const NestedCols = styled.div`
        display: flex;
        align-items: stretch;
        width: 100%;
        box-sizing: border-box;
        min-height: 48px;
        font-size: 12px !important;
        font-family: Open Sans;
        border-bottom: 1px solid #333f50!important;
    
    .nested-col {
        display: flex;
        align-items: center;	
        padding-left: 16px;
        padding-right: 16px;
    }
    .nested-col >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #d1d1d1 !important;
        max-width: 88px;
        height: 18px;
    }
    .nested-col.col-two >span {
        width: 100%;
        max-width: 232px;
    }
    .nested-col.col-eleven >span {
        max-width: 97px;
    }
    .nested-col.col-eleven { 
        padding-right: 10px;
    }

    .nested-col.col-one {
        min-width: 96px;	
        padding-left: 17px;
        display: flex;
        justify-content: right;
        align-self: center;
    }
    .nested-col.col-two {
        width: 100%;
        //min-width: 180px;
    }
    .nested-col.col-three {
        min-width: 125px;
    }
    /* .nested-col.col-four {
        min-width: 110px;
    }
    .nested-col.col-five {
        min-width: 110px;
    }
    .nested-col.col-six {
        min-width: 110px;
    }
    .nested-col.col-seven {
        width: 100%;
        max-width: 232px;
    }*/
    .nested-col.col-eight {
        width: 100%;
        max-width: 232px;
    }
    .nested-col.col-nine {
        width: 100%;
    }
    .nested-col.col-ten {
        min-width: 130px;
    }
    .nested-col.col-eleven {
        width: 100%;
        max-width: 232px;
    }
    .nested-col.col-twelve {
        width: 100%;
        max-width: 232px;
    }
    .nested-col.col-thirteen {
        width: 100%;
    }
    .nested-col.col-fourteen {
        min-width: 80px;
    }
    .nested-col.col-fifteen {
        min-width: 222px;
        position: sticky;
        right: 0;
        padding: 0 !important;
        justify-content: center;
        background-color: #10141b;
    }
`;
/* #endregion */

function Dashboard(props) {
    document.title = "BOstageB - Products";

    /* #region  STATES */
    const ref = useRef();
    useNavigate();
    const [elementData, setElementData] = useState("");
    const [tooltipImg, setTooltip] = useState(true);
    const [tooltipText, setTooltipText] = useState(true);
    const [productList, setProductList] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [gtStatus, setGtStatus] = useState([]);
    const [isPagerefresh, setIsPageRefresh] = useState(false);
    const [preLoader, setPreLoader] = useState(true);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setElementData(ref.current);
        if (localStorage.getItem("brandID")) {
            getProductList(localStorage.getItem("brandID"));
        } else {
            setPreLoader(false);
            setDataLoading(false);
        }
    }, [localStorage.getItem("brandID")]);

    useEffect(() => {
        if (isPagerefresh) {
            getProductList(localStorage.getItem("brandID"));
        }
        setIsPageRefresh(false);
    }, [isPagerefresh]);
    /* #endregion */

    /* #region  CONSTANTS */
    const productOptions = [
        { value: "product-1", label: "Product 1" },
        { value: "product-2", label: "Product 2" },
        { value: "product-3", label: "Product-3" },
    ];

    const locationOptions = [
        { value: "Location-1", label: "Location 1" },
        { value: "Location-2", label: "Location 2" },
        { value: "Location-3", label: "Location-3" },
    ];

    const statusOptions = [
        { value: "all", label: "All" },
        { value: "with-sync-problems", label: "With sync problems" },
        { value: "missing-product-images", label: "Missing product images" },
    ];

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: "#10141B",
            borderColor: "transparent",
            border: state.isFocused ? "1px solid #0B0" : "1px solid #333F50",
            boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
            "&:hover": {
                border: "1px solid #0B0",
            },
            "&:active": {
                border: "1px solid #0B0",
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: "#333F50",
                color: "#d1d1d1",
                cursor: "pointer",
                border: "1px solid #10141B",
                borderLeft: "1px solid transparent",
                borderRight: "1px solid transparent",
                "&:hover": {
                    border: "1px solid #00AEEF",
                },
                "&:active": {
                    backgroundColor: "#333F50",
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: "#333F50", color: "#d1d1d1" }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: "#10141B" }),
        menu: styles => ({ ...styles, backgroundColor: "#10141B", marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: "2px",
            "::-webkit-scrollbar-thumb": {
                borderRadius: "10px"
            }
        }),
        singleValue: styles => ({ ...styles, color: "#d1d1d1" }),
        input: styles => ({ ...styles, color: "#d1d1d1" }),
        placeholder: styles => ({ ...styles, color: "#d1d1d1" }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#fff",
            backgroundColor: "#205380",
            borderRadius: "0px",
            ":hover": {
                backgroundColor: "#205380",
                color: "white",
            },
        }),
    };

    const columns = [
        {
            name: "Product",
            selector: row => row.productName,
            sortable: true,
            cell: (row) => <><span className="store-name" title={row.productName}>{row.productName}</span></>
        },
        {
            name: "Reference",
            selector: row => row.reference,
            sortable: true,
            width: "125px"
        },
        {
            name: "FFC Stock Qty/Store",
            selector: row => row.totalStore,
            sortable: true,
            cell: (row) => <>{row.ffcStock}<br />{row.totalStore}</>,
            width: "180px",
            style: {
                flexDirection: "column",
                ustifyContent: "center",
                alignItems: "flex-start",
                justifyContent: "center",
            },
        },
        {
            name: "Sold",
            selector: row => row.totalSold,
            sortable: true,
            width: "130px"
        },
        {
            name: "Barcode",
            selector: row => row.barcode,
            sortable: true,
            style: {

            }
        },
        {
            name: "Color",
            selector: row => row.color,
            sortable: true,
            style: {

            }
        },
        {
            name: "Size",
            selector: row => row.size,
            sortable: true,
            style: {

            }
        },
        {
            name: "Status",
            selector: row => row.status,
            sortable: true,
            width: "80px",
            cell: (row) => (row.activeStatus) ? <img src={errorSuccess} alt="sucess" className="exclamation-triangle" /> : <img src={errorWarning} alt="error" className="exclamation-triangle" />,
        },
        {
            name: "Action",
            selector: row => row.action,
            sortable: false,
            cell: (row) => <><Link className="btn btn-success button-not-active" to="#">VIEW/EDIT</Link> <Link className="btn btn-success button-not-active" to="#">SUSPEND</Link> <Link className="btn btn-success " to="#" onClick={() => deleteProduct("product", row.id)} >DELETE</Link> {/*< button-not-active  onClick={() => deleteProduct('product',row.id)} >*/} </>,
            width: "300px",
            style: {
                justifyContent: "center",
                flexDirection: "row"
            },
            center: true,

        },
    ];
    /* #endregion */

    /* #region  METHODS */
    const getProductList = (brandID) => {
        setProductList([]);
        setDataLoading(true);
        const formData = {
            "labelBrandID": brandID
        };

        Library.makePostRequest("getProducts", formData).then((res) => {
            if (res.data.status === 200) {
                const data = res.data.data;
                const gtinStatus = [];
                data.map((i) => {
                    (i.gtins).map((c) => {
                        if (c.status) {
                            gtinStatus.push(c.status);
                        }
                    });
                });
                setGtStatus(gtinStatus);
                setProductList(res.data.data);
                setDataLoading(false);
            }
            setPreLoader(false);
        });
    };

    const deleteProduct = (type, ID) => {
        Swal.fire({
            text: "Are you sure want to delete this " + type + "?",
            icon: "success",
            showDenyButton: true,
            allowOutsideClick: false,
            confirmButtonColor: "#205380",
            denyButtonColor: "#205380",
            confirmButtonText: "Yes",
            denyButtonText: "No",
            background: "#1d2530",
            color: "#fff",
            iconColor: "#0B0",
            width: "500px",
            padding: "10px"
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = {
                    "type": type,
                    "ID": ID
                };
                Library.makePostRequest("remove-product", formData).then((res) => {
                    if (res.data.status === 200) {
                        toast.success(res.data.message, {
                            theme: "dark",
                            icon: ({ theme, type }) => <img alt="success" src={errorSuccess} />
                        });
                        getProductList();
                    } else {
                        toast.error(res.data.message, {
                            theme: "dark",
                            width: "390px",
                            icon: ({ theme, type }) => <img alt="warning" src={errorWarning} />
                        });
                    }
                });
            } else if (result.isDenied) {
                Swal.close();
            }
        });
    };

    const dataReloadfunction = (e) => {
        setIsPageRefresh(e);
    };
    /* #endregion */

    /* #region  COMPONENTS */
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox"
                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.gtins?.map((v, i) => {
                        return (
                            <NestedCols className="nested-row" key={i}>
                                <div className="nested-col col-one">
                                    <label className="container-checkbox" style={{ "float": "right" }}>
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                <div className="nested-col col-two">
                                    <span title={data.productName}>{data.productName}</span>
                                </div>
                                <div className="nested-col col-three">
                                    {(v?.statistics?.salesTopBOad?.BOadID) ?
                                        <>
                                            <span onMouseEnter={() => setTooltip(true)} onMouseLeave={() => {
                                                setTooltip(false); setTimeout(() => setTooltip(true), 5);
                                            }} data-tip={v?.statistics?.salesTopBOad?.BOadID} data-for="tooltip-img"><Link className="topboadanc" to="#">v?.statistics?.salesTopBOad?.BOadID</Link></span><br />v?.statistics?.salesTopBOad?.sold
                                        </> : <span title={data.reference}>{data.reference}</span>
                                    }
                                </div>
                                <div className="nested-col col-eight">
                                    <span>{v?.statistics?.totalStock}<br />{v?.statistics?.totalStores}</span>
                                </div>
                                <div className="nested-col col-ten">
                                    <span>{v?.statistics?.totalSold}</span>
                                </div>
                                <div className="nested-col col-eleven">
                                    <span title={v?.barcode}>{v?.barcode}</span>
                                </div>
                                <div className="nested-col col-twelve">
                                    <span title={v?.color}>{v?.color}</span>
                                </div>
                                <div className="nested-col col-thirteen">
                                    <span title={v?.size}>{v?.size}</span>
                                </div>
                                <div className="nested-col col-fourteen">
                                    {(v?.status == "1") ? <img src={errorSuccess} alt="success" className="exclamation-triangle" /> : <img src={errorWarning} alt="error" className="exclamation-triangle" />}
                                </div>
                                <div className="nested-col col-fifteen">
                                    <Link className="btn btn-success" to="#">VIEW/EDIT</Link>
                                    <Link className="btn btn-success" to="#">SUSPEND</Link>
                                    <Link className="btn btn-success" onClick={() => deleteProduct("GTINs", v.ourID)} to="#">DELETE</Link>
                                </div>
                            </NestedCols>
                        );
                    })
                }
            </tbody>
        </table>);
    };

    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>);
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation dataReloadfunction={dataReloadfunction} data={elementData} cdata={props.compData} />
                {(props.type == "new") ? <BrandAsideNew cdata={props.compData} /> : <BrandAside cdata={props.compData} />}
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to="#" className="btn btn-success active">Products</Link>
                                <Link to="#" className="btn btn-success button-not-active" >New Product</Link>
                                <Link to="/products/importer" className="btn btn-success" >Importer</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/products" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/products/bopics" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-id-badge"></i></span>
                                        <span className="searchprodicttext">BOpics</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas view-edit notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-eye"></i></span>
                                        <span className="searchprodicttext">View/Edit</span>
                                    </Link>
                                </div>
                                <div className="productsearch rightareas notactive">
                                    <Link data-widget="search" to="#" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext">Stats</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        
                        {(preLoader)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="filtersarea">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="serchbopicspr">
                                                    <form className="form-inline">
                                                        <input className="form-control" type="text" placeholder="Search list:"
                                                            aria-label="Search" disabled='disabled' />
                                                        <span className="customeset gobtn button-not-active">
                                                            <button type="button" className="btn btn-success">
                                                                <i className="fa-thin fa-arrow-right"></i>
                                                            </button>
                                                            <span className="searchprodicttext">Go</span>
                                                        </span>
                                                    </form>
                                                </div>
                                                <div className="filtersection">
                                                    <form className="form-inline" name="filter">
                                                        <p className="form-control-static">Filters:</p>
                                                        <div className="form-group">
                                                            <label htmlFor="collection" className="sr-only">Collection</label>
                                                            <Select className={"selects"}
                                                                options={productOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="creator" className="sr-only">Creator</label>
                                                            <Select className={"selects"}
                                                                options={locationOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="status" className="sr-only">Status</label>
                                                            <Select className={"selects"}
                                                                options={statusOptions}
                                                                styles={colourStyles}
                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                isDisabled={true} />
                                                        </div>
                                                        <span className="btnsets products-fit">
                                                            <span className="customeset revertfilters button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-rotate-left"></i></button>
                                                                <span className="searchprodicttext">Reset</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrow-right"></i></button>
                                                                <span className="searchprodicttext">Apply</span>
                                                            </span>
                                                            <span className="customeset gobtn button-not-active">
                                                                <button type="button" className="btn btn-success"><i className="fa-thin fa-arrows-rotate"></i></button>
                                                                <span className="searchprodicttext">Refresh</span>
                                                            </span>
                                                        </span>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="tablestructure productPages">
                                    <DataTable
                                        columns={columns}
                                        data={productList}
                                        theme="solarized"
                                        sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                        progressPending={dataLoading}
                                        selectableRows
                                        selectableRowsComponent={Checkbox}
                                        defaultSortFieldId={1}
                                        defaultSortAsc={true}
                                        responsive={true}
                                        expandableRows={true}
                                        expandableRowsComponent={ExpandedComponent}
                                        progressComponent={<CustomLoader />}
                                    />
                                </div>
                            </>
                        }
                        {(tooltipImg) ? <ReactTooltip id="tooltip-img" place="top" effect="solid" getContent={(dataTip) => <img src={dataTip} style={{ "height": "250px", "width": "auto" }} />} /> : ""}
                        {(tooltipText) ? <ReactTooltip className="tooltip-text" html={true} id="tooltip-text" place="top" effect="solid" getContent={(dataTip) => `${dataTip}`} /> : ""}
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}

export default Dashboard;