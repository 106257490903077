import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StepCombination from '../layouts/commonLayout';
import ExclamtionBlue from './../../assets/images/exclation-blue.png';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";

toast.configure();


function Dashboard(props) {

    document.title = 'BOstageB - Phone Verification ';

    /* #region  STATES */
    const ref = useRef();
    const [elementData, setElementData] = useState("");
    const [underVerification, setUnderVerification] = useState(0);
    const [afterVerification, setAfterVerification] = useState(0);
    let navigate = useNavigate();
    const { state } = useLocation();
    // console.log("test state",state);
    const [cid, setCid] = useState("");
    const [cemail, setCemail] = useState("");

    const [first, setFirst] = useState("");
    const [second, setSecond] = useState("");
    const [third, setThird] = useState("");
    const [fourth, setFourth] = useState("");
    const [fifth, setFifth] = useState("");
    const [isPhoneVerified, setIsPhoneVerified] = useState(false)
    const [status, setStatus] = useState("");
    const [otp, setOtp] = useState();
    const [resendData, setResendData] = useState(false);

    const [editMode, setEditMode] = useState(0);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {

        if (localStorage.getItem('viewMode') == "editmode") {
            setEditMode(1);

        } else {
            localStorage.removeItem('viewMode');
            setEditMode(0)
        }
    }, [localStorage.getItem('viewMode')]);

    useEffect(() => {
        if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
            if (typeof props.compData?.Companies !== 'undefined')
                setCid(props.compData?.Companies[0].companyID);
            setCemail(props.compData?.email);
        } else {
            if (editMode) {
                setCid(props.compData?.Companies[0].companyID);
                setCemail(props.compData?.email);
            } else {
                setCid(localStorage.getItem('lastInsertedID'));
                setCemail(props.compData?.email);
            }

        }

    });

    useEffect(() => {
        if (typeof props?.compData?.companyDetail != "undefined") {
            setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified)
        }
        setStatus(props?.compData?.completePercentage)
    }, [props?.compData]);


    const getValidated = (values) => {
        var r = /\d+/;

        if (values.match(r)) {
            return true;
        } else {
            return false;
        }
    }

    const [denied, setDenied] = useState(false);


    useEffect(() => {
        let _data = JSON.parse(localStorage.getItem("__data"));

        if (typeof props?.compData?.companyDetail != "undefined") {
            if (_data) {
                if (!_data?.AccountMenu) {

                    setDenied(true)
                } else {
                    setDenied(false)
                }
            }
        }

    }, [props.compData]);
    /* #endregion */

    /* #region  METHODS */
    const handelFirst = (e) => {
        if (getValidated(e.target.value)) {
            setFirst(e.target.value);
            document.getElementById('second').focus();
        } else {
            setFirst("");
        }

    }
    const handelSecond = (e) => {
        if (getValidated(e.target.value)) {
            setSecond(e.target.value);
            document.getElementById('third').focus();
        } else {
            setSecond("");
            document.getElementById('first').focus();
        }
        // setSecond(e.target.value);
        // if(e.target.value)
        // document.getElementById('third').focus();
    }
    const handelThird = (e) => {

        if (getValidated(e.target.value)) {
            setThird(e.target.value);
            document.getElementById('fourth').focus();
        } else {
            setThird("");
            document.getElementById('second').focus();
        }

        // setThird(e.target.value);
        // if(e.target.value)
        //     document.getElementById('fourth').focus();
    }
    const handelFouth = (e) => {
        if (getValidated(e.target.value)) {
            setFourth(e.target.value);
            document.getElementById('fifth').focus();
        } else {
            setFourth("");
            document.getElementById('third').focus();
        }
        // setFourth(e.target.value);
        // if(e.target.value)
        //     document.getElementById('fifth').focus();
    }
    const handelFifth = (e) => {

        var r = /\d+/;

        if (e.target.value.match(r)) {
            setFifth(e.target.value);

            if (first && second && third && fourth && e.target.value) {
                let otp = first.toString() + second.toString() + third.toString() + fourth.toString() + e.target.value.toString();
                //verifyOtp(otp);
                setOtp(otp);
            }

        } else {
            setFifth("");
            document.getElementById('fourth').focus();
        }


    }

    const otpResend = () => {
        // console.log("xsxcsxd" ,cid,cemail);
        setFirst("");
        setSecond("");
        setThird("");
        setFourth("");
        setFifth("");
        setResendData(true);
        let formData = {
            "companyID": cid,
            "emailID": cemail
        }
        axios.post(process.env.REACT_APP_API_URL + "resend-otp?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                toast.success("Verification code has been resent on your registered phone number.", {
                    theme: "dark",
                    icon: ({ theme, type }) => <img src={errorSuccess} />
                });
            } else {
                toast.error(res.data.message, {
                    theme: "dark",
                    icon: ({ theme, type }) => <img src={errorWarning} />
                });

            }

        });


    }

    const verifyOtp = () => {  //console.log(otp);
        setUnderVerification(1);
        setResendData(0);
        let formData = {
            "otp": otp,
            "companyID": cid
        }

        axios.post(process.env.REACT_APP_API_URL + "verify-otp?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
            if (res.data.status == 200) {
                setOtp();
                setAfterVerification(1);
                // localStorage.removeItem('lastInsertedID');
                //props.loginFun(localStorage.getItem('token'), '10')
                navigate('/account/company', { state: { OTPVerified: true } });
                /*let message = "Good start! First step to create your account is completed. Let's add the company logo now.";
              toast.success(message, {
                  theme: "dark",
                  icon: ({theme, type}) =>  <img src={errorSuccess}/>
                  onClose: () => navigate('/account/company/logo');
              });
      
               navigate('/account/company/logo'); */

            } else {

                setFirst("");
                setSecond("");
                setThird("");
                setFourth("");
                setFifth("");

                toast.error(res.data.message, {
                    theme: "dark",
                    icon: ({ theme, type }) => <img src={errorWarning} />
                });

            }
            setUnderVerification(0);
        });
    }
    /* #endregion */

    return (
        <>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">
                        <>
                            <div className="controlbar">
                                <div className="productsearch leftsidetr notactive">
                                    <Link data-widget="search" className="button-not-active" to="#" onClick={(e) => e.preventDefault()} role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                        <span className="searchprodicttext text-center">Accounts</span>
                                    </Link>
                                </div>
                                <div className="centerbtn">
                                    <Link to={(status === 'Completed') ? "/account/company/list" : "#"} className={(status === 'Completed') ? "btn btn-success" : "btn btn-success button-not-active"}>Companies</Link>
                                    <Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Companies</Link>
                                    <Link to="#" className={(editMode) ? "btn btn-success button-not-active saveIt" : "btn btn-success button-not-active saveIt"}>New Company</Link>
                                </div>
                                <div className="rightcontrolserch">
                                    <div className="productsearch rightareas">
                                        <Link data-widget="search" to="/account/company" role="button">
                                            <span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
                                            <span className="searchprodicttext">ID</span>
                                        </Link>
                                    </div>
                                    {/* <div className="productsearch rightareas notactive"> */}
                                    <div className={(isPhoneVerified) ? "productsearch rightareas" : "productsearch rightareas notactive"}>
                                        <Link data-widget="search" to={(isPhoneVerified) ? "/account/company/logo" : ""} role="button">
                                            <span className="btn btn-success"><i className="fa-thin fa-sign-hanging"></i></span>
                                            <span className="searchprodicttext">Logo</span>
                                        </Link>
                                    </div>
                                    <div className="productsearch rightareas notactive">
                                        <Link data-widget="search" to="#" role="button">
                                            <span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
                                            <span className="searchprodicttext">Users</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="filtertopcombo">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <StepCombination cdata={props.compData} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="creators-section" style={{ "display": (afterVerification) ? "none" : "block" }}>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="small-formas" >
                                                <div className="blueicons-area">
                                                    <img src={ExclamtionBlue} />
                                                </div>

                                                <div className="send-blockbtn" style={{ "display": (underVerification) ? "block" : "none" }}>
                                                    <div className="loaderClasses PhVeri " >
                                                        <span className="processingClass ">Validating</span> <span className=""><i className="fa fa-spinner fa-spin fa-2x"></i></span>
                                                    </div>
                                                </div>
                                                <span className="number-textter" >
                                                    <p className="verifying-text" style={{ "display": (underVerification) ? "none" : "block" }} >{(!resendData) ? "We're getting there! We need now to verify the phone number you provided. Please insert the verification code received on the number" : "New code was sent to"}
                                                    </p>
                                                    <span className="bold-number" style={{ "display": (underVerification) ? "none" : "block" }} >{localStorage.getItem('tel')}</span>
                                                </span>

                                                {/* <p className="verifying-text verifyonly" style={{"display":(underVerification)?"block":"none"}}>Verifying</p> */}

                                                <div className="phonenuymber-block">
                                                    <span className="number-filed"><input type="tel" id="first" maxLength={1} pattern="[0-9]{1}" value={first} onChange={handelFirst} readOnly={(underVerification) ? true : false} /></span>
                                                    <span className="number-filed"><input type="tel" id="second" maxLength={1} pattern="[0-9]{1}" value={second} onChange={handelSecond} readOnly={(underVerification) ? true : false} /></span>
                                                    <span className="number-filed"><input type="tel" id="third" maxLength={1} pattern="[0-9]{1}" value={third} onChange={handelThird} readOnly={(underVerification) ? true : false} /></span>
                                                    <span className="number-filed"><input type="tel" id="fourth" maxLength={1} pattern="[0-9]{1}" value={fourth} onChange={handelFouth} readOnly={(underVerification) ? true : false} /></span>
                                                    <span className="number-filed"><input type="tel" id="fifth" maxLength={1} pattern="[0-9]{1}" value={fifth} onChange={handelFifth} readOnly={(underVerification) ? true : false} /></span>
                                                </div>
                                                {/* <div className="allow-line" style={{"display":(underVerification)?"none":"block"}}>
                                             <p>Didn't get it? (allow a few seconds)</p> 
                                             <span className="bypass-line"><Link to="/account/company-admin">Skip for now</Link></span> 
                                            </div> 
                                            */}


                                                <div className="send-blockbtn twobtnsets" >
                                                    <span className="resend-confirm"><Link to="#" onClick={otpResend} >Resend Code</Link>
                                                        <span className="bottom-lines">Didn't get it? (allow a few seconds)</span>
                                                    </span>
                                                    <span className="resend-confirm secons"><Link to="#" onClick={verifyOtp} disabled={(otp != undefined) ? "" : "disabled"}>Confirm</Link>
                                                        <span className="bottom-lines" style={{ "visibility": (editMode) ? "hidden" : (localStorage.getItem("status") != "100" && localStorage.getItem("status") != "Completed") ? "hidden" : "" }}>
                                                            <Link to="/account/company/logo">Skip for now</Link>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                </div>

                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </>
    );
}
export default Dashboard;