import React, { useEffect, useRef, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { BLOB_STORE_CONTAINER } from '../../config/constant.js';
import * as Library from '../../utils/Library';
import BrandAside from '../layouts/BrandAside';
import BrandNavigation from '../layouts/BrandDashboardHeader';
import BrandFooter from '../layouts/BrandFooter';
import './../../assets/css/inputTags.css';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';

toast.configure();

/* #region  SCOPED CSS */
const Container = styled.div`
		.profile-div{
			height: 120px !important;
		}

		.datatable-section h4{
			color: #D1D1D1;
			margin-right: 10px;
			font-size: 14px;
		}
		
		.datatable-section .search-bar{
			margin: 0;
			border-bottom: 2px solid #1E2631;
		}

		.datatable-section .search-bar >span {
			display: flex;
			line-height: 20px;
			color: #4F5B6D;
			align-items: center;
			font-size: 9pt;
		}

		.filter-section >span {
			line-height: 35px;
			font-size: 10pt;
			margin-right: 10px;
			border-bottom: 2px 
		}

		.filter-section >.search-input{
			border-radius: 0;
			background: #1E2631;
			border-color: #1E2631;	
			box-shadow: none;
			height: 35px;
		}

		.creators-section .cardsection{
			margin-bottom: 10px;	
		}
	`;
/* #endregion */

function StoreStaff(props) {
    document.title = 'BOstageB - Store Staff';
    const ref = useRef();

    /* #region  STATES */
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    //const [defaultOption, setDefaultOption] = useState({})
    const [elementData, setElementData] = useState('');
    const [buttonGray, setButtonGray] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [preLoader, setPreLoader] = useState(false);

    //FORM STATES
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('');
    const [roleOption, setRoleOption] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [mobileCode, setMobileCode] = useState({ value: '+47', label: '+47' });
    const [countryMobileCodeOption, setCountryMobileCodeOption] = useState({});
    const [addToSelected, setAddToSelected] = useState('');
    const [addToOption, setAddToOption] = useState({});
    const [files, setFiles] = useState('');
    const [uploadFileName, setUploadFileName] = useState({});
    const [firstNameError, setFirstNameError] = useState(false);
    const [roleError, setRoleError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [mobileCodeError, setMobileCodeError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [addToError, setAddToError] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [profilePhotoBlob, setProfilePhotoBlob] = useState(null);

    //INVITES LIST
    const [invitesList, setInvitesList] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setStatus(props?.compData?.completePercentage);
    }, [props?.compData]);

    useEffect(() => {
        refreshStaffInvites();
    }, []);

    useEffect(() => {
        if (props.compData?.companyEmail === undefined) return;
		
    }, [props.compData]);

    useEffect(() => {
        getMobileCountoryCode();
        getRoleData();
    }, [props]);
    /* #endregion */

    /* #region  METHODS */
    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>);

    const refreshStaffInvites = () => {
        const filterData = {
            id: localStorage.getItem('token'),
            limit: 10
        };

        Library.makePostRequest('getStaffFromCompanies', filterData, false, setPreLoader).then(res => {
            if (res === undefined || res.status !== 200 || res?.data === undefined || res?.data?.data === undefined) {
                setInvitesList([]);
                return;
            }

            const data = res.data.data;
            setInvitesList(data);
            setDataLoading(false);
        });

        const formData = { 'id': localStorage.getItem('token') };
        Library.makePostRequest('getStoresFromCompanies', formData, false, setPreLoader).then(res => {
            if (res.status !== 200) return;

            var stores = res?.data?.data[0]?.stores;
            const addToOptions = [];

            if (stores.length > 0) {
                const store = stores[0];
                stores.forEach(item => {
                    addToOptions.push({ value: item.ourID, label: item.name });
                });
                setAddToOption(addToOptions);
                setAddToSelected({ value: store.ourID, label: store.name });
            }
        });
    };

    const phonenumber = (inputtxt) => {
        var phoneno = /^\d{10}$/;
        if (inputtxt.match(phoneno)) {
            return true;
        }
        else {
            return false;
        }
    };

    function ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleValidation = () => {
        let formIsValid = true;
        if (firstName == '') {
            setFirstNameError(true);
            formIsValid = false;
        }

        if (mobileCode == '') {
            setMobileCodeError(true);
            formIsValid = false;
        } else {
            setMobileCodeError(false);
        }

        if (role == '') {
            setRoleError(true);
            formIsValid = false;
        }
        if (addToSelected == '') {
            setAddToError(true);
            formIsValid = false;
        }

        if (mobile == '') {

            //	setMobileError(true);
            //		formIsValid = false;
        } else {
            if (!phonenumber(mobile)) {
                setMobileError(true);
                formIsValid = false;
            }
        }
        if (email == '') {
            setEmailError(true);
            formIsValid = false;
        } else {
            if (!ValidateEmail(email)) {
                setEmailError(true);
                formIsValid = false;
            }
        }

        if (files == '') {
            //	setImgError(true)
            //	formIsValid = false;
        }


        return formIsValid;
    };

    const getRoleData = () => {
        //setEmail(props.compData?.email);
        const roleOption1 = [];

        Library.makePostRequest('get-store-role').then((res) => {
            if (res.data.status === 200) {
                const data = res.data.data;
                data.forEach((da) => {
                    roleOption1.push({ value: da.name, label: da.name });
                });
                setRoleOption(roleOption1);
            }
        });
    };

    const getMobileCountoryCode = () => {

        const countryOption1 = [];
        const countryMobileCodeOption1 = [];

        Library.makePostRequest('get-country-master-data').then((res) => {
            if (res.data.status === 200) {
                const data = res.data.data;
                data.forEach((da) => {

                    countryOption1.push({ value: da.code, label: da.name });
                    countryMobileCodeOption1.push({ value: da.phone, label: da.phone });
                }
                );

                setCountryMobileCodeOption(countryMobileCodeOption1);
            }
        });
    };

    const handleEmail = (e) => {
        if (e.target.value != ' ') {
            setEmailError(false);
            setEmail(e.target.value);
        }
        if (e.target.value && firstName && role && addToSelected) {
            setButtonGray(true);
        } else {
            setButtonGray(false);
        }
    };

    const FileUpload = async (e) => {

        setUploadFileName(e.target.files[0]);

        if (e.target.files[0] != ' ') {
            //  setImgError(false);
            setFiles(URL.createObjectURL(e.target.files[0]));
            setProfilePhotoBlob(e.target.files[0]);
        }

    };

    const handleFirstName = (e) => {
        if (e.target.value != ' ') {
            setFirstNameError(false);
            setFirstName(e.target.value);
        }
        if (e.target.value && role && addToSelected && email) {
            setButtonGray(true);
        } else {
            setButtonGray(false);
        }
    };

    const handleLastName = (e) => {
        if (e.target.value != ' ') {
            //setLastNameError(false);
            setLastName(e.target.value);
        }

    };

    const handleMobile = (e) => {
        if (e.target.value != ' ') {
            setMobileError(false);
            setMobile(e.target.value);
        }

    };

    const setRoleH = (e) => {
        if (e.value) {
            setRoleError(false);
            setRole(e.value);
        }

        if (e.value && firstName && addToSelected && email) 
            setButtonGray(true);
        else 
            setButtonGray(false);
    };

    const setAddToH = (e) => {
        if (e.value) {
            setAddToError(false);
            setAddToSelected(e);
        }

        if (e.value && firstName && role && email)
            setButtonGray(true);
        else 
            setButtonGray(false);
		
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const imageArray = [];
        if (handleValidation()) {

            setButtonDisable(true);
            let formData = {
                'firstName': firstName,
                'lastName': lastName,
                'role': role,
                'email': email,
                'mobileNo': (mobile) ? mobileCode.value + mobile : '',
                'storeID': addToSelected?.value,
                'profileImage': '', // (uploadFileName.name != undefined) ? BLOB_URL + BLOB_STAFF_CONTAINER + '/' + uploadFileName.name : "",			
                //"brandID": (localStorage.getItem('brandID')) ? localStorage.getItem('brandID') : "",
                //"userType": 'clerk'
            };
            const cname = props?.compData?.Companies[0]?.companyName;
            imageArray.push(uploadFileName);

            if (uploadFileName.name != undefined) {
                setSubmitLoader(true);
                Library.uploadBlobFile(profilePhotoBlob, BLOB_STORE_CONTAINER)
                    .catch(error => Library.defaultApiError(error))
                    .then(res => {
                        formData = { ...formData, 'profileImage': res.data.data.imageURL };
                        //console.log("Invite w/ Blob:", formData)
                        inviteStaffRequest(formData);					
                    }).finally(() => {
                        setSubmitLoader(false);
                    });
            } else {
                //console.log("Invite wo/ Blob:", formData);
                inviteStaffRequest(formData);
            }
        }
    };

    const inviteStaffRequest = (formData) => {
        Library.makePostRequest('invite-staff', formData, false, setSubmitLoader).then((res) => {
            if (res.data.status === 200) {
                props.loginFun(localStorage.getItem('token')); //todo: CHECK 95% ??

                refreshStaffInvites();

                let timerInterval;
                Swal.fire({
                    title: '',
                    html: 'Staff member invited. As soon as the invitation is accepted, credentials are provided, <br/> and they can login to the account.',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonColor: '#205380',
                    confirmButtonText: 'CONTINUE',
                    background: '#1d2530',
                    color: '#fff',
                    iconColor: '#00BB00',
                    width: '500px',
                    padding: '3em',
                    reverseButtons: true,
                    timer: 5000,
                    timerProgressBar: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.timer) {
                        //navigate("/store");
                    }
                });
                setButtonDisable(false);
            } else {
                //Library.defaultApiError(res.data.message);
                setButtonDisable(false);
            }
        });
    };

    const handleStatusChange = (name, storeObjID, userObjID, action) => {

        const filterData = {
            'storeObjID': storeObjID,
            'userObjID': userObjID,
            'action': action
        };

        const message = `Are you sure you want to ${action === 'delete' ? 'delete' : action === 'Suspended' ? 'suspend' : 'enable'} the user '${name}' ?`;
		
        Library.showConfirmationMessage(message, () => {
            Library.makePostRequest('updateStoreStaffStatus', filterData, false, setSubmitLoader).then(res => {
                if (res === undefined || res.status !== 200 || res?.data === undefined) {
                    Library.showErrorMessage(res.data.message);
                    return;
                }
	
                refreshStaffInvites();
            });
        });
    };

    const callFileUpload = (e) => {
        const checkClass = e.target.classList;
        if (checkClass.contains('profile-div') || checkClass.contains('fa-upload') || checkClass.contains('upload-profile-text')) {
            document.getElementById('fud').click();
        }

    };

    const cancelFun1 = (e) => {
        e.preventDefault();
        setFiles('');
        setUploadFileName({});
    };
    /* #endregion */

    /* #region  CONSTANTS */
    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: 'transparent',
            border: state.isFocused ? '1px solid #0B0' : '1px solid transparent',
            boxShadow: state.isFocused ? '0px 0px 1px transparent' : 'none',
            '&:hover': {
                border: '1px solid #0B0',
            },
            '&:active': {
                border: '1px solid #0B0',
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#205380',
                color: '#a6a6a6',
                cursor: 'pointer',
                border: '1px solid #10141B',
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                '&:hover': {
                    border: '1px solid #00AEEF',
                },
                '&:active': {
                    backgroundColor: '#205380',
                }
            };
        },
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: '2px',
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px'
            }
        }),
        singleValue: styles => ({ ...styles, color: '#a6a6a6' }),
        input: styles => ({ ...styles, color: '#a6a6a6' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
            ':hover': {
                backgroundColor: '#205380',
                color: 'white',
            },
        }),
    };

    createTheme('solarized', {
        text: {
            primary: '#fff',
            secondary: '#fff',
        },
        background: {
            default: '#10141B',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#333F50',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '200px'
        },
        {
            name: 'To store',
            selector: row => row.toStore,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            style: {
                minWidth: '150px'
            }
        },
        {
            name: 'Mobile',
            selector: row => row.phoneNumber,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '150px',
            cell: (row) => <><span className="store-name2" style={
                {
                    color: row.status === 'Invited' ? '#FF9900'
                        : row.status === 'Active' ? '#00AEEF'
                            : row.status === 'Enrolled' ? '#00BB00'
                                : row.status === 'Suspended' ? '#FF9900'
                                    : '#D1D1D1'
                }
            } title={row.status}>{row.status}</span></>
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            cell: (row) => <>
                <Link className="btn btn-success button-not-active" to="#">VIEW</Link>
                <Link className={'btn btn-success'} to="#" onClick={() => handleStatusChange(row.name, row.storeObjID, row.userObjID, row.status === 'Suspended' ? 'Active' : 'Suspended')}>{`${row.status === 'Suspended' ? 'Enable' : 'Suspend'}`}</Link>
                <Link className="btn btn-success" to="#" onClick={() => handleStatusChange(row.name, row.storeObjID, row.userObjID, 'delete')}>{`${row.status === 'Invited' ? 'Cancel' : 'Delete'}`} </Link>
            </>,
            width: '130px',
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true
        }
    ];
    /* #endregion */
	
    return (
        <Container>
            {(submitLoader)
                ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div>
                : ''
            }

            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">
                    <div className="controlbar">
                        <div className="productsearch leftsidetr">
                            <Link data-widget="search" className="button-not-active" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                <span className="searchprodicttext text-center">Stores</span>
                            </Link>
                        </div>
                        <div className="centerbtn">
                            <Link to="/store/list" className={(status === 'Completed') ? 'btn btn-success' : 'btn btn-success button-not-active'}>Stores</Link>
                            <Link to="#" className="btn btn-success button-not-active">Edit Store</Link>
                            <Link to="/store" className="btn btn-success">New Store</Link>
                            <Link to="#" className="btn btn-success active">Staff</Link>
                        </div>
                        <div className="rightcontrolserch">
                            <div className="productsearch rightareas">
                                <Link data-widget="search" to="#" role="button">
                                    <span className="btn btn-success active"><i className="fa-thin fa-envelope-open-text"></i></span>
                                    <span className="searchprodicttext">Invite</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas">
                                <Link to="/store/staff/list" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-list"></i></span>
                                    <span className="searchprodicttext">List</span>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {(preLoader)
                        ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                        :
                        <>
                            <div className="filtertopcombo">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="filtersection creatorsbtnset enroll-brand ">
                                                <span className={(buttonGray) ? 'customeset textinside next-new-btn' : 'customeset textinside next-new-btn disabledbtn'}>
                                                    <button type="submit" onClick={handleSubmit} className="btn btn-success invite-control" disabled={(buttonDisable) ? 'disabled' : ''} >
														INVITE {
                                                            (buttonDisable)
                                                                ? (<i className="fas fa-spinner fa-spin"></i>)
                                                                : (<i className="fa-thin fa-chevron-right"></i>)
                                                        }
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="creators-section">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="cardsection">
                                                <div className="card-header">
                                                    <h3 className="card-title">INVITE STAFF MEMBER<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
                                                </div>
                                                <div className="card-body">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <div className="row">
                                                                    <div className="col-md-4">
                                                                        <div className="form-group activeborder selectheight" >
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle " aria-hidden="true"></i> To Store</label>
                                                                            <Select className={(addToError) ? 'selects error-input' : 'selects'}
                                                                                options={addToOption}
                                                                                styles={colourStyles}
                                                                                onChange={setAddToH}
                                                                                value={addToSelected}
                                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group activeborder">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> First name</label>
                                                                            <input type="text" className={(firstNameError) ? 'form-control error-input' : 'form-control'} placeholder="" value={firstName} onChange={handleFirstName} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group ">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Last name</label>
                                                                            <input type="text" className="form-control" placeholder="" value={lastName} onChange={handleLastName} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group activeborder selectheight" >
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle " aria-hidden="true"></i> Role</label>
                                                                            <Select className={(roleError) ? 'selects error-input' : 'selects'}
                                                                                styles={colourStyles}
                                                                                //defaultValue={role}
                                                                                options={roleOption}
                                                                                onChange={setRoleH}
                                                                                //isDisabled
                                                                                placeholder={<div className="italic-placeholder">Select...</div>}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group activeborder ">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Email</label>
                                                                            <div className="addonMailleft ">
                                                                                <input type="text" className={(emailError) ? 'form-control error-input' : 'form-control'} value={email} placeholder="" onChange={handleEmail} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group ">
                                                                            <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Mobile nr.</label>
                                                                            <div className="form-group ">
                                                                                <div className="havesuffixdrop">
                                                                                    <Select className="selects"
                                                                                        defaultValue={mobileCode}
                                                                                        options={countryMobileCodeOption}
                                                                                        styles={colourStyles}
                                                                                        onChange={setMobileCode}
                                                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                                                        isDisabled
                                                                                    />

                                                                                    <input type="tel" className={(mobileError) ? 'form-control error-input' : 'form-control'} pattern="[0-9]{10}" maxLength={10} onChange={handleMobile} value={mobile} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="user-profile-picture">
                                                                            <div className="form-group activeborder"  >
                                                                                <label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Profile photo</label>
                                                                                <div className={(imgError) ? 'profile-div error-input' : 'profile-div'} onClick={callFileUpload} >
                                                                                    <div className="customprofile-pic" style={{ 'display': (files != '') ? 'none' : 'block' }}>
                                                                                        <i className="fa fa-upload" aria-hidden="true" ></i>
                                                                                        <span className="upload-profile-text" >Upload</span>
                                                                                    </div>
                                                                                    <input id="fud" type="file" className="upload-profile-text" accept="image/*" onChange={FileUpload} style={{ 'display': 'none' }} />
                                                                                    {(files) ? (<span className="close-image" onClick={cancelFun1}></span>) : ''}
                                                                                    <img src={files} style={{ 'display': (files != '') ? 'block' : 'none' }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="datatable-section">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="tablestructure productPages search-bar">
                                                <span><h4>ENROLLED STAFF {invitesList ? `(${invitesList.length})` : ''}</h4> (last 10 entries)</span>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                                <div className="filter-section">
                                                    <span>Search for: </span>
                                                    <input className="form-control search-input" type="text" aria-label="Search" disabled='disabled' />
                                                </div>
                                            </div>
                                            <div className="col-md-12 tablestructure productPages">
                                                <DataTable
                                                    className="showItems"
                                                    columns={columns}
                                                    data={invitesList}
                                                    theme="solarized"
                                                    sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true" style={{ marginLeft: '5px' }}></i>}
                                                    progressPending={dataLoading}
                                                    selectableRows
                                                    //selectableRowsComponent={Checkbox}
                                                    //defaultSortFieldId={1}
                                                    //defaultSortAsc={true}
                                                    responsive={true}
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    progressComponent={<CustomLoader />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </Container>
    );
}

export default StoreStaff;