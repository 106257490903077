import React, { useState, useRef, createRef, useEffect } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const ProductListCanvas = ({ isVisible, setShowProductList, canvasProductList, setDataForLinkProduct, activeBOtags, linkedListItems, highlightTagFun, activeProductData, resetValue, resetValueByClose, FiletrData, inputField, unlinkProductList, elementId, resetPl, linkedTabActive, linkedTabActiveFunction, updateSizeData, updateColorData, activePointer, msg }) => {

	const itemEls = useRef([]);
	const [color, setColor] = useState(null)
	const [colorReferenceCode, setColorReferenceCode] = useState(null)
	const [sizeReferenceCode, setSizeReferenceCode] = useState(null)
	const [size, setSize] = useState(null)
	const [name, setName] = useState(null)
	const [disValue, setDisValue] = useState(0);
	const [divSel, setDivSel] = useState([]);

	const [colorPL, setColorPL] = useState(null)
	const [colorReferenceCodePL, setColorReferenceCodePL] = useState(null)
	const [sizeReferenceCodePL, setSizeReferenceCodePL] = useState(null)
	const [sizePL, setSizePL] = useState(null)

	/*******************cc********************** */
	//const [startPtSize,setStartPtSize] =useState(0);
	//const [startPt,setStartPt] =useState(0);
	const [startPt, setStartPt] = useState([]);
	const [startPtSize, setStartPtSize] = useState([]);

	const [startPtSizeSelected, setStartPtSizeSelected] = useState([]);
	const [startPtCol, setStartPtCol] = useState([]);

	//console.log(startPtSize)

	useEffect(() => {
		if (canvasProductList.length) {
			for (var i = 0; i < canvasProductList.length; i++) {
				startPt.push({ "pos": 0 });
				startPtSize.push({ "pos": 0 });
			}
		}
	}, [1])

	/*
		useEffect(()=>{
	 	
		 if(linkedListItems.length){
			 setStartPtSizeSelected([]);
			 setStartPtCol([]);
 
			 for(var i=0;i<linkedListItems.length;i++){
				 startPtCol.push({"pos":0});
				   startPtSizeSelected.push({"pos":0});
			}
		 }  
		 console.log("link",startPtCol,startPtSizeSelected)
	   },[linkedListItems])
	  */


	useEffect(() => {

		if (linkedListItems.length) {

			while (startPtCol.length > 0) {
				startPtCol.pop();
			}
			while (startPtSizeSelected.length > 0) {
				startPtSizeSelected.pop();
			}

			linkedListItems.map((res, indx) => {

				res?.gtinData?.color.map((color, ins) => {

					if (color.color == res.selectedColor) {

						startPtCol.push({ "pos": ins });
					}
				});

				res?.gtinData?.size.map((size, ind) => {

					if (size == res?.selectedSize) {
						startPtSizeSelected.push({ "pos": ind });
					}
				});

			});
		}
		// console.log("link",startPtCol,startPtSizeSelected);
		// console.log("data",linkedListItems,divSel);

	}, [linkedListItems])




	const runFunction = (elementId, colorName, ke) => {

		/*let object = Object.values(itemEls?.current);
		object.map(r=>{
			if(elementId+"_color" == r?.current?.id){
				r?.current?.classList.add("active");
				setColor(colorName)
				let refCode = elementId?.split("_");
				setColorReferenceCode(refCode[0])
			}else{
				r?.current?.classList?.remove("active")
			}
		}) */
		/***added***/
		var elements = document.getElementsByClassName("pListColor");
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList?.remove("active");
		}
		var clist = document.getElementById(elementId + "_color").classList?.add("active");
		setColor(colorName)
		let refCode = elementId?.split("_");
		setColorReferenceCode(refCode[0]);
		//position
		let st = parseInt(refCode[1]) - 1;
		if (st > 6) {
			let r = startPt.map((j, inx) => {

				if (inx == ke) {
					j.pos = st;
				}
				return j;
			})
		} else {
			let r = startPt.map((j, inx) => {
				if (inx == ke) {
					j.pos = 0;
				}
				return j;
			})
		}

		const eleme = document.getElementsByClassName("pListSize");
		var size_checked_id = 0;
		for (var i = 0; i < eleme.length; i++) {
			if (eleme[i].checked) {
				size_checked_id = eleme[i].id;
			}
		}
		if (size_checked_id) {
			let refCodeT = size_checked_id.split("-");
			if (refCodeT[0] != refCode[0]) {
				for (var i = 0; i < eleme.length; i++) {
					eleme[i].checked = false;
				}
			}
		}
	}

	const getSize = (e, ids, key, ke) => {
		setSize(e.target.value)
		let refCode = e.target.id.split("-");
		setSizeReferenceCode(refCode[0])

		const elements = document.getElementsByClassName("pListSize");
		for (var i = 0; i < elements.length; i++) {
			elements[i].checked = false;
		}
		document.getElementById(ids).checked = true;

		if (key > 6) {
			let r = startPtSize.map((j, inx) => {

				if (inx == ke) {
					j.pos = key;
				}
				return j;
			})
		} else {
			let r = startPtSize.map((j, inx) => {
				if (inx == ke) {
					j.pos = 0;
				}
				return j;
			})
		}


		const eleme = document.getElementsByClassName("pListColor active");
		var colorId = 0;
		for (var i = 0; i < eleme.length; i++) {

			colorId = eleme[i].id;
		}

		if (colorId) {
			let refCodeT = colorId.split("_");
			//console.log(refCode[0],refCodeT[0]);	
			if (refCodeT[0] != refCode[0]) {

				var elementss = document.getElementsByClassName("pListColor");
				for (var i = 0; i < elementss.length; i++) {
					elementss[i].classList?.remove("active");
				}
			}
		}
	}



	/*
	  useEffect(()=>{
		  console.log("color",colorReferenceCode); console.log("size",sizeReferenceCode);
  
		  if(colorReferenceCode != null &&  sizeReferenceCode != null){
		  if(colorReferenceCode != sizeReferenceCode){
			   setColorReferenceCode(null);
			   setSizeReferenceCode(null);
			  var elementss = document.getElementsByClassName("pListColor");
			  for (var i = 0; i < elementss.length; i++) {
				  elementss[i].classList?.remove("active");
			  }
  
			  const elements = document.getElementsByClassName("pListSize");
			  for (var i = 0; i < elements.length; i++) {
				  elements[i].checked = false;
			  }
		  }
	  }
  
	  },[colorReferenceCode,sizeReferenceCode]) 
	 */


	const runFunctionPL = (elementClass, colorName, id, indx, key, ke) => {

		const s = document.getElementById(elementClass + id);

		var elements = document.getElementsByClassName(elementClass);

		for (var i = 0; i < elements.length; i++) {
			elements[i].classList?.remove("active");
		}
		s?.classList?.add("active");

		setColorPL(colorName)

		const els = document.getElementsByClassName("editprodclist");
		for (var i = 0; i < els.length; i++) {
			els[i].style.display = "none";
		}
		// document.getElementById(indx).style.display="block";

		/*
		 if(key > 6 ){
			 let r = startPtCol.map((j,inx)=>{
			   
				   if(inx==ke){
					 j.pos = key;
				   }   
				   return j;
			  })
		   }else{
			   let r = startPtCol.map((j,inx)=>{
				   if(inx==ke){
					 j.pos = 0;
				   }
				   return j; 
			   })	
		   }  
		   */
	}

	const getSizePL = (e, ids, key, ke) => {
		//console.log(e.target.value)
		setSizePL(e.target.value)

		let refCode = e.target.id.split("-");
		setSizeReferenceCodePL(refCode[0])

		//setDisValue(1);
		const elements = document.getElementsByClassName("editprodclist");
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.display = "none";
		}
		//document.getElementById(ids).style.display="block";
		/*
		 if(key > 6 ){
			 let r = startPtSizeSelected.map((j,inx)=>{
			   
				   if(inx==ke){
					 j.pos = key;
				   }   
				   return j;
			  })
		   }else{
			   let r = startPtSizeSelected.map((j,inx)=>{
				   if(inx==ke){
					 j.pos = 0;
				   }
				   return j; 
			   })	
		   } 
		   */
	}


	const [shows, setShows] = useState(0);
	const getFunChng = () => {
		if (shows) {
			setShows(0);
		} else {
			setShows(1);
		}
	}
	const changeRadio = (e) => {
		//e.preventDefault()
	}

	///added///
	const resetSizeColor = () => {

		var elements = document.getElementsByClassName("pListColor");
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList?.remove("active");
		}

		var elem = document.getElementsByClassName("pListSize");
		for (var i = 0; i < elem.length; i++) {
			elem[i].checked = false;
		}

		resetPl();
		setColor(null);
		setSize(null);
	}

	///added///
	useEffect(() => {


		const disp = (elementId) => {

			var elements = document.getElementsByClassName("pListColor");
			for (var i = 0; i < elements.length; i++) {
				elements[i].classList?.remove("active");
			}

			var elem = document.getElementsByClassName("pListSize");
			for (var i = 0; i < elem.length; i++) {
				elem[i].checked = false;
			}
			resetPl();
		}

		disp(elementId);

		// 	if (unlinkingItem !== 0) {
		// 	unlinkItem(unlinkingItem);
		//   }

	}, [elementId]);


	const changeValue = (e, indx) => {
		divSel[indx] = e.target.value;

	}

	/*
	useEffect(()=>{  
		const createList = (linkedListItems) =>{
			linkedListItems.map((res, indx) => {
				res?.gtinData?.size.map((size, ind) => {
						if(size == res?.selectedSize){
						let arr=[...divSel];
						arr[indx]=size;
						setDivSel(arr)
					}
				})
		});
	
		}
		createList(linkedListItems);
	}, [linkedListItems]);
	
	*/


	useEffect(() => {
		const createList = (linkedListItems) => {
			let arr = [];
			linkedListItems.map((res, indx) => {
				res?.gtinData?.size.map((size, ind) => {
					if (size == res?.selectedSize) {
						arr[indx] = size;
					}
				});
			});
			setDivSel(arr)
		}
		createList(linkedListItems);

		//	console.log("linkedListItems",linkedListItems);
		//	console.log("divsel",divSel);

	}, [linkedListItems]);

	const hideIt = () => {
		const elements = document.getElementsByClassName("editprodclist");
		for (var i = 0; i < elements.length; i++) {
			elements[i].style.display = "none";
		}
	}
	const [isSearched, setIsSearched] = useState(true)
	const searchOnPres = event => {
		if (event.key === "Enter" && event.target.value !== "") {
			//FiletrData(event)
			setIsSearched(false)
		}
	}
	const cancelCross = () => {
		setIsSearched(true)
	}
	const onEnter = (e) => {
		if (e.key == "Enter") {
			FiletrData(e);
		}
	}

	const preMessage = () => {
		setDataForLinkProduct({ "isLinked": false }, '', '', '', '');
	}
	const ORDER = ['one', 'one size', 'xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50'];
	const sortSize = (arraySize) => {
		//console.log("arraySize",arraySize);
		arraySize.sort((a, b) => {
			//console.log("tttt",a,b);
			a = a.toString().toLowerCase();
			b = b.toString().toLowerCase();

			let nra = parseInt(a);
			let nrb = parseInt(b);

			if ((ORDER.indexOf(a) != -1)) nra = NaN;
			if ((ORDER.indexOf(b) != -1)) nrb = NaN;

			if (nrb === 0) return 1;
			if (nra && !nrb || nra === 0) return -1;
			if (!nra && nrb) return 1;
			if (nra && nrb) {
				if (nra == nrb) {
					return (a.substr(('' + nra).length)).localeCompare((a.substr(('' + nra).length)));
				} else {
					return nra - nrb;
				}
			} else {
				return ORDER.indexOf(a) - ORDER.indexOf(b);
			}
		});
	}

	//console.log("isVisible",isVisible);


	return (
		<><div className={(isVisible == 'none') ? "opencontrolright pushleft displayNonePanel" : "opencontrolright pushleft displayShowPanel"}>
			<div className="itemspanelblue">
				<Link to="#" onClick={() => { (isVisible == 'block') ? setShowProductList('none') : setShowProductList('block'); resetValueByClose(); resetSizeColor(); }} className="btn btn-success">Items Panel <i className={(isVisible == 'none') ? "fa-thin fa-chevron-down" : "fa-thin fa-chevron-up"}></i></Link>
			</div>
		</div>
			<div className={(isVisible == 'block') ? "rightnavigation rightNavOn" : "rightnavigation"} style={{ "display": isVisible }}>
				<div className="parentnav">
					<div className="producthead">
						<Link to="#" onClick={getFunChng} className="opensettingpan"><i className="fa-thin fa-gear"></i></Link>
						<Link to="#" onClick={() => { setShowProductList('none'); resetValueByClose(); resetSizeColor(); }} className="closeicons">
							<i className="fa-thin fa-xmark"></i>
						</Link>
						<div className="prodcutlist-heading superheading">
							<h3>Products</h3>
						</div>
						<div className="xy" style={{ "display": (!shows) ? "block" : "none" }}>
							<div className="searchbarcontent">
								<div className="productsearch rightareas pull-left">
									<Link to="/products/importer" data-widget="search" role="button">
										<span className="btn btn-success">
											<i className="fa-thin fa-upload" aria-hidden="true"></i>
										</span>
										<span className="searchprodicttext">Products</span>
									</Link>
								</div>
								<div className="searchinputfield withClearBtn">
									<input type="text" className="form-control" value={inputField} onChange={(e) => { resetValue(e) }} id="" onKeyUp={event => searchOnPres(event)} onKeyDown={(e) => onEnter(e)} disabled={(canvasProductList.length) ? false : (isSearched) ? true : false} placeholder="" />
									<Link to="#" onClick={(e) => { resetValueByClose(); resetSizeColor(); cancelCross(); }} className="clearBtnSet">
										<i className="fa-thin fa-xmark"></i>
									</Link>
								</div>
								<div className="searchinputicon">
									<Link to="#" onClick={(e) => FiletrData(e)}>
										<i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i>
									</Link>
								</div>
							</div>
						</div>
						<div className="xy" style={{ "display": (shows) ? "block" : "none" }} >

							<div className="prodcutlist-heading">
								<h3>Settings</h3>
							</div>
						</div>
					</div>
					<div className="producttabbing" style={{ "display": (!shows) ? "block" : "none" }}>
						<ul className="nav nav-tabs">
							<li className={(linkedTabActive) ? "" : "active"}><a data-toggle="tab" onClick={() => linkedTabActiveFunction(false)} href="#productslist">Products List</a></li>
							<li className={(linkedTabActive) ? "active" : (activePointer) ? "linkedproductsad" : ""}><a data-toggle="tab" onClick={() => { linkedTabActiveFunction(true); resetSizeColor() }} href="#linkedproducts" >Linked Products</a></li>
						</ul>
						<div className="tab-content">
							<div id="productslist" className={!(linkedTabActive) ? "tab-pane fade in active" : "tab-pane fade in"}>
								{
									(canvasProductList.length) ? canvasProductList.map((r, i) => {
										let dSize = sortSize(r?.gtinData?.size)
										return (
											// <div className={(r.BOtgaId == activeProductData && r.isLinked)?"showcaseproduct nowlinked active":((r.isLinked)?"showcaseproduct nowlinked":"showcaseproduct")} onClick={(e) => highlightTagFun(e, activeBOtags)} key={i}>
											<div className="showcaseproduct" data-id={r.referenceCode} onClick={(e) => highlightTagFun(e, activeBOtags)} key={i}>
												<div className="shocaseimg">
													<img src={r?.gtinData?.image[0]} />
												</div>
												<div className="showdescritption">
													<h4>{r.referenceCodeNew}</h4>
													<div className="shownamecloth">{r.name}</div>
													<div className="coloswatches">
														<OwlCarousel id={"colorswatches_" + r.referenceCode} className='owl-theme' margin={5} nav startPosition={startPt[i]?.pos} items={7} dots={false}>
															{
																r?.gtinData?.color.map((color, ins) => {
																	itemEls.current[r.referenceCode + "_" + (ins + 1)] = createRef();

																	return (
																		(activeBOtags) ?
																			<div className='item'>
																				<Link to="#" onClick={() => runFunction(r.referenceCode + "_" + (ins + 1), color.color, i)} key={ins}>
																					<span className="pListColor" ref={itemEls.current[r.referenceCode + "_" + (ins + 1)]} id={r.referenceCode + "_" + (ins + 1) + "_color"} style={{ "background": "#" + color.colorCode }} title={color.color} ></span>{/*color.color*/}
																				</Link>
																			</div>
																			:
																			<div className='item'>
																				<Link to="#" onClick={preMessage} key={ins} style={{ "text-decoration": "none" }}>
																					<span className="pListColor1" style={{ "background": "#" + color.colorCode }} title={color.color} ></span>{/*color.color*/}
																				</Link>
																			</div>
																	)
																})
															}
														</OwlCarousel>
													</div>
													<div className="sizeclothes">
														<OwlCarousel id={"sizecloethes_" + r.referenceCode} className='owl-theme' margin={5} nav startPosition={startPtSize[i]?.pos} items={7} dots={false}>
															{
																r?.gtinData?.size.map((size, ind) => {
																	return (
																		(activeBOtags) ?
																			<div className='item'>
																				<div className="customradio" key={ind}>
																					<input className="pListSize" type="radio" onClick={(e) => getSize(e, r.referenceCode + "-" + size, ind, i)} value={size} id={r.referenceCode + "-" + size} name={r.referenceCode} />
																					<label htmlFor={r.referenceCode + "-" + size}></label>
																					<span className="sizenem">{size}</span>
																				</div>
																			</div>
																			:
																			<div className='item'>
																				<div className="customradio not-selectable" key={ind}>
																					<input className="pListSize1" type="radio" onChange={preMessage} value={size} id={r.referenceCode + "-" + size} name={r.referenceCode} />
																					<label htmlFor={r.referenceCode + "-" + size}></label>
																					<span className="sizenem">{size}</span>
																				</div>
																			</div>
																	)
																})
															}
														</OwlCarousel>
													</div>
													<div className="productNamejs">{r.productType}</div>
												</div>
												<div className="linkthisproduct">
													{/* <button onClick={() => {setDataForLinkProduct(r, color, size, colorReferenceCode, sizeReferenceCode);resetPl();resetSizeColor();}} className={r.isLinked || (color && size && (colorReferenceCode == r.referenceCode) && (sizeReferenceCode == r.referenceCode))?"btn btn-success active":"btn btn-success"} disabled={r.isLinked || (color && size && (colorReferenceCode == r.referenceCode) && (sizeReferenceCode == r.referenceCode))?"":"disabled"}>{(r.isLinked)?"UNLINK":"LINK"}</button> */}
													<button onClick={() => { setDataForLinkProduct(r, color, size, colorReferenceCode, sizeReferenceCode); resetPl(); resetSizeColor(); }} className={(color && size && (colorReferenceCode == r.referenceCode) && (sizeReferenceCode == r.referenceCode)) ? "btn btn-success active" : "btn btn-success"} disabled={(color && size && (colorReferenceCode == r.referenceCode) && (sizeReferenceCode == r.referenceCode)) ? "" : "disabled"}>{"LINK"}</button>
												</div>
												{/* {(activeBOtags || r.BOtgaId)?<div className="shownumber">{(r.BOtgaId && r.isLinked)?r.BOtgaId:activeBOtags}</div>:""} */}
												{(activeBOtags) ? <div className="shownumber">{(activeBOtags) ? activeBOtags : ""}</div> : ""}


											</div>
										)
									}) : (msg) ? <div><p className="noDataFound">You didn't upload any products yet.<br /> Hit the <i className="fa-thin fa-upload" aria-hidden="true"></i> button to upload products.</p></div>
										: <div><p className="noDataFound">Product not found</p></div>
								}
							</div>
							<div id="linkedproducts" className={(linkedTabActive) ? "tab-pane fade in active" : "tab-pane fade"}>
								{
									(linkedListItems.length) ? linkedListItems.map((res, indx) => {
										let dSize = sortSize(res?.gtinData?.size)
										return (
											<div className={(res.BOtagId == activeProductData) ? "showcaseproduct active" : "showcaseproduct"} onClick={(e) => highlightTagFun(e, res.BOtagId)} key={indx}>
												<div className="shocaseimg">
													<img src={res?.gtinData?.image[0]} />
												</div>
												<div className="showdescritption">
													<h4>{res.referenceCode}</h4>
													<div className="shownamecloth">{res.name}</div>

													<div className="coloswatches">
														<OwlCarousel id={"colorswatches_" + indx + "_" + res.referenceCode} className='owl-theme' startPosition={startPtCol[indx]?.pos} margin={5} nav items={7} dots={false}>
															{
																res?.gtinData?.color.map((color, ins) => {
																	//console.log("cd",startPtCol);
																	//itemEls.current[res.referenceCode+"_"+(ins+1)+"_"+indx] = createRef();
																	return (
																		<div className='item'>
																			<Link to="#" key={ins} onClick={() => { runFunctionPL(res.referenceCode + "_" + indx, color.color, "_" + (ins + 1) + "_tt", res.referenceCode + "_ed_" + indx, ins, indx); updateColorData(res, color.color); }} >
																				{/* <span className = {(color.color == res.selectedColor)?"active":""} id={res.referenceCode+"_"+(ins+1)+"_"+indx+"_color"} ref={itemEls.current[res.referenceCode+"_"+(ins+1)+"_"+indx]} style={{"background":"#"+color.colorCode}} ></span>{color.color} */}
																				<span className={(color.color == res.selectedColor) ? "active " + res.referenceCode + "_" + indx : res.referenceCode + "_" + indx} id={res.referenceCode + "_" + indx + "_" + (ins + 1) + "_tt"} style={{ "background": "#" + color.colorCode }} title={color.color} ></span>{/*color.color*/}
																			</Link>
																		</div>
																	)
																})
															}
														</OwlCarousel>
													</div>
													<div className="sizeclothes">
														<OwlCarousel id={"sizecloethes_" + indx + "_" + res.referenceCode} className='owl-theme' startPosition={startPtSizeSelected[indx]?.pos} margin={5} nav items={7} dots={false}>
															{
																res?.gtinData?.size.map((size, ind) => {

																	return (
																		<div className='item'>
																			<div className="customradio" key={ind}>
																				{/* {(size == res.selectedSize)? */}
																				{/* <input type="radio"   onChange={(e) => {getSizePL(e);changeValue(e)}}  value={sizePL} id={res.referenceCode+"-"+size+"-"+ind+"-"+indx} name={res.referenceCode+indx}  />:  */}

																				<input type="radio" onChange={(e) => { getSizePL(e, res.referenceCode + "_ed_" + indx, ind, indx); changeValue(e, indx); updateSizeData(res, size); }} value={size} id={res.referenceCode + "-" + size + "-" + ind + "-" + indx} name={res.referenceCode + indx} checked={divSel[indx] == size} />
																				{/* } */}
																				<label htmlFor={res.referenceCode + "-" + size + "-" + ind + "-" + indx}></label>
																				<span className="sizenem">{size}</span>
																			</div>
																		</div>
																	)
																})
															}
														</OwlCarousel>
													</div>
													<div className="productNamejs">{res.productType}</div>
												</div>
												<div className="linkthisproduct">
													<button onClick={() => unlinkProductList(res)} className="btn btn-success">Unlink</button>
												</div>
												{/* <div className="editprodclist" id={res.referenceCode+"_ed_"+indx} style={{"display":"none"}} >  <i className="fa fa-pencil" onClick={() => {updateColorSizeData(res, colorPL, sizePL);setColorPL(null);setSizePL(null);hideIt();}}></i></div> */}
												<div className="shownumber">{res.BOtagId}</div>


											</div>
										)
									}) : <div><p className="noDataFound">Product not found</p></div>
								}
							</div>
						</div>
					</div>
					<div className="tabupersettings" style={{ "display": (shows) ? "block" : "none" }}>
						<div className="opensettingsarea">
							<form>
								<div className="setting-options">
									<div className="textandlabelcompund">
										<label className="container-checkbox">
											<input type="checkbox" name="select-row-UON6UCPS0" aria-label="select-row-UON6UCPS0" />
											<span className="checkmark"></span>
										</label>
										<h4>When multiple sizes, always pre-select:</h4>
									</div>
									<div className="default-radios">
										<div className="customradio">
											<input type="radio" id="h1" name="hedsty" value="none" checked />
											<label htmlFor="hedsty-L"></label><span className="sizenem">None</span>
										</div>
										<div className="customradio">
											<input type="radio" id="h2-L" name="hedsty" value="s" />
											<label htmlFor="hedsty"></label><span className="sizenem">S</span>
										</div>
										<div className="customradio">
											<input type="radio" id="h3-L" name="hedsty" value="m" />
											<label htmlFor="hedsty"></label><span className="sizenem">M</span>
										</div>
										<div className="customradio">
											<input type="radio" id="h4-L" name="hedsty" value="l" />
											<label htmlFor="hedsty"></label><span className="sizenem">L</span>
										</div>
										<div className="customradio">
											<input type="radio" id="h5-L" name="hedsty" value="xl" />
											<label htmlFor="hedsty"></label><span className="sizenem">XL</span>
										</div>
									</div>

									<div className="default-radios numericvalues">
										<div className="customradio">
											<input type="radio" id="n2" name="hedstynum" value="none" checked />
											<label htmlFor="hedstynum"></label><span className="sizenem">None</span>
										</div>
										<div className="customradio">
											<input type="radio" id="n2-L" name="hedstynum" value="52" />
											<label htmlFor="hedstynum"></label><span className="sizenem">52</span>
										</div>
										<div className="customradio">
											<input type="radio" id="n3-L" name="hedstynum" value="54" />
											<label htmlFor="hedstynum"></label><span className="sizenem">54</span>
										</div>
										<div className="customradio">
											<input type="radio" id="n4-L" name="hedstynum" value="56" />
											<label htmlFor="hedstynum"></label><span className="sizenem">56</span>
										</div>
										<div className="customradio">
											<input type="radio" id="n5-L" name="hedstynum" value="58" />
											<label htmlFor="hedstynum"></label><span className="sizenem">58</span>
										</div>
									</div>

								</div>
								<div className="setting-options">
									<div className="textandlabelcompund">
										<label className="container-checkbox">
											<input type="checkbox" name="select-row-UON6UCPS0" aria-label="select-row-UON6UCPS0" />
											<span className="checkmark"></span>
										</label>
										<h4>Open Product List when the first BOtag is placed on the image.</h4>
										<p>Leave unchecked if you want to continue placing BOtags after the first one,
											still with the Product List closed. Open manually when needed, on the Product List blue tag.</p>
									</div>
								</div>

								<div className="setting-options">
									<div className="textandlabelcompund">
										<label className="container-checkbox">
											<input type="checkbox" name="select-row-UON6UCPS0" aria-label="select-row-UON6UCPS0" />
											<span className="checkmark"></span>
										</label>
										<h4>Close Product List every time a BOtag is linked.</h4>
										<p>Leave unchecked if you want to continue linking BOtags.
											Recommended to leave unchecked if you prefer to place all BOtags first and then link them one by one in a row.</p>
									</div>
								</div>

								<div className="setting-options">
									<div className="textandlabelcompund">
										<label className="container-checkbox">
											<input type="checkbox" name="select-row-UON6UCPS0" aria-label="select-row-UON6UCPS0" />
											<span className="checkmark"></span>
										</label>
										<h4>Always show linked items first.</h4>
										<p>Check the box if you want to group all linked items at the top of the list.</p>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ProductListCanvas;