import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import fileDownload from 'js-file-download'

const TermsConditions = ({ setShowTermsCondition, isVisible }) => {

	/* #region  METHODS */
	const closeWindow = (e) => {
		e.preventDefault();
		setShowTermsCondition('none')
	}
	const handleDownload = (url, filename) => {
		axios.get(url, {
			responseType: 'blob',
		}).then((res) => {
			fileDownload(res.data, filename)
		})
	}
	/* #endregion */

	return (
		<>
			<div className="rightnavigation" style={{ "display": isVisible }}>
				<div className="toheadnav">
					<Link to="#" onClick={() => handleDownload("https://bo7storage7account.blob.core.windows.net/term-condition/Terms%20and%20conditions%20I.pdf", "Terms_and_conditions_I.pdf")} className="btn btn-success downloadlink">Download</Link>
					<Link to="#" onClick={closeWindow} className="closeicons"><i className="fa fa-times" aria-hidden="true"></i></Link>
				</div>
				<h4>BOtag Norge AS Terms of Service</h4>
				<p>Last updated: 01/03/2023</p>
				<p>These Terms of Service (herein named Terms) rule the use of the free services, software and websites (herein named BOstages or generally, Service) provided by BOtag Norge AS.</p>
				<p>By using BOstages, you agree to be bound by these Terms.</p>
				<p>If you’re using BOstages on behalf of an organization or other entity (herein called Organization), then you are agreeing to these Terms on behalf of that Organization and you represent and warrant that you have the authority to bind the Organization to these Terms. In that case, “You”, “User” and “Your” refers to that Organization.</p>
				<p>If later on you become a paid subscriber to BOstages, then your use of the Service will be ruled by our BOstages Subscriber Agreement.
					E2S provides the Service to You as a User in accordance with and subject to the following Terms.</p>
				<h3>1.	The use of BOstages software</h3>
				<p>We provide BOstages, an online or downloadable client set of software, as part of the Service, which contains other specific routines, features, programs, or other types of E2S software such as BOimages, BOAds, BOstore, BoCart and Bothebot for your use in connection with the service. The use of BOstages software is ruled under these Terms and grant you a limited, nonexclusive, nontransferable, revocable user license. Under such terms this license does not constitute a sale of the BOstages software. Each specific software under the BOstages set may have their own Terms and Conditions made available with the respective software.<br />
					We update BOstages automatically and gradually make it available for a growing number of different operating systems.</p>

				<p>BOstages allows You to:</p>
				<ol className="makeitalphabetical">
					<li>Create marketing campaigns as sets of one or more images powered by the BOtag technology;</li>
					<li>Enhance those images with BOtags to become direct selling tools for each individual featured product using BOstages specific software;</li>
					<li>Affiliate retailers to enroll their stores as Your fulfillment centers for featured products using our specific software;</li>
					<li>Make those images available in BOstages BOimages, BOads and BOpics platform called BOstageP to be fetched and published in any online or off-line publisher as ads or any other type of visual content with the purpose of marketing your products.</li>
					<li>Make those images to be displayed and used as direct selling tools on BOthebot, a consumers’ app downloadable to devices to perform the purchase path.</li>
				</ol>
				<p>To make use of BOstages software you need to upload your own data to E2S servers.</p>

				<h3>2. The User Content</h3>
				<p>All data such as text, photographs, images, illustrations, graphics, video, software, audio or other materials submitted, uploaded, posted or displayed by you on or through E2S servers is considered User Content and under your sole responsibility. You retain ownership of your Content and E2S claims no ownership or control over any User Content.</p>
				<p>By uploading User Content onto the Service, and for the exclusive purpose of:</p>
				<ul className="roman-number">
					<li>providing you the Service and associated support;</li>
					<li>analyzing and improving the operation of the Service;</li>
				</ul>
				<p>you are granting E2S a license to use, copy, reproduce, process, adapt, publish, transmit, host and display that User Content.</p>
				<p>We reserve the right to remove User Content on the Service that violates these Terms or that we otherwise reasonably believe may create liability for E2S.</p>

				<h3>3. The administration and use of the User Content</h3>
				<p>Signing up to BOstages requires one or more email addresses. One of them shall be used as the Account Owner of the BOstages account. This email address cannot be changed after the account is settled. Removal will result in deleting the BOstages account and all data associated with it will be lost. The administrator email address shall be of your organization domain (ex: username@organization.com) and we recommend the use of a dedicated non-personal email address within the company domain. By creating the account under such email address, you acknowledge and understand that your organization may restrict or terminate your access to the BOstages account and elect another person within the organization to take over the admin powers of the BOstages account. In such event new credentials may be generated but the email address shall be kept.<br />
					Other users can be added to the BOstages account without administration powers. These users as well as the administrator, will be able to manage User Content.</p>

				<h3>4. Access to User Content by Third Parties</h3>
				<p>BOstages is a network based service. At some point you’ll be required to enroll retailers under your network and they will affiliate stores to perform as your Fulfillment Centers. This implies sharing User Content with them so the purpose of completing the whole purchase path for end-consumers is granted. By signing-up to the Service you acknowledge and agree to the sharing of your User Content with retailers and their stores under your network.<br />
					Shall you require extra terms for the use of the User Content BOstages makes available to them it is your exclusive responsibility to settle those terms directly with them.
				</p>

				<h3>5. Your obligations</h3>
				<ol className="makeitalphabetical">
					<li>You must provide accurate information when you create your E2S account.</li>
					<li>You are responsible for safeguarding the password and for all activities that occur under your account. You should notify E2S immediately if you become aware of any breach of security or unauthorized use of your account.</li>
					<li>You may never use another user’s account without permission.</li>
					<li>You may not disassemble, decompile or reverse engineer the Service or attempt or assist anyone else to do so, unless such restriction is prohibited by law.</li>
					<li>Our Service is not intended for use by persons under the age of 16. By using the Service, you are representing to us that you are over the age of 16.</li>
				</ol>
				<p>Any questions may be submitted to info@botag.no</p>
			</div>
		</>
	);
};
export default TermsConditions;