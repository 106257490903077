import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import Activate_Account from './../../assets/images/activate_account.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";

export default function PreBilling(props) {
	document.title = 'BOstageB - Pre-Billing';

	/* #region  STATES */
	const ref = useRef();
	const navigate = useNavigate();
	const [elementData, setElementData] = useState("");
	const [targetBtb, setTargetBtb] = useState(false);

	const [denied, setDenied] = useState(false);
	const [preLoader, setPreLoader] = useState(true);

	const [companyId, setCompanyId] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [data1, setData1] = useState(0);
	/* #endregion */

	/* #region  EFFECTS */
	// useEffect(() => {
	// 	let _data = JSON.parse(localStorage.getItem("__data"));

	// 	if(typeof props?.compData?.companyDetail != "undefined"){
	// 		if(_data){	
	// 		// setTimeout((e)=>{
	// 			if(!_data?.BillingMenu){

	// 					setPreLoader(false); 
	// 					setDenied(true);     

	// 			}else{
	// 				setDenied(false);
	// 				setPreLoader(false);   
	// 			}
	// 	//	},1000);	
	// 	  }
	//    }

	// },[props.compData]);

	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.StoresMenu) {

					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {

		if (typeof props?.compData?.Companies != 'undefined') {
			setCompanyId(props?.compData?.Companies[0]?.companyID);
		}

	}, [props.compData]);



	useEffect(() => {

		if (typeof props?.compData?.Companies != 'undefined') {
			setCompanyId(localStorage.getItem('brandID'));
			setCompanyName(localStorage.getItem('brandName'));
		}

	}, [props.compData]);


	// const moveTo = (type) =>{

	// 	  if(type=="store"){
	// 		navigate("/store");
	// 	  }
	// 	//   if(type=="retailer"){
	// 	// 	navigate("/retailer/create")
	// 	//   }
	// 	//   if(type=='boimage'){
	// 	// 	navigate("/boimages/creater")
	// 	//   }

	// }

	useEffect(() => {

		getStoreList();
	}, [])
	/* #endregion */

	/* #region  METHODS */
	const getStoreList = () => {

		axios.post(process.env.REACT_APP_API_URL + "get-brand-store-list?code=" + process.env.REACT_APP_API_CODE, { brandID: localStorage.getItem("brandID") }).then(res => {
			//console.log(res?.data?.data.length);
			setPreLoader(false)
			if (res?.data?.data.length) {
				setData1(1);
			}
		})
	}

	// const handelBill = () =>{
	// 	setPreLoader(true);

	// 	let jsonPost = {

	// 	  companyId:companyId
	//   }
	//   axios.post(process.env.REACT_APP_API_URL + "create-R-In-B?code="+process.env.REACT_APP_API_CODE, jsonPost).then((res) => {
	// 		 props.loginFun(localStorage.getItem('token'),'100');
	// 	  if (res.data.status == 200) {

	// 			/*res.data.message,*/
	// 		  toast.success("Let's proceed with billing plan!",
	// 			  {
	// 				  theme: "dark",
	// 				  icon: ({ theme, type }) => <img src={errorSuccess} />,
	// 				  onOpen: () => {
	// 						  navigate("/billing-plan");		
	// 				  }
	// 			  });
	// 			  setPreLoader(false);

	// 	  } else {
	// 		  toast.error(res.data.message, {
	// 			  theme: "dark",
	// 			  icon: ({ theme, type }) => <img src={errorWarning} />
	// 		  })
	// 	  }
	// 	  setPreLoader(false);

	//   })


	//   // setTimeout(()=>{
	//   // 	setPreLoader(false);
	//   // 	navigate("/billing-plan");
	//   // },2500);

	// }

	const handelBill = () => {
		navigate("/billing-plan/retailer");
	}
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar thankyouwelcomestore">
							<div className="centerwelcome-msg"><span className="brandName-msg">{companyName}</span> is now <span className="grBill">ENROLLED</span></div>
						</div>

						{(preLoader)
							? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div className="creators-section billing-page-plans">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="what-to-do-next">
													<h2 className="what-heading">Congratulations! You completed the enrollment of <span className="brandName-msg">{companyName}</span>. <br />You can now explore your account.</h2>
													<h2 className="what-heading">To start publishing images powered by BOtag technology, you still have to:</h2>
													<div className="row">
														<div className="tManageCenter">
															<div className="col-md-3"></div>
															<div className="col-md-3" onClick={handelBill}>
																{/* <div className="col-md-3" onClick={navigate("/billing-plan")}> */}
																<div className="explore-icons">
																	<span className="explore-information-icon"><i className="fa-regular fa-circle-info"></i></span>
																	{/* <i className="fa-thin fa-user"></i> */}
																	<img src={Activate_Account} />
																	<h3>Activate account <br /> Select a billing plan.</h3>
																</div>
															</div>
															<div className="col-md-3"></div>
														</div>
													</div>
													<h2 className="what-heading">Use menu to explore more</h2>
												</div>
											</div>
										</div>

									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	)
}