import React, { useEffect, useRef, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as Library from '../../utils/Library';
import BrandAside from '../layouts/BrandAside';
import BrandNavigation from '../layouts/BrandDashboardHeader';
import BrandFooter from '../layouts/BrandFooter';
import './../../assets/css/inputTags.css';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';

/* #region  SCOPED CSS */
const Container = styled.div`
		.profile-div{
			height: 120px !important;
		}

		.datatable-section h4{
			color: #D1D1D1;
			margin-right: 10px;
			font-size: 14px;
		}
		
		.datatable-section .search-bar{
			margin: 0;
			border-bottom: 2px solid #1E2631;
		}

		.datatable-section .search-bar >span {
			display: flex;
			line-height: 20px;
			color: #4F5B6D;
			align-items: center;
			font-size: 9pt;
		}

		.filter-section >span {
			line-height: 35px;
			font-size: 10pt;
			margin-right: 10px;
			border-bottom: 2px 
		}

		.filter-section >.search-input{
			border-radius: 0;
			background: #1E2631;
			border-color: #1E2631;	
			box-shadow: none;
			height: 35px;
		}

		.creators-section .cardsection{
			margin-bottom: 10px;	
		}
	`;
/* #endregion */

function StoreStaff(props) {
    document.title = 'BOstageB - Store Staff';
    const ref = useRef();

    /* #region  STATES */
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [elementData, setElementData] = useState('');
    const [preLoader, setPreLoader] = useState(false);

    //INVITES LIST
    const [invitesList, setInvitesList] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        setStatus(props?.compData?.completePercentage);
    }, [props?.compData]);

    useEffect(() => {
        if (props.compData?.companyEmail === undefined) return;
        refreshStaffInvites();
    }, [props.compData]);
    /* #endregion */

    /* #region  METHODS */
	
    const refreshStaffInvites = () => {
        const filterData = {
            id: localStorage.getItem('token'),
            limit: false
        };

        Library.makePostRequest('getStaffFromCompanies', filterData, false, setPreLoader).then(res => {
            if (res === undefined || res.status !== 200 || res?.data === undefined || res?.data?.data === undefined) {
                setInvitesList([]);
                return;
            }

            const data = res.data.data;
            setInvitesList(data);
        });
    };

    const handleStatusChange = (name, storeObjID, userObjID, action) => {

        const filterData = {
            'storeObjID': storeObjID,
            'userObjID': userObjID,
            'action': action
        };

        const message = `Are you sure you want to ${action === 'delete' ? 'delete' : action === 'Suspended' ? 'suspend' : 'enable'} the user '${name}' ?`;

        Library.showConfirmationMessage(message, () => {
            Library.makePostRequest('updateStoreStaffStatus', filterData, false, setDataLoading).then(res => {
                if (res === undefined || res.status !== 200 || res?.data === undefined) {
                    Library.showErrorMessage(res.data.message);
                    return;
                }

                refreshStaffInvites();
            });
        });
    };
    /* #endregion */

    /* #region  CONSTANTS */
    createTheme('solarized', {
        text: {
            primary: '#fff',
            secondary: '#fff',
        },
        background: {
            default: '#10141B',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#333F50',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: '200px'
        },
        {
            name: 'To store',
            selector: row => row.toStore,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            style: {
                minWidth: '150px'
            }
        },
        {
            name: 'Mobile',
            selector: row => row.phoneNumber,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            width: '150px',
            cell: (row) => <><span className="store-name2" style={
                {
                    color: row.status === 'Invited' ? '#FF9900'
                        : row.status === 'Active' ? '#00AEEF'
                            : row.status === 'Enrolled' ? '#00BB00'
                                : row.status === 'Suspended' ? '#FF9900'
                                    : '#D1D1D1'
                }
            } title={row.status}>{row.status}</span></>
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            cell: (row) => <>
                <Link className="btn btn-success button-not-active" to="#">VIEW</Link>
                <Link className={'btn btn-success'} to="#" onClick={() => handleStatusChange(row.name, row.storeObjID, row.userObjID, row.status === 'Suspended' ? 'Active' : 'Suspended')}>{`${row.status === 'Suspended' ? 'Enable' : 'Suspend'}`}</Link>
                <Link className="btn btn-success" to="#" onClick={() => handleStatusChange(row.name, row.storeObjID, row.userObjID, 'delete')}>{`${row.status === 'Invited' ? 'Cancel' : 'Delete'}`} </Link>
            </>,
            width: '130px',
            style: {
                justifyContent: 'center',
                flexDirection: 'row'
            },
            center: true
        }
    ];
    /* #endregion */

    /* #region  COMPONENTS */
    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>);
    /* #endregion */

    return (
        <Container>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation data={elementData} cdata={props.compData} />
                <BrandAside cdata={props.compData} />
                <div className="content-wrapper enroll-wrapper">
                    <div className="controlbar">
                        <div className="productsearch leftsidetr">
                            <Link data-widget="search" className="button-not-active" to="#" role="button">
                                <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
                                <span className="searchprodicttext text-center">Stores</span>
                            </Link>
                        </div>
                        <div className="centerbtn">
                            <Link to="/store/list" className={(status === 'Completed') ? 'btn btn-success' : 'btn btn-success button-not-active'}>Stores</Link>
                            <Link to="#" className="btn btn-success button-not-active">Edit Store</Link>
                            <Link to="/store" className="btn btn-success">New Store</Link>
                            <Link to="#" className="btn btn-success active">Staff</Link>
                        </div>
                        <div className="rightcontrolserch">
                            <div className="productsearch rightareas">
                                <Link data-widget="search" to="/store/staff/invites" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-envelope-open-text"></i></span>
                                    <span className="searchprodicttext">Invite</span>
                                </Link>
                            </div>
                            <div className="productsearch rightareas">
                                <Link to="#" role="button">
                                    <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                    <span className="searchprodicttext">List</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {(preLoader)
                        ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                        :
                        <>
                            <div className="datatable-section">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="tablestructure productPages search-bar">
                                                <span><h4>ENROLLED STAFF {invitesList ? `(${invitesList.length})` : ''}</h4></span>
                                            </div>
                                            <div className="col-md-6" style={{ marginTop: '15px' }}>
                                                <div className="filter-section">
                                                    <span>Search for: </span>
                                                    <input className="form-control search-input" type="text" aria-label="Search" disabled='disabled' />
                                                </div>
                                            </div>
                                            <div className="col-md-12 tablestructure productPages">
                                                <DataTable
                                                    className="showItems"
                                                    columns={columns}
                                                    data={invitesList}
                                                    theme="solarized"
                                                    sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true" style={{ marginLeft: '5px' }}></i>}
                                                    progressPending={dataLoading}
                                                    selectableRows
                                                    //selectableRowsComponent={Checkbox}
                                                    //defaultSortFieldId={1}
                                                    //defaultSortAsc={true}
                                                    responsive={true}
                                                    //expandableRowsComponent={ExpandedComponent}
                                                    progressComponent={<CustomLoader />}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </Container>
    );
}

export default StoreStaff;