import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Slider from 'react-input-slider';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import { BLOB_COMPANY_IMAGE, BLOB_URL } from './../../config/constant.js';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
import uploadFileToBlob from './blob';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
toast.configure();

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				// width: 90,
				width: 100,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

function Dashboard(props) {
	document.title = 'BOstageB - Upload Company Logo ';

	/* 
	 let stepTwo ="STEP 2 - Adjust croup outline to fit logo and click 'CROP'. 'CANCEL' to remove the logo";
	 let stepThree ='STEP 3 - Use ZOOM keys to fit the logo inside the placeholder. Click SAVE when done.'
	 const [steps, setSteps] = useState(<><p>STEP 1 - Upload the logo using the <i className="fa-thin fa-upload" aria-hidden="true" /> button</p></>);
	*/

	/* #region  STATES */
	const ref = useRef();
	const [elementData, setElementData] = useState("");
	const [imgSrc, setImgSrc] = useState('')
	const previewCanvasRef = useRef(null)
	const imgRef = useRef(null)
	const [crop, setCrop] = useState()
	const [completedCrop, setCompletedCrop] = useState()
	//const [cropedData, setCropedData] = useState()
	//
	const [cropedData, setCropedData] = useState({ unit: 'px', x: 0, y: 0, width: 245, height: 90 })
	//const [cropedData, setCropedData] = useState({unit: 'px', x: 0, y: 0, width: 100, height: 100})
	const [scale, setScale] = useState(1)
	const [rotate, setRotate] = useState(0)
	//
	const [aspect, setAspect] = useState(245 / 90)
	const [isCropped, setIsCropped] = useState(false);
	const [fileSrcUrl, setFileSrcUrl] = useState("");
	const [imageName, setImageName] = useState("");
	const [imageNameUpd, setImageNameUpd] = useState("");
	const [saveImage, setSaveImage] = useState([]);
	const [status, setStatus] = useState("");
	const [isPhoneVerified, setIsPhoneVerified] = useState(false)
	const [loader, setLoader] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(false);
	//
	const [sliderInitial, setSliderInitial] = useState(0);
	const [isDisabled, setIsDisabled] = useState(true)
	const [imageSrc, setImageSrc] = useState("");
	const [logoSrc, setLogoSrc] = useState("");

	const [buttonName, setButtonName] = useState("NEXT");
	const [editMode, setEditMode] = useState(0);
	const [editModeBtnFun, setEditModeBtnFun] = useState(0);

	const [complete, setComplete] = useState(false)
	const [lastStep, setLastStep] = useState(0)
	const [tempScale, setTempScale] = useState(1)
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {

		if (localStorage.getItem('viewMode') == "editmode") {
			setEditMode(1);
			setEditModeBtnFun(1);

		} else {
			localStorage.removeItem('viewMode');
			setEditMode(0);
		}
	}, [localStorage.getItem('viewMode')]);

	/*******************save popup********************** */
	const refWarp = useRef(null);   //new datatttt
	useEffect(() => {

		function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode);

			//menu
			let pclass = event?.target?.parentNode?.parentNode?.parentNode?.parentNode;
			let pclassimg = event?.target?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
			// rightcontrolserch
			let prClassIcon = event?.target?.parentNode?.parentNode?.parentNode;
			let prClass = event?.target?.parentNode?.parentNode;
			//center upper btn
			let pCNClass = event?.target?.parentNode;

			if (editMode && !editModeBtnFun) {
				//  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp?.current && !refWarp?.current?.contains(event.target) && (event?.target?.classList?.contains("saveIt") || pclass?.classList?.contains("menusections") || pclass?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("navitems-area") || pclassimg?.classList?.contains("menusections") || prClassIcon?.classList?.contains("saveIt") || prClass?.classList?.contains("saveIt") || pCNClass?.classList?.contains("saveIt"))) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					}).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);
							saveFunction();
						}
						else if (result.isDenied) {
							setEditModeBtnFun(1);
						}

					})
				}
			}
		}
		document.addEventListener("mousedown", handleSaveOutside);
		return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		};
	}, [refWarp, editMode, editModeBtnFun]);
	/**************************************************** */

	useEffect(() => {
		localStorage.setItem('tel', '');
	});

	useEffect(() => {
		if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
			(typeof props?.compData?.companyDetail != "undefined" && props?.compData?.companyDetail?.logoURL) ? setFileSrcUrl(props?.compData?.companyDetail?.logoURL) : setFileSrcUrl("");
			if (typeof props?.compData?.companyDetail != "undefined") {
				setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified)
				setPreLoader(false)
			}
			setStatus(props?.compData?.status)
		} else {
			if (editMode) {
				(typeof props?.compData?.companyDetail != "undefined" && props?.compData?.companyDetail?.logoURL) ? setFileSrcUrl(props?.compData?.companyDetail?.logoURL) : setFileSrcUrl("");
				if (typeof props?.compData?.companyDetail != "undefined") {
					setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified)
					setPreLoader(false)
				}
				setStatus(props?.compData?.status)
			} else {
				setPreLoader(false);
				setIsPhoneVerified(props?.compData?.companyDetail?.isMobileVerified)
			}

		}
	}, [1, props.compData, editMode]);


	const [denied, setDenied] = useState(false)
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));
		if (!_data?.AccountMenu) {
			setDenied(true)
		} else {
			setDenied(false)
		}
	})

	useEffect(() => {
		if (complete) {
			cropFunction();
			setComplete(false)
		}
	}, [complete])
	useEffect(() => {
		setScale(tempScale + sliderInitial)
	}, [sliderInitial])
	/* #endregion */

	/* #region  METHODS AND CONSTANTS */
	let navigate = useNavigate();
	const openFileSelector = () => {
		document.getElementById('companyLogo').click();
	}
	function onSelectFile(e) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined);
			setIsDisabled(false);
			// setCrop({unit: '%', x: 0, y: 0, width: 45, height: 24})
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setImgSrc(reader.result?.toString() || ''),
			)
			reader.readAsDataURL(e.target.files[0])
			setImageName(e.target.files[0].name);

			//setSteps(stepTwo);

			//setImageSrc(URL.createObjectURL(e.target.files[0]));
		}


		if (editMode) {
			setEditModeBtnFun(0);
		}
	}
	function onImageLoad(e) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
		cropFunction();
	}
	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
			}
		},
		100,
		[completedCrop, scale, rotate],
	)
	/*	const zoomImage = (type) => {
			  if(type === "in"){
				  setScale(Number(scale+0.1))
			  }else{
				  setScale(Number(scale-0.1))
			  }
		  }  */

	const zoomImage = (e, type) => {
		if (type === "in") {
			setSliderInitial(sliderInitial + 0.05)
			//setScale(Number(scale+0.1))
		} else {
			setSliderInitial(sliderInitial - 0.05)
			//setScale(Number(scale-0.1))
		}
	}


	const cancelFun = () => {
		//setCrop({unit: '%', x: 0, y: 0, width: 45, height: 24})
		//setLogoSrc("");
		setImgSrc('');
		setCompletedCrop();
		setIsCropped(false)
		setSaveImage([]);
		setIsDisabled(true)
		setSliderInitial(0)
		document.getElementById('companyLogo').value = "";
	}
	const cropFunction = () => {
		//ReactCrop__crop-selection
		//document.querySelector('.imgs').click();
		setCompletedCrop(cropedData)
		setIsCropped(true);
		//setSteps(stepThree);
	}
	const saveFunction = async () => {
		let formArray = [];
		let blob = await fetch(previewCanvasRef.current.toDataURL()).then((r) => r.blob());
		blob.name = props?.compData?.companyDetail?.ourID + imageName.substr(0, imageName.lastIndexOf(".")) + ".png";
		formArray.push(blob)
		//console.log("test",formArray);
		setSaveImage(formArray)
		setImageNameUpd(blob.name)
		setImgSrc('');
		setIsCropped(false);
		setIsDisabled(true)
		setSliderInitial(0)


		if (localStorage.getItem("status") === 'Completed' || (localStorage.getItem("status")) === 100) {
			/*****Status - Active******** */
			submitFun(blob.name, formArray);
		}
		if (editMode) {
			setEditModeBtnFun(1);
		}

	}

	const submitFun = async (imgName, formArray) => {
		/*****Status - Active******** */
		setOnSubmitLoader(true);

		if (typeof props?.compData?.companyDetail !== 'undefined') {
			setLoader(true)
			let companyId = "";

			if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
				companyId = props?.compData?.companyDetail?.ourID;
			} else {
				companyId = localStorage.getItem('lastInsertedID');
				if (localStorage.getItem('lastInsertedID') == undefined) {
					companyId = props?.compData?.companyDetail?.ourID;
				}
			}

			let formData = {
				"companyID": companyId,
				"logoURL": BLOB_URL + BLOB_COMPANY_IMAGE + '/' + imgName
			}
			//setFileSrcUrl("dshfvdu");		
			//console.log("formArray",formArray);
			const blobsInContainer = await uploadFileToBlob(formArray, BLOB_COMPANY_IMAGE).then(res => {
				if (res) {
					axios.post(process.env.REACT_APP_API_URL + "upload-company-logo?code=" + process.env.REACT_APP_API_CODE, formData).then((result) => {
						if (result.data.status == "200") {
							if (editMode) {
								props?.loginFun(localStorage.getItem('token'), 'Completed');
							} else {
								props?.loginFun(localStorage.getItem('token'), '25');
							}

							//toast.success((result.data.message).replace("BRAND_NAME", localStorage.getItem("brandName")), {
							toast.success("Company logo updated successfully", {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorSuccess} />
							});
						} else {
							toast.error(result.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});
						}
						setLoader(false)
						setOnSubmitLoader(false)
					});

				}
			})
		}
	}

	const handleOnChange = (e) => {
		setSliderInitial(e.y);
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		setOnSubmitLoader(true);
		if (fileSrcUrl) {
			if (localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed') {
				navigate("/brand");
			} else {
				navigate("/brand/creator");
			}
			return false;
		}
		if (typeof props?.compData?.companyDetail !== 'undefined') {
			setLoader(true)
			let companyId = "";

			if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
				companyId = props?.compData?.companyDetail?.ourID;
			} else {

				companyId = localStorage.getItem('lastInsertedID');
				if (localStorage.getItem('lastInsertedID') == undefined) {
					companyId = props?.compData?.companyDetail?.ourID;
					//console.log("cons",companyId);
				}

			}


			let formData = {
				"companyID": companyId,
				"logoURL": BLOB_URL + BLOB_COMPANY_IMAGE + '/' + imageNameUpd
			}
			//setFileSrcUrl("dshfvdu");		
			const blobsInContainer = await uploadFileToBlob(saveImage, BLOB_COMPANY_IMAGE).then(res => {
				if (res) {
					axios.post(process.env.REACT_APP_API_URL + "upload-company-logo?code=" + process.env.REACT_APP_API_CODE, formData).then((result) => {
						if (result.data.status == "200") {
							props.loginFun(localStorage.getItem('token'), '25')
							toast.success((result.data.message).replace("BRAND_NAME", localStorage.getItem("brandName")), {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorSuccess} />,
								// onClose: () => {(!props.compData.BrandsMenu)?navigate('/account/company/brand-pre-registration'):(props.compData.completePercentage === "Completed")?navigate('/brand'):navigate("/brand/creator")}
								onClose: () => { (props.compData.completePercentage === "Completed") ? navigate('/brand') : navigate("/brand/creator") }
							});
						} else {
							toast.error(result.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorWarning} />
							});
						}
						setLoader(false)
						setOnSubmitLoader(false)
					});

				}
			})
		}

	}
	const resetFun = () => {
		if (status == 'Completed' || status == '100') {
			setFileSrcUrl("");
		}

	}

	const onCompleteFun = (e) => {
		setCropedData(e);
		setComplete(true)
	}
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar">
							<div className="productsearch leftsidetr notactive">
								<Link data-widget="search" className="button-not-active" to="#" onClick={(e) => e.preventDefault()} role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Accounts</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(status == 'Completed') ? "/account/company/list" : "#"} className={(status == 'Completed') ? "btn btn-success saveIt" : "btn btn-success button-not-active"}>Companies</Link>
								<Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Companies</Link>
								<Link to={(!editMode) ? "/account/company" : ""} className={(editMode) ? "btn btn-success button-not-active saveIt" : "btn btn-success active saveIt"} >New Company</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={(editMode) ? "/account/company/" + props?.compData?.companyDetail?.ourID : "/account/company"} role="button">
										<span className="btn btn-success "><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								{/* <div className="productsearch rightareas notactive"> */}
								<div className={(isPhoneVerified) ? "productsearch rightareas saveIt" : "productsearch rightareas notactive"}>
									<Link data-widget="search" to={(isPhoneVerified) ? "/account/company/logo" : ""} role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-sign-hanging"></i></span>
										<span className="searchprodicttext">Logo</span>
									</Link>
								</div>
								<div className="productsearch rightareas notactive saveIt">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success"><i className="fa-thin fa-users"></i></span>
										<span className="searchprodicttext">Users</span>
									</Link>
								</div>
							</div>
						</div>
						
						{(preLoader)
							?
							<div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div ref={refWarp}>
									<div className="filtertopcombo company-image-fileup">
										<div className="container-fluid">
											<div className="row">
												<div className="col-md-12">
													{(localStorage.getItem("status") != 'Completed' && (localStorage.getItem("status")) != 100) ?
														<div className="filtersection creatorsbtnset enroll-brand">
															<span className={(saveImage.length || fileSrcUrl) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
																<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={((status != 'Completed' && status != '100' && fileSrcUrl) || (!saveImage.length && !fileSrcUrl)) ? 'disabled' : ''}>NEXT {(loader) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
															</span>
														</div> : ""}
													<StepCombination cdata={props.compData} />
												</div>
											</div>
										</div>
									</div>

									<div className="creators-section">
										<div className="container-fluid">
											<div className="row">
												<div className="col-md-12">
													<div className="cardsection">
														<div className="card-header">
															<h3 className="card-title">COMPANY LOGO<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
															{/* <div className="crop-guideline">STEP 1- Upload the logo using the <i className="fa-thin fa-upload"></i> button</div> */}
															{/* <div className="crop-guideline">{steps}</div> */}
														</div>
														<div className="card-body">
															<div className="row">
																<div className="col-md-6">
																	<div className="uploadimage-area">
																		<div className="crop-section">
																			<div className={(!imgSrc) ? "croped-inner" : "croped-inner img-found"}>
																				{!!imgSrc && (
																					<ReactCrop
																						crop={crop}
																						onChange={(_, percentCrop) => setCrop(percentCrop)}
																						//  onComplete={(c) => setCropedData(c)}
																						aspect={aspect}
																						onComplete={(c) => onCompleteFun(c)}
																					>
																						<img
																							ref={imgRef}
																							alt="Crop me"
																							src={imgSrc}
																							style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
																							onLoad={onImageLoad}
																						/>
																					</ReactCrop>
																				)}
																				{
																					(!imgSrc) ? (<div className="croped-message">
																						<h3>LOGO</h3>
																						<p>max 300Kb</p>
																					</div>) : ""
																				}

																				<div className={(isDisabled) ? "zoom-slider" : "zoom-slider active"}>
																					<div className={(isDisabled) ? "slider-sm-btns" : "slider-sm-btns active"}>
																						<Link to="#" onClick={(e) => (isDisabled) ? e.preventDefault() : zoomImage(e, 'out')} className="bottomicons"><i className="fa-light fa-minus"></i></Link>
																						<Link to="#" onClick={(e) => (isDisabled) ? e.preventDefault() : zoomImage(e, 'in')} className="topicons"><i className="fa-light fa-plus"></i></Link>

																					</div>
																					<div className="form-group zoom-label">
																						<label for="tobrand"></label>
																					</div>
																					<Slider
																						styles={{
																							track: {
																								backgroundColor: '#4f5b6d',
																								borderRadius: 0,
																								width: 2,
																								height: 220
																							},
																							active: {
																								backgroundColor: '#4f5b6d',
																								borderRadius: 0,
																								width: 2
																							},
																							thumb: {
																								width: 15,
																								height: 15,
																								backgroundColor: '#d1d1d1'
																							}
																						}}
																						axis="y"
																						ymin={1}
																						ymax={-1}
																						ystep={.05}
																						y={sliderInitial}
																						disabled={isDisabled}
																						onChange={handleOnChange}
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="crop-btns">
																			<ul className="multibtns">
																				<li className={(imgSrc) ? "active" : ""}><button className={(imgSrc) ? "btn btn-default  tunintoGreen" : "btn btn-default"} onClick={cancelFun} disabled={!imgSrc}>Reset</button></li>
																				<li className={(isCropped) ? "active" : ""}><button className={(isCropped) ? "btn btn-default  tunintoGreen" : "btn btn-default"} onClick={saveFunction} disabled={!isCropped}>Save</button></li>
																			</ul>
																			{/* <div className="crop-guideline">1) Upload the logo; 2) Adjust cropping; 3) Click 'Crop'; 4) Click 'Save'</div> */}
																		</div>
																	</div>
																</div>
																<div className="col-md-6">
																	<div className="company-logo-previewarea">
																		<div className="user-profile-picture">
																			<div className={(completedCrop && !fileSrcUrl) ? "form-group activeborder blueborder" : (status == 'Completed' || status == '100' || !fileSrcUrl) ? "form-group activeborder" : "form-group inActiveborder"}>
																				<label htmlFor="tobrand"><i className="fa fa-info-circle" aria-hidden="true"></i> Main logo</label>

																				{(completedCrop)
																					?
																					(
																						<div className="profile-div">
																							{(saveImage.length) ? (<span className="close-image" onClick={cancelFun}></span>) : ""}
																							<canvas
																								ref={previewCanvasRef}
																								style={{
																									border: '1px solid black',
																									objectFit: 'contain',
																									width: completedCrop.width,
																									height: completedCrop.height,
																								}}
																							/></div>
																					)
																					:
																					(fileSrcUrl)
																						?
																						(<div className="profile-div"><span className="close-image" onClick={resetFun}></span> <img src={fileSrcUrl} /> </div>)
																						:
																						(<div className="profile-div" onClick={openFileSelector}><div className="customprofile-pic"><i className="fa-thin fa-upload" aria-hidden="true"></i>
																							<span className="upload-profile-text">Upload</span>
																							<input id="companyLogo" type="file" className="upload-profile-text" accept="image/*" onChange={onSelectFile} style={{ "display": "none" }} />
																						</div></div>)
																				}
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			{(onSubmitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
		</>
	);
}

export default Dashboard;