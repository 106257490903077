import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircleTick from './../../assets/images/circle-tick.png';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import BrandAside from "./../layouts/BrandAsideStatic";
import BrandNavigation from "./../layouts/BrandDashboardHeaderStatic";
import BrandFooter from "./../layouts/BrandFooter";
toast.configure();

function SmallBox(props) {
	document.title = 'BOstageB - PreEnrrolment';

	/* #region  STATES */
	const ref = useRef();
	let navigate = useNavigate();
	const AutoGenerateUrlId = useParams();
	const [elementData, setElementData] = useState("");
	const [addTo, setAddTo] = useState("");
	const [buttonDisable, setButtonDisable] = useState(false);
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		setElementData(ref.current);
		checkAutogeneratedUrl();
		localStorage.clear();

	}, []);
	/* #endregion */

	/* #region  METHODS */
	const checkAutogeneratedUrl = () => {

		let formData = {
			"invitedId": AutoGenerateUrlId.id
		}
		axios.post(process.env.REACT_APP_API_URL + 'validate-url?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status === 200) {

				setAddTo(res.data.data.brandName);

				toast.success(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorSuccess} />
				})
			} else {
				toast.error(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorWarning} />
				})

				navigate("/");
			}
		})

	}

	const confirmation = () => {
		setButtonDisable(true);
		let formData = {
			"uiid": AutoGenerateUrlId.id
		}
		axios.post(process.env.REACT_APP_API_URL + 'accept-invite-request?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status === 200) {

				toast.success(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorSuccess} />
				})

				navigate("/");

			} else {
				toast.error(res.data.message, {
					theme: "dark",
					icon: ({ theme, type }) => <img src={errorWarning} />
				})
				setButtonDisable(false);
				//navigate("/");
			}
		})

	}

	const navLogin = () => {
		navigate("/");
	}
	/* #endregion */

	return (
		<>
			<div className="wrapper">
				<BrandNavigation />
				<BrandAside />
				
				<div className="content-wrapper singlepages smallareabox">
					<div className="controlbar">
						<div className="productsearch leftsidetr">
						</div>
						<div className="centerbtn">
							<h3 className="titleonly">PRE-ENROLLMENT</h3>
						</div>
					</div>

					<div className="creators-section">
						<div className="container-fluid">
							<div className="row">
								<div className="col-md-12">
									<div className="small-formas smallbox" >
										<div className="blueicons-area">
											<img src={CircleTick} />
										</div>
										<p className="verifying-text dimtext"><span>{addTo} Account Admin</span> invited.<br />
											As soon as the invitation is accepted, credentials are provided and they can login to the account.</p>
										<div className="send-blockbtn twobtnsets">
											<button type="submit" className="btn btn-primary btn" onClick={confirmation} disabled={(buttonDisable) ? 'disabled' : ''} >Yes</button>
											<button type="submit" className="btn btn-primary btn-md" onClick={navLogin} >No</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}

export default SmallBox;