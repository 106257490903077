import { memo, useState, useRef } from "react";
import { useDrop } from "react-dnd";

const newStyle = {};

export const Dustbin = memo(function Dustbin(
    {
        accept,
        lastDroppedItem,
        onDrop,
        label,
        clear,
        arrData,
        validates,
        isMandatory,
        isRecommended = false
    }) {
    //console.log("onDrop",onDrop);
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const [ent, setEnt] = useState(true);
    const disallow = useRef();
    const isActive = isOver && canDrop;

    if (lastDroppedItem && ent) {
        arrData(label, lastDroppedItem.name);
    }

    const clearIt = (ele) => {
        setEnt(false);
    };

    if (validates === false) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";
    }
    if (validates === true) {
        newStyle["borderColor"] = "red";
        newStyle["borderWidth"] = "1px";
    }
    if (isActive) {
        newStyle["borderColor"] = " ";
        newStyle["borderWidth"] = " ";

    } else if (canDrop) {
        newStyle["borderColor"] = "#00AEEF";
        newStyle["borderWidth"] = "1px";
    }

    return (
        <div ref={lastDroppedItem && ent ? disallow : drop} className="products-col">
            <div className="form-group import-fields-group">
                {lastDroppedItem && ent ?
                    (
                        <>
                            <div className="perfom-input active" >
                                <span className='field-label' title={lastDroppedItem.name}>{lastDroppedItem.name}</span>
                                <span className="removethis-entry">
                                    <i className="fa fa-times pts" aria-hidden="true" onClick={() => { clear(lastDroppedItem.name, label); clearIt(lastDroppedItem.name); }}></i>
                                </span>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div
                                className={isMandatory ? "perfom-input blank" : isRecommended ? "perfom-input recommended" : "perfom-input non-mondate"}
                                onDrop={() => { setEnt(true); }}
                                style={{ ...newStyle, newStyle }}
                            >
                                { /*Drop <span className="draggerdfield">{label}</span> field here */ }
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    );
});
