import React from 'react';
import { Link } from 'react-router-dom';
import './../../assets/css/inputTags.css';
import BoimageProgressIconWhite from './../../assets/images/progress-bar-boimage-icon-white.png';
import BoimageProgressIcon from './../../assets/images/progress-bar-boimage-icon.png';
import StoreProgressIconWhite from './../../assets/images/progress-bar-store-icon-white.png';
import StoreProgressIcon from './../../assets/images/progress-bar-store-icon.png';

function CommonLayout(props) {

    return (
        (localStorage.getItem('status') != 'Completed' && localStorage.getItem('status') != '100')
            ?
            <>
                <div className="stepcombination">
                    <ul>
                        <li className={(parseInt(localStorage.getItem('status')) == 25)
                            ? 'step-onec bydefaultcircle active addgreenline addgreenlineactive'
                            : (parseInt(localStorage.getItem('status')) > 25)
                                ? 'step-onec bydefaultcircle active addgreenline addgreenlineactive'
                                : 'step-onec bydefaultcircle active'}>

                            <span className="circlele-tick"><Link to="/account/company"><i className="fa-thin fa-user"></i></Link></span>

                        </li>
                        <li className={(parseInt(localStorage.getItem('status')) == 50)
                            ? 'step-twoc bydefaultcircle active addgreenline addgreenlineactive'
                            : (parseInt(localStorage.getItem('status')) > 50)
                                ? 'step-twoc bydefaultcircle active addgreenline addgreenlineactive'
                                : (parseInt(localStorage.getItem('status')) < 50 && parseInt(localStorage.getItem('status')) >= 25)
                                    ? 'bydefaultcircle active '
                                    : ''}>

                            <span className="circlele-tick">{(props.cdata?.BrandsMenu)
                                ? <Link to="/brand/creator"><i className="fa-thin fa-tag"></i></Link>
                                : <><i className="fa-thin fa-tag"></i></>}</span>

                        </li>
                        <li className={(parseInt(localStorage.getItem('status')) == 75)
                            ? 'bydefaultcircle active addgreenline addgreenlineactive'
                            : (parseInt(localStorage.getItem('status')) > 75)
                                ? 'bydefaultcircle active addgreenline addgreenlineactive'
                                : (parseInt(localStorage.getItem('status')) < 75 && parseInt(localStorage.getItem('status')) >= 50)
                                    ? 'bydefaultcircle active'
                                    : ''}>
                            <span className="circlele-tick">
                                {(props.cdata?.ProductsMenu) ? <Link to="/boimages"><img src={BoimageProgressIconWhite} /></Link> : <><img src={BoimageProgressIcon} /></>}
                            </span>

                        </li>
                        <li className={(parseInt(localStorage.getItem('status')) >= 75) ? 'bydefaultcircle active' : ''}>
                            {
                                (parseInt(localStorage.getItem('status')) >= 75)
                                    ? <span className="circlele-tick"><img src={StoreProgressIconWhite} /></span>
                                    : <span className="circlele-tick"><img src={StoreProgressIcon} /></span>
                            }

                        </li>
                    </ul>
                </div>
            </>
            :
            ''
    );
}

export default CommonLayout;