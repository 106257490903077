import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import StepCombination from '../layouts/commonLayout';
import errorWarning from './../../assets/images/icons-error-48.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import BrandAside from "./../layouts/BrandAside";
import BrandNavigation from "./../layouts/BrandDashboardHeader";
import BrandFooter from "./../layouts/BrandFooter";
toast.configure();

function Dashboard(props) {
	document.title = 'BOstageB - Targets ';

	/* #region  STATES */
	const ref = useRef();

	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [genderTarget, setGenderTarget] = useState([]);
	const [ageGroup, setAgeGroups] = useState([]);
	const [lifestyle, setLifestyle] = useState([]);
	const [majorMarket, setMajorMarket] = useState([]);
	const [genderError, setGenderError] = useState(false);
	const [ageGroupError, setAgeGroupError] = useState(false);
	const [lifestyleError, setLifestyleError] = useState(false);
	const [majorMarketError, setMajorMarketError] = useState(false);
	const [hashtag, setHashtag] = useState("");
	const [hashtagError, setHashtagError] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [brandID, setBrandID] = useState("");

	const [tagDataGender, setTagDataGender] = useState([]);
	const [tagDataAge, setTagDataAge] = useState([]);
	const [tagDataLifeStyle, setTagDataLifeStyle] = useState([]);
	const [tagDataMarket, setTagDataMarket] = useState([]);
	const [tagHashtag, setTagHashtag] = useState([]);

	const [genderValue, setGenderValue] = useState(null);
	const [ageGroupValue, setAgeGroupValue] = useState(null);
	const [lifestyleValue, setLifestyleValue] = useState(null);
	const [majorMarketValue, setMajorMarketValue] = useState(null);
	const [hashValue, setHashValue] = useState(null);

	const [showPreLoader, setShowPreLoader] = useState(true)
	const [dataReadonly, setDataReadonly] = useState(0);
	const [submitLoader, setSubmitLoader] = useState(false);

	const animatedComponents = makeAnimated();

	const [genResp, setGenResp] = useState(false);
	const [ageResp, setAgeResp] = useState(false);
	const [lifeResp, setLifeResp] = useState(false);
	const [marketResp, setMarketResp] = useState(false);
	const [hashResp, setHashResp] = useState(false);

	const [editMode, setEditMode] = useState(0);
	const [editModeBtnFun, setEditModeBtnFun] = useState(0);
	const [buttonName, setButtonName] = useState("NEXT");


	let navigate = useNavigate();
	let { id } = useParams();

	const [denied, setDenied] = useState(false)
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BrandsMenu) {
					setShowPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
					//setShowPreLoader(false);
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {
		if (genResp && ageResp && hashResp) {
			if (genderTarget.length && ageGroup.length && hashtag.length) {
				setShowPreLoader(false);
			}

		}
	}, [genResp, ageResp, hashResp]);

	/* 
	useEffect(()=>{
	  if(genResp && ageResp && lifeResp && marketResp && hashResp){
		if(genderTarget.length && ageGroup.length && lifestyle.length && majorMarket.length && hashtag.length){
			setShowPreLoader(false);	
		}
		
	  }           
	},[genResp,ageResp,lifeResp,marketResp,hashResp]);
   */


	/*
	  useEffect(() => {
		  checkBrandID(id);
	  }, [id]);
	  const checkBrandID = (id) => {
		  let formData = { "id": id }
		  axios.post(process.env.REACT_APP_API_URL + "get-brand-info?code="+process.env.REACT_APP_API_CODE, formData).then((res) => {
			  if (res.data.status != 200) {
				  navigate("/brand/creator");
			  }
		  })
	  }    */


	useEffect(() => {



		getBrandAllData(id);


	}, [id]);

	useEffect(() => {
		if (localStorage.getItem("viewMode") == 'editmode') {
			getBrandAllData(id);
			setEditMode(1);
			setEditModeBtnFun(1);
			setButtonName("SAVE");
		}
	}, [localStorage.getItem("viewMode")])


	useEffect(() => {
		setBrandID(id);
	}, [id]);

	useEffect(() => {
		getGenderData();
	}, [1]);


	useEffect(() => {
		getAgeGroupsData();
	}, [1]);
	/*
		useEffect(() => {
			getLifestyleData();
		}, [1]);
	
		useEffect(() => {
			getMajorMarketData();
		}, [1]);
	
		useEffect(() => {
			getHashtagData();
		}, [1]);
		*/

	const refWarp = useRef(null);   //new datatttt
	useEffect(() => {

		function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode.parentNode.parentNode);
			/*
			 console.log("ref.curren",refWarp.current.contains(event.target)); 
			console.log("eventswq",event.target.value);
			console.log("event",event.target.classList);
			console.log("refWarp.current",refWarp.current); */
			//menu
			let pclass = event.target.parentNode.parentNode.parentNode.parentNode;
			let pclassimg = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
			// rightcontrolserch
			let prClassIcon = event.target.parentNode.parentNode.parentNode;
			let prClass = event.target.parentNode.parentNode;
			//center upper btn
			let pCNClass = event.target.parentNode;

			if (editMode && !editModeBtnFun) {
				// console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || pclass.classList.contains("menusections") || pclass.classList.contains("navitems-area") || pclassimg.classList.contains("navitems-area") || pclassimg.classList.contains("menusections") || prClassIcon.classList.contains("saveIt") || prClass.classList.contains("saveIt") || pCNClass.classList.contains("saveIt"))) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					}).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);
							handleSubmit(event)
						}
						else if (result.isDenied) {
							setEditModeBtnFun(1);
						}

					})


				}
			}
		}
		document.addEventListener("mousedown", handleSaveOutside);
		return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		};
	}, [refWarp, editMode, editModeBtnFun]);

	useEffect(() => {
		//if(genderTarget.length && ageGroup.length && lifestyle.length && majorMarket.length ){
		if (genderTarget.length && ageGroup.length) {
			setButtonGray(true);

		} else {
			setButtonGray(false);
		}
	})
	/* #endregion */

	/* #region  METHODS AND CONSTANTS */
	const getGenderData = () => {
		const genderCollectionOption1 = [];
		axios.get(process.env.REACT_APP_API_URL + "get-target-genders?code=" + process.env.REACT_APP_API_CODE).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					genderCollectionOption1.push({ value: da.name, label: da.name });
				}
				);
				setTagDataGender(genderCollectionOption1);
				setGenResp(true);
			}
		})
	}

	const getAgeGroupsData = () => {
		const ageGruopsOption1 = [];
		axios.get(process.env.REACT_APP_API_URL + "get-target-age-group?code=" + process.env.REACT_APP_API_CODE).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					ageGruopsOption1.push({ value: da.name, label: da.name });
				}
				);
				setTagDataAge(ageGruopsOption1);
				setAgeResp(true);
			}
		})
	}


	const getLifestyleData = () => {
		const lifeStyleOption1 = [];
		axios.get(process.env.REACT_APP_API_URL + "get-target-market-lifestyle?code=" + process.env.REACT_APP_API_CODE).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					lifeStyleOption1.push({ value: da.name, label: da.name });
				}
				);
				setTagDataLifeStyle(lifeStyleOption1);
				setLifeResp(true);
			}
		})
	}

	const getMajorMarketData = () => {
		const majorMarketOption1 = [];
		axios.get(process.env.REACT_APP_API_URL + "get-target-markets?code=" + process.env.REACT_APP_API_CODE).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					majorMarketOption1.push({ value: da.name, label: da.name });
				}
				);
				setTagDataMarket(majorMarketOption1);
				setMarketResp(true);
			}
		})
	}

	/*
	const getHashtagData = () =>{
		const hashtagOption1 = [] ;
		axios.get(process.env.REACT_APP_API_URL + "get-hash-tags?code="+process.env.REACT_APP_API_CODE).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {
					
					hashtagOption1.push({ value: da.name, label: da.name });
				}
				);
				setTagHashtag(hashtagOption1);
				setHashResp(true);
			}
		})
	} */

	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({
			...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
				borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};

	const handleGenderTags = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		setGenderTarget(data);
		setGenderValue(data1);
		if (data.length) {
			setGenderError(false)
		}

		if (editMode) {
			setEditModeBtnFun(0);
		}
		//if(ageGroup.length && lifestyle.length && majorMarket.length && data.length){
		if (ageGroup.length && data.length) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handleAgeGroupsTags = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		setAgeGroups(data);
		setAgeGroupValue(data1);
		if (data.length) {
			setAgeGroupError(false)
		}
		if (editMode) {
			setEditModeBtnFun(0);
		}
		//if(genderTarget.length && lifestyle.length && majorMarket.length  && data.length){
		if (genderTarget.length && data.length) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}


	const handleLifeStyleTags = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		setLifestyle(data);
		setLifestyleValue(data1);
		if (data.length) {
			setLifestyleError(false)
		}
		if (genderTarget.length && ageGroup.length && majorMarket.length && data.length) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handMajorMarketsTags = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			data.push(da.value);
			data1.push({ value: da.value, label: da.label });
		})
		setMajorMarket(data);
		setMajorMarketValue(data1);
		if (data.length) {
			setMajorMarketError(false)
		}
		if (genderTarget.length && ageGroup.length && lifestyle.length && data.length) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
	}

	const handHashtagTags = (e) => {
		let data = [];
		let data1 = [];
		e.forEach(da => {
			if (da?.__isNew__) {
				data.push("#" + da.value);
				data1.push({ value: "#" + da.value, label: "#" + da.label });
			} else {
				data.push(da.value);
				data1.push({ value: da.value, label: da.label });
			}

		})
		setHashtag(data);
		setHashValue(data1);
		if (data.length) {
			setHashtagError(false)
		}

		if (editMode) {
			setEditModeBtnFun(0);
		}
		/*if(genderTarget.length && ageGroup.length && lifestyle.length && majorMarket.length && data.length){
			setButtonGray(true);
		}else{
			setButtonGray(false);
		} */
	}

	const handleValidation = () => {

		let formIsValid = true;
		if (!genderTarget.length) {
			formIsValid = false;
			setGenderError(true)
		}
		if (!ageGroup.length) {
			formIsValid = false;
			setAgeGroupError(true)
		}
		/*
		if (!lifestyle.length) {
			formIsValid = false;
			setLifestyleError(true)
		}
		if (!majorMarket.length) {
			formIsValid = false;
			setMajorMarketError(true)
		}
		if (!hashtag.length) {
			formIsValid = false;
			setHashtagError(true)
		} */

		return formIsValid;
	}
	const handleSubmit = (e) => {
		e.preventDefault();

		if (dataReadonly) {
			navigate("/boimages/creater");
		} else {
			if (handleValidation()) {

				setSubmitLoader(true);

				let formData = {
					brandID: brandID,
					gender: genderTarget,
					age: ageGroup,
					lifestyle: lifestyle,
					majorMarket: majorMarket,
					hashtag: hashtag,

				}
				let cname = props?.compData?.Companies[0]?.companyName;
				setButtonDisable(true);
				axios.post(process.env.REACT_APP_API_URL + "update-brand-tag-target?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
					if (res.data.status == 200) {
						if (localStorage.getItem('preEnrollData') != null) {
							localStorage.removeItem('preEnrollData');
						}

						setDataReadonly(true);
						props.loginFun(localStorage.getItem('token'), '50');

						toast.success("Tags and hashtags saved successfully. " + props.compData?.activeBrand?.brandName + " is now ready to create its first BOimage. Let's BO!",
							{
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorSuccess} />,
								onClose: () => {

									if (!editMode) {
										if (!props.compData.ProductsMenu) {
											navigate("/boimages/pre-boimages");
										} else {

											(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? navigate("/brand") : navigate("/brand/creator");
										}
									} else {
										setEditModeBtnFun(1);
										setDataReadonly(false);
									}


								}
							});
						setSubmitLoader(false);
						setButtonDisable(false);
					} else {
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorWarning} />
						});
						setButtonDisable(false);
						setSubmitLoader(false);
					}

				})
			}
		}
	}

	// useEffect(()=>{
	// 	if(genderTarget && ageGroup && lifestyle && majorMarket && hashtag){
	// 		setButtonGray(true);
	// 	  }
	// });


	const getBrandAllData = (brandId) => {


		let formData = {
			"id": brandId
		}

		axios.post(process.env.REACT_APP_API_URL + "get-brand-info?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status === 200) {

				const hashtagOption1 = [];
				hashtagOption1.push({ value: '#' + res.data.data?.industry, label: '#' + res.data.data?.industry });
				hashtagOption1.push({ value: '#' + res.data.data?.mainProductCategory, label: '#' + res.data.data?.mainProductCategory });
				hashtagOption1.push({ value: '#' + res.data.data?.designAndLifestyle, label: '#' + res.data.data?.designAndLifestyle });
				//console.warn("brand data traget", res.data.data?.Targets);
				setTagHashtag(hashtagOption1);
				setHashResp(true);

				if (res.data.data?.Targets != undefined) {

					let targ = res.data.data?.Targets;
					let genderCo1 = [];
					let gen = res.data.data?.Targets?.Genders
					//console.log(data);
					gen.forEach((da) => {

						genderCo1.push({ value: da, label: da });
					}
					);
					setGenderValue(genderCo1);
					setGenderTarget(res.data.data?.Targets?.Genders);


					let ageCo1 = [];
					let age = res.data.data?.Targets?.AgeGroups
					//console.log(data);
					age.forEach((da) => {

						ageCo1.push({ value: da, label: da });
					}
					);
					setAgeGroupValue(ageCo1);
					setAgeGroups(res.data.data?.Targets?.AgeGroups);
					/*    
					 let lifeCo1=[];
					 let life = res.data.data?.Targets?.Lifestyle;
					 //console.log(data);
						life.forEach((da) => {
						  
						 lifeCo1.push({ value: da, label: da });	
						 }
					   );
					   setLifestyleValue(lifeCo1);
	 
					 setLifestyle(res.data.data?.Targets?.Lifestyle);
				    
					 let marketCo1=[];
					 let market = res.data.data?.Targets?.MajorMarkets;
					 //console.log(data);
						 market.forEach((da) => {
							marketCo1.push({ value: da, label: da });	
						 }
					   );
					   setMajorMarketValue(marketCo1);
					 setMajorMarket(res.data.data?.Targets?.MajorMarkets);
					 */

					let hashCo1 = [];
					let hashs = res.data.data?.Targets?.HashTags;
					//console.log(data);
					hashs.forEach((da) => {
						hashCo1.push({ value: da, label: da });
					}
					);
					setHashValue(hashCo1);

					setHashtag(res.data.data?.Targets?.HashTags);
					setShowPreLoader(false);

					//if(targ?.Genders.length && targ?.AgeGroups.length && targ?.Lifestyle.length && targ?.MajorMarkets.length && targ?.HashTags.length){
					if (targ?.Genders.length && targ?.AgeGroups.length && targ?.HashTags.length) {

						if (localStorage.getItem('status') != "Completed" && localStorage.getItem('status') != '100') {
							setDataReadonly(true);
						}
					}
				} else {
					setShowPreLoader(false);
				}
			} else {
				//check wethere brind id valid or not
				navigate("/brand/creator");
			}

		})
	}
	/* #endregion */

	return (
		<>
			{(submitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" className="button-not-active" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass" aria-hidden="true"></i></span>
									<span className="searchprodicttext text-center">Brands</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(props.compData?.completePercentage === "Completed") ? "/brand" : "#"} className={(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? "btn btn-success saveIt" : " btn btn-success button-not-active"}>Brands</Link>
								<Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Brand</Link>
								<Link to="/brand/creator" className={(editMode) ? "btn btn-success saveIt" : "btn btn-success active saveIt"}>New Brand</Link>
							</div>
							<div className="rightcontrolserch">
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={(editMode) ? "/brand/creator/" + id : "/brand/creator"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className="productsearch rightareas saveIt">
									<Link to={"/brand/logo/" + id} role="button">
										<span className="btn btn-success "><i className="fa-thin fa-sign-hanging"></i></span>
										<span className="searchprodicttext">Logos</span>
									</Link>
								</div>
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to={"/brand/tags-target/" + id} role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>
						{(showPreLoader) ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div> : <>
							<div ref={refWarp}>
								<div className="filtertopcombo">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="filtersection creatorsbtnset enroll-brand">
													<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
														<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun) ? true : buttonDisable} >{buttonName} {(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
													</span>
												</div>

												<StepCombination cdata={props.compData} />

											</div>
										</div>
									</div>
								</div>

								<div className="creators-section">
									<div className="container-fluid">
										<div className="row">
											<div className="col-md-12">
												<div className="cardsection">
													<div className="card-header">
														<h3 className="card-title">TARGETS & HASHTAGS <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
													</div>
													<div className="card-body">

														<div className="row">
															<div className="col-md-6">
																{/* <div className="form-group activeborder">
					                  					<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Gender identities</label>
																			<div className="customtaginput">
									        						<InputTag selectedTags={selectedTags} savedTag={tagDataGender} tagType="genderTarget" isError={genderError} />
									        					</div>
					                  				</div> */}
																<div className={(dataReadonly) ? "form-group inActiveborder selectheight tag-inactive target-tagsin" : "form-group  selectheight multiset-catg activeborder target-tagsin"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Gender identities</label>
																	<Select value={genderValue} className={(genderError) ? "selects error-input" : "selects"}
																		//defaultValue={countryOption[0]}
																		options={tagDataGender}
																		styles={colourStyles}
																		onChange={handleGenderTags}
																		closeMenuOnSelect={false}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isMulti
																		isSearchable
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>
															<div className="col-md-6">
																<div className={(dataReadonly) ? "form-group inActiveborder selectheight tag-inactive target-tagsin" : "form-group  selectheight multiset-catg activeborder target-tagsin"}>
																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Age Groups</label>
																	<Select value={ageGroupValue} className={(ageGroupError) ? "selects error-input" : "selects"}
																		//defaultValue={countryOption[0]}
																		options={tagDataAge}
																		styles={colourStyles}
																		onChange={handleAgeGroupsTags}
																		closeMenuOnSelect={false}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isMulti
																		isSearchable
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>
														</div>

														{/*<div className="row">
												<div className="col-md-6">
												<div className={(dataReadonly)?"form-group inActiveborder selectheight tag-inactive target-tagsin":"form-group  selectheight multiset-catg activeborder target-tagsin"}>
														<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Lifestyle & Social profile</label>
														<Select value={lifestyleValue} className={(lifestyleError) ? "selects error-input" : "selects"}
															//defaultValue={countryOption[0]}
															options={tagDataLifeStyle}
															styles={colourStyles}
															onChange={handleLifeStyleTags}
															closeMenuOnSelect={false}
															placeholder={<div className="italic-placeholder">Select...</div>}
															isMulti
															isSearchable
															isDisabled={(dataReadonly)?true:false}
														/>
													</div>
												</div>
												<div className="col-md-6">
												<div className={(dataReadonly)?"form-group inActiveborder selectheight tag-inactive target-tagsin":"form-group  selectheight multiset-catg activeborder target-tagsin"}>
														<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Major markets</label>
														<Select value={majorMarketValue} className={(majorMarketError) ? "selects error-input" : "selects"}
															//defaultValue={countryOption[0]}
															options={tagDataMarket}
															styles={colourStyles}
															onChange={handMajorMarketsTags}
															closeMenuOnSelect={false}
															placeholder={<div className="italic-placeholder">Select...</div>}
															isMulti
															isSearchable
															isDisabled={(dataReadonly)?true:false}
														/>
													</div>
												</div>
												</div> */}

														<div className="row">
															<div className="col-md-12">
																{/* <div className={(dataReadonly)?"form-group inActiveborder transparent-hashtag tag-inactive target-tagsin":"form-group activeborder multiset-catg transparent-hashtag target-tagsin"}> */}
																<div className={(dataReadonly) ? "form-group multiset-catg transparent-hashtag tag-inactive" : "form-group multiset-catg transparent-hashtag"}>

																	<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Hashtags</label>
																	<CreatableSelect value={hashValue} className={(hashtagError) ? "selects error-input" : "selects"}
																		isMulti
																		options={tagHashtag}
																		onChange={handHashtagTags}
																		styles={colourStyles}
																		closeMenuOnSelect={false}
																		placeholder={<div className="italic-placeholder">Select...</div>}
																		isDisabled={(dataReadonly) ? true : false}
																	/>
																</div>
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</>}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
		</>
	);
}

export default Dashboard;