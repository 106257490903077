import React, { useEffect, useRef, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import './../../assets/css/checkbox.css';
import errorWarning from './../../assets/images/error-icon.png';
import errorSuccess from './../../assets/images/icons8-check-64.png';
import { APP_TITLE } from '../../config/constant';
import * as Library from '../../utils/Library';
import LoaderIcon from './../../assets/images/loader-icon.png';
import LoaderSvg from './../../assets/images/loader.svg';
import BrandAside from '../layouts/BrandAside';
import BrandAsideNew from '../layouts/BrandAsideNew';
import BrandNavigation from '../layouts/BrandDashboardHeader';
import BrandFooter from '../layouts/BrandFooter';
import './../../App.css';

createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: '#10141B',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#333F50',
    },
    action: {
        button: 'rgba(0,0,0,.54)',
        hover: 'rgba(0,0,0,.08)',
        disabled: 'rgba(0,0,0,.12)',
    },
}, 'dark');

/* #region  SCOPED CSS */
const StyledPage = styled.div`
.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child {
    min-width: 100px !important;
}

.table-export-buttons {
    float: right;
    margin: 10px 0px;
}

.filter-section .form-control {
	width: 100%;
	border-color: #333F50;
	background: #10141B;
	border-width: 1px;
	height: 40px;
	border-radius: 6px;
	max-width: 300px;
	font-style: italic;
	display: inline-flex;
	color: #d1d1d1;
}

.filter-section .form-control::placeholder{
	color: #7c868b;
}

.filter-section .form-control:hover,
.filter-section btn:hover{
	border-color: #00AEEF;
}

.filter-btn{
	/* width: 40px; */
    height: 36px;
    font-size: 16px;
    background-color: #1E2631;
    border-color: #1E2631;
    color: #A6A6A6;
}

.filter-btn-with-label{
	display: inline-grid;
    width: auto;
    text-align: center;
	font-size: 10px;
	margin-left: 8px;
	top: -1px;
	color: #4f5b6d !important;
    position: relative;
}

.filter-btn-with-label >span {
    text-align: center;
	font-size: 10px;
}

.filter-btn-with-label >.btn,
.filter-btn-with-label >.btn:hover,
.filter-btn-with-label.disabled >.btn,
.filter-btn-with-label.disabled >.btn:hover{
	opacity: 1;
    background-color: #1e2631 !important;
	color: #d1d1d1;
	border-color: #1E2631;
}

.filter-btn-with-label.disabled >.btn,
.filter-btn-with-label.disabled >.btn:hover{
	color: #4f5b6d;
    cursor: not-allowed;
}

.filter-btn-with-label >.btn:hover,
.filter-btn-with-label.disabled >.btn:hover{
	border-color: #00AEEF;
}

.filter-section{
	display: flex;
}

.filter-section.right{
	float: right;
}

.filter-section > .selects {
	width: 290px;
	margin-right: 40px;
}

.filter-section >.selects >div {
	height: 40px;
}

.filter-section >p {
	margin-right: 15px;
	padding: 9px 0px;
	color: #d1d1d1;
	font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

.filter-section span {
	color: #7c868b;
}

.showItems .sc-hHLeRK,
.showItems .sc-hHLeRK.fhIruZ {
	display: block !important;	
}

.filter-container{
	display: contents;
}

// STATUS WILL BE STICKY
/*.rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
.rdt_TableRow .rdt_TableCell:nth-last-child(2) {
	position: sticky !important;
	background-color: #10141b;
	padding: 0 !important;
    right: 80px;
    z-index: 99990;
    justify-content: center;
}*/

`;

const NestedCols = styled.tr`
        display: flex;
        align-items: stretch;
        width: 100%;
        box-sizing: border-box;
        font-size: 12px !important;
        font-weight: 400;
        font-family: Open Sans;
        border-bottom: 1px solid #333f50 !important;
        min-height: 48px;
    
    .nested-col {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        
        position: relative;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        line-height: normal;
        padding-left: 16px;
        padding-right: 16px;
        word-break: break-word;
        max-width: 100%;
        min-width: 100px;
    }

    .nested-col >span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #7c868b !important;
    }

    .nested-col.status-sticky {
        position: sticky !important;
        background-color: #10141b;
        padding: 0 !important;
        right: 80px;
        z-index: 99990;
        justify-content: center;
    }

    .nested-col.action-menu {
        position: sticky;
        right: 0;
        padding: 0 !important;
        justify-content: center;
        background-color: #10141b;
    }
`;
/* #endregion */

function OrdersList(props) {
    document.title = `${APP_TITLE} - Orders`;

    /* #region  STATES */
    const ref = useRef();
    const [elementData, setElementData] = useState('');
    const [currentBrandID, setCurrentBrandID] = useState('');
    const [currentCompanyID, setCurrentCompanyID] = useState('');
    const [tooltipImg, setTooltip] = useState(true);
    const [tooltipText, setTooltipText] = useState(true);
    const [orderList, setOrderList] = useState([]);
    const [dataLoading, setDataLoading] = useState(true);
    const [preLoader, setPreLoader] = useState(true);
    const [isPagerefresh, setIsPageRefresh] = useState(false);

    const [showFilteringSection, setShowFilteringSection] = useState(false);

    const [controlBarLeftBtns, setControlBarLeftBtns] = useState([
        { to: '#', disabled: false, active: showFilteringSection, title: 'Orders', icon: 'fa-magnifying-glass', onClick: ((e) => { e.preventDefault(); setShowFilteringSection(!showFilteringSection); }) }
    ]);
    const [controlBarCenterBtns, setControlBarCenterBtns] = useState([
        { to: '#', active: false, disabled: true, title: 'Auto-mapping' },
        { to: '/orders', active: true, disabled: false, title: 'orders info' },
        { to: '#', active: false, disabled: true, title: 'Analytics' }
    ]);
    const [controlBarRigthBtns, setControlBarRightBtns] = useState([
        { to: '#', active: true, disable: false, title: 'Status', icon: 'fa-temperature-half' },
        { to: '#', active: false, disabled: true, title: 'Content', icon: 'fa-microscope' }
    ]);
    /* #endregion */

    /* #region  EFFECTS */
    useEffect(() => {
        const brandID = localStorage.getItem('brandID');
        const currentCompanyID = localStorage.getItem('currentCompanyID');
        setElementData(ref.current);
        if (brandID) {
            setCurrentBrandID(brandID);
            setCurrentCompanyID(currentCompanyID);
            getOrderList(brandID, currentCompanyID);
        } else {
            setPreLoader(false);
            setDataLoading(false);
        }
    }, [localStorage.getItem('brandID')]);

    useEffect(() => {
        if (!props?.setControlBarLeftBtns)
            return;

        setControlBarLeftBtns([{ to: '#', disabled: false, active: showFilteringSection, title: 'Orders', icon: 'fa-magnifying-glass', onClick: ((e) => { e.preventDefault(); setShowFilteringSection(!showFilteringSection); }) }]);

        props.setControlBarLeftBtns([{ to: '#', disabled: false, active: showFilteringSection, title: 'Orders', icon: 'fa-magnifying-glass', onClick: ((e) => { e.preventDefault(); setShowFilteringSection(!showFilteringSection); }) }]);
        props.setControlBarCenterBtns(controlBarCenterBtns);
        props.setControlBarRightBtns(controlBarRigthBtns);

    }, [showFilteringSection]);

    useEffect(() => {
        if (isPagerefresh) {
            getOrderList(localStorage.getItem('brandID'), localStorage.getItem('currentCompanyID'));
        }
        setIsPageRefresh(false);
    }, [isPagerefresh]);
    /* #endregion */

    /* #region  CONSTANTS */
    const triggerChange = () => {
        console.log('Cliked!', showFilteringSection);
        setShowFilteringSection(!showFilteringSection);
    };

    const colourStyles = {
        control: (styles, state) => ({
            ...styles,
            backgroundColor: '#10141B',
            borderColor: 'transparent',
            border: state.isFocused ? '1px solid #00AEEF' : '1px solid #333F50',
            boxShadow: state.isFocused ? '0px 0px 1px transparent' : 'none',
            '&:hover': {
                border: '1px solid #00AEEF',
            },
            '&:active': {
                border: '1px solid #00AEEF',
            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: '#333F50',
                color: '#d1d1d1',
                cursor: 'pointer',
                border: '1px solid #10141B',
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                '&:hover': {
                    border: '1px solid #00AEEF',
                },
                '&:active': {
                    backgroundColor: '#333F50',
                }
            };
        },
        noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
        indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
        menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
        menuList: styles => ({
            ...styles, padding: 0, paddingRight: '2px',
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px'
            }
        }),
        singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
        input: styles => ({ ...styles, color: '#d1d1d1' }),
        placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: '#fff',
            backgroundColor: '#205380',
            borderRadius: '0px',
            ':hover': {
                backgroundColor: '#205380',
                color: 'white',
            },
        }),
    };

    const columns = [
        {
            name: 'Order Nr.',
            selector: row => row?.orderNumber || '-',
            sortable: true,
            style: {
                minWidth: '150px'
            }
        },
        {
            name: 'Order date',
            selector: row => row?.creationDateFromPOS || '-',
            sortable: true,
            width: '150px'
        },
        {
            name: 'Order Type',
            selector: row => row?.orderType || '-',
            sortable: true,
            width: '125px'
        },
        {
            name: 'Delivery Windows',
            selector: row => row?.deliveryWindow || '-',
            sortable: true,
            width: '110px',
        },
        {
            name: 'Season',
            selector: row => row?.season || '-',
            sortable: true,
            width: '130px'
        },
        {
            name: 'Collection',
            selector: row => row?.collectionName || '-',
            sortable: true,
            width: '110px'
        },
        {
            name: 'Collection nr.',
            selector: row => row?.collectionNumber || '-',
            sortable: true,
            width: '110px'
        },
        {
            name: 'Customer name',
            selector: row => row?.customerName || '-',
            sortable: true,
            width: '100px',
        },
        {
            name: 'Store name',
            selector: row => row?.member || '-', //TODO: STORE NAME!
            sortable: true,
            width: '110px'
        },
        {
            name: <div>Total items<span className="font--small"></span></div>,
            selector: row => row?.itemsTotalQuantity || '-',
            sortable: true,
            width: '130px',
        },
        {
            name: 'Currency',
            selector: row => row?.currency || '-',
            sortable: true,
            width: '130px',
        },
        {
            name: 'Total (incl. VAT)',
            selector: row => row?.totalPriceIncludingVAT || '-',
            sortable: true,
            width: '110px'
        },
        {
            name: 'Order status',
            selector: row => row?.orderStatus || '-',
            sortable: true,
            width: '135px',
        },
        {
            name: 'Status',
            selector: row => row?.status || '-',
            sortable: true,
            width: '100px',
            cell: (row) =>
                <>
                    <span className={row?.status === 'Ready' ? 'default-color--green' : 'default-color--orange'}>{row?.status || '-'}</span>
                </>
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: false,
            cell: (row) =>
                <>
                    <Link className="btn btn-success button-not-active" to="#">VIEW</Link>
                    <Link className="btn btn-success button-not-active hidden" style={{ visibility: 'hidden' }} to="#">SALES</Link>
                    <Link className="btn btn-success button-not-active hidden" style={{ visibility: 'hidden' }} to="#">STOCK</Link>

                </>,
            width: '80px',
            style: {
                justifyContent: 'center',
                flexDirection: 'row',
                minWidth: '80px'
            }
        }
    ];

    const productStylesOptions = [
        { value: 'All', label: 'all' }
    ];
    /* #endregion */

    /* #region  METHODS */
    const getOrderList = (labelBrandId, companyID) => {
        setOrderList([]);
        setPreLoader(false);
        setDataLoading(false);

        const formData = {
            'labelBrandID': labelBrandId,
            'companyID': companyID
        };

        Library.makePostRequest('getOrders', formData, false, setPreLoader).then((res) => {
            if (res.data.status !== 200)
                return;

            const data = res.data.data?.tableItems;
            setOrderList(data);
            setDataLoading(false);
        });
    };

    /*const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);
*/

    const dataReloadfunction = (e) => {
        setIsPageRefresh(e);
    };
    /* #endregion */

    /* #region  COMPONENTS */
    const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
        return (
            <>
                <label className="container-checkbox">
                    <input type="checkbox"
                        ref={ref}
                        onClick={onClick}
                        {...rest}
                    />
                    <span className="checkmark"></span>
                </label>
            </>
        );
    });

    const ExpandedComponent = ({ data }) => {
        return (<table className="table child-table">
            <tbody>
                {
                    data?.gtins?.map((v, i) => {
                        return (
                            <NestedCols className="nested-row" key={i}>
                                <td className="nested-col" style={{ minWidth: '96px', maxWidth: '96px', justifyContent: 'right' }}>
                                    <label className="container-checkbox" style={{ 'float': 'right' }}>
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                    </label>
                                </td>
                                <td className="nested-col" style={{ minWidth: '180px', maxWidth: '180px' }}>
                                    <span title={data.productType}>{data.productType}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '125px', maxWidth: '125px' }}>
                                    <span title={data.brand.name}>{data.brand.name}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '125px', maxWidth: '125px' }}>
                                    <span title={data.referenceCode}>{data.referenceCode}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '110px', maxWidth: '110px' }}>
                                    <span title={data.name}>{data.name}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '110px', maxWidth: '110px' }}>
                                    <span>{data?.category ? data.category : '-'}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '130px', maxWidth: '130px' }}>
                                    <span title={v?.barcode}>{v?.barcode}</span>
                                </td>
                                <td className="nested-col">
                                    {(data?.description) ?
                                        <>
                                            <span
                                                onMouseEnter={() => setTooltipText(true)}
                                                onMouseLeave={() => { setTooltipText(false); setTimeout(() => setTooltipText(true), 5); }}
                                                data-tip={data?.description}
                                                data-for="tooltip-text">
                                                <span className="topboadanc tooltip-text" to="#">{data?.description}</span>
                                            </span>
                                        </> : <span>-</span>
                                    }
                                </td>
                                <td className="nested-col" style={{ minWidth: '110px', maxWidth: '110px' }}>
                                    <span>{v?.size}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '100px', maxWidth: '100px' }}>
                                    <span title={v?.colorCode}>{v?.colorCode}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '100px', maxWidth: '100px' }}>
                                    <span title={v?.color}>{v?.color}</span>
                                </td>
                                <td className="nested-col" style={{ minWidth: '100px', maxWidth: '100px' }}>
                                    <span title={v?.colorCode}>{v?.colorCode}</span>
                                </td>
                                <td className="nested-col status-sticky" style={{ minWidth: '80px', maxWidth: '80px' }}>
                                    {(Number(v?.status) === 1) ?
                                        <img src={errorSuccess} alt="success" className="exclamation-triangle" /> :
                                        <img src={errorWarning} alt="error" className="exclamation-triangle" />}
                                </td>
                                <td className="nested-col action-menu" style={{ minWidth: '80px', maxWidth: '80px' }}>
                                    { /*
                                    <div style={{minWidth: "80px", maxWidth: "80px", textAlign: "center"}}>
                                        {(Number(v?.status) === 1) ? <img src={errorSuccess} alt="success" className="exclamation-triangle" /> : <img src={errorWarning} alt="error" className="exclamation-triangle" />}
                                    </div>
                                    */ }
                                    <Link className="btn btn-success button-not-active" to="#">VIEW</Link>
                                    <Link className="btn btn-success button-not-active" to="#">SALES</Link>
                                    <Link className="btn btn-success button-not-active" to="#">STOCK</Link>
                                </td>
                            </NestedCols>
                        );
                    })
                }
            </tbody>
        </table>);
    };

    const CustomLoader = () => (<div className="loaderclass"><i className="fa fa-spinner fa-spin"></i></div>);
    /* #endregion */

    return (
        <StyledPage>
            <div className="wrapper" ref={ref} id="myscreen">
                <BrandNavigation dataReloadfunction={dataReloadfunction} data={elementData} cdata={props.compData} />
                {(props.type == 'new') ? <BrandAsideNew cdata={props.compData} /> : <BrandAside cdata={props.compData} />}
                <div className="content-wrapper">
                    <>
                        <div className="controlbar">
                            <div className="productsearch leftsidetr">
                                <Link data-widget="search" className="button-not-active" to="#" role="button">
                                    <span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
                                    <span className="searchprodicttext">Products</span>
                                </Link>
                            </div>

                            <div className="centerbtn">
                                <Link to="/orders" className="btn btn-success active">Orders</Link>
                            </div>

                            <div className="rightcontrolserch">
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/orders" role="button">
                                        <span className="btn btn-success active"><i className="fa-thin fa-list"></i></span>
                                        <span className="searchprodicttext">List</span> 
                                    </Link>
                                </div>
                                <div className="productsearch rightareas">
                                    <Link data-widget="search" to="/orders/importer" role="button">
                                        <span className="btn btn-success"><i className="fa-thin fa-chart-simple"></i></span>
                                        <span className="searchprodicttext">Importer</span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        {(preLoader)
                            ? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
                            :
                            <>
                                <div className="filtersarea">
                                    <div className="container-fluid filter-container">
                                        <div className="row" style={{ paddingTop: '15px' }}>

                                            <div className="col-md-6">
                                                <div className={'filter-section' + (showFilteringSection ? ' show-transition--faster' : ' hide-transition--faster')}>
                                                    <input className="form-control" style={{ marginRight: '8px' }} type="text" placeholder="Search list:" aria-label="Search" disabled='disabled' />

                                                    <span className="filter-btn-with-label disabled">
                                                        <button type="button" className="btn btn-success filter-btn disabled">
                                                            <i className="fa-thin fa-magnifying-glass"></i>
                                                        </button>
                                                        <span className="disabled">Go</span>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="filter-section right">
                                                    <p>Filters:</p>

                                                    <Select className={'selects'}
                                                        options={productStylesOptions}
                                                        styles={colourStyles}
                                                        placeholder={<div className="italic-placeholder">Select...</div>}
                                                    />

                                                    <span className="filter-container">
                                                        <span className="filter-btn-with-label">
                                                            <button type="button" className="btn btn-success filter-btn" onClick={() => getOrderList(currentBrandID, currentCompanyID)}><i className="fa-thin fa-rotate-left"></i></button>
                                                            <span className="">Reset</span>
                                                        </span>
                                                        <span className="filter-btn-with-label disabled text-not-active">
                                                            <button type="button" className="btn btn-success filter-btn"><i className="fa-thin fa-arrow-right"></i></button>
                                                            <span className="disabled">Go</span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-export-buttons">
                                    <div className="btn-group">
                                        <button className="btn main-btn--small disabled">Copy</button>
                                        <button className="btn main-btn--small disabled">CSV</button>
                                        <button className="btn main-btn--small disabled">PDF</button>
                                        <button className="btn main-btn--small disabled">Print</button>
                                    </div>

                                    <span className="filter-btn-with-label">
                                        <button type="button" className="btn btn-success filter-btn" onClick={() => getOrderList(currentBrandID, currentCompanyID)}><i className="fa-thin fa-arrows-rotate"></i></button>
                                        <span className="">Refresh</span>
                                    </span>

                                    <button type="button" className="btn btn-success main-btn disabled hidden" style={{ marginLeft: '8px' }}>SALES</button>
                                    <button type="button" className="btn btn-success main-btn disabled hidden" style={{ marginLeft: '8px' }}>STOCK</button>
                                </div>

                                <div className="tablestructure">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>Orders List</h4>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <DataTable
                                                columns={columns}
                                                data={orderList}
                                                theme="solarized"
                                                sortIcon={<i className="fa fa-sort-amount-asc" aria-hidden="true"></i>}
                                                progressPending={dataLoading}
                                                selectableRows
                                                selectableRowsComponent={Checkbox}
                                                defaultSortFieldId={1}
                                                defaultSortAsc={true}
                                                responsive={true}
                                                //expandableRows={true}
                                                expandableRowsComponent={ExpandedComponent}
                                                progressComponent={<CustomLoader />}
                                                //actions={actionsMemo}
                                                pagination
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {(tooltipImg) ? <ReactTooltip id="tooltip-img" place="top" effect="solid" getContent={(dataTip) => <img src={dataTip} style={{ 'height': '250px', 'width': 'auto' }} />} /> : ''}
                        {(tooltipText) ? <ReactTooltip className="tooltip-text" html={true} id="tooltip-text" place="top" effect="solid" getContent={(dataTip) => `${dataTip}`} /> : ''}
                    </>
                </div>
                <BrandFooter logo={props.imageData.footer_logo} />
            </div>
        </StyledPage>
    );
}

export default OrdersList;