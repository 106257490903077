import axios from 'axios';
import React, { useEffect, useRef, useState } from "react";
import { useBeforeunload } from 'react-beforeunload';
import { Calendar, DateObject } from "react-multi-date-picker";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select/creatable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";
import StepCombination from '../../layouts/commonLayout';
import errorWarning from './../../../assets/images/icons-error-48.png';
import errorSuccess from './../../../assets/images/icons8-check-64.png';
import LoaderIcon from './../../../assets/images/loader-icon.png';
import LoaderSvg from './../../../assets/images/loader.svg';
import BrandAside from "./../../layouts/BrandAside";
import BrandNavigation from "./../../layouts/BrandDashboardHeader";
import BrandFooter from "./../../layouts/BrandFooter";
toast.configure();


function Dashboard(props) {
	document.title = 'BOstageB - BOimages Creater';

	/* #region  STATES */
	const ref = useRef();
	let navigate = useNavigate();
	const { BOimageIdData } = useParams();
	const [elementData, setElementData] = useState("");
	const [buttonGray, setButtonGray] = useState(false);
	const [name, setName] = useState("");
	const [collection, setCollection] = useState("");
	const [caption, setCaption] = useState("");
	const [text, setText] = useState("");
	const [nameError, setNameError] = useState(false);
	const [captionError, setCaptionError] = useState(false);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [preLoader, setPreLoader] = useState(true);
	const [onSubmitLoader, setOnSubmitLoader] = useState(false);
	const [dataReadonly, setDataReadonly] = useState(false);
	const [denied, setDenied] = useState(false);
	const [imgUrlCk, setImgUrlCk] = useState(true);
	const [collectionVal, setCollectionVal] = useState(null);
	const [brandCollection, setBrandCollection] = useState(true);
	const [buttonName, setButtonName] = useState("NEXT");
	const [editMode, setEditMode] = useState(0);
	const [editModeBtnFun, setEditModeBtnFun] = useState(0);
	const [msgs, setMsgs] = useState("BOimage saved");
	/* #endregion */

	/* #region  EFFECTS */
	useEffect(() => {
		let _data = JSON.parse(localStorage.getItem("__data"));

		if (typeof props?.compData?.companyDetail != "undefined") {
			if (_data) {
				if (!_data?.BOimagesMenu) {
					setPreLoader(false);
					setDenied(true)
				} else {
					setDenied(false)
				}
			}
		}

	}, [props.compData]);

	useEffect(() => {
		setElementData(ref.current);
		if (typeof BOimageIdData !== 'undefined') {
			getDataOfLid(BOimageIdData);
			setButtonName("SAVE");
			if (localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed') {
				setEditMode(1);
				setEditModeBtnFun(1);
			}

		} else {
			localStorage.removeItem('viewMode');
			if (localStorage.getItem('boimageID') !== 'undefined') {
				if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
					getDataOfLid(localStorage.getItem('boimageID'));
				}
				else {
					setButtonGray(false)
					setName("")
					setCollection("")
					setCaption("")
					setText("")
					setValues([])
					setFirstValues("")
					setSecondValues("")
					setMainDateRange("")
					setPreLoader(false)
				}
			} else {
				setButtonGray(false)
				setName("")
				setCollection("")
				setCaption("")
				setText("")
				setValues([])
				setFirstValues("")
				setSecondValues("")
				setMainDateRange("")
				setPreLoader(false)
			}
			setEditMode(0);
		}
	}, [BOimageIdData]);
	useBeforeunload((event) => {
		if (name || caption) {
			event.preventDefault();
		}
	});

	useEffect(() => {
		if (localStorage.getItem('status') == 100 || localStorage.getItem('status') == 'Completed') {
			setButtonName("SAVE");
		}
	}, [localStorage.getItem('status')])

	useEffect(() => {
		getBrandCollectionData();

	}, [])
	/* #endregion */

	/* #region  METHODS */
	const getBrandCollectionData = () => {

		const brandCollectionOption1 = [];
		const formData = { "brandID": localStorage.getItem('brandID') }

		axios.post(process.env.REACT_APP_API_URL + "get-brand-bo-collection?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status === 200) {
				let data = res.data.data;
				data.forEach((da) => {

					brandCollectionOption1.push({ value: da.name, label: da.name });
				}
				);

				setBrandCollection(brandCollectionOption1);
			}
		})
	}

	const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning(name, caption)
	const getDataOfLid = async (dataLid) => {
		const formData = {
			"id": dataLid
		}
		await axios.post(process.env.REACT_APP_API_URL + "get-boimage-id-data-by-id?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
			if (res.data.status == 200) {
				setName(res?.data?.resData?.name)
				setCaption(res?.data?.resData?.caption)
				setText(res?.data?.resData?.description)
				setFirstValues(res?.data?.resData?.activePeriodStart);
				setSecondValues(res?.data?.resData?.activePeriodEnd);

				setCollectionVal({ value: res.data.resData?.collectionName, label: res.data.resData?.collectionName });
				//added
				if (res?.data?.resData?.activePeriodStart != "" && res?.data?.resData?.activePeriodEnd != "") {
					//
					let dat = res?.data?.resData?.activePeriodStart + " to " + res?.data?.resData?.activePeriodEnd;
					setMainDateRange(dat);

					let startDay = (res?.data?.resData?.activePeriodStart)?.split('-');
					let endDay = (res?.data?.resData?.activePeriodEnd)?.split('-');
					setValues([
						new DateObject().setDay(startDay[0]).setMonth(startDay[1]).setYear(startDay[2]),
						new DateObject().setDay(endDay[0]).setMonth(endDay[1]).setYear(endDay[2])
					]
					)
				}
				if (res?.data?.resData?.name && res?.data?.resData?.caption) {
					//console.log("image",res?.data?.resData);
					if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {
						setDataReadonly(true);
					}

					if (res.data.resData?.BOtags.length < 1) {
						setImgUrlCk(false);
					}
				}


				setButtonGray(true);
			} else {
				navigate("/boimages/creater")
			}
			setPreLoader(false)
		});
	}
	const colourStyles = {
		control: (styles, state) => ({
			...styles,
			backgroundColor: '#10141B',
			borderColor: "transparent",
			border: state.isFocused ? "1px solid #0B0" : "1px solid transparent",
			boxShadow: state.isFocused ? "0px 0px 1px transparent" : "none",
			"&:hover": {
				border: "1px solid #0B0",
			},
			"&:active": {
				border: "1px solid #0B0",
			}
		}),
		noOptionsMessage: (styles) => ({ ...styles, backgroundColor: '#333F50', color: '#d1d1d1' }),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#333F50',
				color: '#d1d1d1',
				cursor: 'pointer',
				border: "1px solid #10141B",
				borderLeft: "1px solid transparent",
				borderRight: "1px solid transparent",
				"&:hover": {
					border: "1px solid #00AEEF",
				},
				"&:active": {
					backgroundColor: "#333F50",
				}
			};
		},
		indicatorSeparator: styles => ({ ...styles, backgroundColor: '#10141B' }),
		menu: styles => ({ ...styles, backgroundColor: '#10141B', marginTop: 0, borderRadius: 0 }),
		menuList: styles => ({
			...styles, padding: 0, paddingRight: "2px",
			"::-webkit-scrollbar-thumb": {
				borderRadius: "10px"
			}
		}),
		singleValue: styles => ({ ...styles, color: '#d1d1d1' }),
		input: styles => ({ ...styles, color: '#d1d1d1' }),
		placeholder: styles => ({ ...styles, color: '#d1d1d1' }),
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: "#fff",
			backgroundColor: "#205380",
			borderRadius: "0px",
			':hover': {
				backgroundColor: "#205380",
				color: 'white',
			},
		}),
	};

	const productOptions = [
		{ value: 'No collection', label: 'No collection' },
		{ value: 'Spring summer 2023', label: 'Spring summer 2023' },
		{ value: 'High summer 2023', label: 'High summer 2023' },
		{ value: 'Fall 2023', label: 'Fall 2023' },
		{ value: 'Winter 2023', label: 'Winter 2023' },
		{ value: 'Christmas 2023', label: 'Christmas 2023' },
	];

	const handleName = (e) => {
		if (e.target.value != " ") {
			setNameError(false);
			setName(e.target.value);
			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
		if (e.target.value && caption) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}

	};
	const handleCollection = (e) => {
		if (e) {
			setCollectionVal({ value: e.value, label: e.label });
			setCollection(e.value);
			if (editMode) {
				setEditModeBtnFun(0);
			}
		} else {
			setCollectionVal(null);
			setCollection("")
		}
	}

	/*const handleActivePeriod = (e) => {
		setActivePeriod(e.target.value)
	} */
	const handleCaption = (e) => {
		if (e.target.value != " ") {
			setCaptionError(false);
			setCaption(e.target.value);
			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
		if (e.target.value && name) {
			setButtonGray(true);
		} else {
			setButtonGray(false);
		}
		//console.log(buttonGray);

	}
	const handleText = (e, data) => {
		setText(data);
		const element = document.getElementById("inputData");
		element.focus();
		window.getSelection().selectAllChildren(element);
		window.getSelection().collapseToEnd();

		if (editMode) {
			setEditModeBtnFun(0);
		}
	}

	const [hides, setHides] = useState(0);
	const handalCal = () => {
		if (hides) {
			setHides(0);
		} else {
			setHides(1);
		}
	}

	const hideCal = () => {
		setHides(0);
	}

	const wrapperRef = useRef(null);

	useEffect(() => {

		function handleClickOutside(event) {
			//console.log("wrapperRef.curren",wrapperRef.current);
			//console.log("event",event.target.name);
			if (wrapperRef.current && !wrapperRef.current.contains(event.target) && wrapperRef.current.contains(event.target.name) != 'dateRange') {
				setHides(0);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [wrapperRef]);

	const refWarp = useRef(null);   //new datatttt
	useEffect(() => {

		function handleSaveOutside(event) {
			// console.log("parentNode",event.target.parentNode.parentNode.parentNode);
			/*
			 console.log("ref.curren",refWarp.current.contains(event.target)); 
			console.log("eventswq",event.target.value);
			console.log("event",event.target.classList);
			console.log("refWarp.current",refWarp.current); */
			//menu
			let pclass = event.target.parentNode.parentNode.parentNode.parentNode;
			let pclassimg = event.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
			// rightcontrolserch
			let prClassIcon = event.target.parentNode.parentNode.parentNode;
			let prClass = event.target.parentNode.parentNode;
			//center upper btn
			let pCNClass = event.target.parentNode;

			if (editMode && !editModeBtnFun) {
				//  console.log("test",editMode ,editModeBtnFun);
				//if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || event.target.classList.contains("menuimages"))  ) {
				if (refWarp.current && !refWarp.current.contains(event.target) && (event.target.classList.contains("saveIt") || pclass.classList.contains("menusections") || pclass.classList.contains("navitems-area") || pclassimg.classList.contains("navitems-area") || pclassimg.classList.contains("menusections") || prClassIcon.classList.contains("saveIt") || prClass.classList.contains("saveIt") || pCNClass.classList.contains("saveIt"))) {
					Swal.fire({
						title: '',
						text: "Changes will not be saved",
						icon: 'warning',
						showDenyButton: true,
						allowOutsideClick: false,
						confirmButtonColor: '#205380',
						denyButtonColor: '#205380',
						confirmButtonText: 'SAVE NOW',
						denyButtonText: 'CANCEL',
						background: '#1d2530',
						color: '#fff',
						iconColor: "#FF9900",
						width: "500px",
						padding: "10px",
						reverseButtons: false
					}).then((result) => {
						if (result.isConfirmed) {
							setEditModeBtnFun(1);
							handleSubmit(event)
						}
						else if (result.isDenied) {
							setEditModeBtnFun(1);
						}

					})


				}
			}
		}
		document.addEventListener("mousedown", handleSaveOutside);
		return () => {
			document.removeEventListener("mousedown", handleSaveOutside);
		};
	}, [refWarp, editMode, editModeBtnFun]);

	const [values, setValues] = useState([]);
	const [firstValues, setFirstValues] = useState("");
	const [secondValues, setSecondValues] = useState("");
	const [mainDateRange, setMainDateRange] = useState("");

	const changeDate = () => {
		if ((values[0] != undefined) && (values[1] != undefined)) {
			let firstDate = values[0]?.format("DD-MM-YYYY")//((values[0]!=undefined)?(values[0].day +"-" + values[0].month.number + "-" + values[0].year):"");
			let secondDate = values[1]?.format("DD-MM-YYYY")//((values[1]!=undefined)?(values[1].day +"-" + values[1].month.number + "-" + values[1].year):"");
			setFirstValues(firstDate);
			setSecondValues(secondDate);
			let dat = firstDate + " to " + secondDate;
			setMainDateRange(dat);
			setHides(0);

			if (editMode) {
				setEditModeBtnFun(0);
			}
		}
	}

	const clearDate = () => {
		setMainDateRange("");
		setFirstValues("");
		setSecondValues("");
		setValues([]);
	}

	const handleValidation = () => {
		let formIsValid = true;
		if (name == "") {
			setNameError(true)
			formIsValid = false;
		}
		if (caption == "") {
			setCaptionError(true);
			formIsValid = false;
		}
		return formIsValid;
	}
	const [isCompleted, setIsCompleted] = useState(false)
	useEffect(() => {
		if (props?.compData?.completePercentage === 'Completed' || props.compData?.status == '100') {
			setIsCompleted(true)
		}
	}, [props?.compData])

	const handleSubmit = (e) => {
		e.preventDefault();

		if (handleValidation()) {
			//if(localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed'){
			/**********enrollment process******************** */
			setDataReadonly(true);
			//	}
			setPristine();
			setButtonDisable(true);
			const formData = {
				"companyID": (typeof props?.compData?.companyDetail != "undefined") ? props?.compData?.companyDetail?.ourID : "",
				//"brandID":props?.compData?.activeBrand?.brandID,
				"brandID": localStorage.getItem('brandID'),
				"name": name,
				"collectionName": collection,
				"description": text,
				"caption": caption,
				"activePeriodStart": firstValues,
				"activePeriodEnd": secondValues,
				"createdBy": localStorage.getItem('token')
			}
			setOnSubmitLoader(true)
			if (BOimageIdData) {
				formData.BOid = BOimageIdData;
				formData.pageType = "idData";
				axios.post(process.env.REACT_APP_API_URL + "update-boimage-target?code=" + process.env.REACT_APP_API_CODE, formData).then((res) => {
					if (res.data.status == 200) {

						props.loginFun(localStorage.getItem('token'), '55');

						toast.success("BOimage ID updated",
							{
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorSuccess} />,
								onClose: () => {
								}
							});
						if (editMode) {
							setEditModeBtnFun(1);
						}
						setButtonDisable(false);
						setDataReadonly(false);
					} else {
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorWarning} />
						});
						setButtonDisable(false);
					}
					setOnSubmitLoader(false)
				});
			} else {
				axios.post(process.env.REACT_APP_API_URL + 'boimage-id-data-creator?code=' + process.env.REACT_APP_API_CODE, formData).then((res) => {
					if (res.data.status === 200) {
						props.loginFun(localStorage.getItem('token'), '55');
						if (localStorage.getItem('status') != 100 && localStorage.getItem('status') != 'Completed') {

							localStorage.setItem("boimageID", res.data.insertedID);
						}

						//  console.log(props.compData?.ProductsMenu);
						if (!props.compData?.ProductsMenu) {

							// toast.success("BOimage ID data is now saved. Let's create the BOimage now.",
							toast.success("BOimage ID data saved. Get the image ready to upload next.",
								{
									theme: "dark",
									icon: ({ theme, type }) => <img src={errorSuccess} />,
									onClose: () => {
										navigate('/boimages/canvas/' + res.data.insertedID);

									}
								});

						} else {
							toast.success(res.data.message, {
								theme: "dark",
								icon: ({ theme, type }) => <img src={errorSuccess} />,
							});
							navigate('/boimages/canvas/' + res.data.insertedID);
						}

					} else {
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({ theme, type }) => <img src={errorWarning} />
						});
						setButtonDisable(false);
					}
					setOnSubmitLoader(false)
				});
			}
		}

	}

	const resetCreator = (e) => {
		if (typeof BOimageIdData == 'undefined') {
			e.preventDefault()
		}
	}
	/* #endregion */

	return (
		<>
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation data={elementData} cdata={props.compData} />
				<BrandAside cdata={props.compData} />
				<div className="content-wrapper enroll-wrapper">
					<>
						<div className="controlbar">
							<div className="productsearch leftsidetr">
								<Link data-widget="search" className="button-not-active" to="#" role="button">
									<span className="btn btn-success"><i className="fa-thin fa-magnifying-glass"></i></span>
									<span className="searchprodicttext">BOimages</span>
								</Link>
							</div>
							<div className="centerbtn">
								<Link to={(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? "/boimages" : "#"} className={(props.compData?.completePercentage === "Completed" || props.compData?.status == '100') ? "btn btn-success saveIt" : " btn btn-success button-not-active"}>Boimages</Link>
								<Link to="#" className={(editMode) ? "btn btn-success active saveIt" : "btn btn-success button-not-active"}>Edit Boimage</Link>
								<Link to="/boimages/creater" className={(editMode) ? "btn btn-success saveIt" : "btn btn-success active saveIt"}>New Boimage</Link>
							</div>
							<div className="rightcontrolserch">
								<span className="extraBtns">
									<div className="productsearch rightareas  notactive">
										<Link data-widget="search" to="#" role="button">
											<span className="btn btn-success "><i className="fa-thin fa-list"></i></span>
											<span className="searchprodicttext">List</span>
										</Link>
									</div>
									<div className="productsearch rightareas notactive">
										<Link to="#" role="button">
											<span className="btn btn-success"><i className="fa-thin fa-grid"></i></span>
											<span className="searchprodicttext">Grid</span>
										</Link>
									</div>

								</span>
								<div className="productsearch rightareas saveIt">
									<Link data-widget="search" to="#" role="button">
										<span className="btn btn-success active"><i className="fa-thin fa-fingerprint"></i></span>
										<span className="searchprodicttext">ID</span>
									</Link>
								</div>
								<div className={(dataReadonly || BOimageIdData) ? "productsearch rightareas saveIt" : "productsearch rightareas notactive"}>
									<Link data-widget="search" onClick={resetCreator} to={(typeof BOimageIdData !== "undefined") ? "/boimages/canvas/" + BOimageIdData : "/boimages/creater"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-diagram-lean-canvas"></i></span>
										<span className="searchprodicttext">Canvas</span>
									</Link>
								</div>
								<div className={(imgUrlCk) ? (typeof BOimageIdData !== "undefined") ? "productsearch rightareas saveIt" : "productsearch rightareas notactive" : "productsearch rightareas notactive"}>
									<Link data-widget="search" to={(imgUrlCk) ? (typeof BOimageIdData !== "undefined") ? "/boimages/target/" + BOimageIdData : "#" : "#"} role="button">
										<span className="btn btn-success"><i className="fa-thin fa-bullseye"></i></span>
										<span className="searchprodicttext">Targets</span>
									</Link>
								</div>
							</div>
						</div>
						{(preLoader)
							? <div className="pre-loader-img top-content"><div className="maintain-height"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div></div>
							:
							<>
								<div ref={refWarp}>
									<div className="filtertopcombo">
										<div className="container-fluid">
											<div className="row">
												<div className="col-md-12">
													<div className="filtersection creatorsbtnset enroll-brand">
														<span className={(buttonGray) ? "customeset textinside next-new-btn" : "customeset textinside next-new-btn disabledbtn"}>
															<button type="submit" onClick={handleSubmit} className="btn btn-success" disabled={(dataReadonly || editModeBtnFun) ? true : (buttonDisable) ? 'disabled' : ''}>{buttonName} {(buttonDisable) ? (<i className="fas fa-spinner fa-spin"></i>) : (<i className="fa-thin fa-chevron-right"></i>)}</button>
														</span>
													</div>
													<StepCombination cdata={props.compData} />
												</div>

											</div>
										</div>
									</div>
									<div className="creators-section">
										<div className="container-fluid">
											<div className="row">
												<div className="col-md-12">
													<div className="cardsection">
														<div className="card-header">
															<h3 className="card-title">BOIMAGE ID <span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span></h3>
														</div>
														<div className="card-body" > { /*ref={wrapperRef} */}
															<form>
																<div className="row">
																	<div className="col-md-3">
																		<div className={(dataReadonly) ? "form-group inActiveborder" : "form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Name (internal)</label>
																			<input type="text" className={(nameError) ? "form-control error-input" : "form-control"} onChange={handleName} value={name} placeholder="" disabled={dataReadonly} onClick={hideCal} />
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className={(dataReadonly) ? "form-group lockedNoborder" : "form-group"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> To collection</label>
																			<Select
																				options={brandCollection}
																				styles={colourStyles}
																				onChange={handleCollection}
																				defaultValue={collectionVal}
																				isDisabled={dataReadonly}
																				isClearable
																				placeholder={<div className="italic-placeholder">Select...</div>}
																			/>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className={(dataReadonly) ? "form-group haveaddon slecticons lockcalendar active-period" : "form-group haveaddon slecticons active-period"} >

																			<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Active Period</label>
																			<div className="addonimgleft ">
																				<input type="text" className="form-control" name="dateRange" value={mainDateRange} onClick={(!dataReadonly) ? handalCal : ""} placeholder="Always Active" disabled={dataReadonly} />
																			</div>
																		</div>
																		<div className="float-containers " ref={wrapperRef} style={{ display: (hides) ? "block" : "none" }}>
																			<div className="float-childs">
																				<div className="inputcontainer">
																					<label >From</label>


																					<input type="text" className="df" name="dateFirst" value={(values[0] != undefined) ? values[0]?.format("DD-MM-YYYY") : ""} placeholder="" />
																				</div>
																				<div className="inputcontainer">
																					<label >To</label>
																					<input type="text" className="df" name="dateSecond" value={(values[1] != undefined) ? values[1]?.format("DD-MM-YYYY") : ""} placeholder="" />

																				</div>
																				<div className="brtninouts">
																					<button type="button" onClick={() => { clearDate(); handalCal() }} className="cancelbtns">Cancel</button>
																					<button type="button" onClick={changeDate} className="aplpybtnd">Apply</button>

																				</div>


																			</div>
																			<div className="float-childstwo">
																				<Calendar
																					value={values}
																					onChange={setValues}
																					range
																					numberOfMonths={2}
																					showOtherDays

																				/>
																			</div>
																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className={(dataReadonly) ? "form-group inActiveborder" : "form-group activeborder"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Caption (meta data)</label>
																			<input type="text" className={(captionError) ? "form-control error-input" : "form-control"} onChange={handleCaption} value={caption} placeholder="" disabled={dataReadonly} onClick={hideCal} />
																		</div>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-12">
																		<div className={(dataReadonly) ? "form-group expandHeight lockedNoborder" : "form-group expandHeight"}>
																			<label htmlFor="tobrand"><i className="fas fa-info-circle"></i> Text</label>
																			{/*<input type="text" className="form-control" onChange={handleText} value={text} placeholder="" disabled={dataReadonly}/>*/}
																			<span id="inputData" spellcheck='false' className={(dataReadonly) ? 'disableTextArea customspaninput' : 'customspaninput'} suppressContentEditableWarning={true} onInput={(e) => { handleText(e, e.currentTarget.innerText) }} contentEditable  >{text}</span>
																		</div>
																	</div>
																</div>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						}
					</>
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			{/* {(dataReadonly)?"":Prompt} */}
			{(onSubmitLoader) ? <div className="pre-loader-img bottompage-loader"><img className="loader-boicon" src={LoaderIcon} alt="loader" /><img src={LoaderSvg} alt="loader" /></div> : ""}
		</>
	);
}

export default Dashboard;